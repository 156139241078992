import { Box, Typography } from '@mui/material';

export const Steps = (pathname) => {
  const page = pathname === '/app/course-details' || pathname === '/auth/course-details' ? 'course-details' : pathname;
  switch (page) {
    case 'course-details':
      return [
        {
          content: (
            <Box sx={{ margin: '0px 0px -24px' }}>
              <Typography
                variant="h6"
                sx={{
                  // textAlign: 'start',
                  color: 'white',
                  fontSize: '1.1rem',
                  fontWeight: '400',
                  textAlign: 'center',
                }}
              >
                Let's begin keySkillset journey!
              </Typography>
            </Box>
          ),

          locale: {
            // skip: (
            //   <strong aria-label="skip" style={{ backgroundColor: '#f4c79a' }}>
            //     S-K-I-P
            //   </strong>
            // ),
            // prev: (
            //   <strong aria-label="skip" style={{ color: '#FE7000' }}>
            //     {' '}
            //     Back
            //   </strong>
            // ),
            // next: (
            //   <button type="button" style={{ backgroundColor: '#FE7000' }} aria-label="skip">
            //     Next
            //   </button>
            // ),
          },
          placement: 'center',
          target: 'body',
        },

        {
          target: '.firstAssessment',
          content: (
            <Box sx={{ margin: '-20px -10px -35px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '1.2',
                  letterSpacing: '0.89px',
                }}
              >
                When you take the initial IQ assessment your first score will be shown here.
              </Typography>
            </Box>
          ),
          placement: 'top',
          title: (
            <Typography
              variant="h6"
              sx={{ textAlign: 'start', color: 'white', fontSize: '1.1rem', fontWeight: '300' }}
              gutterBottom
            >
              First Assessment
            </Typography>
          ),
        },
        {
          target: '.bestAssessment',
          content: (
            <Box sx={{ margin: '-20px -10px -25px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '1.2',
                  letterSpacing: '0.89px',
                }}
              >
                If you repeat the IQ assessment over time your best assessment will be shown here.
              </Typography>
            </Box>
          ),
          placement: 'top',
          title: (
            <Typography
              variant="h6"
              sx={{ textAlign: 'start', color: 'white', fontSize: '1.1rem', fontWeight: '300' }}
              gutterBottom
            >
              Best Assessment
            </Typography>
          ),
        },
        {
          target: '.takeIQ',
          content: (
            <Box sx={{ margin: '-20px -10px -25px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '1.2',
                  letterSpacing: '0.89px',
                }}
              >
                The initial IQ assessment is there to evaluate your existing knowledge.
              </Typography>
            </Box>
          ),
          placement: 'top',
          title: (
            <Typography
              variant="h6"
              sx={{ textAlign: 'start', color: 'white', fontSize: '1.1rem', fontWeight: '300' }}
              gutterBottom
            >
              Take IQ Assessment
            </Typography>
          ),
        },
        {
          target: '.vedio',
          content: (
            <Box sx={{ margin: '-20px -10px -35px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '1.2',
                  letterSpacing: '0.89px',
                }}
              >
                Watch videos to discover how we enhance your learning experience.
              </Typography>
            </Box>
          ),
          placement: 'top',
          title: (
            <Typography
              variant="h6"
              sx={{ textAlign: 'start', color: 'white', fontSize: '1.1rem', fontWeight: '300' }}
              gutterBottom
            >
              Video
            </Typography>
          ),
        },

        {
          target: '.skillsetIQ',
          content: (
            <Box sx={{ margin: '-20px -10px -25px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '1.2',
                  letterSpacing: '0.89px',
                }}
              >
                We strongly encourage you to take the Skillset IQ at the end of each submodule in order for the overall
                efficiency score to update over time.
              </Typography>
            </Box>
          ),
          placement: 'top',
          title: (
            <Typography
              variant="h6"
              sx={{ textAlign: 'start', color: 'white', fontSize: '1.1rem', fontWeight: '300' }}
              gutterBottom
            >
              Skillset IQ
            </Typography>
          ),
        },

        {
          target: '.overall',
          content: (
            <Box sx={{ margin: '-20px -10px -25px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '1.2',
                  letterSpacing: '0.89px',
                }}
              >
                The purpose of the overall progress bar is to keep track of your progress throughout the course.
              </Typography>
            </Box>
          ),
          placement: 'top',
          title: (
            <Typography
              variant="h6"
              sx={{ textAlign: 'start', color: 'white', fontSize: '1.1rem', fontWeight: '300' }}
              gutterBottom
            >
              Overall Progress
            </Typography>
          ),
        },
        {
          target: '.casestudy',
          content: (
            <Box sx={{ margin: '-20px -10px -25px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '1.2',
                  letterSpacing: '0.89px',
                }}
              >
                After finishing the course's modules,you need to download the Case Study materials, do it offline then
                get 1 attempt to take the test. The passing score is 65% and the test duration is 45 minutes.
              </Typography>
            </Box>
          ),
          placement: 'top',
          title: (
            <Typography
              variant="h6"
              sx={{ textAlign: 'start', color: 'white', fontSize: '1.1rem', fontWeight: '300' }}
              gutterBottom
            >
              Case study
            </Typography>
          ),
        },
        {
          target: '.efficiencyScore',
          content: (
            <Box sx={{ margin: '-20px -10px -25px' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: 'start',
                  fontSize: '14px',
                  color: 'white',
                  lineHeight: '1.2',
                  letterSpacing: '0.89px',
                }}
              >
                The efficiency score depends on the amount of times you repeat the course, the more you repeat it the
                higher your score will be.{' '}
              </Typography>
            </Box>
          ),
          placement: 'top',
          title: (
            <Typography
              variant="h6"
              sx={{ textAlign: 'start', color: 'white', fontSize: '1.1rem', fontWeight: '300' }}
              gutterBottom
            >
              Efficiency Score
            </Typography>
          ),
          locale: {
            last: (
              <strong aria-label="last" style={{ fontWeight: '300' }}>
                Close
              </strong>
            ),
          },
        },
      ];
    case '/app/course-simulation':
      return [
        {
          content: <h2>Let's begin keySkillset journey!</h2>,
          locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
          placement: 'center',
          target: 'body',
        },
        {
          target: '.ms-Excel',
          content: 'This is my awesome feature!',
          // placement: 'bottom',
          placement: 'top',
          title: 'MS Excel',
          // locale: {
          //   next: <strong aria-label="skip">Next..</strong>,
          // },
          // placement: 'center',
          // target: 'body',
        },
        {
          target: '.my-other-step',
          content: 'This another awesome feature!',
          placement: 'top',
          title: 'Our Mission',
        },
        {
          target: '.my-other-stepfour',
          content: 'This another awesome stepfour!',
          placement: 'top',
          title: 'stepfour',
        },
        {
          target: '.my-otherPage',
          content: 'This anotherPage awesome feature!',
          placement: 'top',
          title: 'About Us',
        },
      ];
    default:
      return [];
  }
};

// export const Steps = [
//   {
//     StepOne: {
//       path: '/app/course-details',
//       Steps: [
//         {
//           content: <h2>Let's begin keySkillset journey!</h2>,
//           locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
//           placement: 'center',
//           target: 'body',
//         },
//         {
//           target: '.my-first-step',
//           content: 'This is my awesome feature!',
//           placement: 'bottom',
//           title: 'Lets Begin',
//           locale: {
//             next: <strong aria-label="skip">Next..</strong>,
//           },
//           // placement: 'center',
//           // target: 'body',
//         },
//         {
//           target: '.my-other-step',
//           content: 'This another awesome feature!',
//           placement: 'top',
//           title: 'Our Mission',
//         },
//         {
//           target: '.my-other-stepfour',
//           content: 'This another awesome stepfour!',
//           placement: 'top',
//           title: 'stepfour',
//         },
//         {
//           target: '.my-otherPage',
//           content: 'This anotherPage awesome feature!',
//           placement: 'top',
//           title: 'About Us',
//         },
//       ],
//     },
//     // StepTwo: {
//     //   path: '/app/course-details',
//     //   Steps: [
//     //     {
//     //       content: <h2>Let's begin keySkillset journey!</h2>,
//     //       locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
//     //       placement: 'center',
//     //       target: 'body',
//     //     },
//     //     {
//     //       target: '.my-first-step',
//     //       content: 'This is my awesome feature!',
//     //       placement: 'bottom',
//     //       title: 'Lets Begin',
//     //       locale: {
//     //         next: <strong aria-label="skip">Next..</strong>,
//     //       },
//     //       // placement: 'center',
//     //       // target: 'body',
//     //     },
//     //     {
//     //       target: '.my-other-step',
//     //       content: 'This another awesome feature!',
//     //       placement: 'top',
//     //       title: 'Our Mission',
//     //     },
//     //     {
//     //       target: '.my-other-stepfour',
//     //       content: 'This another awesome stepfour!',
//     //       placement: 'top',
//     //       title: 'stepfour',
//     //     },
//     //     {
//     //       target: '.my-otherPage',
//     //       content: 'This anotherPage awesome feature!',
//     //       placement: 'top',
//     //       title: 'About Us',
//     //     },
//     //   ],
//     // },
//   },
// ];
