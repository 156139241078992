import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Hidden } from '@mui/material';

import NavSection from '../../components/NavSection';
import menuItem from './NavConfig';
import Logo from '../../assets/logo/logo.png';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: 280,
  },
}));

export default function MobileNavbar({ openNav, toggleNavbar }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLogin = useSelector((state) => state.isLogin);
  const userRole = useSelector((state) => (state.userInfo ? state.userInfo.role : null));

  const navItem = [
    {
      title: 'Solutions',
      // path: '/app/dashboard',
      // icon: getIcon('akar-icons:dashboard'),
      children: [
        {
          title: 'Individual Learning Solution',
          // path: '/self-study'
          hrefLink: 'https://www.keyskillset.com/self-study',
        },
        {
          title: 'Business Learning Solutions',
          // path: '/business'
          hrefLink: 'https://www.keyskillset.com/business',
        },
        {
          title: 'Educational Learning Solutions',
          // path: '/higher-education'
          hrefLink: 'https://www.keyskillset.com/higher-education',
        },
      ],
    },
    {
      title: 'Courses',
      // path: '/app/users',
      hrefLink: 'https://www.keyskillset.com/courses',
      // icon: getIcon('ph:users-three-bold'),
      // children: [
      //   { title: 'Excel Efficiency', path: '/excel-efficiency' },
      //   { title: 'Quickbooks', path: '/quickbooks' },
      //   { title: 'Financial Modeling', path: '/financial-modeling' },
      //   { title: 'Powerpoint Speed', path: '/powerpoint' },
      //   { title: 'Explore more', path: isLogin && userRole === 'USER_DTC' ? '/auth/subscribe' : '/explore-course' },
      // ],
    },
    // {
    //   title: 'Course Creation',
    //   path: '/course-creation',
    //   // icon: getIcon('bx:book-reader'),
    // },
    // {
    //   title: 'Pricing',
    //   path: '/pricing',
    //   // icon: getIcon('ic:outline-sell'),
    // },
    {
      title: 'Learning Center',
      // path: '/app/reports',
      hrefLink: 'https://www.keyskillset.com/learning-center',
      // icon: getIcon('bi:bar-chart'),
    },
  ];

  useEffect(() => {
    if (openNav) {
      toggleNavbar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle>
      <Drawer
        anchor={'left'}
        open={openNav}
        onClose={toggleNavbar}
        PaperProps={{
          sx: { width: 280, bgcolor: '#000E3B' },
        }}
      >
        <Box
          p={2}
          m={'auto'}
          mt={4}
          mb={3}
          onClick={() => {
            navigate('/');
            toggleNavbar();
          }}
        >
          <img src={Logo} alt="logo" width="145" style={{ margin: 'auto' }} />
        </Box>
        {isLogin ? (
          <NavSection navConfig={navItem.slice(0, 3)} callBack={toggleNavbar} />
        ) : (
          <NavSection navConfig={navItem} callBack={toggleNavbar} />
        )}
      </Drawer>
    </RootStyle>
  );
}
