import React, { useState } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Link from '@mui/material/Link';
import SnackBar from '../../../components/snackbar/snackbar';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import userServices from '../../../services/clientAdmin/userServices';

export default function CreateAdminForm(props) {
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const importUserDetiles = async (values) => {
    setLoading(true);

    const data = new FormData();
    data.append('userFile', values.userFile);
    try {
      const response = await userServices.importUserDetails(data);
      if (response.ok) {
        props.getUsersDetails();
        setLoading(false);
        setOpenSnackbar(true);
        props.snackBarControl('User details imported succesfully');

        let errorDataArray = [];

        if (response.data.length > 0) {
          errorDataArray = response.data.map((res) => {
            const data = { ...res.error, ...res.row };
            return data;
          });
          props.errorData(errorDataArray, true);
        } else {
          props.errorData(errorDataArray, false);
        }

        props.handleImportModalClose();
      } else if (response.status === 400) {
        setLoading(false);
        setOpenSnackbar(true);
        props.snackBarControl(response.data.message);
        props.handleImportModalClose();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const SUPPORTED_FORMATS = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv',
  ];
  return (
    <div>
      <Formik
        initialValues={{
          userFile: null,
        }}
        validationSchema={Yup.object().shape({
          userFile: Yup.mixed()
            .nullable()
            .required('The Field is Required.')
            .test(
              'fileFormat',
              'Unsupported Format',
              (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type))
            )
            .test('fileSize', 'Maximum file size is 3MB', (value) => value === null || (value && value.size < 3145728)),
        })}
        onSubmit={(values) => {
          importUserDetiles(values);
        }}
      >
        {({ errors, handleBlur, setFieldValue, touched, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Upload File (xl,csv)
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="userFile"
                  onBlur={handleBlur}
                  onChange={(event, value) => {
                    setFieldValue('userFile', event.currentTarget.files[0]);
                  }}
                  type="file"
                  variant="outlined"
                  error={Boolean(touched.userFile && errors.userFile)}
                  helperText={touched.userFile && errors.userFile}
                />
                <ErrorFocus />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'end' }}>
                <Link
                  href="https://keySkillsetbucket.s3.us-east-2.amazonaws.com/userInfo.xlsx"
                  style={{ textDecoration: 'none' }}
                  color="secondary"
                >
                  Download template
                </Link>
              </Grid>

              <Grid item xs={12}>
                <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                  Import User
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <SnackBar open={openSnackbar} close={() => setOpenSnackbar(false)} />
    </div>
  );
}
