import React, { useState } from 'react';
import { Button, Grid, Paper, Typography, Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GaugeChart from 'react-gauge-chart';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactApexChart from 'react-apexcharts';

import { useLocation } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';


import AnalyticsTable from './Analytics';
import analytics from '../../../services/analytics/analytics';
import LottieLoadingApexChart from '../../../components/LottieLoadingApexChart';


export const CoursesAnalyticsTab = ({
  efficiencyScore,
  totalProgress,
  courseAnalytics,
  moduleData,
  learnData,
  loading,
  setEfficiencyScore,
  setTotalProgress,
  setCourseAnalytics,
  setModuleData,
  setLearnData,
  setLoading,
}) => {
  const classes = useStyles();
  const location = useLocation();
  console.log(location);
  const courseId = location.state && location.state.id;
  // const [efficiencyScore, setEfficiencyScore] = useState(0);
  // const [totalProgress, setTotalProgress] = useState(0);
  // const [courseAnalytics, setCourseAnalytics] = useState(null);
  // const [moduleData, setModuleData] = React.useState([]);
  // const [learnData, setLearnData] = React.useState([]);
  // const [loading, setLoading] = React.useState(true);

  const options = {
    responsive: [
      {
        breakpoint: 500,
        options: {
          grid: {
            show: true,
            padding: {
              top: 0,
              right: 0,
              bottom: -30,
              left: 0,
            },
          },
        },
      },
    ],

    grid: {
      padding: {
        top: 0,
        right: 0,
        bottom: -30,
        left: 0,
      },
      show: true,
      borderColor: '#90A4AE',
      strokeDashArray: 0,
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    series: [
      {
        data: moduleData,
      },
    ],
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },

    colors: ['#FF7171', '#FFCF7D', '#43FF68'],
    plotOptions: {
      bar: {
        columnWidth: moduleData.length * 4,
        distributed: true,
        borderRadius: 4,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      theme: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (series) => {
            return '';
          },
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      min: (min) => {
        return 0;
      },
      max: (max) => {
        return 100;
      },
      labels: {
        offsetX: 0,
        offsetY: 0,
        formatter(val) {
          return `${val}`;
        },
        style: {
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
        fill: {
          show: false,
        },
      },
    },
    xaxis: {
      tickPlacement: 'between',
      min: 6,
      max: 6,
      crosshairs: {
        show: false,
        fill: {
          show: false,
        },
      },
      labels: {
        show: true,
        trim: true,
        rotate: -60,
        rotateAlways: true,
        minHeight: 50,
        maxHeight: 150,
        formatter(val) {
          return val.length > 0 ? `${val}` : '';
        },
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  const learnoptions = {
    series: [
      {
        data: learnData,
      },
    ],

    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false,
      },
    },

    colors: ['#FF7171', '#FFCF7D', '#43FF68'],
    plotOptions: {
      crosshairs: {
        show: false,
        fill: {
          show: false,
        },
      },

      bar: {
        horizontal: true,
        barHeight: learnData.length * 7,
        distributed: true,
        borderRadius: 4,
      },
    },
    tooltip: {
      followCursor: false,
      theme: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (series) => console.log(series, 'series'),
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      min: (min) => {
        return 0;
      },
      max: (max) => {
        return 100;
      },

      labels: {
        show: true,
        tickAmount: 1,
        formatter(val) {
          return `${val}`;
        },
        style: {
          fontSize: '12px',
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '12px',
        },
      },
    },
  };

  const getAnalytics = async () => {
    try {
      const response = await analytics.getAnalytics(courseId);
      if (response.ok) {
        setCourseAnalytics(response.data);

        if (response?.data?.overallModulePercentage) {
          const moduleData = response.data.overallModulePercentage.map((res) => {
            return {
              x: res.name,
              y: res.overallModulePercentage,
            };
          });
          // setModuleData(moduleData);
          // console.log(moduleData);
          setLoading(false);
        }
        if (response?.data?.learnerProgress) {
          const learnData = response.data.learnerProgress.map((res) => {
            return {
              x: res.name,
              y: res.courseProgress,
            };
          });
          setLearnData(learnData);
          setLoading(false);
        }
      }
      if (!response.ok) {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAnalytics();
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box className={classes.boxCard}>
            <Typography variant="subtitle1" className={classes.typopadding} textAlign="center" gutterBottom>
              Number of Learners
            </Typography>
            {courseAnalytics ? (
              <Box className={classes.number}>
                <Typography variant="h6" className={classes.typonumber}>
                  {courseAnalytics.numberOfLearners}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ margin: 'auto', textAlign: 'center', width: '32%' }}>
                <Typography variant="h6" className={classes.typonumber}>
                  <Skeleton
                    variant="rectangular"
                    // width={100}
                    height={49}
                    sx={{ marginTop: '20px', borderRadius: '10px' }}
                  />
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className={classes.boxCarLorem}>
            <Typography variant="subtitle1" className={classes.typopadding} textAlign="center" gutterBottom>
              Overall Course Progress
            </Typography>
            <Box className={classes.numberCircular}>
              <CircularProgressbar
                // value={parseInt(courseDetails.completed, 10)}
                value={Math.round(totalProgress)}
                text={`${Math.round(totalProgress)}%`}
                className={classes.progress}
                styles={buildStyles({
                  textColor: '#00B673',
                  pathColor: '#FE7000',
                  trailColor: '#fe700036',
                })}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className={classes.boxCarLoremAverage}>
            <Typography variant="subtitle1" className={classes.typopadding} textAlign="center" gutterBottom>
              Average Efficiency
            </Typography>
            <Box className={classes.numberMeter}>
              <GaugeChart
                id="gauge-chart1"
                nrOfLevels={4}
                arcWidth={0.3}
                percent={0.3}
                animate={false}
                textColor="#00b673"
                cornerRadius={0}
                arcPadding={0}
                formatTextValue={() => `${efficiencyScore}x`}
                colors={['#e5eaec', '#92d4b5', '#59bd91', '#1da86b']}
                // hideText
                needleColor="#d8dcdd"
                needleBaseColor="#d8dcdd"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {loading ? (
            <div className={classes.apexchartWidth}>
              <LottieLoadingApexChart loading={loading} />
            </div>
          ) : null}
          <Box className={classes.boxshodowone}>
            <Typography variant="h6" className={classes.typoPadding} gutterBottom>
              Progress by Module
            </Typography>
            <ReactApexChart options={options} series={options.series} type="bar" height={420} />
          </Box>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={12}>
            <Box className={classes.boxshodowone}>
              {loading ? (
                <div className={classes.apexchartWidth}>
                  <LottieLoadingApexChart loading={loading} />
                </div>
              ) : null}
              <Typography variant="h6" className={classes.typoPadding} gutterBottom>
                Learners Progress
              </Typography>

              <ReactApexChart options={learnoptions} series={learnoptions.series} type="bar" height={420} />
            </Box>
          </Grid> */}
        <Grid item xs={12}>
          <Box className={classes.boxshodowone} mt={2}>
            <AnalyticsTable
              courseId={courseId}
              getEfficiencyScore={(efficiency, courseProgress, graphData) => {
                console.log(efficiency, courseProgress, 'courseProgress');
                setEfficiencyScore(efficiency);
                setTotalProgress(Math.round(courseProgress));
                setModuleData(graphData);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  apexchartWidth: {
    width: '74%',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    height: '450px',
    [theme.breakpoints.down('md')]: {
      width: '45%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  boxshodowone: {
    boxShadow: '0 2px 3px 0 rgb(32 40 45 / 8%)',
    borderRadius: '12px',
    marginTop: '1rem',
  },
  typoPadding: {
    padding: '1rem 0rem 0rem 1rem',
    fontWeight: '500',
  },
  boxCard: {
    height: '180px',
    background: '#D1EDC0 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLorem: {
    height: '180px',
    background: '#FFE7AB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLoremAverage: {
    height: '180px',
    background: '#FEEADB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLoremMeter: {
    height: '180px',
    background: '#FEEADB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },

  typopadding: {
    paddingTop: '2rem',
    fontSize: '1rem',
  },
  number: {
    marginTop: '30px',
    margin: 'auto',
    textAlign: 'center',
    width: '30%',
    background: '#FFFFFF 0% 0% no-repeat ',
    borderRadius: '10px',
    opacity: 1,
  },
  numberCircular: {
    margin: 'auto',
    textAlign: 'center',
    marginTop: '0.5rem',
  },
  numberMeter: {
    margin: 'auto',
    textAlign: 'center',
    width: '70% ',
    height: '196px',
    [theme.breakpoints.up('xl')]: {
      width: '60% !important',
      height: '196px',
    },
  },

  typonumber: {
    padding: '6px',
    fontSize: '1.5rem',
    fontWeight: '700',
  },
  progress: {
    width: '100%',
    height: '95px',
    marginBottom: '0.9rem',
  },
  textBox: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    textAlign: 'center',
    background: '#eee',
    border: '1px solid #dce0e4',
    borderRadius: '4px',
    padding: '4px',
  },
}));
