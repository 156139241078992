import React, { useState, useEffect } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import PhoneNumber from '../../../../components/PhoneNumber/Index';
import '../../../../components/PhoneNumber/styles.css';
import ClinetAdminServices from '../../../../services/clientAdmin/course/clientAdminServices';
import adminServices from '../../../../services/adminServices';

export default function AddUser(props) {
  const classes = useStyles();
  const [coursePlan, setCoursePlan] = useState([]);
  const [userList, setUserList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [mode, setMode] = useState('user');
  const [selectUser, setSelectUser] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  console.log(errorMessage, 'errorMessage');

  const [userFormData, setUserFormData] = useState({
    selectUser: [],
    // selectCoursePlan: '',
  });

  const [initialValue, setInitialValue] = useState({
    FirstName: '',
    lastName: '',
    phone: '',
    email: '',
    // selectCoursePlan: '',
    formMode: 'user',
  });

  // const getCoursePlan = async () => {
  //   try {
  //     const result = await ClinetAdminServices.getCoursePlans(props.courseId);
  //     if (result.ok) {
  //       setCoursePlan(result.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getUserLists = async () => {
    try {
      const items = await ClinetAdminServices.getUserList(props.coursePlanId);
      if (items.ok) {
        setSelectUser(items.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalValues = async (values) => {
    setLoading(true);
    const array = [];
    console.log(array, 'values.selectUser');
    array.push(values.selectUser.map((res) => JSON.stringify(res.id)));
    const courseData = {
      planId: props.subscriptionId,
      userIds: array[0],
    };
    try {
      const data = await ClinetAdminServices.enrollUserToCourse(props.clientPlanId, props.coursePlanId, courseData);
      if (data.ok) {
        // setOpenSnackbar(true);
        // props.snackBarControl(data.data.message);
        props.setOpenAlertModal(true);
        props.setErrorMessage(data.data);
        props.closeModel();
        props.getSubscription();
        props.getEnrolledUserById();
      } else {
        setOpenSnackbar(true);
        props.snackBarControl(data.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleNewUserValues = async (values) => {
    setLoading(true);
    const formData = {
      firstName: values.FirstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone.toString(),
      planId: props.subscriptionId,
    };
    try {
      const res = await ClinetAdminServices.enrollNewUser(props.clientPlanId, props.coursePlanId, formData);
      if (res.ok) {
        // setOpenSnackbar(true);
        // props.snackBarControl(res.data.message);
        props.setOpenAlertModal(true);
        props.closeModel();
        props.getSubscription();
        props.getEnrolledUserById();
      } else {
        setOpenSnackbar(true);
        props.snackBarControl(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getUserLists();
  }, []);

  return (
    <div>
      {mode === 'user' && (
        <Formik
          initialValues={userFormData}
          validationSchema={Yup.object().shape({
            selectUser: Yup.string().nullable().trim().required('Required'),
            // selectCoursePlan: Yup.string().nullable().trim().required('Required'),
          })}
          onSubmit={(values) => {
            handleModalValues(values);
          }}
        >
          {({ errors, handleBlur, handleChange, setFieldValue, touched, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs="12" sm="12" md="12">
                  <Typography variant="subtitle1" gutterBottom>
                    Select User*
                  </Typography>
                  <Autocomplete
                    fullWidth
                    multiple
                    disableCloseOnSelect
                    options={selectUser}
                    autoHighlight
                    getOptionLabel={(option) => option.email || ''}
                    value={values.selectUser}
                    onChange={(e, value) => {
                      setFieldValue('selectUser', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="selectUser"
                        error={Boolean(touched.selectUser && errors.selectUser)}
                        helperText={touched.selectUser && errors.selectUser}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ color: '#BCBCBC' }}>OR</Divider>
                </Grid>
                <ThemeProvider theme={customeTheme}>
                  <Grid align="center" item xs={12}>
                    <FormControlLabel
                      label="Create New User"
                      name="formMode"
                      variant="outlined"
                      value={values.formMode}
                      onChange={(e, value) => {
                        setMode('newUser');
                      }}
                      control={<Radio sx={{ display: 'none' }} />}
                    />
                  </Grid>
                </ThemeProvider>
                <LoadingButton
                  sx={{ mt: 2 }}
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Add
                </LoadingButton>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      {mode === 'newUser' && (
        <Formik
          initialValues={initialValue}
          validationSchema={Yup.object().shape({
            // selectCoursePlan: Yup.string().trim().nullable().required('Required'),
            FirstName: Yup.string()
              .trim()
              .nullable()
              .required('Required')
              .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
              .min(3, 'First Name must required 3 characters'),
            lastName: Yup.string()
              .trim()
              .nullable()
              .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field'),
            phone: Yup.string()
              .trim()
              .nullable()
              .min(11, 'Phone Number must be at least 10 characters')
              .max(12, 'Phone Number must be with in 12 characters')
              .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number'),
            // .required('Phone Number required field'),
            email: Yup.string().trim().nullable().required('Email is required field').email('Invalid email address'),
          })}
          onSubmit={(values) => {
            handleNewUserValues(values);
          }}
        >
          {({ errors, handleBlur, handleChange, setFieldValue, touched, values }) => (
            <Form>
              <Grid container spacing={2} mb={3}>
                <ThemeProvider theme={customeTheme}>
                  <Grid align="center" item xs={12}>
                    <FormControlLabel
                      label="Enroll User"
                      name="formMode"
                      variant="outlined"
                      value={values.formMode}
                      onChange={(e, value) => {
                        setMode('user');
                      }}
                      control={<Radio sx={{ display: 'none' }} />}
                    />
                  </Grid>
                </ThemeProvider>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    First Name*
                  </Typography>
                  <TextField
                    fullWidth
                    name="FirstName"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.FirstName && errors.FirstName)}
                    helperText={touched.FirstName && errors.FirstName}
                    value={values.FirstName}
                    inputProps={{ maxLength: 180 }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="lastName"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    value={values.lastName}
                    inputProps={{ maxLength: 180 }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Email*
                  </Typography>
                  <TextField
                    fullWidth
                    name="email"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    value={values.email}
                    inputProps={{ maxLength: 180 }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Phone Number
                  </Typography>
                  <PhoneNumber fullWidth country={'us'} specialLabel={''} />
                </Grid>
              </Grid>
              <LoadingButton sx={{ mt: 2 }} fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                Add
              </LoadingButton>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  typoFontWeight: {
    fontSize: '1rem;',
    fontWeight: 600,
  },
}));
const customeTheme = createTheme({
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '20px',
          fontWeight: 'bold',
          borderBottom: '2px dotted green',
        },
      },
    },
  },
});
