import React, { useEffect, useState } from 'react';
import { Typography, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import adminServices from '../../../../services/adminServices';
import Iconify from '../../../../components/Iconify';
import DeleteAlert from '../../../../components/modal/DeleteModal';
import SnackBar from '../../../../components/snackbar/snackbar';

export default function User(props) {
  // console.log(props.data);
  const classes = useStyles();
  const location = useLocation();
  const { from } = location.state;

  const [loading, setLoading] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [userId, setuserId] = React.useState();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [checked, setChecked] = React.useState(false);
  const [userList, setUserList] = useState(props.data);

  const handleModal = (id) => {
    setDeleteAlert(true);
    setuserId(id);
  };

  useEffect(() => {
    setUserList(props.data);
  }, [props.data]);

  const handleDelete = async () => {
    try {
      const result = await adminServices.deleteUserDetails(from, userId);
      if (result.ok) {
        props.getUserLists();
        setDeleteAlert(false);
        setOpenSnackbar(true);
        setSnackbarTitle(result.data.message);
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle(result.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitch = async (data, id, value) => {
    setUserList(
      userList.map((item) => {
        if (item.id === data[0]) {
          item.status = data[3] === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
          return item;
        }
        return item;
      })
    );

    try {
      const res = await adminServices.handleUserStatus(from, data[0], {
        status: data[3] === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      });
      if (res.ok) {
        props.getUserLists();
        setOpenSnackbar(true);
        setSnackbarTitle(res.data.message);
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: 'id',
      label: 'User ID',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta);
          return (
            <>
              <div style={{ display: 'flex' }}>
                <Typography>{value}</Typography>
                {tableMeta.rowData[4] === 'CLIENT_ADMIN' && (
                  <Iconify icon={'mdi:crown'} width={16} height={14} color="orange" />
                )}
              </div>
            </>
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <FormControlLabel
                checked={value === 'ACTIVE'}
                value={value}
                onClick={() => {
                  handleSwitch(tableMeta.rowData);
                }}
                control={<Switch color="secondary" {...label} />}
              />
            </>
          );
        },
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'course',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const subscriptions =
            value &&
            value.map((item) => {
              // console.log(item);
              return (
                <>
                  <Typography gutterBottom style={{ fontSize: '14px' }}>
                    {`${item.title}`}
                  </Typography>
                </>
              );
            });
          return (
            <>
              <div className={classes.div}>
                <div className={classes.subscription}>
                  {value && value !== null ? (
                    <ThemeProvider theme={customeTheme}>
                      <Tooltip title={subscriptions} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}>
                        <Typography
                          style={{ color: 'white', fontSize: '0.9rem' }}
                        >{`(${value.length}) Enrolled Courses`}</Typography>
                      </Tooltip>
                    </ThemeProvider>
                  ) : (
                    <Typography style={{ color: 'white', fontSize: '0.9rem' }}>{`(0) Enrolled Courses`}</Typography>
                  )}
                </div>
                <IconButton
                  onClick={() => {
                    handleModal(tableMeta.rowData[0]);
                  }}
                  size="small"
                >
                  <DeleteIcon fontSize="small" style={{ color: '#BCBCBC' }} />
                </IconButton>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <div style={{ marginTop: '1rem' }}>
      <MUIDataTable
        data={props.data}
        columns={columns}
        options={{
          rowsPerPage: 25,
          responsive: 'scroll',
          rowsPerPageOptions: [25, 50, 75, 100],
          selectableRows: false,
          filter: false,
          download: false,
          print: false,
          textLabels: {
            body: {
              noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
            },
          },
        }}
      />
      <DeleteAlert
        open={deleteAlert}
        title="Are you sure you want delete this User"
        confirm={handleDelete}
        close={() => setDeleteAlert(false)}
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  subscription: {
    backgroundColor: '#747474',
    borderRadius: '4px',
    padding: '3px 6px ',
    marginRight: '7px',
    minWidth: '123px',
  },
  div: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  arrow: {
    color: '#FE7000',
  },
  tooltip: {
    backgroundColor: '#FE7000',
    color: '#FFFFFF',
  },
}));

const customeTheme = createTheme({
  components: {
    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       color: '#FFFFFF',
    //       backgroundColor: '#FE7000',
    //     },
    //   },
    // },
  },
});
