import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import DialogModal from '../../../components/modal/DialogModal';

import adminServices from '../../../services/adminServices';


function MoveSubModule({ open, modules, from, submoduleId, onClose, onSubmit }) {  
  const [loading, setLoading] = useState(false);
  const [selectedModule, setSelectedModule] = useState('');
  const [filteredModules, setFilteredModules] = useState(modules);

  MoveSubModule.propTypes = {
    open: PropTypes.bool,
    modules: PropTypes.array,
    from: PropTypes.number,
    submoduleId: PropTypes.number,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
  };

  useEffect(() => {
    
      setLoading(false)
      setSelectedModule('')
      setFilteredModules(modules.filter(mod => mod.id !== from))
    
  }, [open]);
     
  const handleSubmit = async (module) => {

    console.log('new module...',module)
    try {
      setLoading(true);
      const result = await adminServices.updateSubModuleParent(from, module, submoduleId);
      if (result.ok) {
          setLoading(false)
          onSubmit();
      } else {
        console.log('Error moving sub module:', result.error);
        onClose()
      }
      
    } catch (error) {
      console.error('Error moving sub module:', error);
    } 

    
    
  };

  return (
    <DialogModal open={open} handleClose={onClose} title="Move Sub Module to Another Module">    
    
    
      <form>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Select Module
        </Typography>
        
        <Select
          value={selectedModule}
          fullWidth      
          onChange={(e) => setSelectedModule(e.target.value)}
          disabled={loading}    
        >
          {filteredModules.map((module) => (
           
            <MenuItem key={module.id} value={module.id}>
              {module.courseModuleName}
            </MenuItem>
          ))}
        </Select>
  

       
        {selectedModule && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <LoadingButton type="submit" onClick={() => handleSubmit(selectedModule)} variant="contained" color="primary" fullWidth loading={loading}>
                    Submit
            </LoadingButton>
          </div>
        )}
      </form>
      
    </DialogModal>

  );
}

export default MoveSubModule;
