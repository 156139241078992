/* eslint-disable */
import React, { useEffect, useState,useRef ,useLayoutEffect} from 'react';
import {
  TabContext,
  TabList,
  TabPanel
} from '@mui/lab';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Fade,
  Grid,
  IconButton,
  Link,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import 'react-circular-progressbar/dist/styles.css';
import 'react-html5video/dist/styles.css';
import { DefaultPlayer as Video } from 'react-html5video';
import { Icon } from '@iconify/react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import GaugeChart from 'react-gauge-chart';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Skeleton from '@mui/material/Skeleton';
import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { IKContext, IKImage, IKVideo } from 'imagekitio-react';

import SlideshowIcon from '@mui/icons-material/Slideshow';

import './index.css';
import background from '../../../assets/logo/cardBackground.png';
import BasicModal from '../../../components/modal/BasicModel';
import ButtonAnimation from '../../../components/ButtonAnimation';
import caseStudyService from '../../../services/caseStudy/caseStudyService';
import courseApi from '../../../services/users/courseApi';
import Iconify from '../../../components/Iconify';
import iqImage from '../../../assets/logo/iqImage.png';
import iqServices from '../../../services/iqTest/index';
import Page from '../../../components/Page';
import palette from '../../../theme/palette';
import ImageKit from '../../../settings';
import { openSubscriptionModal, setOpenSubscriptionModalDetails } from '../../../store/reducer';
import SearchBar from './SearchBar';
import ListPage from './ListPage';
import { blue } from '@mui/material/colors';
import adminServices from 'src/services/adminServices';
import ReferenceScreen from '../../course/ReferenceScreen';

const LinkBtn = React.forwardRef((props, ref) => {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
});

export default function UserCourseDetails(props) {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  const testrole = useSelector((state) => state);
  const courseDetails = location.state;
  const [caseStudy, setCaseStudy] = useState([]);
  const [courseModules, setCourseModules] = useState([]);
  const [subscription, setSubscription] = useState({});

  const [courseProgress, setCourseProgress] = useState(null);
  const [csTestResult, setCsTestResult] = useState(null);
  const [csTestLoading, setCsTestLoading] = useState(false);
  const storedExpandedItem = localStorage.getItem('expandedItem');
  const initialExpanded = storedExpandedItem !== null ? Number(storedExpandedItem) : null;
  const storedSubExpandedItem = localStorage.getItem('subModuleExpandedItem');
  const initialSubmoduleExpanded = storedSubExpandedItem !== null ? Number(storedSubExpandedItem) : null;
  const [expanded, setExpanded] = React.useState(initialExpanded);    
  const [isWantMoreText, setIsWantMoreText] = useState(true);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [openResult, setOpenResult] = useState(false);
  const [resumeData, setResumeData] = useState(null);
  const [subModuleExpanded, setSubModuleExpanded] = React.useState(initialSubmoduleExpanded);
  const [csConfig, setCsConfig] = useState(null);
  const [efficiencyVal, setEfficiencyVal] = useState(10);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoTitle, setVideoTitle] = useState({
    title: '',
    video: null,
  });

  const [searchResults,setSearchResults] = useState([]);
  const [searchComplete,setSearchComplete] = useState(false);

  const [appliedFilter,setAppliedFilter] = useState('All');
  const [allBlocks,setAllBlocks] = useState([]);
  const [simBlocks,setSimBlocks] = useState([]);
  const [vidBlocks,setVidBlocks] = useState([]);

  const [slice,setSlice] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#00B673' : '#00B673',
    },
  }));

  const SubcriptionEnable = (data) => {
    return (
      <Paper elevation={3}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
          <div className={classes.renewContent}>
            <Typography style={{ fontWeight: 'bold', fontSize: '18px' }} component="h2">
              {data.title}
            </Typography>
            <Typography> {data.description}</Typography>
          </div>
          <div className={classes.renewContent}>
            {userRole === 'USER_DTC' && (
              <>
                {subscription?.planStatus?.toLowerCase() === 'expired' ? (
                  <Button variant="contained" onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}>
                    {subscription?.isTrial && 'Subscribe'}
                    {subscription?.isTrial === false && 'Renew'}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.subscribe}
                    onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}
                  >
                    Subscribe
                  </Button>
                )}
              </>
            )}
            {userRole === 'AUTH_USER' &&
              subscription?.isSubscribed &&
              subscription?.planStatus?.toLowerCase() === 'active' && (
                <Button
                  variant="contained"
                  className={classes.subscribe}
                  onClick={() => postSubscribeDetails(subscription?.subscriptionPlanId)}
                >
                  {subscription?.planStatus?.toLowerCase()}
                </Button>
              )}
          </div>
        </div>
      </Paper>
    );
  };


  const [isQuery,setIsQuery] = useState('');
  const getFilterData = (masterData) => {
    const aBlocks = [];
    const sBlocks = [];
    const vBlocks = [];
    masterData.forEach(res =>
    {
      if (typeof(res.subModule) !== undefined && res.subModule!=null) {
        // console.log('subModule.....',res.subModule);
        res.subModule.forEach(sub =>
        {
          aBlocks.push({
            type: 'v',
            sub: sub.courseSubmoduleName,
            mod: res.courseModuleName,
            video: sub.video,
            id: sub.id
          });
          vBlocks.push({
            type: 'v',
            sub: sub.courseSubmoduleName,
            mod: res.courseModuleName,
            video: sub.video,
            id: sub.id
          });
          if (typeof(sub.subModuleTopics) !== undefined && sub.subModuleTopics!=null) {
            sub.subModuleTopics.forEach(topic =>
            {
              aBlocks.push({
                type: 's',
                topic: topic.courseSubmoduleTopics,
                sub: sub.courseSubmoduleName,
                mod: res.courseModuleName,
                tid: topic.id,
                sid: sub.id
              });
              sBlocks.push({
                type: 's',
                topic: topic.courseSubmoduleTopics,
                sub: sub.courseSubmoduleName,
                mod: res.courseModuleName,
                tid: topic.id,
                sid: sub.id
              });
            });
          }
        });
      }
    });

    setAllBlocks(aBlocks);
    setSimBlocks(sBlocks);
    setVidBlocks(vBlocks);
    setSlice(aBlocks);
    // setSlice({"All":aBlocks,"Sim":sBlocks,"Vid":vBlocks,"Rtc":[]})
    setSearchResults(slice);
  };

 ;

//  const handleReferenceClick = (id,name) => {
//   console.log("inside handleReferenceClick......",id,name);
//   if (userRole === 'USER_DTC') {
//     console.log("userRole === 'USER_DTC...");
//     navigate(`/auth/reference-screen?id=${id}&courseSubmoduleName=${name}`, {
//       replace: true,
//       state: { id,name},
//     });
//   } else {
//     console.log("userRole...2");
//     navigate(`/app/reference-screen?id=${id}&courseSubmoduleName=${name}`, {
//       replace: true,
//       state: { id,name },
//     });
//   }

//  };


  const getCourseDetails = async () => {
        try {
      const response = await courseApi.getCourseModulesAndSubmodules(
        courseDetails.id,
        courseDetails.subscriptionPlanId
      );
      if (response.ok) {
        setCourseModules(response?.data?.course);
        setCaseStudy(response?.data?.files);
        setSubscription(response?.data?.subscription);
        if (!response?.data?.subscription?.isTrial && response?.data?.subscription?.isSubscribed) {
          if (
            response?.data?.subscription?.planStatus?.toLowerCase() === 'expired' ||
            response?.data?.subscription?.planStatus?.toLowerCase() === 'cancel'
          ) {
            if (userRole === 'USER_DTC') {
              postSubscribeDetails(response?.data?.subscription?.subscriptionPlanId);
            }
          }
        }
        getFilterData(response?.data?.course);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (courseModules && courseModules.length > 0) {
      getFilterData(courseModules);
    }
  }, [courseModules]);

  const getLastCompletedDetails = async () => {
    const res = await courseApi.getUserLastCompletedDetails(courseDetails.id);
    if (res.ok) {
      setResumeData(res.data);
    }
  };

  const getCourseProgress = async () => {
    const iqResponse = await iqServices.getIqAndCourseProgress(courseDetails.id);
    if (iqResponse.ok) {
      setCourseProgress(iqResponse.data);

      if (parseFloat(iqResponse.data.efficiencyScore) >= 10 && parseFloat(iqResponse.data.efficiencyScore) < 100) {
        setEfficiencyVal(100);
      } else if (
        parseFloat(iqResponse.data.efficiencyScore) >= 100 &&
        parseFloat(iqResponse.data.efficiencyScore) < 1000
      ) {
        setEfficiencyVal(1000);
      } else if (
        parseFloat(iqResponse.data.efficiencyScore) >= 1000 &&
        parseFloat(iqResponse.data.efficiencyScore) < 10000
      ) {
        setEfficiencyVal(10000);
      }
    }
  };

  const postSubscribeDetails = async (planId) => {
    dispatch(setOpenSubscriptionModalDetails(courseDetails));
    dispatch(openSubscriptionModal(planId));
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: expanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const ExpandMoreSub = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: subModuleExpanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  useEffect(() => {
    getCourseDetails();
    getLastCompletedDetails();
    getCourseProgress();
    getCaseStudyResult();
    getCaseStudyConfig();
  }, []);

  const getCaseStudyResult = () => {
    try {
      caseStudyService.getCaseStudyResult(courseDetails.id).then((res) => {
        if (res.ok) {
          if (Object.keys(res.data).length > 0) {
            setCsTestResult(res.data);
          }
          setCsTestLoading(true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCaseStudyConfig = () => {
    caseStudyService.getCaseStudyConfig(courseDetails.id).then((response) => {
      if (response.ok && response.status === 200) {
        if (Object.keys(response.data).length > 0) {
          setCsConfig(response.data);
        }
      }
    });
  };


  const moduleRefs = useRef([]);
  const subModuleRefs = useRef([]);

  useLayoutEffect(() => {
  if (initialExpanded >= 0 && initialExpanded < courseModules.length) {
    const moduleElement = moduleRefs.current[initialExpanded];
    if (moduleElement) {
      moduleElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      console.warn('Module ref not found at index:', initialExpanded);
    }
  } else {
    console.warn('Invalid initialExpanded index:', initialExpanded);
  }

  if (initialExpanded >= 0 && initialExpanded < courseModules.length) {
    const subModuleList = subModuleRefs.current[initialExpanded] || [];
    if (initialSubmoduleExpanded >= 0 && initialSubmoduleExpanded < subModuleList.length) {
      const subModuleElement = subModuleList[initialSubmoduleExpanded];
      if (subModuleElement) {
        subModuleElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        console.warn('Submodule ref not found at index:', initialSubmoduleExpanded);
      }
    } else {
      console.warn('Invalid initialSubmoduleExpanded index:', initialSubmoduleExpanded);
    }
  }
}, [initialExpanded, initialSubmoduleExpanded, courseModules]); 

const handleExpandClick = (i) => {
  if (expanded === i) {
    setExpanded(-1);
    setSubModuleExpanded(null);
    localStorage.setItem('expandedItem', -1);
  } else {
    setSubModuleExpanded(null);
    localStorage.setItem('subModuleExpandedItem', -1);
    setTimeout(() => {
      setExpanded(i);
      localStorage.setItem('expandedItem', i);
    }, 300); 
  }
};

const handleSubModuleExpandClick = (i) => {
  const isExpandingSameSubModule = subModuleExpanded === i;

  setSubModuleExpanded(isExpandingSameSubModule ? -1 : i);

  setTimeout(() => {
    if (!isExpandingSameSubModule) {
      setSubModuleExpanded(i); 
    }
  }, 300); 

  localStorage.setItem('subModuleExpandedItem', isExpandingSameSubModule ? -1 : i);
};

  const handleStartSimulation = (subModuleId, module, resumeClic = false) => {
    // alert('resumne'); simulation-preview
    if (resumeData && resumeClic) {
      if (userRole === 'USER_DTC') {
        navigate(
          `/auth/course-simulation?module=${resumeData.Modulename}&id=${subModuleId}&topic=${resumeData.courseSubModuleTopicId}&screen=${resumeData.courseSubModuleTopicScreenId}`,
          { replace: true, state: courseDetails }
        );
      } else {
        navigate(
          `/app/course-simulation?module=${resumeData.Modulename}&id=${subModuleId}&topic=${resumeData.courseSubModuleTopicId}&screen=${resumeData.courseSubModuleTopicScreenId}`,
          { replace: true, state: courseDetails }
        );
      }
    } else {
      if (userRole === 'USER_DTC') {
        navigate(`/auth/course-simulation?id=${subModuleId}&module=${module}`, { replace: true, state: courseDetails });
      } else {
        navigate(`/app/course-simulation?id=${subModuleId}&module=${module}`, { replace: true, state: courseDetails });
      }
    }
  };

  const handleIQstart = (type) => {
    const keyType = { actionType: courseModules[0]?.subModule[0]?.subModuleTopics[0]?.actionType };
    if (type === 'COURSE') {
      if (userRole === 'USER_DTC') {
        navigate(`/auth/skilliq-test?courseId=${courseDetails.id}`, {
          replace: true,
          // state: { ...courseDetails, ...keyType,subModule },
          state: { ...courseDetails, ...keyType },

        });
      } else {
        navigate(`/app/skilliq-test?courseId=${courseDetails.id}`, {
          replace: true,
          state: { ...courseDetails, ...keyType},

          // state: { ...courseDetails, ...keyType ,subModule},
        });
      }
    }
  };

  const handleIQstartVideo = (type) => {
    console.log(courseModules,"courseModules",type);
    const keyType = 'VIDEO';
    console.log(keyType,"keyTypekeyType");

  };

  async function downloadURI(url, filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  const fileIcon = [
    { type: 'image/jpeg', icon: 'ant-design:file-image-outlined' },
    { type: 'image/png', icon: 'ant-design:file-image-outlined' },
    { type: 'image/jpg', icon: 'ant-design:file-image-outlined' },
    { type: 'image/svg+xml', icon: 'ant-design:file-image-outlined' },
    { type: 'application/pdf', icon: 'fa6-regular:file-pdf' },
    { type: 'video/mp4', icon: 'la:file-video-solid' },
    { type: 'text/csv', icon: 'teenyicons:csv-outline' },
    { type: 'application/octet-stream', icon: 'teenyicons:xls-outline' },

    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: 'teenyicons:xls-outline' },
  ];

  const takeCaseStudyTestHandle = () => {
    if (courseProgress && parseInt(courseProgress.overallProgress, 10) < 100) {
      swal({
        // title: 'Good job!',
        text: 'You must complete 100% to be eligible to take the test',
        // icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
          // cancel: {
          //   text: 'Cancel',
          //   value: null,
          //   visible: true,
          //   className: '',
          //   closeModal: true,
          // },
        },
        closeOnClickOutside: false,
      });
    } else {
      swal({
        // title: 'Good job!',
        text: ' You have only one chance to pass the test. Please make sure that you have prepared well',
        // icon: 'success',
        buttons: {
          confirm: {
            text: 'Okay',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
          cancel: {
            text: 'Cancel',
            value: null,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
      }).then((res) => {
        if (res) {
          const keyType = { actionType: courseModules[0]?.subModule[0]?.subModuleTopics[0]?.actionType };
          navigate(
            userRole === 'USER_DTC'
              ? `/auth/case-studyiq-test?courseId=${courseDetails.id}`
              : `/app/case-studyiq-test?courseId=${courseDetails.id}`,
            {
              replace: true,
              state: { ...courseDetails, ...keyType,subModule},
            }
          );
        }
      });
    }
  };

  const CaseStudyHtml = () => {
    return (
      <>
        {csConfig?.status === 'ACTIVE' && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={9}>
              <Paper elevation={2} sx={{ padding: '1.4rem', marginTop: '1rem' }}>
                <Box className={classes.courserContent}>
                  <Typography variant="h6" className="casestudy">
                    Case Study
                  </Typography>
                </Box>

                {caseStudy.length === 0 && (
                  <Typography align="center" variant="subtitle1">
                    Files are not available
                  </Typography>
                )}

                {caseStudy.map((res, index) => (
                  <Box className={classes.fileList}>
                    <Link underline="none" variant="subtitle1" sx={{ display: 'flex' }} href={res.url} target="_blank">
                      {fileIcon.map((item) => {
                        return <>{res.type === item.type ? <Iconify icon={item.icon} width={20} height={20} /> : ''}</>;
                      })}
                      &nbsp; {res.name}
                    </Link>

                    {/* <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
                  <CloudDownloadIcon color="primary" />
                </IconButton> */}

                    {res?.type === 'image/jpeg' ||
                    res?.type === 'image/png' ||
                    res?.type === 'image/jpg' ||
                    res?.type === 'image/svg+xml' ||
                    res?.type === 'application/pdf' ||
                    res?.type === 'video/mp4' ? (
                      <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
                        <CloudDownloadIcon color="primary" />
                      </IconButton>
                    ) : (
                      <Link
                        underline="none"
                        variant="subtitle1"
                        sx={{ display: 'flex' }}
                        href={res.url}
                        target="_blank"
                      >
                        <IconButton size="small">
                          <CloudDownloadIcon color="primary" />
                        </IconButton>
                      </Link>
                    )}
                  </Box>
                ))}
                {/* 
            <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
              <CloudDownloadIcon color="primary" />
            </IconButton>
          </Box>
        ))} */}

                {csTestLoading && (
                  <Box mt={3}>
                    {csTestResult ? (
                      <Button variant="contained" color="primary" onClick={() => setOpenResult(true)}>
                        View result
                      </Button>
                    ) : (
                      <>
                        {csConfig?.status === 'ACTIVE' && (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              takeCaseStudyTestHandle();
                            }}
                          >
                            Take Test
                          </Button>
                        )}
                      </>
                    )}
                  </Box>
                )}

                {/* <Button
          color="primary"
          variant="contained"
          onClick={() => {
            swal({
              // title: 'Good job!',
              text: ' You have only one chance to pass the test. Please make sure that you have prepared well',
              // icon: 'success',
              buttons: {
                confirm: {
                  text: 'Okay',
                  value: true,
                  visible: true,
                  className: '',
                  closeModal: true,
                },
                cancel: {
                  text: 'Cancel',
                  value: null,
                  visible: true,
                  className: '',
                  closeModal: true,
                },
              },
              closeOnClickOutside: false,
            }).then((res) => {
              if (res) {
                navigate(userRole==="USER_DTC"?`/auth/case-studyiq-test?courseId=${courseDetails.id}`:`/app/case-studyiq-test?courseId=${courseDetails.id}`, {
                  replace: true,
                  state: courseDetails,
                });
              }
            });
          }}
        >
          Take Test
        </Button> */}
              </Paper>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Page title={'Course-details'}>
      <Box className={classes.coursecard}>
        <Grid sx={{ alignItems: 'stretch', display: 'flex' }} container spacing={0}>
          <Grid items xs={12} sm={12} md={7} lg={5.5} xl={5.5}>
            <Box>
              {/* <Typography className={classes.coursetitle} gutterBottom variant="h3" sx={{ color: '#fff' }}>
                {courseDetails.title}
              </Typography> */}
              {courseDetails.title && courseDetails.title?.length > 54 ? (
                <Tooltip title={courseDetails.title} arrow>
                  <Typography className={classes.coursetitle} gutterBottom variant="h3" sx={{ color: '#fff' }}>
                    {courseDetails.title}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.coursetitle} gutterBottom variant="h3" sx={{ color: '#fff' }}>
                  {courseDetails.title}
                </Typography>
              )}
              <div style={{ dispaly: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    color: '#fff ! important',
                    backgroundColor: 'transparent ! important',
                  }}
                  variant="body2"
                  className={isWantMoreText ? classes.showMoreText : classes.textcolor}
                  dangerouslySetInnerHTML={{ __html: courseDetails.description }}
                />

                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                  {courseDetails.description && courseDetails.description.length > 950 && (
                    <Typography
                      className={classes.readMore}
                      color="primary"
                      onClick={() => setIsWantMoreText(!isWantMoreText)}
                    >
                      {isWantMoreText ? 'Read more' : 'Read less'}
                    </Typography>
                  )}
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Box
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '6px',
                      width: 'auto',
                      marginTop: '10px',
                    }}
                  >
                    <img className={classes.logo} src={courseDetails.category.categoryImgUrl} alt="logo" width="100%" />{' '}
                  </Box>
                  <Box className={clsx(classes.powerpoint, 'ms-Excel')}>{courseDetails.category.description}</Box>

                  {resumeData && (
                    <Button
                      onClick={() => {
                        console.log(resumeData);
                        handleStartSimulation(resumeData.courseSubModuleId, resumeData.Modulename, true);
                      }}
                      className={clsx(classes.resume, 'resumeCourse')}
                    >
                      Resume Course&nbsp;
                      <PlayCircleIcon fontSize="small" sx={{ color: '#000' }} />
                      {/* <IconButton
                      size="small"
                      sx={{ marginLeft: '6px' }}
                      // onClick={() => handleStartSimulation(resumeData.courseSubModuleId, resumeData.Modulename, true)}
                    >
                      <PlayCircleIcon fontSize="small" sx={{ color: '#000' }} />
                    </IconButton> */}
                    </Button>
                  )}

                  {/* <Typography
                    onClick={() => setOpenContent(true)}
                    sx={{
                      color: '#fff',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      marginLeft: '1rem',
                      marginTop: '0.6rem',
                      '&:hover': {
                        color: '#FE7000',
                      },
                    }}
                    className={clsx('itWorks')}
                  >
                    How it works?
                  </Typography> */}
                </div>
              </div>
            </Box>
          </Grid>
          <Grid items xs={12} sm={12} md={5} lg={6.5} xl={6.5} className={classes.courseImg}>
            <img
              src={courseDetails.coverageImage && courseDetails.coverageImage}
              className={classes.image}
              alt="meetingimg"
            />
          </Grid>
        </Grid>

        {courseDetails.introVideo ? (
          <>
            <Box className={classes.playbutton}>
              <Button
                onClick={handleOpen}
                className={classes.glowing}
                sx={{
                  background: 'unset',
                  '&:hover': {
                    background: 'unset',
                  },
                  '&:after': {
                    background: 'unset !important',
                  },
                }}
              >
                <ButtonAnimation />
              </Button>
            </Box>

            {/* <Box className={classes.playbutton}>
              <Button onClick={handleOpen} className={classes.playbuttonStyle}>
                <Icon icon="bi:play-circle-fill" className={classes.iconifystyle} />
              </Button>
            </Box> */}
            {/* <Box className={classes.playbutton}>
              <Button onClick={handleOpen} className={classes.play}>
                <Icon icon="bi:play-circle-fill" className={classes.playbuttonStyle} />
              </Button>
            </Box> */}

            {/* <div className={classes.playbutton}>
            <Button onClick={handleOpen} className={classes.play}>
              <Icon icon="bi:play-circle-fill" className={classes.iconifystyle} />
              
            </Button>
          </div> */}
          </>
        ) : (
          <div className={classes.playbutton} />
        )}
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          '.css-moysu4': {
            border: 'none ! important',
          },
        }}
      >
        <Fade in={open}>
          {courseDetails.introVideo && (
            <Box className={classes.vediobox}>
              <Video controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                <source src={courseDetails.introVideo} type="video/webm" />
                <track label="English" kind="subtitles" srcLang="en" src={courseDetails.introVideo} default />
              </Video>
            </Box>
          )}
        </Fade>
      </Modal>

      {loading ? (
        <div>
          <Skeleton width="100%" height="100px" />
          <Skeleton />
        </div>
      ) : (
        <Box className={classes.imagebox}>
          <div className={classes.iqbox}>
            <img src={iqImage} alt="iqImage" />
            <Typography sx={{ fontSize: '20px' }}>SKILLSET IQ</Typography>
            <div className={clsx(classes.assesment, 'firstAssessment')}>
              <Typography>First Assessment</Typography>
              <Typography>{courseProgress && courseProgress.firstIq ? courseProgress.firstIq : 'N/A'}%</Typography>
            </div>
            <div className={clsx(classes.assesment, 'bestAssessment')}>
              <Typography>Best Assessment</Typography>
              <Typography>{courseProgress && courseProgress.bestIq ? courseProgress.bestIq : 'N/A'}%</Typography>
            </div>

            {/* {!subscription?.isTrial &&
              (subscription?.planStatus?.toLowerCase() === 'expired' ||
                subscription?.planStatus?.toLowerCase() === 'cancel') && (
                <Button
                  variant="contained"
                  onClick={() => handleIQstart('COURSE')}
                  className={clsx(classes.iqbutton, 'takeIQ')}
                >
                  Take IQ Assessment
                </Button>
              )} */}



            {(userRole === 'AUTH_USER' || userRole === 'USER_DTC') &&
              subscription?.isSubscribed &&
              (subscription?.planStatus?.toLowerCase() === 'active' || subscription?.planStatus?.toLowerCase() === 'expired' ||
              subscription?.planStatus?.toLowerCase() === 'cancel') && courseDetails?.type!=="video" ?(
                
                <Button variant="contained" onClick={() => handleIQstart('COURSE')} className={classes.iqbutton}>
                  Take IQ Assessment
                </Button>
              )
              :
              (
                
                <Button variant="contained"   
                //   onClick={() => {
                //   const path = ['AUTH_USER', 'SUPER_ADMIN', 'CONTENT_WRITER'].includes(userRole) ?
                //     `/app/skilliq-test?subModuleId=2242` :
                //     `/auth/skilliq-test?subModuleId=2242`;
  
                //   navigate(path, { state: { ...location.state } });
                // }} 
                className={classes.iqbutton}
                onClick={() => handleIQstartVideo('COURSE')}
              >
                  Take IQ Assessment
                </Button>
              )
            }
          </div>
        </Box>
      )}
      <BasicModal openModel={openContent} closeModel={() => setOpenContent(!openContent)} title={courseDetails.title}>
        <Typography id="transition-modal-title" variant="subtitle1" component="h2">
          Description
        </Typography>
        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in tortor a ex dictum commodo. Fusce pretium,
          quam a egestas dictum, purus erat sodales nibh, eget sollicitudin urna felis non felis.
        </Typography>
      </BasicModal>

      <Grid container spacing={2} mt={2}>
        {userRole === 'USER_DTC' && (
          <>
            {subscription?.isSubscribed &&
              (subscription?.planStatus?.toLowerCase() === 'cancel' ||
                subscription?.planStatus?.toLowerCase() === 'expired') && (
                <Grid item xs={12}>
                  <SubcriptionEnable
                    title={
                      subscription?.planStatus?.toLowerCase() === 'expired'
                        ? 'Your trial period is expired '
                        : 'Your trial period is cancel'
                    }
                    description={
                      subscription?.planStatus?.toLowerCase() === 'expired'
                        ? 'To continue learning, kindly renew your subscription.'
                        : 'To continue learning, kindly subscription.'
                    }
                  />
                </Grid>
              )}
          </>
        )}

        {subscription?.isTrial && (
          <Grid item xs={12}>
            <SubcriptionEnable
              title={
                subscription?.planStatus?.toLowerCase() !== 'expired'
                  ? 'You are currently using the trial version.'
                  : ' Your trial has expired. Please subscribe to continue learning.'
              }
              description="Subscribe today to unlock the full course."
            />
          </Grid>
        )}

        {userRole === 'AUTH_USER' &&
          subscription?.isSubscribed &&
          subscription?.planStatus?.toLowerCase() === 'cancel' && (
            <Grid item xs={12}>
              <SubcriptionEnable
                title="Your subscription has been cancelled."
                description="Kindly contact admin support."
              />
            </Grid>
          )}
        {userRole === 'AUTH_USER' &&
          subscription?.isSubscribed &&
          subscription?.planStatus?.toLowerCase() === 'expired' && (
            <Grid item xs={12}>
              <SubcriptionEnable
                title="Your subscription has expired."
                description="To renew your subscription, please contact admin support."
              />
            </Grid>
          )}
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Box className={classes.courserContent}>
            <Typography variant="h6">Course Content</Typography>
          </Box>

          {loading && (
            <Box>
              <Card
                className={classes.cardItem}
                sx={{ padding: '0.6rem 1rem', display: 'flex', justifyContent: 'space-between' }}
              >
                <Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" variant="circular" width={25} height={25} />
              </Card>
              <Card
                className={classes.cardItem}
                sx={{ padding: '0.6rem 1rem', display: 'flex', justifyContent: 'space-between' }}
              >
                <Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" variant="circular" width={25} height={25} />
              </Card>
            </Box>
          )}

          {courseModules &&
            courseModules.map((res, index) => (
              <Card className={classes.cardItem} key={index} ref={(el) => moduleRefs.current[index] = el}>
                <CardActions disableSpacing>
              
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '16px',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                    
                  >
                    <Box sx={{ cursor: 'pointer' }}>
                      <Box>
                        <Typography sx={{ fontSize: '0.8rem' }} onClick={() => handleExpandClick(index)}>
                          Module {index + 1}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontSize: '1.1rem' }}
                          onClick={() => handleExpandClick(index)}
                        >
                          {res.courseModuleName}
                        </Typography>
                      </Box>
                    </Box>

                  </div> 

                  <ExpandMore
                    expand={index}
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={expanded}
                    aria-label="show more"
                    sx={{ marginLeft: '16px' }}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>

                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                  <CardContent>
                    {res.subModule &&
                      res.subModule.map((subModule, indx) => (
                        <Card className={classes.cardItem} key={indx} ref={(el) => {
                          if (!subModuleRefs.current[index]) subModuleRefs.current[index] = [];
                          subModuleRefs.current[index][indx] = el;
                        }}>
                          <CardActions disableSpacing>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '16px',
                                width: '100%',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box sx={{ cursor: 'pointer' }}>
                                <Typography
                                  sx={{ fontSize: '0.8rem' }}
                                  onClick={() => handleSubModuleExpandClick(indx)}
                                >
                                  Sub Module {indx + 1}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  onClick={() => handleSubModuleExpandClick(indx)}
                                  sx={{ fontSize: '0.95rem', minWidth: '60%', display: 'flex', alignItems: 'center' }}
                                >
                                  {subModule.courseSubmoduleName} &nbsp;
                                  <Typography variant="body2" color="secondary" marginLeft={2}>
                                    {` ${subModule.completed}`}% completed
                                  </Typography>
                                </Typography>
                              </Box>

                              <Box sx={{ display: 'flex' }}>
                                {/* <Box mr={1}>
                                   {subModule.video && (
                                    <Tooltip arrow title="Watch Video">
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                          setOpenVideoModal(true);
                                          const arrLength = subModule.video.split('/');
                                          setVideoTitle({
                                            title: subModule.courseSubmoduleName,
                                            video: arrLength[arrLength.length - 1],
                                          });
                                        }}
                                      >
                                        Video
                                      </Button>
                                    </Tooltip>
                                  )} 
                                </Box> */}

                                {((userRole !== 'AUTH_USER' &&
                                  subscription?.isSubscribed &&
                                  subscription?.planStatus?.toLowerCase() === 'active') ||
                                  (subscription?.isTrial && subModule.isTrial)) && (
                                  <Box>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      onClick={() => handleStartSimulation(subModule?.id, res?.courseModuleName)}
                                    >
                                      Start
                                    </Button>
                                  </Box>
                                )}

                                {userRole === 'AUTH_USER' &&
                                  subscription?.isSubscribed &&
                                  subscription?.planStatus?.toLowerCase() === 'active' && (
                                    <Box>
                                      <Button
                                        sx={{ marginLeft: '0.8rem' }}
                                        variant="contained"
                                        onClick={() => handleStartSimulation(subModule?.id, res?.courseModuleName)}
                                      >
                                        Start
                                      </Button>
                                    </Box>
                                  )}
                              </Box>
                            </div>

                            <ExpandMoreSub
                              expand={indx}
                              onClick={() => handleSubModuleExpandClick(indx)}
                              aria-expanded={subModuleExpanded}
                              aria-label="show more"
                              sx={{ marginLeft: '16px' }}
                            >
                              <ExpandMoreIcon />
                            </ExpandMoreSub>
                          </CardActions>

                          <Collapse in={subModuleExpanded === indx} timeout="auto" unmountOnExit>
                            <CardContent sx={{ paddingTop: 1 }}>
                              {subModule.subModuleTopics &&
                                subModule.subModuleTopics.map((topic, idx) => (
                                  <Box display="flex" alignItems="center" sx={{ padding: '8px 0 !important' }}>
                                    <Iconify
                                      icon={'ant-design:check-circle-outlined'}
                                      width={18}
                                      height={18}
                                      marginRight={1}
                                      sx={{ color: topic.isCompleted ? palette.secondary.main : '#00000029' }}
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      // component={LinkBtn}
                                      // to={{
                                      //   pathname: '/app/submodules',
                                      // }}
                                      sx={{ textDecoration: 'none !important' }}
                                      color="inherit"
                                    >
                                      {topic.courseSubmoduleTopics}
                                    </Typography>
                                  </Box>
                                ))}
                              {subModule.referenceText &&(
                                <IconButton
                                // onClick={() =>
                                // {
                                //   console.log("inside onclick...",subModule.id,subModule.courseSubmoduleName);
                                //   handleReferenceClick(subModule.id,subModule.courseSubmoduleName)}}
                                 sx={{ textDecoration: 'none !important', padding: 0,fontSize:'14px',color:'blue'}}
                                 >
                                  <Iconify
                                    icon={'ant-design:check-circle-outlined'}
                                    width={18}
                                    height={18}
                                    marginRight={1}
                                    sx={{color:blue}}
                                  />
                                  <Typography
                                    variant="text"
                                  >
                                    Reference
                                  </Typography>
                                </IconButton>
                               )}
                            <div>
                              {((userRole !== 'AUTH_USER' &&
                                subscription?.isSubscribed &&
                                subscription?.planStatus?.toLowerCase() === 'active') ||
                                (subscription?.isTrial && subModule.isTrial)) && (
                                <Button
                                  variant="text"
                                  key={'myRoute'}
                                  // component={LinkBtn}
                                  // to={{
                                  //   pathname:
                                  //     userRole === 'USER_DTC'
                                  //       ? `/auth/skilliq-test?subModuleId=${subModule.id}`
                                  //       : `/app/skilliq-test?subModuleId=${subModule.id}`,
                                  // }}
                                  // state={{ data: courseDetails }}
                                  onClick={() => {
                                    const keyType = { actionType: subModule?.subModuleTopics[0]?.actionType };
                                    navigate(`/auth/skilliq-test?subModuleId=${subModule.id}`, {
                                      replace: true,
                                      state: { ...courseDetails, ...keyType,subModule },
                                    });
                                  }}
                                  sx={{ textDecoration: 'none !important' }}
                                  color="primary"
                                  className="skillsetIQ"
                                  // disabled={(subscription?.isSubscribed || subscription?.planStatus?.toLowerCase()==="active")}
                                >
                                  SkillSet IQ
                                </Button>
                              )}
                              {userRole === 'AUTH_USER' &&
                                subscription?.isSubscribed &&
                                subscription?.planStatus?.toLowerCase() === 'active' && (
                                  <Button
                                    variant="text"
                                    key={'myRoute'}
                                    // component={LinkBtn}
                                    // to={{
                                    //   pathname:
                                    //     userRole === 'USER_DTC'
                                    //       ? `/auth/skilliq-test?subModuleId=${subModule.id}`
                                    //       : `/app/skilliq-test?subModuleId=${subModule.id}`,
                                    // }}
                                    onClick={() => {
                                      if(subModule?.subModuleTopics !== null  ){
                                        const keyType = { actionType: subModule?.subModuleTopics[0]?.actionType };
                                        navigate(`/app/skilliq-test?subModuleId=${subModule.id}`, {
                                          replace: true,
                                          state: { ...courseDetails, ...keyType,subModule },
                                        });
                                      }
                                    }}
                                    // state={{ data: courseDetails }}
                                    sx={{ textDecoration: 'none !important' }}
                                    color="primary"
                                    className="skillsetIQ"
                                    // disabled={(subscription?.isSubscribed || subscription?.planStatus?.toLowerCase()==="active")}
                                  >
                                    SkillSet IQ
                                  </Button>
                                )}
                            </div>
                        </CardContent>
                          </Collapse>
                        </Card>
                      ))}
                  </CardContent>
                </Collapse>
              </Card>
            ))}
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box className={classes.courserContent}>
            <Typography variant="h6">Statistics</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={12}>
              <Paper elevation={1} className={classes.paper1}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                  className="overall"
                >
                  <Typography>Overall Progress</Typography>
                  <Typography sx={{ color: '#00B673' }}>
                    {courseProgress ? `${courseProgress.overallProgress}%` : '0%'}
                  </Typography>
                </div>
                <BorderLinearProgress
                  variant="determinate"
                  value={courseProgress && parseInt(courseProgress.overallProgress, 10)}
                />
              </Paper>
            </Grid>
            <Grid mt={0} item xs={12} sm={6} md={12}>
              <Paper elevation={1} className={classes.paper}>
                <div>
                  <GaugeChart
                    id="gauge-chart2"
                    nrOfLevels={4}
                    arcWidth={0.2}
                    percent={courseProgress ? parseInt(courseProgress.efficiencyScore, 10) / efficiencyVal : 0.2}
                    animate={false}
                    textColor="#00b673"
                    formatTextValue={() => (courseProgress ? `${parseInt(courseProgress.efficiencyScore, 10)}x` : '0x')}
                    cornerRadius={0}
                    arcPadding={0}
                    colors={['#e5eaec', '#92d4b5', '#59bd91', '#1da86b']}
                    hideText
                    needleColor="#d8dcdd"
                    needleBaseColor="#d8dcdd"
                  />
                </div>
                <Box className="efficiencyScore">
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{ marginTop: '-1.5rem', fontWeight: '600', fontSize: '1.4rem' }}
                  >
                    {courseProgress ? `${parseInt(courseProgress.efficiencyScore, 10)}x` : '0x'}
                  </Typography>
                  <Typography variant="subtitle1">Efficiency Score</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid mt={0} item xs={12} sm={6} md={12}>

                        {/* anything above this line is necessary */}
                        <Paper elevation={1} className={classes.paper1}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <SearchBar
                                slice={slice}
                                setSearchResults={setSearchResults}
                                filter={appliedFilter}
                                setSearchComplete={setSearchComplete}
                                setIsQuery={setIsQuery}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                            
                                <Box width="100%" />
                            </Grid>
                            
                        </Grid>
                        {/* <Grid container>
                        <TabContext
                          // value={value}
                          className={classes.searchtabsgrid}
                          sx={{
                            marginBottom:'0',
                            float:'left'
                          }}
                        >
                                <Grid item xs={4} sm={4} md={4} lg={4}
                                  className={classes.searchtabsgrid}
                                >
                                  <Button label="All"
                                    variant={appliedFilter === 'All' ? "contained" : "text"}
                                    disableElevation
                                    sx={{
                                      borderRadius:'0',
                                      paddingRight: '8px'
                                    }}
                                    onClick={(e) => { setSlice(allBlocks); setAppliedFilter(e.target.textContent); }}
                                    value="1"
                                  >
                                    All
                                  </Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4}
                                  className={classes.searchtabsgrid}
                                >
                                  <Button label="Video"
                                    variant={appliedFilter === 'Video' ? "contained" : "text"}
                                    disableElevation
                                    sx={{
                                      borderRadius:'0',
                                      paddingLeft: '8px',
                                      paddingRight: '8px',
                                    }}
                                    onClick={(e) => { setSlice(vidBlocks); setAppliedFilter(e.target.textContent); }}
                                    value="2"
                                  >
                                    Video
                                  </Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={4}
                                  className={classes.searchtabsgrid}
                                >
                                  <Button label="Simulation"
                                    variant={appliedFilter === 'Simulation' ? "contained" : "text"}
                                    disableElevation
                                    sx={{
                                      borderRadius:'0',
                                      paddingLeft: '8px',
                                    }}
                                    onClick={(e) => { setSlice(simBlocks); setAppliedFilter(e.target.textContent); }}
                                    value="3"
                                  >
                                    Simulation
                                  </Button>
                                </Grid>
                        </TabContext>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={12}
                            className={classes.searchtabsgrid}
                          >
                            <ListPage
                              searchResults={searchResults}
                              searchComplete={searchComplete}
                              handleStartSimulation={handleStartSimulation}
                              setOpenVideoModal={setOpenVideoModal}
                              setVideoTitle={setVideoTitle}
                              isQuery={isQuery}
                            />
                          </Grid>
                        </Paper>
                      { /* anything below this line is necessary */ }

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Paper elevation={2} sx={{ padding: '1.4rem', marginTop: '1rem' }}>
        <Box className={classes.courserContent}>
          <Typography variant="h6">Case studies</Typography>

          {csTestResult && (
            <Box>
              {csTestResult ? (
                <Button variant="contained" color="primary" onClick={() => setOpenResult(true)}>
                  View result
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    swal({
                      // title: 'Good job!',
                      text: ' You have only one chance to pass the test. Please make sure that you have prepared well',
                      // icon: 'success',
                      buttons: {
                        confirm: {
                          text: 'Okay',
                          value: true,
                          visible: true,
                          className: '',
                          closeModal: true,
                        },
                        cancel: {
                          text: 'Cancel',
                          value: null,
                          visible: true,
                          className: '',
                          closeModal: true,
                        },
                      },
                      closeOnClickOutside: false,
                    }).then((res) => {
                      if (res) {
                        navigate(`/app/case-studyiq-test?courseId=${courseDetails.id}`, {
                          replace: true,
                          state: courseDetails,
                        });
                      }
                    });
                  }}
                >
                  Take Test
                </Button>
              )}
            </Box>
          )}
        </Box>
        {caseStudy.map((res, index) => (
          <Box className={classes.fileList}>
            <Link underline="none" variant="subtitle1" sx={{ display: 'flex' }} href={res.url} target="new">
              {fileIcon.map((item) => {
                return (
                  <>
                    {res.type === item.type ? (
                      <Iconify
                        icon={item.icon}
                        width={20}
                        height={20}
                        // marginRight={1}
                        // sx={{ color: topic.isCompleted ? palette.secondary.main : '#00000029' }}
                      />
                    ) : (
                      ''
                    )}
                  </>
                );
              })}
              &nbsp; {res.name}
            </Link>

            <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
              <CloudDownloadIcon color="primary" />
            </IconButton>
          </Box>
        ))}
      </Paper> */}
      {userRole !== 'AUTH_USER' &&
        !subscription?.isTrial &&
        (subscription?.isSubscribed || subscription?.planStatus?.toLowerCase() === 'active') && <CaseStudyHtml />}

      {userRole === 'AUTH_USER' &&
        subscription?.isSubscribed &&
        subscription?.planStatus?.toLowerCase() === 'active' && <CaseStudyHtml />}

      <BasicModal openModel={openResult} closeModel={() => setOpenResult(!openResult)} title="Case study result">
        <Typography variant="h5" gutterBottom sx={{ display: 'flex' }}>
          Test Result :{' '}
          {csTestResult && csTestResult.result ? (
            <Typography variant="h5" color="secondary">
              &nbsp;Pass
            </Typography>
          ) : (
            <Typography variant="h5" color="primary">
              &nbsp; Fail
            </Typography>
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Percentage : {csTestResult && csTestResult.resultInPercentage} %
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          Time Taken : {csTestResult && csTestResult.timeTaken}
        </Typography> */}
        <Typography variant="body1" gutterBottom>
          Correct Answer: {csTestResult && csTestResult.rightAnswer}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Wrong Answer: {csTestResult && csTestResult.wrongAnswer}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Total number of question: {csTestResult && csTestResult.totalScreen}
        </Typography>
      </BasicModal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openVideoModal}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          backgroundColor: '#0a00008c !important',
          '.css-moysu4': {
            border: 'none !important',
          },
        }}
      >
        <Fade in={openVideoModal}>
          <Box className={classes.vediobox}>
            <Typography variant="h6" color="#fff" gutterBottom>
              {videoTitle.title}
            </Typography>
            <IKContext urlEndpoint={['dev','test'].includes($(process.env.REACT_APP_ENV))? process.env.REACT_APP_AWS_S3_URL : ImageKit.ImageKitUrl} isPrivateFile>
              <IKVideo
                className={classes.videoContainer}
                path={`/${videoTitle.video}`}
                // transformation={[{ width: 600, controlsList: 'nodownload' }]}
                controls={true}
                controlsList="nodownload"
              />
            </IKContext>
            <IconButton
              sx={{
                position: 'absolute',
                top: '24px',
                background: '#f6f6f6',
                // '.css-3u56ba-MuiButtonBase-root-MuiIconButton-root:hover': {
                //   backgroundColor: 'rgb(255 255 255 / 67%) !important',
                // },
              }}
              className={classes.closeBtn}
              size="small"
              onClick={() => setOpenVideoModal(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Fade>
      </Modal>
    </Page>
  );
}

const useStyles = makeStyles((theme) => ({
  subscribe: {
    color: '#FFFFFF',
    backgroundColor: '#00B673',
    padding: '9px 18px',
    borderRadius: '20px',
    fontWeight: '400',
    '&:hover': {
      backgroundColor: '#00B673',
    },
  },
  closeBtn: {
    '&:hover': {
      backgroundColor: 'rgb(255 255 255 / 67%)',
    },
  },
  paper: {
    padding: '16px',
    textAlign: 'center',
  },
  banner: {
    dispaly: 'flex',
    justifyContent: 'space-between',
  },
  renewContent: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    height: '103px',
  },
  paper1: {
    padding: '16px',
    textAlign: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  },
  cardItem: {
    marginBottom: '24px',
    border: '1px solid #e1e1e1ab',
  },
  resumeBox: {
    width: '140px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  courserContent: {
    margin: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  infoIcon: {
    color: 'gray',
    marginLeft: '8px',
  },
  progress: {
    width: '42%',
    marginBottom: '0.9rem',
  },
  courseBtn: {
    width: 'max-content',
    background: ' #fff',
    padding: '4px 8px',
    borderRadius: '6px',
  },
  logo: {
    boxShadow: '0px 3px 22px #00000029',
    padding: '1px',
    width: '35px',
    borderRadius: '6px',
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  categoryPosition: {
    position: 'relative',
    top: '13rem',
  },
  coursecard: {
    position: 'relative',
    minHeight: '20rem',
    backgroundColor: '#102D73',
    padding: '2rem',
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },
  videoContainer: {
    width: '100%',
  },
  coursetitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      WebkitLineClamp: 'initial',
      WebkitBoxOrient: 'initial',
    },
  },

  // coursetitle: {
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  //   display: '-webkit-box',
  //   WebkitLineClamp: '1',
  //   WebkitBoxOrient: 'vertical',
  //   [theme.breakpoints.down('md')]: {
  //     overflow: 'auto',
  //     WebkitBoxOrient: 'initial',
  //   },
  // },
  content: {
    minHeight: '12rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '10',
    WebkitBoxOrient: 'vertical',
    color: '#fff',
    fontWeight: '200',
    margin: '1rem 0rem 1.5rem 0rem',
    [theme.breakpoints.down('lg')]: {
      minHeight: '13rem',
    },
    '@media (max-width: 1040px)': {
      minHeight: '10rem',
    },
  },
  resume: {
    color: '#000000',
    fontWeight: '450',
    backgroundColor: '#fff',
    fontSize: '14px',
    borderRadius: '6px',
    padding: '2.5px 8px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  powerpoint: {
    backgroundColor: '#fff',
    fontSize: '14px',
    color: '#02723B',
    borderRadius: '6px',
    padding: '7px 8px',
    width: 'fit-content',
    margin: '0px 12px',
    marginTop: '10px',
  },
  image: {
    borderRadius: '6px',
    [theme.breakpoints.up('lg')]: {
      width: '75%',
      maxHeight: '400px',
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  imagebox: {
    padding: '6px',
    marginTop: '1rem',
    backgroundImage: `url(${background})`,
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  halfRow: {
    width: '50%',
  },
  center: {
    alignItems: 'center',
    width: '100%',
  },
  courseImg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  play: {
    padding: '0px ! important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  vedio: {
    height: '100%',
    width: '100%',
  },
  vediobox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: 'auto',
    bgcolor: 'background.paper',
    padding: '20px',
    boxShadow: 24,
    p: 4,
  },
  iqbox: {
    display: 'flex',
    padding: '10px 50px 10px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
      justifyContent: 'space-around',
    },
  },
  assesment: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0px',
    },
  },
  iqbutton: {
    padding: '8px',
    fontSize: '0.95rem',
    fontWeight: ' bold',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  videoIcon: {
    padding: '0px',
  },
  fileList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    background: '#F5F5F5',
    borderRadius: '10px',
    marginBottom: '0.8rem',
  },
  showMoreText: {
    minHeight: '14rem',
    display: '-webkit-box',
    WebkitLineClamp: 11,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    color: '#fff ! important',
  },
  textcolor: {
    minHeight: '14rem',
    maxHeight: '14rem',
    color: '#fff ! important',
    overflow: 'scroll',
    WebkitLineClamp: 11,
    WebkitBoxOrient: 'vertical',
    // display: '-webkit-box',
  },
  readMore: {
    width: 'max-content',
    cursor: 'pointer',
  },
  searchtabsgrid: {
    display:'flex',
    justifyContent: 'center'
  },
  playbtn: {
    width: '60px',
    height: '60px',
    background: 'radial-gradient( gree 60%, rgba(255, 255, 255, 1) 62%)',
    borderRadius: '50%',
    position: 'relative',
    display: 'block',
    // margin: "100px auto",
    boxShadow: '0px 0px 25px 3px rgba(255, 0, 128, 0.8)',
    ':after': {
      content: '',
      position: 'absolute',
      left: '50%',
      top: '50%',
      webkitTransform: 'translateX(-40%) translateY(-50%)',
      transform: 'translateX(-40%) translateY(-50%)',
      transformOrigin: 'center center',
      width: '0',
      height: '0',
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: '15px solid #fff',
      zIndex: '100',
      webkitTransition: 'all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
      transition: 'all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    },
    ':before': {
      content: '',
      position: 'absolute',
      width: '140%',
      height: '140%',
      webkitAnimationDelay: '0s',
      animationDelay: '0s',
      webkitAnimation: 'pulsate1 2s',
      animation: 'pulsate1 2s',
      webkitAnimationDirection: 'forwards',
      animationDirection: 'forwards',
      webkitAnimationIterationCount: 'infinite',
      animationIterationCount: 'infinite',
      webkitAnimationTimingFunction: 'steps',
      animationTimingFunction: 'steps',
      opacity: '1',
      borderRadius: '50%',
      border: '5px solid rgba(255, 255, 255, .75)',
      top: '-30%',
      left: '-30%',
      background: 'rgba(198, 16, 0, 0)',
    },
  },

  renewContent: {
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
    height: '90px',
  },
  iconifystyle: {
    // color: '#63B182',
    minWidth: '51px',
    height: '65px',
  },
  playbutton: {
    // boxShadow: '0px 0px 25px 3px #ff6600',

    // borderRadius: '70px',
    backgroundColor: 'unset !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    '&:hover': {
      backgroundColor: 'unset !important',
    },
    '@media (max-width: 1440px)': {
      top: '44%',
      left: '50%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  glowing: {
    padding: '0px',
    borderRadius: '50%',
    animation: '$pulse 1.5s infinite',
  },
  '@global': {
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
      },
    },
  },
}));
