import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { Grid, Typography, Box } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import CourseDetail from './courseDetailTabs/CourseDetail';
import EnrolledUser from './courseDetailTabs/EnrolledUser';
import ClientAdminServices from '../../../services/clientAdmin/course/clientAdminServices';

export default function Index() {
  const location = useLocation();
  const courseId = location.state;
  const isGoBack = true;
  const [value, setValue] = React.useState(0);
  const [courseModule, setCourseModule] = useState([]);
  const [courseDetails, setCourseDetails] = useState([]);
  const [enrolledUser, setEnrolledUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const getCourseDetailsById = async () => {
    try {
      const response = await ClientAdminServices.getCourseDetails(courseId);
      if (response.ok) {
        setCourseDetails(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getCourseModuleById = async () => {
    try {
      const response = await ClientAdminServices.getCourseModule(courseId);
      if (response.ok) {
        setCourseModule(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourseDetailsById();
    getCourseModuleById();
    setTableLoading(true);
    // getEnrolledUserById();
  }, []);

  return (
    <Page title="Course-list">
      <PageHeader pageTitle={courseDetails.title} goBack={isGoBack} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab iconPosition="start" icon={<AutoStoriesOutlinedIcon />} label="Courses Detail" {...a11yProps(0)} />
                <Tab
                  iconPosition="start"
                  icon={<GroupAddOutlinedIcon />}
                  label="Subscriptions & Users"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CourseDetail loading={loading} courseModule={courseModule} courseDetails={courseDetails} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EnrolledUser
                // enrolledUser={enrolledUser}
                courseId={courseId}
                // getEnrolledUserById={getEnrolledUserById}
                tableLoading={tableLoading}
              />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
}
