import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useProSidebar } from 'react-pro-sidebar';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Helmet } from 'react-helmet-async';

// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ThemeProvider from '../../theme';
import settings from '../../settings';
import helper from '../../utils/helper';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme, collapsed }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  background: '#fff',
  // paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  // [theme.breakpoints.up('lg')]: {
  // paddingTop: APP_BAR_DESKTOP + 24,
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2)
  // }

  // [theme.breakpoints.up('md')]: {
  //   marginLeft: '270px',
  //   width: `calc(100% - ${collapsed ? 70 : 270 }px)`,
  //   // marginRight: '20px',
  // },
}));

const useStyles = makeStyles((theme) => ({
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { collapsed } = useProSidebar();
  const locationPath = useLocation();
  const dispalySalasIq = settings.dispalySalasIqUrl;

  useEffect(() => {
    // removeWeglot();
  });

  // const removeWeglot = async () => {
  //   const res = await helper.removeScript('https://cdn.weglot.com/weglot.min.js');
  //   window.Weglot.initialize({
  //     api_key: 'wg_014b43b2959a227f6bd358580eba225b9',
  //   });
  // };

  return (
    <>
      <ThemeProvider>
        <RootStyle>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />

          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'auto',
              minHeight: '100%',
              background: '#fff',
              '@media (min-width: 1200px)': {
                marginLeft: `${collapsed ? '70px' : '270px'} !important`,
                width: `calc(100% - ${collapsed ? 70 : 270}px) !important`,
              },
            }}
          >
            <Outlet />
          </Box>
        </RootStyle>
      </ThemeProvider>
    </>
  );
}
