import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {
  return {
    body: {
      color: '#fff',
      fontFamily: 'Nunito Semibold',
      textAlign: 'center',
    },
    content: {
      display: 'grid'
    },
    gridCell: {
      border: '1px solid #555',
      cursor: 'pointer',
    },
    selectedButton: {
      background: '#3bbced',
      color: '#fff',
    },
    gridButton: {
      padding: '5px 10px 5px 10px',
      cursor: 'pointer',
      margin: '0 5px',
    },
    gutter: {
      marginBottom: '20px',
    },
  };
});

function GridComponent({toggleClose,prevState,pixelSet,backgroundHeight,backgroundWidth}) {

  const widthCalculator = {
    '12x12': 36,
    '24x24': 60,
    '36x36': 72,
  };

  const [numColumns,setNumColumns] = useState(0);
  const [numRows,setNumRows] = useState(0);

  const [shouldRenderGrid, setShouldRenderGrid] = useState(false);
  const [selectedGridSize, setSelectedGridSize] = useState('12x12');

  const [cellDimensions, setCellDimensions] = useState({ width: '100px', height: '100px' });

  const [allCells, setAllCells] = useState({}); // Initialize allCells with useState
  const [selectedCells, setSelectedCells] = useState({}); // Stores just highlighted keys
  const [resetClicked,setResetClicked] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (selectedCells.length === 0) {
      setResetClicked((prevState) => !prevState);
      setIsRectangle(true);
    }
  }, [selectedCells]);

  useEffect(() => {

    const cellWidth = 960 / widthCalculator[selectedGridSize] - 2;
    const cellHeight = cellWidth;

    const estColumns = backgroundWidth / cellWidth;
    const estRows = estColumns / 1.86956521739;
    const numColumns = Math.ceil(estColumns);
    const numRows = Math.ceil(estRows);

    setCellDimensions({ width: `${cellWidth}px`, height: `${cellHeight}px` });
    setNumColumns(numColumns);
    setNumRows(numRows);
    
    setAllCells({});
    setSelectedCells({});

    const newAllCells = {};
    for (let i = 0; i < numRows * numColumns; i += 1) {
      const row = Math.floor(i / numColumns);
      const col = i % numColumns;
      const upIndex = row > 0 ? i - numColumns : null;
      const downIndex = row < numRows - 1 ? i + numColumns : null;
      const leftIndex = col > 0 ? i - 1 : null;
      const rightIndex = col < numColumns - 1 ? i + 1 : null;

      newAllCells[i] = {
        value: false,
        up: {where:upIndex,what:false},
        down: {where:downIndex,what:false},
        left: {where:leftIndex,what:false},
        right: {where:rightIndex,what:false},
        location: {row,col,cellWidth}
      };
    }

    setAllCells(newAllCells); // Update allCells with the newAllCells object

    setShouldRenderGrid(true);
  }, [selectedGridSize,resetClicked,backgroundWidth]);

  // Run this function on each highlighted cell. If numCorners doesn't end up 4, it's not a rectangle
  const isCorner = (currCellObj) => {
    
    let numCorners = 0;

    // is this cell a top left corner
    if (!currCellObj.up.what && !currCellObj.left.what) {
      numCorners += 1;
    }

    // is it also a top right corner
    if (!currCellObj.up.what && !currCellObj.right.what) {
      numCorners += 1;
    }

    // bottom left corner
    if (!currCellObj.down.what && !currCellObj.left.what) {
      numCorners += 1;
    }

    // bottom right corner
    if (!currCellObj.down.what && !currCellObj.right.what) {
      numCorners += 1;
    }

    return numCorners;
  }

  const [eraseOn,setEraseOn] = useState(false);
  const callHandleCellClick = (index,event) => {

    // Check if the left mouse button is pressed
    const isMousePressed = event.buttons === 1;
    if (!eraseOn && isMousePressed) {
      if (!allCells[index].value) {
        handleCellClick(index);
      }
    }
    else if (eraseOn && isMousePressed) {
      if (allCells[index].value) {
        handleCellClick(index);
      }
    }
  }

  useEffect(() => {
    if (shouldRenderGrid && prevState && prevState.length > 0) {
      let index = 0;
      const intervalId = setInterval(() => {
        if (index < prevState.length) {
          const cell = prevState[index];
          if (cell < numRows * numColumns) {
            document.getElementById(`cell-${cell}`).click();
          }
          index += 1;
        } else {
          clearInterval(intervalId); // Stop the interval once all cells have been processed
        }
      }, 0);
    }
  }, [shouldRenderGrid]);

  const handleCellClick = (cellIndex) => {

    const upIndex = allCells[cellIndex].up.where;
    const downIndex = allCells[cellIndex].down.where;
    const leftIndex = allCells[cellIndex].left.where;
    const rightIndex = allCells[cellIndex].right.where;
    
    const hasHighlightedAdjacent =
    (upIndex !== null && allCells[upIndex].value) ||
    (downIndex !== null && allCells[downIndex].value) ||
    (leftIndex !== null && allCells[leftIndex].value) ||
    (rightIndex !== null && allCells[rightIndex].value);

    const updatedAllCells = { ...allCells }; // Create a copy
    const updatedSelectedCells = { ...selectedCells };

    if (allCells[cellIndex].value || eraseOn) {
      updatedAllCells[cellIndex].value = false;
      delete updatedSelectedCells[cellIndex];
    }
    else if (Object.keys(selectedCells).length < 1 || hasHighlightedAdjacent) {
      updatedAllCells[cellIndex].value = true;
      updatedSelectedCells[cellIndex] = true;
    }

    // Update the key that represents each adjacent cell's relationship
    // with this cell to its current value
    if (upIndex !== null) {
      updatedAllCells[upIndex].down.what = updatedAllCells[cellIndex].value;
    }
    if (downIndex !== null) {
      updatedAllCells[downIndex].up.what = updatedAllCells[cellIndex].value;
    }
    if (leftIndex !== null) {
      updatedAllCells[leftIndex].right.what = updatedAllCells[cellIndex].value;
    }
    if (rightIndex !== null) {
      updatedAllCells[rightIndex].left.what = updatedAllCells[cellIndex].value;
    }

    setAllCells(updatedAllCells);
    setSelectedCells(updatedSelectedCells);
  };
  
  const handleGridSizeChange = (gridSize) => {
    if (gridSize !== selectedGridSize) {
      setShouldRenderGrid(false);
      setIsRectangle(true);
    }
    setSelectedGridSize(gridSize);
  };

  // Function that counts total number of corners and return their indices if there are 4
  const getOuterCorners = () => {
    
    const outerCorners = [];

    let cornerCounter = 0;
    let prevCount = 0;

    Object.keys(selectedCells).forEach((cellIndex) => {
      cornerCounter += isCorner(allCells[cellIndex]);
      if (cornerCounter > prevCount) {
        prevCount = cornerCounter;
        outerCorners.push({"index":cellIndex});
      }
    });

    if (cornerCounter === 4) {
      return outerCorners;
    }
    return [];
  };

  const getSingleCellPx = (cellNumber) => {

    // Assuming you have a reference to the cell element
    const cellElement = document.getElementById(`cell-${cellNumber}`);

    // Get the position and dimensions of the cell
    const cellRect = cellElement.getBoundingClientRect();

    // Extract the pixel coordinates
    const cellX = cellRect.left;
    const cellY = cellRect.top;
    const cellXplusWidth = cellX+cellRect.width;
    const cellYplusHeight = cellY+cellRect.height;

    return {"index":cellNumber,"A":{"X":cellX,"Y":cellY},"B":{"X":cellXplusWidth,"Y":cellY},"C":{"X":cellX,"Y":cellYplusHeight},"D":{"X":cellXplusWidth,"Y":cellYplusHeight}}
  }

  // const mergeSort = (subArray1,subArray2) => {
  //   const sortedArray = [];

  //   if (subArray1[0].index > subArray1[1].index) {
  //     const temp = subArray1[0];
  //     subArray1[0] = subArray1[1];
  //     subArray1[1] = temp;
  //   }

  //   if (subArray2.length === 0) {
  //     sortedArray.push(subArray1[0],subArray1[1]);
  //   } else {
  //     if (subArray2[0].index > subArray2[1].index) {
  //       const temp = subArray2[0];
  //       subArray2[0] = subArray2[1];
  //       subArray2[1] = temp;
  //     }

  //     let i = 0;
  //     let j = 0;
  //     while (i < 2 && j < 2) {
  //       if (subArray1[i].index <= subArray2[j].index) {
  //         sortedArray.push(subArray1[i]);
  //         i += 1;
  //       } else {
  //         sortedArray.push(subArray2[j]);
  //         j += 1;
  //       }
  //     }

  //     while (i < 2) {
  //       sortedArray.push(subArray1[i]);
  //       i += 1;
  //     }
  //     while (j < 2) {
  //       sortedArray.push(subArray2[j]);
  //       j += 1;
  //     }
  //   }

  //   return sortedArray;
  // }

  // const sortCornerCells = (cornerCells) => {
  //   const array1 = [];
  //   const array2 = [];
  //   for (let i = 0; i < cornerCells.length; i+=1) {
  //     if (array1.length < 2) {
  //       array1.push(cornerCells[i]);
  //     } else {
  //       array2.push(cornerCells[i]);
  //     }
  //   }
  //   return mergeSort(array1,array2);
  // }

  // const getRangeFromPx = (coordinates) => {
  //   const range = [];
  //   console.log("your coordinates are ",coordinates);

  //   let maxX = -1;
  //   let maxY = -1;
  //   let minX = 999999;
  //   let minY = 999999;
  //   for (let i = 0; i < coordinates.length; i += 1) {
  //     if (coordinates[i].D.X > maxX) {
  //       maxX = coordinates[i].D.X;
  //     }
  //     if (coordinates[i].D.Y > maxY) {
  //       maxY = coordinates[i].D.Y;
  //     }
  //     if (coordinates[i].A.X < minX) {
  //       minX = coordinates[i].A.X;
  //     }
  //     if (coordinates[i].A.Y < minY) {
  //       minY = coordinates[i].A.Y;
  //     }
  //   }

  //   range.push({
  //     "horizontal":{"X1":minX,"X2":maxX},
  //     "vertical":{"Y1":minY,"Y2":maxY}
  //   });

  //   return range;
  // }

  // const calcBhwProportion = (cellIndex) => {
  //     const row = allCells[cellIndex].location.row;
  //     const col = allCells[cellIndex].location.col;
  //     const cellWidth = allCells[cellIndex].location.cellWidth;
      
  //     const cellRatio1 = (row*cellWidth)/backgroundHeight;
  //     const cellRatio2 = (col*cellWidth)/backgroundWidth;
  //     const cellRatio3 = ((row+1)*cellWidth)/backgroundHeight;
  //     const cellRatio4 = ((col+1)*cellWidth)/backgroundWidth;

  //     return {"A":[cellRatio1,cellRatio2],"B":[cellRatio1,cellRatio4],"C":[cellRatio3,cellRatio2],"D":[cellRatio3,cellRatio4]}
  // }

  const checkIfFilledIn = (cornerIndices) => {
    const width = (cornerIndices[1].index-cornerIndices[0].index) + 1;
    const height = Math.floor((cornerIndices[2].index-cornerIndices[0].index) / numColumns) + 1;
    const area = width * height;

    if (Object.keys(selectedCells).length !== area) {
      return false;
    }
    return true;
  }

  // State variable to store whether the selected cells form a rectangle
  const [isRectangle, setIsRectangle] = useState(true);

  // Function that checks if there are either 0 or 4 corners, hence a rectangle
  const checkForRectangle = () => {
    if (Object.keys(selectedCells).length > 0) {
      const cornerIndices = getOuterCorners();

      if (cornerIndices.length === 1) {

        const sortedCells = Object.keys(selectedCells).sort();

        setIsRectangle(true);

        const rowRatio = allCells[cornerIndices[0].index].location.row / numRows;
        const colRatio = allCells[cornerIndices[0].index].location.col / numColumns;

        pixelSet({backgroundWidth,backgroundHeight,selectedGridSize,selectedCells:sortedCells,highlightedCellRatios:[{rowRatio,colRatio}]});
        toggleClose(false);
      }
      else if (cornerIndices.length === 2 || cornerIndices.length === 4) {
        
        const sortedCells = Object.keys(selectedCells).sort();
        const sortedCornerCells = cornerIndices.sort();

        if (cornerIndices.length === 2) {
          setIsRectangle(true);
          toggleClose(false);
        } else if (checkIfFilledIn(sortedCornerCells)) {
          setIsRectangle(true);
          toggleClose(false);
        } else {
          setIsRectangle(false);
        }

        const highlightedCellRatios = [];
        sortedCells.forEach((index) => {
          const rowRatio = allCells[index].location.row / numRows;
          const colRatio = allCells[index].location.col / numColumns;
          highlightedCellRatios.push({rowRatio,colRatio});
        });

        pixelSet({backgroundWidth,backgroundHeight,selectedGridSize,selectedCells:sortedCells,highlightedCellRatios});
      } else {
        setIsRectangle(false);
      }
    } else {
      setIsRectangle(false);
    }
  };

  return (
    <div className={classes.body}>
      <div className={classes.container}>
        {shouldRenderGrid && (
          <div
            className={classes.content}
            style={{
              gridTemplateColumns: `repeat(${numColumns}, 1fr)`
            }}
          >
            {[...Array(numRows * numColumns)].map((_, index) => (
              <div
                key={`cell-${index}`}
                id={`cell-${index}`}
                className={`${classes.gridCell} ${index >= (numRows - 1) * numColumns ? classes.gutter : ''}`}
                style={{
                  ...cellDimensions,
                  backgroundColor: allCells[index].value ? 'rgba(255, 255, 0, 0.6)' : 'transparent',
                }}
                onClick={() => { handleCellClick(index) }}
                onMouseEnter={(event) => callHandleCellClick(index,event)}
                onDrag={(event) => event.preventDefault()}
                role="button"
                onKeyDown={() => {}}
                tabIndex={0}
              />
            ))}
          </div>
        )}
        <div>
          {/* Button group for selecting grid size */}
          {/* <button
            className={`${classes.gridButton} ${
              selectedGridSize === '12x12' ? classes.selectedButton : ''
            }`}
            onClick={() => handleGridSizeChange('12x12')}
          >
            12x12
          </button>
          <button
            className={`${classes.gridButton} ${
              selectedGridSize === '24x24' ? classes.selectedButton : ''
            }`}
            onClick={() => handleGridSizeChange('24x24')}
          >
            24x24
          </button>
          <button
            className={`${classes.gridButton} ${
              selectedGridSize === '36x36' ? classes.selectedButton : ''
            }`}
            onClick={() => handleGridSizeChange('36x36')}
          >
            36x36
          </button> */}
          <button
            className={`${classes.gridButton} ${
              eraseOn ? classes.selectedButton : ''
            }`}
            onClick={() => setEraseOn((prevState) => !prevState)}
            disabled={Object.keys(selectedCells).length === 0 && !eraseOn}
          >
            Erase
          </button>
          <button
            className={`${classes.gridButton}`}
            onClick={() => { setResetClicked((prevState) => !prevState); setIsRectangle(true); }}
          >
            Reset
          </button>
          <button
            className={`${classes.gridButton}`}
            onClick={() => checkForRectangle()}
          >
            Submit
          </button>
          {isRectangle ? (
            <p style={{visibility:'hidden',color:'red'}}>The highlighted cells form a rectangle.</p>
          ) : (
            <p style={{visibility:'visible',color:'red'}}>The highlighted cells do not form a rectangle. Please build a rectangle.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default GridComponent;