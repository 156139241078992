import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import AddIcon from '@mui/icons-material/Add';

import Iconify from '../../../components/Iconify';
import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import BasicModal from '../../../components/modal/BasicModel';
import AddUser from './AddUser';
import ImportUser from './ImportUser';
import userServices from '../../../services/clientAdmin/userServices';
import DeleteAlert from '../../../components/modal/DeleteModal';
import SnackBar from '../../../components/snackbar/snackbar';

export default function UserTable() {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [importModalOpen, setImportModalOpen] = React.useState(false);
  const [mode, setMode] = React.useState('');
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [editUserId, setEditUserId] = useState(null);

  const [usersData, setUsersData] = React.useState([]);
  const [userId, setUserId] = React.useState('');
  const [errorData, setErrorData] = React.useState([]);
  const [errorDataTableHide, setErrorDataTableHide] = React.useState(false);

  const [loading, setLoading] = useState(true);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleErrorData = (data, shown) => {
    setErrorData(data);
    setErrorDataTableHide(shown);
  };

  const handleImportModalOpen = () => {
    setImportModalOpen(true);
  };
  const handleImportModalClose = () => {
    setImportModalOpen(false);
  };

  const getUsersDetails = async () => {
    try {
      const response = await userServices.getUsersDetails();
      if (response.ok) {
        setUsersData(response.data);
        setLoading(false);
        setUserId(response.data.id);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsersDetails();
  }, []);

  const handleDeleteModalOpen = (userId) => {
    setDeleteAlert(true);
    setDeleteUserId(userId);
  };

  const handleEditModalOpen = (userId) => {
    setMode('edit');
    handleModalOpen(true);
    setEditUserId(userId);
  };

  const snakbarHandle = (message) => {
    setOpenSnackbar(true);
    setSnackbarTitle(message);
  };

  const handleDeleteProject = async () => {
    try {
      const response = await userServices.deleteUserDetailsByID(deleteUserId);
      if (response.ok) {
        getUsersDetails();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setDeleteAlert(false);
        setLoading(false);
      } else if (response.status === 400) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const errorColums = [
    {
      name: 'error',
      label: 'Error',
      options: {
        filter: true,
        sort: false,
        download: false,
      },
    },
    {
      name: 'mandatoryColumns',
      label: 'Mandatory Columns',
      options: {
        filter: true,
        sort: false,
        download: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div className={classes.name}>{value}</div>;
        },
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div className={classes.emailText}>{value}</div>;
        },
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'phone',
      label: 'Phone Number',
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const columns = [
    {
      name: 'id',
      label: 'User ID',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ display: 'flex' }}>
              <Typography>{value}</Typography>
              {tableMeta.rowData[4] === 'CLIENT_ADMIN' && (
                <Iconify icon={'mdi:crown'} width={16} height={14} color="orange" />
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div className={classes.emailText}>{value}</div>;
        },
      },
    },
    {
      name: 'phone',
      label: 'Phone Number',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <div className={classes.emailText}>{value ? `+${value}` : ''}</div>;
        },
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },

    {
      name: '',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={() => {
                    handleEditModalOpen(tableMeta.rowData[0]);
                  }}
                >
                  <EditIcon fontSize="small" style={{ color: '#BCBCBC', marginLeft: '2px' }} />
                </IconButton>

                {tableMeta.rowData[4] !== 'CLIENT_ADMIN' && (
                  <IconButton
                    onClick={() => {
                      handleDeleteModalOpen(tableMeta.rowData[0]);
                    }}
                  >
                    <DeleteIcon fontSize="small" style={{ color: '#BCBCBC' }} />
                  </IconButton>
                )}
              </div>
            </>
          );
        },
      },
    },
  ];

  const ButtonGroup = () => {
    return (
      <Box>
        <Button
          sx={{ marginRight: '10px' }}
          variant="contained"
          onClick={() => {
            handleModalOpen(true);
            setMode('create');
          }}
        >
          <AddIcon /> Add User
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleImportModalOpen(true);
          }}
        >
          <AddIcon />
          Import User
        </Button>
      </Box>
    );
  };

  return (
    <Page title="User">
      <PageHeader pageTitle={'Users'} buttonComponent={<ButtonGroup />} />

      {errorDataTableHide ? (
        <MUIDataTable
          data={errorData}
          columns={errorColums}
          options={{
            responsive: "scroll",
            rowsPerPage: 25,
            rowsPerPageOptions: [25, 50, 75, 100],
            selectableRows: false,
            filter: false,
            download: true,
            print: false,
            textLabels: {
              body: {
                noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
              },
            },
          }}
        />
      ) : (
        <></>
      )}

      <div style={{ marginTop: '1rem' }}>
        <MUIDataTable
          data={usersData}
          columns={columns}
          options={{
            responsive: "scroll",
            rowsPerPage: 25,
            rowsPerPageOptions: [25, 50, 75, 100],
            selectableRows: false,
            filter: false,
            download: false,
            print: false,
            textLabels: {
              body: {
                noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
              },
            },
          }}
        />
        <DeleteAlert
          open={deleteAlert}
          title="Are you sure you want delete this User?"
          confirm={handleDeleteProject}
          close={() => setDeleteAlert(false)}
        />
        <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
      </div>

      <BasicModal
        openModel={modalOpen}
        title={mode === 'create' ? 'Add User' : 'Edit User'}
        closeModel={() => setModalOpen(false)}
        children={
          <AddUser
            mode={mode}
            getUsersDetails={() => getUsersDetails()}
            handleModalClose={() => handleModalClose()}
            id={editUserId}
            snackBarControl={snakbarHandle}
          />
        }
      />

      <BasicModal
        openModel={importModalOpen}
        title={'Import User'}
        closeModel={() => setImportModalOpen(false)}
        children={
          <ImportUser
            handleImportModalClose={() => handleImportModalClose()}
            getUsersDetails={() => getUsersDetails()}
            snackBarControl={snakbarHandle}
            errorData={handleErrorData}
          />
        }
      />
    </Page>
  );
}

const useStyles = makeStyles({
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '155px',
    '&:hover': {
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
  },
  emailText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '215px',
    '&:hover': {
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
  },
});
