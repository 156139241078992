import React from 'react';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { Tooltip, Button } from '@mui/material';

export default function Invoices(props) {
  const classes = useStyles();

  return (
    <div style={{ marginTop: '1rem' }}>
      <table className={classes.table}>
        <tr>
          <th className={classes.tableHeader}>Amount</th>
          <th className={classes.tableHeader} />
          <th className={classes.tableHeader} />
          <th className={classes.tableHeader}>Invoice No</th>
          <th className={classes.tableHeader}>Customer</th>
          {/* <th className={classes.tableHeader}>Due</th> */}
          <th className={classes.tableHeader}>Created</th>
        </tr>
        {props.userDetailsLength ? (
          props.invoiceData.map((item, index) => (
            <tr key={index}>
              <td className={classes.tableData}>
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: item.currencyType,
                }).format(item.amount)}
              </td>
              <td className={classes.tableData}>{item.currencyType}</td>
              <td className={classes.tableData} style={{ textTransform: 'capitalize' }}>
                <Button
                  sx={{
                    color: item.status === 'PAID' ? 'green' : 'red',
                    backgroundColor: item.status === 'PAID' ? '#caf5d4' : 'rgba(254, 112, 0, 0.08)',
                    '&:hover': {
                      color: item.status === 'PAID' ? 'green' : 'red',
                      backgroundColor: item.status === 'PAID' ? '#caf5d4' : 'rgba(254, 112, 0, 0.08)',
                    },
                  }}
                  variant="outlined"
                  className={classes.activeButton}
                >
                  {item.status.toLowerCase().charAt(0).toUpperCase() + item.status.toLowerCase().slice(1)}
                </Button>
              </td>
              <td className={classes.tableData}>{item.invoiceNo}</td>
              <td className={classes.tableData}>{item.customer}</td>
              {/* <td className={classes.tableData}>
              {' '}
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: item.currencyType,
              }).format(item.due)}
            </td> */}
              <td className={classes.tableData}>{moment(item.created).format('lll')}</td>
            </tr>
          ))
        ) : (
          <td className={classes.tableData} style={{ textAlign: 'center' }} colSpan="6">
            No data found
          </td>
        )}
      </table>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    borderRadius: '4px',
    width: '100%',
    boxShadow:
      '0px 2px 4px -1px rgb(145 158 171 / 20%), 0px 4px 5px 0px rgb(145 158 171 / 14%), 0px 1px 10px 0px rgb(145 158 171 / 12%)',
  },
  tableHeader: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '8px',
    padding: '10px',
    textAlign: 'left',
    fontWeight: '400',
    color: 'rgb(26, 27, 37)',
  },
  tableData: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '8px',
    padding: '10px',
  },
  activeButton: {
    padding: '1px 4px',
    marginLeft: '1rem',
    border: 'none',
    fontSize: '0.8rem',
    fontWeight: '550',
    '&:hover': {
      border: 'none',
      fontSize: '0.8rem',
      cursor: 'auto',
    },
  },
}));
