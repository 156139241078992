import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Tooltip, TextField, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { makeStyles } from '@material-ui/styles';
import { Form, Formik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import * as Yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';

import BasicModal from '../../../components/modal/BasicModel';
import DeleteAlert from '../../../components/modal/DeleteModal';
import subscriptionServices from '../../../services/subscriptionServices';
import adminServices from '../../../services/adminServices';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import SnackBar from '../../../components/snackbar/snackbar';

const SubscriptionInfo = ({ data, loading, getData }) => {
  const classes = useStyles();
  const [formLoading, setFormLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [formMode, setFormMode] = useState('CREATE');
  const [billingMethods, setBillingMethods] = useState([]);
  const [durationType, setDurationType] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    billingMethod: '',
    durationType: '',
    description: '',
  });

  const getLookUpDetails = async () => {
    const key = ['billing_method_type', 'duration_type'];

    const result = await adminServices.getLookUpData(key);
    if (result.ok) {
      setBillingMethods(result.data.billing_method_type);
      setDurationType(result.data.duration_type);
    }
  };

  useEffect(() => {
    getLookUpDetails();
  }, []);

  const tableColumns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'billingMethodType',
      label: 'Billing Method',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value.description}</Typography>;
        },
      },
    },

    {
      name: 'durationType',
      label: 'Duration',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value.description}</Typography>;
        },
      },
    },
    {
      name: 'lastUpdatedDate',
      label: 'Last Updated',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{moment(value).format('DD-MM-YYYY')}</Typography>;
        },
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: '',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setFormMode('EDIT');
                  setFormValues({
                    name: tableMeta.rowData[1],
                    billingMethod: tableMeta.rowData[2],
                    durationType: tableMeta.rowData[3],
                    description: tableMeta.rowData[5],
                  });
                  setOpenModal(true);
                  setSubscriptionId(tableMeta.rowData[0]);
                }}
              >
                <EditIcon fontSize="small" style={{ color: '#BCBCBC' }} />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleOpenDeleteModal(tableMeta.rowData[0]);
                }}
              >
                <DeleteIcon fontSize="small" style={{ color: '#BCBCBC' }} />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const handleSubmitForm = async (values) => {
    setFormLoading(true);
    const data = {
      name: values.name,
      description: values.description,
      billingMethodType: values.billingMethod.code,
      durationType: values.durationType.code,
    };

    try {
      if (formMode === 'CREATE') {
        const infoRes = await subscriptionServices.postSubscriptionInfo(data);
        if (infoRes.ok) {
          getData();
          setOpenModal(false);
        }
      } else {
        const infoRes = await subscriptionServices.putSubscriptionInfo(subscriptionId, data);
        if (infoRes.ok) {
          getData();
          setOpenModal(false);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setFormLoading(false);
  };

  const handleDeleteBillingType = async (id) => {
    const deleteRes = await subscriptionServices.deleteSubscriptionInfo(subscriptionId);
    if (deleteRes.ok) {
      setOpenSnackbar(true);
      setSnackbarTitle('Billing type deleted successfully');
      getData();
    } else {
      console.log('deleted');
    }
    setDeleteAlert(false);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteAlert(true);
    setSubscriptionId(id);
  };

  return (
    <Box>
      <MUIDataTable
        data={data}
        columns={tableColumns}
        // title="Subscription Info"
        options={{
          rowsPerPage: 25,
          responsive: "scroll",
          rowsPerPageOptions: [25, 50, 75, 100],
          selectableRows: 'none',
          filter: false,
          download: false,
          print: false,
          viewColumns: false,
          textLabels: {
            body: {
              noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
            },
          },
          customToolbar: (value) => {
            return (
              <IconButton
                variant="contained"
                onClick={() => {
                  setFormValues({
                    name: '',
                    billingMethod: '',
                    durationType: '',
                    description: '',
                  });
                  setOpenModal(true);
                  setFormMode('CREATE');
                }}
              >
                <Tooltip title="Add Subscription info">
                  <AddCircleOutlineIcon fontSize="small" className={classes.addIcon} />
                </Tooltip>
              </IconButton>
            );
          },
        }}
      />
      <BasicModal
        openModel={openModal}
        title={'Add Subscription plan'}
        closeModel={() => setOpenModal(false)}
        children={
          <Box mt={'0.55rem'}>
            <Formik
              initialValues={formValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().trim().min(3, 'Name contains at least 3 character').required('Required').nullable(),
                billingMethod: Yup.string().required('Required').nullable(),
                durationType: Yup.string().required('Required').nullable(),
              })}
              onSubmit={(values) => handleSubmitForm(values)}
            >
              {({ errors, handleBlur, handleChange, values, touched, setFieldValue }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid mt={1} item xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        type="text"
                        id="outlined-basic"
                        name="name"
                        label="Subscription Name *"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sx={6} sm={6}>
                      <Autocomplete
                        options={billingMethods}
                        filterSelectedOptions
                        autoHighlight
                        getOptionLabel={(option) => option.description || ''}
                        value={values.billingMethod}
                        onChange={(e, value) => {
                          setFieldValue('billingMethod', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Billing method *"
                            variant="outlined"
                            name="billingMethod"
                            fullWidth
                            error={Boolean(touched.billingMethod && errors.billingMethod)}
                            helperText={touched.billingMethod && errors.billingMethod}
                          />
                        )}
                      />
                      <ErrorFocus />
                    </Grid>

                    <Grid item xs={12} sx={6} sm={6}>
                      <Autocomplete
                        options={durationType}
                        filterSelectedOptions
                        autoHighlight
                        getOptionLabel={(option) => option.description || ''}
                        value={values.durationType}
                        onChange={(e, value) => {
                          setFieldValue('durationType', value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Duration Type *"
                            variant="outlined"
                            name="durationType"
                            fullWidth
                            error={Boolean(touched.durationType && errors.durationType)}
                            helperText={touched.durationType && errors.durationType}
                          />
                        )}
                      />
                      <ErrorFocus />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        multiline
                        minRows={2}
                        type="text"
                        id="outlined-basic"
                        name="description"
                        label="Description  "
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        variant="outlined"
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                        sx={{
                          '.css-18dmrcd-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '0px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <LoadingButton variant="contained" color="primary" loading={formLoading} type="submit" fullWidth>
                        {formMode === 'CREATE' ? 'Create' : 'Update'}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        }
      />

      <DeleteAlert
        open={deleteAlert}
        title="Are you sure you want delete this subscription plan"
        confirm={handleDeleteBillingType}
        close={() => setDeleteAlert(false)}
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  addIcon: {
    '&:hover': {
      color: '#fa7343',
    },
  },
}));

export default SubscriptionInfo;
