import React, { useState } from 'react';
import { Button, Grid, Paper, Typography, Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GaugeChart from 'react-gauge-chart';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ReactApexChart from 'react-apexcharts';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useLocation } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import Page from '../../../components/Page';
import Cards from '../../../components/cards/UserCard';
import PageHeader from '../../../components/PageHeader';
import AnalyticsTable from './Analytics';
import analytics from '../../../services/analytics/analytics';
import LottieLoadingApexChart from '../../../components/LottieLoadingApexChart';
import Iconify from '../../../components/Iconify';
import BasicModal from '../../../components/modal/BasicModel';
import { CoursesAnalyticsTab } from './CoursesAnalyticsTab';
import { CaseStudiesTab } from './CaseStudiesTab';

export const CourseAnalyticDetails = () => {
  const classes = useStyles();
  const location = useLocation();

  const courseId = location.state && location.state.id;
  const [efficiencyScore, setEfficiencyScore] = useState(0);
  const [totalProgress, setTotalProgress] = useState(0);
  const [courseAnalytics, setCourseAnalytics] = useState(null);
  const [moduleData, setModuleData] = React.useState([]);
  const [learnData, setLearnData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const isGoBack = true;
  const [openModal, setOpenModal] = useState(false);
  const [avgSalary, setAvgSalary] = useState(null);
  const [hoursPerDay, setHoursPerDay] = useState(null);
  const [pricePerPerson, setPricePerPerson] = useState(null);
  const [showCaclRes, setShowCaclRes] = useState(false);
  const [value, setValue] = React.useState('1');

  const [averageRightAnswer, setAverageRightAnswer] = useState();
  const [pass, setPass] = useState();
  const [fail, setFail] = useState();
  const [testCompleted, setTestCompleted] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [roiCalc, setRoiCalc] = useState({
    spentOnTraining: null,
    savingsPerYear: null,
    roi: null,
    roip: null,
  });

  const getAnalytics = async () => {
    try {
      const response = await analytics.getAnalytics(courseId);
      if (response.ok) {
        setCourseAnalytics(response.data);

        if (response?.data?.overallModulePercentage) {
          const moduleData = response.data.overallModulePercentage.map((res) => {
            return {
              x: res.name,
              y: res.overallModulePercentage,
            };
          });
          // setModuleData(moduleData);
          // console.log(moduleData);
          setLoading(false);
        }
        if (response?.data?.learnerProgress) {
          const learnData = response.data.learnerProgress.map((res) => {
            return {
              x: res.name,
              y: res.courseProgress,
            };
          });
          setLearnData(learnData);
          setLoading(false);
        }
      }
      if (!response.ok) {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAnalytics();
  }, []);

  const calculatingRoiScore = (learnerCount, avrEff, avrSalary, hour, price) => {
    console.log(learnerCount, avrEff, avrSalary, hour, price);

    // avrEff = parseFloat(avrEff.replace('X', ''));
    // avrSalary = parseFloat(avrSalary.replace(',', ''));
    // console.log(formatter.format(learnerCount * price, 1));
    // document.getElementById('spent-on-training').value = formatter.format(learnerCount * price, 1);
    const mesSaved = ((12 - 12 / avrEff) * hour) / 8;
    const savings = ((learnerCount * avrSalary) / 12) * mesSaved;

    // document.getElementById('savings').value = formatter.format(Math.round(savings));
    const roi = savings - learnerCount * price;
    // document.getElementById('roi').value = formatter.format(Math.round(roi));
    // document.getElementById('roip').value = Math.round(roi / (learnerCount * price));
    setRoiCalc({
      spentOnTraining: learnerCount * price,
      savingsPerYear: Math.round(savings),
      roi: Math.round(roi),
      roip: Math.round(roi / (learnerCount * price)),
    });

    console.log(Math.round(roi / (learnerCount * price)), mesSaved, savings, roi);
  };

  return (
    <div>
      <Page title={`${location.state && location.state.title}-analytics`}>
        <PageHeader
          pageTitle={location.state && location.state.title}
          goBack={isGoBack}
          // buttonComponent={<RoiButton />}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<Iconify icon={'fluent:calculator-arrow-clockwise-20-regular'} />}
          sx={{ position: 'absolute', top: '5.4rem', right: '1.8rem' }}
          onClick={() => {
            setOpenModal(true);
            setAvgSalary(null);
            setPricePerPerson(null);
            setHoursPerDay(null);
          }}
        >
          ROI Calculator
        </Button>

        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                sx={{ color: '#ff6600', border: '#ff6600;', margin: '0px 0px 0px 10px' }}
                aria-label="lab API tabs example"
              >
                <Tab label="Courses Analytics" value="1" />
                <Tab label="Case Study" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <CoursesAnalyticsTab
                courseid={courseId}
                setEfficiencyScore={setEfficiencyScore}
                setTotalProgress={setTotalProgress}
                setCourseAnalytics={setCourseAnalytics}
                setModuleData={setModuleData}
                setLearnData={setLearnData}
                setLoading={setLoading}
                efficiencyScore={efficiencyScore}
                totalProgress={totalProgress}
                courseAnalytics={courseAnalytics}
                moduleData={moduleData}
                learnData={learnData}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value="2">
              <CaseStudiesTab courseId={courseId} />
            </TabPanel>
          </TabContext>
        </Box>
      </Page>

      <BasicModal
        openModel={openModal}
        title={'ROI Calculator'}
        closeModel={() => {
          setOpenModal(false);
          setShowCaclRes(false);
        }}
        children={
          <Paper elevation={2} sx={{ padding: '4px' }}>
            <Grid container spacing={0.5}>
              <Grid item xs={12} sm={6} md={6}>
                <Box className={classes.textBox}>
                  <Typography># of participants</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box className={classes.textBox}>
                  <Typography>{courseAnalytics ? courseAnalytics.numberOfLearners : 0}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className={classes.textBox}>
                  <Typography>Average Efficiency Score</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className={classes.textBox}>
                  <Typography>{efficiencyScore}x</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box className={classes.textBox}>
                  <Typography>Average Salary</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  sx={{
                    '.css-10kqz53-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '4px 12px',
                    },
                  }}
                  fullWidth
                  type="number"
                  name="name"
                  value={avgSalary}
                  onChange={(e) => setAvgSalary(e.target.value)}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box className={classes.textBox}>
                  <Typography>Hours per day on the tool</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  sx={{
                    '.css-10kqz53-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '4px 12px',
                    },
                  }}
                  type="number"
                  value={hoursPerDay}
                  onChange={(e) => setHoursPerDay(e.target.value)}
                  fullWidth
                  name="name"
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Box className={classes.textBox}>
                  <Typography>Price per person</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  sx={{
                    '.css-10kqz53-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '4px 12px',
                    },
                  }}
                  fullWidth
                  type="number"
                  name="name"
                  value={pricePerPerson}
                  onChange={(e) => setPricePerPerson(e.target.value)}
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box textAlign={'center'} mt={1} mb={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      calculatingRoiScore(
                        courseAnalytics.numberOfLearners,
                        efficiencyScore,
                        avgSalary,
                        hoursPerDay,
                        pricePerPerson
                      );
                      setShowCaclRes(true);
                    }}
                    disabled={avgSalary === null || hoursPerDay === null || pricePerPerson === null}
                  >
                    Get your result
                  </Button>
                </Box>
              </Grid>

              {showCaclRes && (
                <>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.textBox}>
                      <Typography>Spent on training</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.textBox}>
                      <Typography>{roiCalc.spentOnTraining.toLocaleString()}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.textBox}>
                      <Typography>Savings per year</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.textBox}>
                      <Typography>{roiCalc.savingsPerYear.toLocaleString()}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.textBox}>
                      <Typography>ROI $</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.textBox}>
                      <Typography>{roiCalc.roi.toLocaleString()}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.textBox}>
                      <Typography>ROI %</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Box className={classes.textBox}>
                      <Typography>{roiCalc.roip}</Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        }
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  apexchartWidth: {
    width: '74%',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
    height: '450px',
    [theme.breakpoints.down('md')]: {
      width: '45%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  boxshodowone: {
    boxShadow: '0 2px 3px 0 rgb(32 40 45 / 8%)',
    borderRadius: '12px',
    marginTop: '1rem',
  },
  typoPadding: {
    padding: '1rem 0rem 0rem 1rem',
    fontWeight: '500',
  },
  boxCard: {
    height: '180px',
    background: '#D1EDC0 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLorem: {
    height: '180px',
    background: '#FFE7AB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLoremAverage: {
    height: '180px',
    background: '#FEEADB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLoremMeter: {
    height: '180px',
    background: '#FEEADB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },

  typopadding: {
    paddingTop: '2rem',
    fontSize: '1rem',
  },
  number: {
    marginTop: '30px',
    margin: 'auto',
    textAlign: 'center',
    width: '30%',
    background: '#FFFFFF 0% 0% no-repeat ',
    borderRadius: '10px',
    opacity: 1,
  },
  numberCircular: {
    margin: 'auto',
    textAlign: 'center',
    marginTop: '0.5rem',
  },
  numberMeter: {
    margin: 'auto',
    textAlign: 'center',
    width: '70% ',
    height: '196px',
    [theme.breakpoints.up('xl')]: {
      width: '60% !important',
      height: '196px',
    },
  },

  typonumber: {
    padding: '6px',
    fontSize: '1.5rem',
    fontWeight: '700',
  },
  progress: {
    width: '100%',
    height: '95px',
    marginBottom: '0.9rem',
  },
  textBox: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    textAlign: 'center',
    background: '#eee',
    border: '1px solid #dce0e4',
    borderRadius: '4px',
    padding: '4px',
  },
}));
