import React from 'react';
// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export default [
  {
    title: 'Solutions',
    // path: '/app/dashboard',
    // icon: getIcon('akar-icons:dashboard'),
    children: [
      { title: 'Individual Learning Solution', path: '/self-study' },
      { title: 'Business Learning Solutions', path: '/business' },
      { title: 'Educational Learning Solutions', path: '/higher-education' },
    ],
  },
  {
    title: 'Courses',
    // path: '/app/users',
    // icon: getIcon('ph:users-three-bold'),
    children: [
      { title: 'Excel Efficiency', path: '/excel-efficiency' },
      { title: 'Python Coding', path: '/python' },
      { title: 'Financial Modeling', path: '/financial-modeling' },
      { title: 'Powerpoint Speed', path: '/powerpoint' },
      { title: 'Explore more', path: '/explore-course' },
    ],
  },
  {
    title: 'Course Creation',
    path: '/course-creation',
    // icon: getIcon('bx:book-reader'),
  },
  {
    title: 'Pricing',
    path: '/pricing',
    // icon: getIcon('ic:outline-sell'),
  },
  {
    title: 'Learning Center',
    // path: '/app/reports',
    hrefLink: 'https://www.keyskillset.com/learning-center',
    // icon: getIcon('bi:bar-chart'),
  },
];
