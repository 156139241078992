import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, Link, TextField, Typography, FormControl, FormHelperText, Box, Tooltip } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@mui/styles';
import BasicModals from '../../../components/modal/BasicModel';
import SnackBar from '../../../components/snackbar/snackbar';

const HighlightCellsModel = ({ open, handleClose, handleSubmit }) => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <BasicModals
        openModel={open}
        closeModel={handleClose}
        title='Mouse Click'
        children={
          <Formik
            initialValues={{
              widowImage: '',
              macImage: '',
              windowBase64: '',
              macBase64: ''
            }}
            validationSchema={Yup.object().shape({
              widowImage: Yup.mixed()
                .required('Background image required')
                .test(
                  'fileSize',
                  'Maximum file size is 4MB',
                  (value) => value && typeof value === 'object' && value.size < 4194304
                ),
              macImage: Yup.mixed()
                .required('Background image required')
                .test(
                  'fileSize',
                  'Maximum file size is 4MB',
                  (value) => value && typeof value === 'object' && value.size < 4194304
                ),
            })}
            onSubmit={(values) => {
              console.log("your values are ",values);
              handleSubmit(values);
            }}
          >
            {({ values, errors, touched, handleBlur, setFieldValue }) => (
              <Form>

                <Grid container spacing={4} rowSpacing={4}>

                  {/* START OF WINDOWS PART */}
                  <Grid item xs="12" sm="12" md="12">
                  <Typography>
                    <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                        Background Image
                    </span>
                  </Typography>
                    <div>
                      <FormControl
                        required
                        component="fieldset"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        name="widowImage"
                        value={values.widowImage}
                      >
                        <DropzoneArea
                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                          showPreviews
                          dropzoneText="Drag and Drop Image or Browse File"
                          showPreviewsInDropzone={false}
                          maxFileSize={104857600}
                          filesLimit={1}
                          showAlerts={false}
                          value={values.widowImage}
                          onChange={async (file) => {
                            console.log(file);
                            setFieldValue('widowImage', file[0]);
                            if (file[0]?.size <= 4194304) {
                              setFieldValue('macImage', file[0]);
                              setFieldValue('windowBase64', await convertBase64(file[0]));
                              setFieldValue('macBase64', await convertBase64(file[0]));
                            }
                          }}
                          useChipsForPreview
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          showFileNamesInPreview
                        />
                        <FormHelperText style={{ color: '#F44336' }}>
                          {(errors.widowImage || touched.widowImage) && errors.widowImage}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  {/* END OF WINDOWS PART */}

                  <Grid item xs="12">
                    <Button fullWidth color="primary" variant="contained" type="submit">
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        }
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  boxItem: {
    border: '1px solid gray',
    padding: '4px 8px',
    fontSize: '0.8rem',
  },
}));

export default HighlightCellsModel;
