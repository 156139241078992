import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import SubCard from '../../../../components/cards/SubCard';

export default function Billing() {
  const classes = useStyles();

  return (
    <div style={{ marginTop: '1rem' }}>
      <SubCard
        sx={{ mt: 3 }}
        title="Invoices"
        subtitle="View all invoices"
        children={
          <div style={{ padding: '1rem' }}>
            <Typography>Coming Soon</Typography>
          </div>
        }
      />
      <SubCard
        sx={{ mt: 3 }}
        title="Payments"
        subtitle="Payment History"
        children={
          <div style={{ padding: '1rem' }}>
            <Typography>Coming Soon</Typography>
          </div>
        }
      />
      <SubCard
        sx={{ mt: 3 }}
        title="Billing Address"
        subtitle="View billing address"
        children={
          <div style={{ padding: '1rem' }}>
            <Typography>Coming Soon</Typography>
          </div>
        }
      />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({}));
