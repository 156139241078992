/* eslint no-else-return: "error" */
/* eslint no-var: off */

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled, useTheme } from '@mui/material/styles';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/python';
import simulationApi from '../../services/simulation';
import MouseClickGrid from './Grid';

const GridRenderer = ({toggleClose,prevState,setFunction,gridURL}) => {
  
  const classes = useStyles();
  const theme = useTheme();

  const getBase64FromUrl = async (url = 'https://ik.imagekit.io/k38yuwpb2/windows1654795623.jpeg') => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const [backgroundImageVar,setBackgroundImageVar] = useState("");
  const [backgroundImageWidth, setBackgroundImageWidth] = useState(0);
  const [backgroundImageHeight, setBackgroundImageHeight] = useState(0);

  const addBase64 = async () => {
    const base64Img = await getBase64FromUrl(gridURL);
    const backgroundImageTemp = `url(${base64Img})`;
    setBackgroundImageVar(backgroundImageTemp);
  }

  const [screenWidth,setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Calculate the width and height by fetching the image dimensions using JavaScript
    const img = new Image();
    img.src = backgroundImageVar.slice(4, -1).replace(/['"]+/g, ''); // Remove 'url(' and any surrounding quotes
    img.onload = () => {
      const parentElement = document.getElementById('image-container'); // Replace with the actual ID of the container element
      const width = parentElement.clientWidth;
      const height = parentElement.clientHeight;
      
      setBackgroundImageWidth(width);
      setBackgroundImageHeight(height);
    };
  }, [backgroundImageVar,screenWidth]);

  useEffect(() => {
    addBase64();

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Box
        className={classes.gridContainer}
        sx={{
          backgroundImage: backgroundImageVar,
        }}
        id="image-container"
      >
          {backgroundImageWidth > 0 && backgroundImageHeight > 0 && 
          <MouseClickGrid toggleClose={toggleClose} prevState={prevState} pixelSet={setFunction} backgroundHeight={backgroundImageHeight} backgroundWidth={backgroundImageWidth} />
          }
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: `calc(100vh - ${70}px)`,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',

    display: 'flex',
  }
}));

export default GridRenderer;