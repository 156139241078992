import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function BasicPaper(props) {
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.paper}>
        <div>{props.children}</div>
      </Box>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '1rem',
    border: '1px solid #ECECEC',
    boxShadow: '0px 3px 30px #0000000A',
    borderRadius: '10px',
  },
}));
