import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, TableRow, TableCell, Typography, Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment';
import SchoolIcon from '@mui/icons-material/School';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import analytics from '../../../services/analytics/analytics';
import './analytics.css'


export default function AnalyticsTable({ courseId, getEfficiencyScore }) {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  function groupArrayOfObjects(list, key) {
    return list.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const getAnalyticsTable = async () => {
    // let i = 0;
    let progressAdd = 0;
    let eachModP = 0;
    try {
      const response = await analytics.getAnalyticsTable(courseId);
      if (response.ok) {
        const data = await response.data.analytics.map((item) => {
          progressAdd += parseInt(item.progress, 10);
          return item;
        });
        const courseProgress = progressAdd / response.data.analytics.length;

        setTableData(data);
        setLoading(false);

        const allModules = response.data.analytics
          .map((res) => {
            return res.moduleProgress;
          })
          .reduce((pre, cur) => {
            return pre.concat(cur);
          })
          .map((e, i) => {
            return { moduleId: e.moduleId, moduleName: e.moduleName, progress: e.progress };
          });

        const groupedPeople = await groupArrayOfObjects(allModules, 'moduleId');
        const graphData = Object.keys(groupedPeople).map((key, index) => {
          const getpro = groupedPeople[key].map((mod) => {
            eachModP += mod.progress;
            return { name: mod.moduleName, eachModP };
          });
          eachModP = 0;
          return {
            x: getpro[getpro.length - 1].name,
            y: Math.round(getpro[getpro.length - 1].eachModP / response.data.analytics.length),
          };
        });

        getEfficiencyScore(parseInt(response.data.averageEfficiency, 10), courseProgress, graphData);
      }
      if (!response.ok) {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAnalyticsTable();
  }, []);

  const courseColumnData = [
    {
      name: 'moduleProgress',
      label: 'course',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'skillSetIQ',
      label: 'skillSetIQ',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'name',
      label: 'Learners',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: 'startDate',
      label: 'Start Date',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return <Typography sx={{ minWidth: '80px' }}>
            {value && moment(value).format('DD-MM-YYYY')}</Typography>;
        },
      },
    },

    {
      name: 'efficiencyScore',
      label: 'Efficiency score',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return <Typography sx={{ minWidth: '80px' }}>{parseInt(value, 10)}x</Typography>;
        },
      },
    },
    {
      name: 'simulation',
      label: (
        <Box>
          <Typography variant="subtitle2">Overall Progress</Typography>
          <Box display="flex">
            <Tooltip title="Simulation" arrow>
              <SchoolIcon sx={{ marginRight: '3.5rem', color: '#FE7000' }} />
            </Tooltip>
            <Tooltip title="skillSetIQ" arrow>
              <SportsEsportsOutlinedIcon sx={{ color: '#FE7000' }} />
            </Tooltip>
          </Box>
        </Box>
      ),
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box display="flex">
              <Typography sx={{ minWidth: '80px' }}>{`${value}%`}</Typography>
              <Typography sx={{ minWidth: '80px' }}>{`${tableMeta.rowData[1]}%`}</Typography>
            </Box>
          );
        },
      },
    },

    {
      name: 'progress',
      label: 'Course Progress',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Typography sx={{ minWidth: '80px', textAlign: 'center' }}>{value}%</Typography>
              <LinearProgress sx={{ borderRadius: '4px', height: '8px' }} variant="determinate" value={value} />
            </>
          );
        },
      },
    },
  ];

  const courseColumnOption = {
    filterType: 'checkbox',
    search: false,
    print: false,
    pagination: true,
    download: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 75, 100],
    selectableRows: false,
    expandableRows: true,
    responsive: 'standard',
    expandableRowsHeader: false,
    elevation: 0,
    textLabels: {
      body: {
        noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
      },
    },
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <>
          <ThemeProvider theme={customTheme}>
            <TableRow>
              <TableCell colSpan={rowData.length + 1}>
                <MUIDataTable
                  data={rowData[0] !== null ? rowData[0] : []}
                  columns={moduleColumnData}
                  options={moduleColumnOption}
                />
              </TableCell>
            </TableRow>
          </ThemeProvider>
        </>
      );
    },
  };

  const moduleColumnData = [
    {
      name: 'subModules',
      label: 'Submodule',
      options: {
        filter: false,
        sort: false,
        display: false,
        customHeadRender: () => null,
        viewColumns: false,
      },
    },

    {
      name: 'moduleName',
      label: 'Module Name',
      options: {
        sort: false,
        display: true,
        customHeadRender: () => null,
        setCellProps: () => ({ style: { width: '51.5%' } }),
        customBodyRender: (value, tableMeta) => {
          return <Typography sx={{ minWidth: '80px' }}>{value}</Typography>;
        },
      },
    },
    {
      name: 'skillSetIQ',
      label: 'Course Progress',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },

    {
      name: 'skillSetIQ',
      label: 'Course Progress',
      options: {
        filter: true,
        sort: false,
        display: false,
        customHeadRender: () => null,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Typography sx={{ minWidth: '80px', textAlign: 'center' }} />
            </>
          );
        },
      },
    },
    {
      name: 'simulation',
      label: 'Overall progress',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box display="flex">
              <Typography sx={{ minWidth: '76px', fontSize: '0.867rem' }}>{`${value}%`}</Typography>
              <Typography sx={{ fontSize: '0.867rem' }}>{`${tableMeta.rowData[2]}%`}</Typography>
            </Box>
          );
        },
      },
    },
  
    {
      name: 'progress',
      label: 'Module Progress',
      options: {
        filter: true,
        sort: false,
        display: true,
        customHeadRender: () => null,
        setCellProps: () => ({
          style: {
            width: '18%',
            paddingRight: '16px',
            paddingLeft: '0',
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <Box>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {value}%
              </Typography>
              <LinearProgress
                sx={{
                  '.css-1x9r62b-MuiLinearProgress-bar1': {
                    backgroundColor: '#FE7000 ',
                  },
                  borderRadius: '89px',
                  backgroundColor: 'rgb(254, 200, 158) ',
                  height: '8px',
                }}
                variant="determinate"
                value={value}
              />
            </Box>
          );
        },
      },
    },
  ];

  const moduleColumnOption = {
    search: false,
    print: false,
    pagination: false,
    download: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 10,
    elevation: 0,
    expandableRows: true,
    expandableRowsHeader: false,
    responsive: "scroll",
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <>
          <ThemeProvider theme={SubModulecustomTheme}>
            <TableRow>
              <TableCell colSpan={rowData.length + 1}>
                <MUIDataTable data={rowData[0]} columns={submoduleColumn} options={submoduleOption} />
              </TableCell>
            </TableRow>
          </ThemeProvider>
        </>
      );
    },
    selectableRows: false,
  };

  const submoduleColumn = [
    {
      name: 'submoduleName',
      label: 'Submodule Name',
      options: {
        // filter: true,
        sort: false,
        display: true,
        customHeadRender: () => null,
        setCellProps: () => ({
          style: {
            width: '55.6%',
            '@media (min-width: 780px)': {
              width: '55%',
            },
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return <Typography sx={{ marginLeft: '3rem' }}>{value}</Typography>;
        },
      },
    },
    {
      name: 'skillSetIQ',
      label: 'Simulation',
      options: {
        filter: true,
        sort: false,
        display: false,
        customHeadRender: () => null,
        customBodyRender: (value, tableMeta) => {
          return <Typography sx={{ minWidth: '80px', textAlign: 'center' }} />;
        },
      },
    },

    {
      name: 'simulation',
      label: 'Course Progress',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box display="flex">
              <Box sx={{ width: '80px' }}>
                {value ? (
                  <CheckCircleOutlineOutlinedIcon fontSize="small"
                   style={{ color: '#00b673' }} />
                ) : (
                  <HighlightOffOutlinedIcon fontSize="small"
                   style={{ color: '#ffad6c' }} />
                )}
              </Box>
              <Box>
                {tableMeta.rowData[1] ? (
                  <CheckCircleOutlineOutlinedIcon fontSize="small" style={{ color: '#00b673' }} />
                ) : (
                  <HighlightOffOutlinedIcon fontSize="small" style={{ color: '#ffad6c' }} />
                )}
              </Box>
            </Box>
          );
        },
      },
    },
    {
      name: 'skillSetIQ',
      label: 'Simulation',
      options: {
        filter: true,
        sort: false,
        display: true,
        customHeadRender: () => null,
        customBodyRender: (value, tableMeta) => {
          return <Typography sx={{ minWidth: '80px', textAlign: 'center' }} />;
        },
      },
    },
  ];

  const submoduleOption = {
    search: false,
    print: false,
    pagination: false,
    download: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 75, 100],
    selectableRows: false,
    responsive: "scroll",
    expandableRowsHeader: false,
    elevation: 0,
  };

  return <MUIDataTable data={tableData} columns={courseColumnData} options={courseColumnOption} />;
}

const customTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '0px solid',
          padding: '8px 0px',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        fixedLeft: {
          left: '12px',
        },
      },
    },

    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#f0f0f0;',
          },
          '&:nth-child(even)': {
            backgroundColor: '#f9f9f9;',
          },
        },
      },
    },
    MUIDataTableHead: {
      styleOverrides: {
        main: {
          display: 'none',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1: { backgroundColor: '#FE7000 !important' },
      },
    },
  },
});

const SubModulecustomTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          borderBottom: '0px solid',
          padding: '4px 2px 8px 0px',
        },
      },
    },
    // MUIDataTable: {
    //   styleOverrides: {
    //     paper: {
    //       // marginLeft: '-1rem !important',
    //     },
    //   },
    // },

    MUIDataTableHeadRow: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#eef6f09c',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1: { backgroundColor: '#FE7000 !important' },
      },
    },
  },
});
