import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

export default function BasicCard(props) {
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.box}>
        <div>{props.children}</div>
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: '#FAFAFA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    padding: '1rem',
    minHeight: '6.1rem',
    // display: 'flex',
    // alignItem: 'center',
    // justifyContent: 'space-between',
  },
}));
