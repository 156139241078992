
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField, Button, Chip, Box, Paper, Typography, InputAdornment, IconButton, FormHelperText } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import DOMPurify from 'dompurify';
import DialogModal from '../../../components/modal/DialogModal';
import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';

const GenericAssessmentAdd = ({ open, onClose, callBack }) => {
  const scrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c1c1c1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a1a1a1',
    },
    scrollbarWidth: 'thin',
    scrollbarColor: '#c1c1c1 #f1f1f1',
  };
  const [loading, setLoading] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [hashtag, setHashtag] = useState([]);
  const [category, setCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [nodata, setNodata] = useState(true);

  useEffect(async () => {
    await getLookUpDetails();
  }, []);

  useEffect(() => {
    setLoading(false)
  }, [open]);

  const handleAddTag = () => {
    if (searchTerm && !tags.includes(searchTerm)) {
      setTags([...tags, searchTerm]);
      getQuestionList([...tags, searchTerm]);
      setIsSearched(true);
    }
    setSearchTerm("");
  };

  const handleRemoveTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    // filterQuestions(newTags);
    // getQuestionList(newTags);
    console.log(searchTerm, "searchTermsearchTerm");
    console.log(newTags, "newTags");

    if (newTags?.length > 0) {
      getQuestionList([...newTags]);
    }
    else {
      setQuestionList([])
    }

    setIsSearched(false);
  };


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddQuestion = (question) => {
    if (!selectedQuestions.some(q => q.id === question.id)) {
      setSelectedQuestions([...selectedQuestions, question]);
    }
  };

  const handleRemoveQuestion = (question) => {
    setSelectedQuestions(selectedQuestions.filter(q => q.id !== question.id));
  };


  const getQuestionList = async (data) => {
    setNodata(false)
    const result = await adminServices.getQuestionData(data);
    if (result.ok) {
      if(result.data?.length === 0){
        setNodata(true)
      }
      else{
        setQuestionList(result.data)
        setNodata(false)
      }
    }
  };


  const getLookUpDetails = async () => {
    const key = ['hashtag', 'course_category'];
    const result = await adminServices.getLookUpData(key);
    if (result.ok) {
      setHashtag(result.data.hashtag);
      setCategory(result.data.course_category);
    }
  };


  const Validation = () => {
    if (!name) {
      setNameError("This field is required")
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    const valid = Validation()
    if (valid) {
      setLoading(true);
      const filteredquestions = selectedQuestions.map((data) => data.id)

      const details = {
        name,
        selectedquestions: filteredquestions,
        category: "general"
      }
      try {
        const response = await adminServices.genericAssessMent(details);
        if (response.ok) {
          setSnackbarTitle('Assignment created successfully');
          setOpenSnackbar(true);
          CloseFunction()
          setName('')
          setLoading(false);
          callBack()
        }

      } catch (error) {
        console.log(error);
      }

    }
  };

  const CloseFunction = () => {
    setQuestionList([])
    setSelectedQuestions([])
    setTags([])
    onClose()
    setIsSearched(false);
  }

  return (
    <>
      <DialogModal open={open} handleClose={CloseFunction} title="Generic Assessment">
        <Box sx={{ mb: 2 }}>
          <TextField
            variant="outlined"
            fullWidth
            id="addname"
            placeholder="Name"
            value={name}
            onChange={(e) => { setName(e.target.value); setNameError(''); }}
            sx={{
              bgcolor: "#f0f0f0",
              borderRadius: 1,
              height: 36,
              '& .MuiInputBase-input': {
                fontSize: 14,
                padding: "8px 12px",
              },
            }}
            error={!!nameError}
          />
          {nameError && (
            <FormHelperText error>{nameError}</FormHelperText>
          )}
        </Box>

        {selectedQuestions.length > 0 && (
          <>
            <Typography
              variant="body2"
              sx={{ fontSize: '0.875rem', textDecoration: 'underline', mb: 1 }}
            >
              Selected Questions
            </Typography>
            <Paper
              sx={{
                mb: 2,
                p: 1,
                bgcolor: "#e3faf4",
                width: '100%',
                maxHeight: selectedQuestions.length > 4 ? '150px' : 'auto',
                overflowY: 'auto',
                ...scrollbarStyles,
              }}
            >
              {selectedQuestions.map((question) => (
                <Box key={question.id} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  {/* <Typography variant="body2" sx={{ flexGrow: 1, fontSize: '0.75rem' }}>
                    {question.question_text}
                  </Typography> */}
                  <Typography
                      id='questionviewadd1'
                      variant="body2"
                      sx={{
                        flexGrow: 1,
                        fontSize: '0.75rem',
                      }}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question_text) }}
                    />
                  <Button
                    variant="outlined"
                    color="error"
                    id="removeaddquestiontag"
                    onClick={() => handleRemoveQuestion(question)}
                    sx={{
                      fontSize: '0.75rem',
                      minWidth: '24px',
                      minHeight: '24px',
                      padding: '2px',
                      borderRadius: '12px',
                      '& .MuiButton-label': { fontSize: '1rem' },
                    }}
                  >
                    x
                  </Button>
                </Box>
              ))}
            </Paper>
          </>
        )}

        <Paper sx={{ mb: 2, p: 1, bgcolor: "#f7f7f7", width: '100%' }}>
          <Box sx={{ display: "flex", mb: 1 }}>
            <TextField
              variant="outlined"
              fullWidth
              id="searchaddquestion"
              placeholder="Search Questions..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                bgcolor: "#f0f0f0",
                borderRadius: 1,
                height: 36,
                '& .MuiInputBase-input': {
                  fontSize: 14,
                  padding: "8px 12px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton id="searchiconadd" onClick={handleAddTag} sx={{ p: 0, color: "#1976d2" }}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1 }}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleRemoveTag(index)}
                color="primary"
                size="small"
                sx={{ fontSize: 10 }}
              />
            ))}
          </Box>

          {isSearched && questionList?.length === 0 ? (
            <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
             {nodata === false ?"loading...":"No records found"}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: questionList.length > 4 ? '140px' : 'auto',
                overflowY: 'auto',
                ...scrollbarStyles,
              }}
            >
              {questionList?.map((question) => {
                const isSelected = selectedQuestions.some((selected) => selected.question_text === question.question_text);

                return (
                  <Box key={question.id} sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    {/* <Typography
                id='questionview1'
                variant="body2"
                sx={{
                  flexGrow: 1,
                  fontSize: '0.75rem',
                  bgcolor: isSelected ? "#cfe0ff" : "transparent",
                  padding: '4px',
                  borderRadius: '4px',
                }}
              >
                {question.question_text}
              </Typography> */}
                    <Typography
                      id='questionviewadd'
                      variant="body2"
                      sx={{
                        flexGrow: 1,
                        fontSize: '0.75rem',
                        bgcolor: isSelected ? "#cfe0ff" : "transparent",
                        padding: '4px',
                        borderRadius: '4px',
                      }}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question_text) }}
                    />
                    <Button
                      id='questionadd1'
                      variant="outlined"
                      color="primary"
                      onClick={() => handleAddQuestion(question)}
                      sx={{
                        fontSize: '0.75rem',
                        minWidth: '24px',
                        minHeight: '24px',
                        padding: '2px',
                        borderRadius: '12px',
                        '& .MuiButton-label': { fontSize: '1rem' },
                      }}
                    >
                      +
                    </Button>
                  </Box>
                );
              })}
            </Box>
          )}
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2, ml: 1.4 }}>
          {selectedQuestions.length > 0 && (
            <LoadingButton
              type="submit"
              id='addassessmentgeneral'
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              fullWidth
              loading={loading}
            >
              Submit
            </LoadingButton>
          )}
        </Box>
      </DialogModal>

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

    </>

  );
}
export default GenericAssessmentAdd;