/* eslint-disable */
import React from 'react';
import Box from '@mui/material/Box';
import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import * as Yup from 'yup';
import FormHelperText from '@mui/material/FormHelperText';
import './styles.css';
import { useFormikContext, Form, Formik } from 'formik';

const Index = (props) => {
  const { name, inputRef, onChange, ...other } = props;
  const { values, errors, touched, handleBlur, handleChange, onSubmit, setFieldValue } = useFormikContext();
  return (
    <Box pr={1} pl={1}>
      <Box>
        <PhoneInput
          // excludeCountries={["kp",'mu', 'ua', 'zm', 'tr', 'tl', 'ro', 'rw', 'ps', 'pw', 'gr', 'bd', 'bz']}
          // onlyCountries={['fr', 'at', 'af']}
          // preserveOrder={['excludeCountries', 'preferredCountries']}
          // isValid={(value, country) => {
          //   if (value.match(/12345/)) {
          //     return `Invalid value:  ${value}  ,   ${country.name}`;
          //   } else if (value.match(/1234/)) {
          //     return false;
          //   } else {
          //     return true;
          //   }
          // }}
          onChange={(value) => {
            setFieldValue('phone', value);
            console.log(value, 'Phonevalue');
          }}
          enableSearch
          disableSearchIcon
          country={'us'}
          value={values.phone}
          {...props}
          specialLabel={''}
          placeholder="Phone"
          name="phone"
          countryCodeEditable={false}
          onBlur={handleBlur}
          inputProps={{
            name: 'phone',
            required: true,
            autoFocus: true,
          }}
        />
        {<FormHelperText style={{ color: '#F44336' }}>{errors.phone}</FormHelperText>}
      </Box>
    </Box>
  );
};

export default Index;
