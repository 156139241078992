/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function BarChat(props) {
  const classes = useStyles();
  //   const [toValue, setToValue] = React.useState(optionTo[1]);
  //   const [fromValue, setFromValue] = React.useState(optionFrom[1]);
  //   const [resData, setResData] = useState([]);
  //   const [loading, setLoading] = useState(true);

  //   const getAveragePrice = async () => {

  //     try {
  //       const response = await dashboardServices.getAveragePricePerCarton(fromValue, toValue);
  //       if (response.status === 200) {
  //         console.log(response.data, "response.data")
  //         setLoading(false)
  //         let data=response.data.map(res=>{
  //           return{
  //             y:Math.round(res.cartonCalculated),
  //             x:res.zone,
  //           }
  //         })

  //         setResData(data)
  //       }
  //     } catch (error) {

  //       console.log(error);
  //     }
  //   };

  //   useEffect(() => {
  //     getAveragePrice()
  //   }, [toValue, fromValue])

  const options = {
    series: [
      {
        data: [21, 22, 10, 18, 16, 21],
      },
    ],
    legend: {
      show: false,
    },
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      fillSeriesColor: false,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: Math.round(0.9),
        title: {
          formatter: (series) => '',
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '25%',
        distributed: true,
      },
    },
    // plotOptions: {
    //   bar: {
    //     horizontal: true,
    //     distributed: true,
    //     // columnWidth: resData.length * 8,
    //     // barHeight: resData.length * 8,
    //     barHeight: '30%',
    //   },
    // },
    dataLabels: {
      enabled: false,
    },

    colors: ['rgb(0, 143, 251)', 'rgb(0, 227, 150)', 'rgb(254, 176, 25)', '#B060EF', '#DCF11F', '#6C9AF9'],

    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          //   fontFamily: config.fontFamily
        },
      },
    },
    xaxis: {
      show: true,
      categories: ['Excel', 'Python', 'Quickbooks', 'Financial modeling', 'Powerpoint speed', 'Microsoft word'],
      labels: {
        show: true,
        // formatter: function (val) {
        //   return '$' + val;
        // },
        style: {
          fontSize: '12px',
          //   fontFamily: config.fontFamily
        },
      },
    },
  };

  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: '550', margin: '1rem' }}>
        Bar Chart
      </Typography>
      <div style={{ borderBottom: '1px solid #80808038' }} />
      <Chart options={options} series={options.series} type="bar" height={300} className="apex-charts" dir="ltr" />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {},
}));
