import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({ animation,loading = false }) => {
  const loader = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: loader.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animation,
    });
  }, []);

  return loading ? <div style={{ width: '80%', margin: 'auto' }} ref={loader} /> : null;
};

export default LottieAnimation;
