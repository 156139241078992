import client from '../../apiClient';

const getCourse = () => client.get(`/client/course`);

const getCourseDetails = (courseId) => client.get(`/course?courseId=${courseId}`);

const getCourseModule = (courseId) => client.get(`/client/course/topics?courseId=${courseId}`);

const getEnrolledUser = (courseId, planId) => client.get(`/user/enroll?courseId=${courseId}&&clientPlanId=${planId}`);

const deleteEnrolledUser = (planId, courseId) => client.delete(`/user/enroll?planId=${planId}&userId=${courseId}`);

const getCoursePlans = (courseId) => client.get(`/lookup/client/course_plans?courseId=${courseId}`);

const getUserList = (courseId) => client.get(`/lookup/users?courseId=${courseId}`);

const getTrialDetailsOnCourseId = (courseId) => client.get(`/course/trial?courseId=${courseId}`);

const postTrialDetailsOnCourseId = (data) => client.post(`/course/trial`, data);

const getSubscriptionData = (courseId) => client.get(`/subscription/details?courseId=${courseId}`);

const enrollUserToCourse = (clientId, courseId, data) =>
  client.post(`/user/enroll?clientId=${clientId}&&courseId=${courseId}`, JSON.stringify(data));

const enrollNewUser = (clientId, courseId, data) =>
  client.post(`/user/course/enroll?clientId=${clientId}&&courseId=${courseId}`, JSON.stringify(data));

export default {
  getCourse,
  getCourseDetails,
  getCourseModule,
  getEnrolledUser,
  deleteEnrolledUser,
  getCoursePlans,
  enrollUserToCourse,
  enrollNewUser,
  getUserList,
  getTrialDetailsOnCourseId,
  postTrialDetailsOnCourseId,
  getSubscriptionData,
};
