import React, { useState, useEffect } from 'react';
import { Button, createTheme, Grid, TableRow, TableCell, Typography, Box, ThemeProvider, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useLocation } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { LoadingButton } from '@mui/lab';
import analytics from '../../../services/analytics/analytics';
import SnackBar from '../../../components/snackbar/snackbar';
import './analytics.css';

export const CaseStudiesTab = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const [usersData, setUsersData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [courseAnalytics, setCourseAnalytics] = useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [length, setLength] = React.useState();
  const [openSnackbar, setOpenSnackbar] = React.useState();
  const [snackbarTitle, setSnackbarTitle] = React.useState('');

  const courseId = location.state && location.state.id;
  const rowId = [];

  const courseColumnData = [
    {
      name: 'id',
      label: 'CourseId',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'skillSetIQ',
      label: 'skillSetIQ',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'userName',
      label: 'Learners',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: 'caseStudiesAllDetails',
      label: 'caseStudiesAllDetails',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },

    {
      name: 'completedOn',
      label: 'Completed Date',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography sx={{ minWidth: '80px' }}>
              {tableMeta.rowData[3].completedOn && moment(tableMeta.rowData[3].completedOn).format('DD-MM-YYYY')}
            </Typography>
          );
        },
      },
    },

    {
      name: 'completedOn',
      label: 'Time',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography sx={{ minWidth: '80px' }}>
              {tableMeta.rowData[3].completedOn && moment(tableMeta.rowData[3].completedOn).format('LTS')}
            </Typography>
          );
        },
      },
    },

    {
      name: 'score',
      label: 'Score',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Typography sx={{ minWidth: '80px' }}>
              {tableMeta.rowData[3].result && tableMeta.rowData[3].result.resultInpercentage}%
            </Typography>
          );
        },
      },
    },
    {
      name: 'certificateSent',
      label: 'Certificate Sent',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Box sx={{ width: '80px' }}>
                <Typography>{value ? 'Yes' : 'No'}</Typography>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: 'screens',
      label: 'screens',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const getCaseStudiesAnalytics = async () => {
    try {
      const response = await analytics.getCaseStudiesAnalytics(courseId);
      if (response.ok) {
        if (Object.keys(response.data).length !== 0) {
          setCourseAnalytics(response.data);
        }
        setLoading(false);
      }
      if (!response.ok) {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCaseStudiesDetails = async () => {
    try {
      const response = await analytics.getCaseStudiesDetails(courseId);
      if (response.ok) {
        setUsersData(response.data);
        setLoading(false);
        console.log(response.data, 'clientdata');
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postUserCertificate = async () => {
    const data = {
      userIds: selectedRows,
    };
    try {
      const response = await analytics.postUserCertificate(data, courseId);
      console.log(response, 'clientdata');
      if (response.ok) {
        // setUsersData(response.data);
        getCaseStudiesDetails();
        setLoading(false);
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      } else {
        setLoading(false);
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCaseStudiesAnalytics();
    getCaseStudiesDetails();
  }, []);

  const moduleColumnOption = {
    search: false,
    print: false,
    pagination: false,
    download: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 10,
    elevation: 0,
    responsive: "scroll",
    selectableRows: false,
  };

  const moduleColumnData = [
    {
      name: 'question',
      label: 'Questions',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          setLength(tableMeta.tableData.length);
          return (
            <>
              {console.log(tableMeta.rowData[2], 'Key////')}
              <Typography
                sx={{ minWidth: '580px', color: '#000000', fontWeight: '600', fontSize: '14px', display: 'flex' }}
              >
                Q{tableMeta.rowIndex + 1}:&nbsp;
                <div
                  // className={classes.description}
                  color="text.secondary"
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              </Typography>
              <Typography
                sx={{
                  minWidth: '580px',

                  color: '#595454d9',
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Ans :{' '}
                {tableMeta.rowData[1].map((res, index) =>
                  res.windowsKeyName.map((val, index) => (
                    <>
                      {tableMeta.rowData[2] === 'COMBINED_HOT_KEYS' ? (
                        <>
                          <button
                            style={{
                              color: ' #595454d9',
                              fontSize: '12px',
                              background: 'white',
                              border: '1px solid #00000026',
                              padding: '3px 6px',
                              borderRadius: ' 4px',
                            }}
                          >
                            {val}{' '}
                          </button>{' '}
                          {val[res.windowsKeyName.length - 1] && '+'} {''}
                        </>
                      ) : (
                        <>
                          <button
                            style={{
                              color: ' #595454d9',
                              fontSize: '12px',
                              background: 'white',
                              border: '1px solid #00000026',
                              padding: '3px 6px',
                              borderRadius: ' 4px',
                            }}
                          >
                            {val}{' '}
                          </button>{' '}
                          {val[res.windowsKeyName.length - 1] && ''}
                        </>
                      )}
                    </>
                  ))
                )}
              </Typography>
            </>
          );
        },
      },
    },

    {
      name: 'answer',
      label: 'answer',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'keyType',
      label: 'keyType',
      options: {
        filter: false,
        sort: false,
        display: false,
        customHeadRender: () => null,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {console.log(value, 'keyType')}
              <Box sx={{ width: '80px' }}>{value}</Box>
            </>
          );
        },
      },
    },
    {
      name: 'isCorrect',
      label: 'answer',
      options: {
        filter: false,
        sort: false,
        display: true,
        customHeadRender: () => null,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Box sx={{ width: '80px' }}>
                {value ? (
                  <CheckCircleOutlineOutlinedIcon fontSize="small" style={{ color: '#00b673' }} />
                ) : (
                  <HighlightOffOutlinedIcon fontSize="small" style={{ color: '#ffad6c' }} />
                )}
              </Box>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    search: false,
    print: false,
    pagination: false,
    download: false,
    viewColumns: false,
    filter: false,
    rowsPerPage: 10,
    elevation: 0,
    onRowsSelect: (rowsSelected, allRows) => {
      const dataToState = allRows.map((item) => {
        return usersData[item.dataIndex].id;
      });
      const mySelectedRows = dataToState;
      setSelectedRows(mySelectedRows);
    },
    selectableRows: 'multiple',
    disableToolbarSelect: true,
    responsive: 'standard',
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <ThemeProvider theme={SubModulecustomTheme}>
          <TableRow>
            <TableCell colSpan={rowData.length + 1}>
              <MUIDataTable
                data={rowData[8] !== null ? rowData[8] : []}
                columns={moduleColumnData}
                options={moduleColumnOption}
              />
            </TableCell>
          </TableRow>
        </ThemeProvider>
      );
    },
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Box className={classes.boxCard}>
            <Typography variant="subtitle1" className={classes.typopadding} textAlign="center" gutterBottom>
              Passed v/s Failed ratio
            </Typography>
            {courseAnalytics ? (
              <Box className={classes.number}>
                <Typography variant="h6" className={classes.typonumber}>
                  {courseAnalytics.pass}/{courseAnalytics.fail}
                </Typography>
              </Box>
            ) : (
              <Box className={classes.number}>
                <Typography variant="h6" className={classes.typonumber}>
                  {/* <Skeleton variant="rectangular" height={49} sx={{ marginTop: '20px', borderRadius: '10px' }} > */}
                  0{/* </Skeleton> */}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className={classes.boxCarLorem}>
            <Typography variant="subtitle1" className={classes.typopadding} textAlign="center" gutterBottom>
              Average Correct Answers
            </Typography>
            <Box className={classes.numberCircular}>
              <CircularProgressbar
                value={courseAnalytics !== null ? Math.round(courseAnalytics.averageRightAnswer) : 0}
                text={courseAnalytics ? `${Math.round(courseAnalytics.averageRightAnswer)}%` : '0%'}
                className={classes.progress}
                styles={buildStyles({
                  textColor: '#1F78B4',
                  pathColor: '#00B673',
                  trailColor: '#DFDFDF',
                })}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box className={classes.boxCarLoremAverage}>
            <Typography variant="subtitle1" className={classes.typopadding} textAlign="center" gutterBottom>
              Test Completed
            </Typography>

            <Box className={classes.numberCircular}>
              <CircularProgressbar
                value={courseAnalytics ? Math.round(courseAnalytics.testCompleted) : 0}
                text={courseAnalytics ? `${Math.round(courseAnalytics.testCompleted)}%` : '0%'}
                className={classes.progress}
                styles={buildStyles({
                  textColor: '#1F78B4',
                  pathColor: '#00B673',
                  trailColor: '#DFDFDF',
                })}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.boxshodowone} mt={2}>
            <MUIDataTable
              data={usersData}
              columns={courseColumnData}
              options={options}
              sx={{
                '.tss-17usntu-MUIDataTableToolbarSelect-root': { display: 'none' },
                '.tss-gi9jj2-MUIDataTableSelectCell-fixedLeft': { background: '#fff' },
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} textAlign="end">
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={loading}
            disabled={selectedRows.length === 0}
            onClick={() => {
              setLoading(true);
              postUserCertificate();
            }}
            sx={{
              fontSize: '14px',

              fontWeight: '500',
              padding: '0.4rem 1.4rem',
            }}
          >
            Send Certificate
          </LoadingButton>
        </Grid>
      </Grid>
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
};

const SubModulecustomTheme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          fontSize: '0.9rem',
          fontWeight: '700',
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  boxshodowone: {
    boxShadow: '0 2px 3px 0 rgb(32 40 45 / 8%)',
    borderRadius: '12px',
    marginTop: '1rem',
  },
  typoPadding: {
    padding: '1rem 0rem 0rem 1rem',
    fontWeight: '500',
  },
  boxCard: {
    height: '180px',
    background: '#D1EDC0 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLorem: {
    height: '180px',
    background: '#FFE7AB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLoremAverage: {
    height: '180px',
    background: '#FEEADB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },
  boxCarLoremMeter: {
    height: '180px',
    background: '#FEEADB 0% 0% no-repeat',
    borderRadius: '6px',
    opacity: 1,
  },

  typopadding: {
    paddingTop: '2rem',
    fontSize: '1rem',
  },
  number: {
    marginTop: '30px',
    margin: 'auto',
    textAlign: 'center',

    background: '#FFFFFF 0% 0% no-repeat ',
    borderRadius: '10px',
    opacity: 1,

    width: '30%',
    [theme.breakpoints.up('sm', 'md')]: {
      maxWidth: '50%',
    },
  },
  numberCircular: {
    margin: 'auto',
    textAlign: 'center',
    marginTop: '0.5rem',
  },
  numberMeter: {
    margin: 'auto',
    textAlign: 'center',
    width: '70% ',
    height: '196px',
    [theme.breakpoints.up('xl')]: {
      width: '60% !important',
      height: '196px',
    },
  },

  typonumber: {
    padding: '6px',
    fontSize: '1.5rem',
    fontWeight: '700',
  },
  progress: {
    width: '100%',
    height: '95px',
    marginBottom: '0.9rem',
  },
}));
