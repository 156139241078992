import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Typography, Box, Backdrop, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';

const BasicModel = ({ children, openModel, closeModel, title, maxWidth = 'sm' }) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={openModel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div>
        <DialogTitle id="alert-dialog-title" sx={{ padding: '0px', textAlign: 'right' }}>
          <Box className={classes.combined}>
            <Typography className={classes.title}>{title}</Typography>
            <IconButton className={classes.icon} size="small" onClick={() => closeModel()}>
              <CloseIcon style={{ color: '#FE7000' }} fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box sx={{ paddingTop: '16px' }}>{children}</Box>
        </DialogContent>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  combined: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '22px',
    paddingRight: '22px',
    paddingTop: '22px',
  },
  dialogContent: {
    marginTop: '1rem',
  },
  background: {
    fontWeight: 'bold',
    color: '#000000',
  },
  icon: {
    backgroundColor: '#FFE7D5',
    borderRadius: '50%',
  },
  title: {
    fontSize: '19px',
    fontWeight: 'bold',
    color: '#FE7000',
  },
}));
const customeTheme = createTheme({
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '-1px !important',
        },
      },
    },
  },
});

export default BasicModel;
