import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, TextField } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import BackgroundImg from '../../assets/logo/images/bg.jpg';
import keySkillsetLogo from '../../assets/logo/logo.png';
import Page from '../../components/Page';
import loginServices from '../../services/loginServices';
import SnackBar from '../../components/snackbar/snackbar';
import apiClient from '../../services/apiClient';

export default function ResetPassword() {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [searchParams] = useSearchParams();
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    const token = searchParams.get('token');
    apiClient.setHeader('Authorization', `Bearer ${token}`);
    const query = '';
  }, []);

  const handleResetPassword = async (values) => {
    setLoading(true);
    const resetData = {
      password: values.password,
      confirmPassword: values.ConfirmPassword,
    };
    try {
      const response = await loginServices.createPassword(resetData);
      if (response.ok) {
        setSnackbarTitle(response.data.message);
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate('/login');
        }, 4000);
      } else if (response) {
        setSnackbarTitle(response.data.message);
        setOpenSnackbar(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <Page title={'Create Password'} style={{ padding: '0px !important' }}>
        <Box className={classes.boxContainer}>
          <Container>
            <Grid container className={classes.grid}>
              <Grid item xs="12" sm="12" md="4">
                <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                  <img width={'220px'} className={classes.keySkillsetLogo} src={keySkillsetLogo} alt="logo" />
                </Box>
              </Grid>
              <Grid className={classes.gridContainer} item xs="12" sm="12" md="4">
                <Container>
                  <Formik
                    initialValues={{
                      password: '',
                      ConfirmPassword: '',
                    }}
                    validationSchema={Yup.object().shape({
                      password: Yup.string()
                        .trim()
                        .min(8)
                        .nullable()
                        .matches(
                          /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                          'The password must contain 8 characters, one upper case, one number and one special case character'
                        )
                        .required('Password is Required.'),
                      ConfirmPassword: Yup.string()
                        .trim()
                        .nullable()
                        .required('required')
                        .oneOf([Yup.ref('password'), null], "Passwords does't match"),
                    })}
                    onSubmit={(values) => {
                      handleResetPassword(values);
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values,
                    }) => (
                      <Form>
                        <Grid className={classes.form} container spacing={2}>
                          <Grid item xs="12">
                            <Typography color="primary" className={classes.login} align="left" variant="h4">
                              Create Password
                            </Typography>
                          </Grid>
                          <Grid item xs="12">
                            <Typography className={classes.email} variant="subtitle1">
                              Password
                            </Typography>
                            <TextField
                              fullWidth
                              name="password"
                              variant="outlined"
                              type={visible ? 'text' : 'password'}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('password', e.target.value.trim());
                              }}
                              error={Boolean(touched.password && errors.password)}
                              helperText={touched.password && errors.password}
                              value={values.password}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                    >
                                      {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs="12">
                            <Typography className={classes.email} variant="subtitle1">
                              Confirm Password
                            </Typography>
                            <TextField
                              fullWidth
                              name="ConfirmPassword"
                              variant="outlined"
                              type="password"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('ConfirmPassword', e.target.value.trim());
                              }}
                              error={Boolean(touched.ConfirmPassword && errors.ConfirmPassword)}
                              helperText={touched.ConfirmPassword && errors.ConfirmPassword}
                              value={values.ConfirmPassword}
                            />
                          </Grid>
                          <Grid align="center" item xs="12">
                            <LoadingButton
                              fullWidth
                              size="medium"
                              type="submit"
                              variant="contained"
                              loading={loading}
                              className={classes.LoadingButton}
                            >
                              Create
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </Container>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Page>
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  LoadingButton: {
    padding: '6px 16px',
    borderRadius: '4px',
    backgroundColor: '#FE7000',
    color: '#fff',
  },
  boxContainer: {
    backgroundImage: `url(${BackgroundImg})`,
    backgroundSize: 'cover',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  gridContainer: {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px #00000029',
    [theme.breakpoints.down('md')]: {
      marginTop: '4rem',
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    paddingBottom: '2rem',
    paddingTop: '2rem',
  },
  divider: {
    height: '2px',
    width: '145px',
    backgroundColor: 'black',
  },
  keySkillsetLogo: {
    position: 'fixed',
    left: '2rem',
    top: '2rem',
  },
  ok: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  email: {
    padding: '4px',
  },
}));
