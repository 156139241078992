import React, { useState, useEffect, useRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  IconButton,
  Typography,
  Box,
  CardActions,
  Card,
  Collapse,
  CardContent,
  FormControlLabel,
  Checkbox,
  Dialog,
  Button,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { createTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { LoadingButton } from '@mui/lab';
import lottie from 'lottie-web';

import clientAdminServices from '../../services/clientAdmin/course/clientAdminServices';
import lottieData from '../../assets/lottie/lottie-loading.json';

const TrialModal = (props) => {
  const classes = useStyles();
  const lottoLoad = useRef();
  const [expanded, setExpanded] = React.useState(false);
  const [courseContent, setCourseContent] = useState(null);
  const [loading, setLoading] = React.useState(true);

  const handleExpandClick = (i) => {
    setExpanded(expanded === i ? -1 : i);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: expanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const getTrialDetailsOnCourseId = async (courseId) => {
    // setLoading(true)
    try {
      const res = await clientAdminServices.getTrialDetailsOnCourseId(courseId);
      if (res.ok) {
        console.log(res);
        setCourseContent(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      lottie.loadAnimation({
        container: lottoLoad.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottieData,
      });
    } catch (error) {
      console.log(error);
    }
  }, [props.open]);

  useEffect(() => {
    if (props.open === true) {
      setCourseContent(null);
      setExpanded(false);
      setLoading(true);
      getTrialDetailsOnCourseId(props.courseId);
    }
  }, [props.open]);

  const handleChangeCheckbox = (moduleId, subModuleId) => {
    setCourseContent({
      ...courseContent,
      moduleObj: courseContent?.moduleObj.map((item) => {
        if (moduleId === item.moduleId) {
          item.submoduleObj = item.submoduleObj.map((res) => {
            if (res.submoduleId === subModuleId) {
              res.isTrial = !res.isTrial;
            }
            return res;
          });
        }
        return item;
      }),
    });
  };

  const { fullWidth } = props;

  const sizeToBoolean = {
    'sm': false, 
    'md': true,  
    'lg': true,  
    
  };

  const isFullWidth = sizeToBoolean[fullWidth] || false;

  return (
    <Dialog
    fullWidth={isFullWidth}
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div>
        <DialogTitle id="alert-dialog-title" sx={{ padding: '0px', textAlign: 'right' }}>
          <Box className={classes.combined}>
            <Typography className={classes.title}>{props.title}</Typography>
            <IconButton className={classes.icon} size="small" onClick={() => props.close()}>
              <CloseIcon style={{ color: '#FE7000' }} fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {loading ? (
            <>
              {/* <div style={{ width: 50, margin: 'auto' }} ref={lottoLoad} /> */}
              <div style={{ width: 50, margin: 'auto' }}>Loading...</div>
            </>
          ) : (
            <>
              {courseContent &&
                courseContent?.moduleObj?.map((res, index) => {
                  return (
                    <>
                      <Card className={classes.cardItem} key={index}>
                        <CardActions disableSpacing>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingLeft: '16px',
                              width: '100%',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box onClick={() => handleExpandClick(index)} sx={{ minWidth: '60%' }}>
                              <Typography sx={{ fontSize: '0.85rem' }}>
                                Module
                                {index + 1}
                              </Typography>
                              <Typography variant="subtitle1" sx={{ 
                                fontSize: '1.1rem' }}>
                                {res.name}
                              </Typography>
                            </Box>
                          </div>
                          <ExpandMore
                            expand={index}
                            onClick={() => handleExpandClick(index)}
                            aria-expanded={expanded}
                            aria-label="show more"
                            sx={{ marginLeft: '16px' }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </CardActions>

                        <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                          <CardContent>
                            {res.submoduleObj &&
                              res.submoduleObj.map((response, index) => {
                                return (
                                  <Box
                                    className={classes.cardBox}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    key={index}
                                  >
                                    <Box>
                                      <Typography sx={{ fontSize: '0.85rem' }}>
                                        Sub Module
                                        {index + 1}
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ textDecoration: 'none !important' }}
                                        color="primary"
                                      >
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={response.isTrial}
                                              onClick={() => {
                                                handleChangeCheckbox(res.moduleId, response.submoduleId);
                                              }}
                                            />
                                          }
                                          label={response.name}
                                        />
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              })}
                          </CardContent>
                        </Collapse>
                      </Card>
                    </>
                  );
                })}
            </>
          )}

          {courseContent?.moduleObj ? (
            <div style={{ textAlign: 'right', marginTop: 16 }}>
              <LoadingButton
                variant="contained"
                color="primary"
                disabled={props?.loading}
                // fullWidth
                loading={props?.loading}
                onClick={() => {
                  props.submit(courseContent);
                }}
              >
                save
              </LoadingButton>
            </div>
          ) : (
            <Typography>No Data</Typography>
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  combined: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '22px',
    paddingRight: '22px',
    paddingTop: '20px',
  },
  dialogContent: {
    marginTop: '1rem',
  },
  background: {
    fontWeight: 'bold',
    color: '#000000',
  },
  icon: {
    backgroundColor: '#FFE7D5',
    borderRadius: '50%',
  },
  title: {
    fontSize: '19px',
    fontWeight: 'bold',
    color: '#FE7000',
  },
  cardItem: {
    background: '#F9F9F9',
    marginBottom: '24px',
    border: '1px solid #e1e1e1ab',
  },
  cardBox: {
    background: '#E6E6E6',
    padding: '12px',
    borderRadius: '4px',
    marginBottom: '8px',
  },
}));
const customeTheme = createTheme({
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '-1px !important',
        },
      },
    },
  },
});

export default TrialModal;
