import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box, Grow, Popper, TextField, Typography, Link } from '@mui/material';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@mui/styles';
import jwtdecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import ClickAwayListener from '@mui/base/ClickAwayListener';

import SnackBar from '../../components/snackbar/snackbar';
import { loginSuccess } from '../../store/reducer';
import loginServices from '../../services/loginServices';
import apiClient from '../../services/apiClient';

const LoginForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [dropdown, setDropdown] = useState('');
  const [dropdownEl, setDropdownEl] = useState('');
  const [visible, setVisible] = useState(false);

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };

  const getLoginUserDetails = async () => {
    try {
      const response3 = await loginServices.getUserInfo();
      if (response3.ok) {
        dispatch(loginSuccess(response3.data));
        if (response3.data.role === 'CONTENT_WRITER' || response3.data.role === 'AUTH_USER') {
          navigate('/app/course');
        } else {
          navigate('/app/dashboard');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitForm = async (values) => {
    setLoading(true);
    const data = {
      emailAddress: values.email,
      password: values.password,
    };
    const response = await loginServices.userLogin(data);
    if (response.ok) {
      apiClient.setHeader('Authorization', `BEARER ${response.data.token}`);
      Cookies.set('token', response.data.token);
      Cookies.set('refreshToken', response.data.refreshToken);
      Cookies.set('isLogIn', true);
      const decoded = await jwtdecode(response.data.token);
      Cookies.set('tokenExpireTime', decoded.exp);
      console.log(decoded);
      await getLoginUserDetails();
    } else if (!response.ok) {
      if (typeof response.data.message === 'object') {
        setSnackbarTitle(response.data.message.password);
        setOpenSnackbar(true);
      } else {
        setSnackbarTitle(response.data.message);
        setOpenSnackbar(true);
      }
    }
    setLoading(false);
  };

  return (
    <Box mr={2}>
      {/* <ClickAwayListener
        onClickAway={() => {
          setDropdown(null);
        }}
      > */}
      <Typography
        component={RouterLink}
        to={'/login'}
        // onClick={() => {
        //   window.location.href = '/login';
        // }}
        color="primary"
        sx={{ textDecoration: 'none', fontSize: '1rem', cursor: 'pointer' }}
      >
        Login
      </Typography>

      {/* </ClickAwayListener> */}

      <Popper
        anchorEl={dropdown}
        popperRef={null}
        open={Boolean(dropdown)}
        //   open={test}
        placement="top-start"
        transition
        style={{ zIndex: 10, minWidth: '320px' }}
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            // options: {
            //   element: arrowRef,
            // },
          },
        ]}
        onMouseEnter={() => setDropdown(dropdownEl)}
        onMouseLeave={() => {
          setDropdown(null);
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            sx={{
              transformOrigin: 'right top',
              background: '#fff',
              boxShadow: '0px 2px 15px -10px #6a6767',
              marginTop: '1.5rem',
              borderRadius: '8px',
              PointerEvent: 'none',
            }}
          >
            <Box borderRadius="lg">
              <Box shadow="lg" borderRadius="lg" p={2} mt={1}>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().required('Email is required field').email(),
                    password: Yup.string()
                      .trim()
                      .min(8)
                      .nullable()
                      .matches(
                        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                        'The password must contain 8 characters, one upper case, one number and one special case character'
                      )
                      .required('Password is Required.'),
                  })}
                  onSubmit={(values) => handleSubmitForm(values)}
                >
                  {({ values, touched, errors, handleBlur, setFieldValue }) => (
                    <Form>
                      <Box sx={{ padding: '1rem 1rem 0px !important' }}>
                        <Typography gutterBottom variant="subtitle1">
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          name="email"
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue('email', e.target.value.trim());
                          }}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          value={values.email}
                        />
                      </Box>
                      <Box sx={{ padding: '1rem 1rem 0px !important' }}>
                        <Typography gutterBottom variant="subtitle1">
                          Password
                        </Typography>
                        <TextField
                          fullWidth
                          name="password"
                          variant="outlined"
                          type={visible ? 'text' : 'password'}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue('password', e.target.value.trim());
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          value={values.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {visible ? (
                                  <VisibilityIcon onClick={handleClickShowPassword} />
                                ) : (
                                  <VisibilityOffIcon onClick={handleClickShowPassword} />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box sx={{ padding: '1rem !important' }}>
                        <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                          Login
                        </LoadingButton>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Box>
          </Grow>
        )}
      </Popper>
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({}));

export default LoginForm;
