import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ user, redirectPath = '/', children }) => {
  const isLogin = useSelector((state) => state.isLogin);

  if (!isLogin) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
