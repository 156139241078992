import LeftArrow from '../../assets/arrows/left.svg';
import DownArrow from '../../assets/arrows/down.svg';
import RightArrow from '../../assets/arrows/right.svg';
import UpArrow from '../../assets/arrows/up.svg';

const multipleKeyCode = [
  {
    key: '0',
    keyCode: [48, 96],
  },
  {
    key: '1',
    keyCode: [49, 97],
  },
  {
    key: '2',
    keyCode: [50, 98],
  },
  {
    key: '3',
    keyCode: [51, 99],
  },
  {
    key: '4',
    keyCode: [52, 100],
  },
  {
    key: '5',
    keyCode: [53, 101],
  },
  {
    key: '6',
    keyCode: [54, 102],
  },
  {
    key: '7',
    keyCode: [55, 103],
  },
  {
    key: '8',
    keyCode: [56, 104],
  },
  {
    key: '9',
    keyCode: [57, 105],
  },
  {
    key: '*',
    keyCode: [56, 106],
  },
  {
    key: '+',
    keyCode: [187, 107],
  },
  {
    key: '-',
    keyCode: [189, 109],
  },
  {
    key: '.',
    keyCode: [190, 110],
  },
  {
    key: '/',
    keyCode: [191, 111],
  },
  {
    key: 'Backspace',
    keyCode: [8, 46],
  },
  {
    key: 'Meta',
    keyCode: [91, 93],
  },
  {
    key: 'S',
    keyCode: [83, 229],
  },
];

const sameCodeForMultipleKeys = [
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '192',
  '187',
  '189',
  '219',
  '220',
  '221',
  '186',
  '222',
  '188',
  '190',
  '191',
];

const ignoreNameCheckKeys = [
  {
    key: 'Meta',
    keyCode: ['91', '93'],
  },
  {
    key: 'Backspace',
    keyCode: ['8', '46'],
  },
];
const arrowIcons = {
  37: <img src={LeftArrow} alt="LeftArrow" width="24" />,
  38: <img src={UpArrow} alt="UpArrow" width="24" />,
  39: <img src={RightArrow} alt="RightArrow" width="24" />,
  40: <img src={DownArrow} alt="DownArrow" width="24" />,
};

const codeAndNameDifferent = {
  76: 189,
};

export default {
  multipleKeyCode,
  arrowIcons,
  sameCodeForMultipleKeys,
  ignoreNameCheckKeys,
  codeAndNameDifferent,
};
