import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  Tooltip,
  Link,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Iconify from '../Iconify';
import palette from '../../theme/palette';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  marginTop: 10,
  marginBottom: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.main : '#308fe8',
  },
}));

const SubModuleProgress = (props) => {
  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertTime, setAlertTime] = useState(20);

  useEffect(() => {
    const resendOtpSec = setTimeout(() => {
      if (alertTime > 0) {
        if(props && props.type === 'video'){
          setAlertTime(alertTime - 1);
          setOpenAlert(false)
        }
        else{
            setAlertTime(alertTime - 1);
            setOpenAlert(true)
          
        }
      } else {
        setOpenAlert(false);
        clearTimeout(resendOtpSec);
      }
    }, 1000);
  }, [alertTime]);

  return (
    <>
      <Box p={3} className={classes.progressBox}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton size="small" sx={{ backgroundColor: '#edededc9' }}>
              <ArrowBackIcon fontSize="small" sx={{ fontSize: '1rem' }} />
            </IconButton>
            <Typography sx={{ fontSize: '0.75rem' }}>{props.moduleName}</Typography>
          </div> */}
          {props.moduleName}
          {/* <Tooltip arrow title={props.mic ? 'Turn off music' : 'Turn on music'} placement="left-start">
            <IconButton onClick={props.micToggle} size="small">
              <Iconify
                icon={props.mic ? 'fluent:speaker-2-48-regular' : 'fluent:speaker-off-48-regular'}
                width={18}
                height={18}
              />
            </IconButton>
          </Tooltip> */}
        </div>
        <Typography gutterBottom variant="subtitle2">
          {props.subModuleName}
        </Typography>

        {props.value && <BorderLinearProgress color="primary" variant="determinate" value={props.value} />}

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Box display={'flex'} justifyContent="space-between">
            {props.value && (
              <Typography variant="subtitle2" align="center" sx={{ fontSize: '0.775rem', fontWeight: '500' }}>
                {props.value}% Completed
              </Typography>
            )}
          </Box>

          {(props.isKeyboardScreen === 'INDIVIDUAL_HOT_KEYS' || props.isKeyboardScreen === 'COMBINED_HOT_KEYS') && (
            <Box mt={'6px'}>
              <ThemeProvider theme={customTheme}>
                <ToggleButtonGroup
                  value={props.keyboardType}
                  exclusive
                  onChange={(event, selected) => {
                    if (selected) props.handleKeyboard(selected);
                  }}
                  aria-label="text alignment"
                >
                  <Tooltip title="Switch to Windows keyboard">
                    <ToggleButton value="windows" aria-label="left aligned">
                      <Iconify
                        icon="mdi:microsoft-windows"
                        width={24}
                        height={26}
                        color={props.keyboardType === 'windows' ? palette.primary.main : '#00000029'}
                      />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title="Switch to Mac keyboard">
                    <ToggleButton value="mac" aria-label="centered">
                      <Iconify
                        icon="ant-design:apple-filled"
                        width={24}
                        height={26}
                        color={props.keyboardType === 'mac' ? palette.primary.main : '#00000029'}
                      />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </ThemeProvider>
            </Box>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.775rem', fontWeight: '500' }} variant="body2">
              Autoplay
            </Typography>
            <Switch
              size="small"
              checked={props.autoPlay}
              onChange={props.handleAutoPlay}
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>

        {props.screenAudio && (
          <Box mt={1}>
            <audio
              style={{ height: '24px', width: '100%' }}
              // autoPlay
              controls
              src={props.screenAudio}
              controlsList="nodownload"
              autoPlay={props.autoPlay}
            >
              <track
                kind="captions"
                src="https://keyskillsetbucket.s3.amazonaws.com/courseAudio/space-hole-test-236651677578992.mp3"
              />
            </audio>
          </Box>
        )}

        {openAlert && (
          <Alert
            sx={{ padding: '6px' }}
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            If you're a Mac user, make sure you have the right keyboard settings to take the simulation.
            <Link href="https://support.apple.com/en-in/guide/mac-help/mchlp2596/mac" target="new">
              Click here for more details.
            </Link>
          </Alert>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  courseItem: {
    background: '#fff',
    minHeight: `calc(100vh - ${68}px)`,
    borderLeft: '1px solid #BCBCBC',
  },
  progressBox: {
    background: '#FAFAFA',
    borderLeft: 'none',
    border: '1px solid #BCBCBC',
    padding: 10,
  },
}));

const customTheme = createTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          backgroundColor: '#FE700000',
          color: 'white',
          fontWeight: 'bold',
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '0px 6px',
          '&.Mui-selected': {
            backgroundColor: '#FFF0E5',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#FFF0E5',
            },
          },
          '&:hover': {
            backgroundColor: '#D1FFEE',
          },
        },
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRight: '1px solid #FFFFFF',
        },
      },
    },
  },
});

export default SubModuleProgress;
