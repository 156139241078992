import client from '../apiClient';

const getUsersDetails = () => client.get(`/client/users`);

const postUsersDetails = (data) => client.post(`/client/user`, data);

const deleteUserDetailsByID = (userId) => client.delete(`/client/user?userId=${userId}`);

const editUserDetailsByID = (userId, data) => client.put(`/client/user?userId=${userId}`, data);

const getUserDetalsById = (userId) => client.get(`/client/users/${userId}`);

const importUserDetails = (data) => client.post(`/user/importUserInfo`, data);

export default {
  getUsersDetails,
  postUsersDetails,
  deleteUserDetailsByID,
  editUserDetailsByID,
  getUserDetalsById,
  importUserDetails,
};
