import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Stack, Box, Grid, TextField, FormHelperText, FormControl, Typography, Link } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { DropzoneArea } from 'material-ui-dropzone';
import { useSelector, useDispatch } from 'react-redux';

import ResetPasswordModal from '../../profile/ResetPasswordModal';
import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';
import { loginSuccess } from '../../../store/reducer';
import preSignedServices from '../../../services/preSignedServices';
import loginServices from '../../../services/loginServices';
import PhoneNumber from '../../../components/PhoneNumber/Index';
import '../../../components/PhoneNumber/styles.css';

const YourProfileTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [urlImage, setUrlImage] = useState(null);
  const [showimageo, setShowimageo] = useState(true);
  const [editField, setEditField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openResetModel, setOpenResetModel] = useState(false);
  const [isClickedButton, setIsClickedButton] = useState(false);
  const closeResetModel = () => setOpenResetModel(false);
  // const values.phone = `1${userInfo.phone}`

  const [initialValue, setInitialValue] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    imgUrl: null,
    profileImg: null,
    thumbPreview: null,
  });

  const getLoginUserDetails = async () => {
    const getUserResponse = await loginServices.getUserInfo();
    if (getUserResponse.ok) {
      dispatch(loginSuccess(getUserResponse.data));
    }
  };

  const handleSubmitProfileValues = async (values) => {
    const formData = new FormData();
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('phone', values.phone);
    formData.append('profileImg', values.profileImg);

    try {
      const response = await adminServices.profileUpdate(formData);
      if (response.ok) {
        if(response.data && response.data.preSignedUrl) {
          await preSignedServices.preSignedUrlUpload(response.data.preSignedUrl, values.profileImg)
        }
        await getLoginUserDetails();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setEditField(false);
        setLoading(false);
      } else if (!response.ok) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmitForm = (values) => {
    handleSubmitProfileValues(values);
    setLoading(true);
  };

  return (
    <div>
      {editField ? (
        <Formik
          initialValues={{
            firstName: userInfo?.firstName,
            lastName: userInfo?.lastName,
            phone: userInfo?.phone,
            profileImg: null,
            thumbPreview: userInfo?.profileImg,
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .trim()
              .nullable()
              .min(3, 'First name must required 3 characters')
              .max(180, 'First Name must be with in 180 characters')
              .required('First name is required field')
              .label()
              .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field '),
            lastName: Yup.string()
              .trim()
              .nullable()
              .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field '),
            phone: Yup.string()
              .trim()
              .nullable()
              .min(11, 'Contact phone must be at least 10 characters')
              .max(12, 'Contact phone must be with in 12 characters')
              .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number')
              .required('Contact phone is required field'),
            thumbPreview: Yup.string().nullable(),
            profileImg: Yup.mixed()
              .nullable()
              .when('thumbPreview', {
                is: (val) => {
                  return val === null || val === '' || val === undefined;
                },
                then: Yup.mixed()
                  .nullable()
                  .required('Profile image required')
                  .test('fileFormat', 'Unsupported Format', (value) => {
                    return value === null || (value && SUPPORTED_FORMATS.includes(value.type));
                  })
                  .test('fileSize', 'Maximum file size is 3MB', (value) => {
                    console.log('value', value);
                    return value === null || (value && value.size < 3145728);
                  }),
              }),
          })}
          onSubmit={(values) => {
            handleSubmitForm(values);
          }}
        >
          {({ errors, handleBlur, setFieldValue, handleSubmit, handleChange, touched, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    First Name*
                  </Typography>
                  <TextField
                    fullWidth
                    name="firstName"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    value={values.firstName}
                    inputProps={{ maxLength: 180 }}
                  />
                </Grid>

                <Grid item xs="12" sm="6">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="lastName"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('lastName', e.target.value);
                    }}
                    inputProps={{ maxLength: 180 }}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    value={values.lastName}
                  />
                </Grid>

                <Grid item xs="12" sm="6">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Phone Number*
                  </Typography>
                  <PhoneNumber fullWidth country={'us'} specialLabel={''} />
                </Grid>

                <Grid item xs="12" sm="6">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Profile Picture*
                  </Typography>

                  <>
                    <Box mb={0.5}>
                      {values.thumbPreview !== '' ? (
                        <>
                          <Box mb={0.5}>
                            <img width={'90px'} src={values.thumbPreview} alt="pic" />
                          </Box>
                          <Box mb={1}>
                            <div>
                              <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                type="file"
                                name="profileImg"
                                onChange={(e) => {
                                  if (
                                    SUPPORTED_FORMATS.includes(e.target.files[0].type) &&
                                    e.target.files[0].size <= 2097152
                                  ) {
                                    setUrlImage(URL.createObjectURL(e.target.files[0]));
                                    setFieldValue('thumbPreview', URL.createObjectURL(e.target.files[0]));
                                  }
                                  setFieldValue('profileImg', e.currentTarget.files[0]);
                                }}
                              />
                              <label htmlFor="contained-button-file">
                                <Typography
                                  color="primary"
                                  component="span"
                                  sx={{ cursor: 'pointer;' }}
                                  onClick={() => {
                                    setShowimageo(false);
                                    setUrlImage('');
                                    setFieldValue('thumbPreview', null);
                                    setFieldValue('profileImg', null);
                                  }}
                                >
                                  Change Profile
                                </Typography>

                                <FormHelperText style={{ color: '#F44336', paddingLeft: 16 }}>
                                  {errors.profileImg ? errors.profileImg : ''}
                                </FormHelperText>
                              </label>
                            </div>
                          </Box>
                        </>
                      ) : (
                        <>
                          {(values.thumbPreview !== null || values.thumbPreview === '') && (
                            <>
                              <div className={classes.dropzone}>
                                <FormControl
                                  required
                                  component="fieldset"
                                  color="primary"
                                  variant="outlined"
                                  type="file"
                                  fullWidth
                                  name="profileImg"
                                  value={values.profileImg}
                                >
                                  <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    dropzoneText="Drag and Drop Image or Browse File"
                                    showPreviewsInDropzone={false}
                                    maxFileSize={30000000}
                                    filesLimit={1}
                                    value={values.profileImg}
                                    showAlerts={false}
                                    onChange={(file) => {
                                      if (SUPPORTED_FORMATS.includes(file[0]?.type) && file[0]?.size <= 3145728) {
                                        const url = URL.createObjectURL(file[0]);
                                        setFieldValue('thumbPreview', url);
                                      }
                                      // setFieldValue('profileImg', file.currentTarget.files[0]);
                                      setFieldValue('profileImg', file[0]);
                                    }}
                                    previewGridClasses={{
                                      container: classes.previewContainer,
                                      item: classes.preview,
                                    }}
                                    showFileNamesInPreview
                                  />
                                </FormControl>
                              </div>
                              {
                                <FormHelperText style={{ color: '#F44336' }}>
                                  {errors.profileImg && isClickedButton ? errors.profileImg : ''}
                                </FormHelperText>
                              }
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  </>
                </Grid>

                <Grid align="end" item xs="12">
                  <Stack direction="row" align="end" spacing={2} sx={{ display: 'flex;', justifyContent: 'end;' }}>
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      sx={{
                        color: '#fff !important',
                        background: 'gray;',
                      }}
                      onClick={() => setEditField(false)}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton loading={loading} size="medium" type="submit" variant="contained">
                      Update
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <Grid container mt={0}>
            <Grid item xs={10} sm={11}>
              <Grid container spacing={2}>
                <Grid className={classes.girdContainer} item xs="12" sm="3">
                  {userInfo && userInfo.profileImg ? (
                    <Box className={classes.logo}>
                      <img width={'120px'} src={userInfo && userInfo.profileImg}
                       alt="logo" />
                    </Box>
                  ) : (
                    <Box ml={0} className={classes.logo}>
                      <Avatar alt="Remy Sharp" src={userInfo.profileImg} sx={{ width: 50, height: 50 }} />
                    </Box>
                  )}
                </Grid>
                <Grid item xs="12" sm="9">
                  <Box>
                    <Typography>{userInfo && userInfo.name}</Typography>
                    <Typography> {userInfo && userInfo.email}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={1} textAlign="end">
              <IconButton
                onClick={() => {
                  setEditField(!editField);
                  setShowimageo(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container mt={0} spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
                Phone
              </Typography>
              <Typography variant="body1" component="div">
                {userInfo && userInfo.phone && userInfo.phone.length > 9 ? `+${userInfo.phone}` : null}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={3} sx={{ cursor: 'pointer' }} mt={3}>
              <Typography variant="subtitle1" component="div" className={classes.changePassword} gutterBottom>
                <Link
                  onClick={() => {
                    setOpenResetModel(true);
                  }}
                  variant="subtitle2"
                  sx={{ color: '#e7443e;', textDecoration: 'unset;' }}
                >
                  Change Password
                </Link>
              </Typography>
            </Grid> */}
          </Grid>
        </>
      )}
      <ResetPasswordModal openModal={openResetModel} closeModel={() => setOpenResetModel(false)} />

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  girdContainer: {
    display: 'flex',
  },
  logo: {
    border: '1px solid lightgrey',
    borderRadius: '10px',
    marginRight: '3rem',
    padding: '1rem',
  },

  name: {
    fontSize: '14px',
    color: '#0C0A09',
    fontWeight: '600',
  },

  changePassword: {
    color: '#FE7000',
    letterSpacing: '0px;',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start',
    },
  },
  backgroundStyle: {
    boxShadow: '0px 3px 30px #0000000A;',
    border: '1px solid #ECECEC',
    borderRadius: '10px;',
    background: '#FFFFFF',
    padding: '1rem',
  },

  btnStyle: {
    color: '#FE7000;',
    fontSize: '1rem',
    fontWeight: '600',
    outline: '1px solid #FE7000',
  },

  fontSizeiconname: {
    letterSpacing: '0.43px;',
    color: '#6D6969;',
    marginLeft: '1rem;',
    fontSize: '0.8rem',
  },
  contanerBackground: {
    backgroundColor: '#FAFAFA',
    height: '100vh',
  },

  typoFontWeight: {
    fontSize: '1rem;',
    fontWeight: '400',
  },
  typoFontWeightone: {
    fontSize: '1rem;',
  },
  input: {
    display: 'none',
  },

  marginTopSm: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '-13rem',
    },
  },
}));

export default YourProfileTab;
