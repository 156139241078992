import React, { useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import lottie from 'lottie-web';
import adminServices from '../../../../services/adminServices';
import lottieData from '../../../../assets/lottie/lottie-loading.json';

export default function AddSubscriptionForm(props) {
  const location = useLocation();
  const lottoLoad = useRef();
  const { from } = location.state;
  const [selectCourse, setSelectCourse] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [lottieLoading, setLottieLoading] = React.useState(true);

  const [formValues, setFormValues] = React.useState({
    name: '',
    selectCourse: '',
    noOfLicense: '',
    remarks: '',
    fromDate: '',
    toDate: '',
    formType: props.mode,
  });

  const getSubscriptionLookup = async () => {
    try {
      const response = await adminServices.getLookUpData('subscription_plan');
      if (response.ok) {
        setSelectCourse(response.data.subscriptionPlan);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubscriptionData = async (values) => {
    setLoading(true);
    const formData = {
      name: values.name,
      noOfLicenseAllocated: values.noOfLicense,
      validFrom: moment(values.fromDate).format('YYYY-MM-DD'),
      validTo: moment(values.toDate).format('YYYY-MM-DD'),
      planId: values.selectCourse.id,
      remarks: values.remarks,
    };
    if (props.mode === 'create') {
      try {
        const result = await adminServices.postSubsrciptionplan(from, formData);
        if (result.ok) {
          props.getSubscription();
          setOpenSnackbar(true);
          props.snackBarControl(result.data.message);
          props.closeModel();
        } else {
          setOpenSnackbar(true);
          props.snackBarControl(result.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await adminServices.editSubsrciptionplan(props.subscriptionId, formData);
        if (res.ok) {
          props.closeModel();
          props.handleCloseMenu();
          props.getSubscription();
          setOpenSnackbar(true);
          props.snackBarControl(res.data.message);
        } else {
          setOpenSnackbar(true);
          props.snackBarControl(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  const getSubscriptionPlansByid = async () => {
    try {
      const item = await adminServices.getSubscriptionCoursePlansByID(props.subscriptionId);
      if (item.ok) {
        const data = { ...formValues };
        data.name = item.data.clientPlanName;
        data.selectCourse = item.data.coursePlan;
        data.noOfLicense = parseInt(item.data.no_of_license_allocated, 10);
        data.fromDate = moment(item.data.valid_from).format('YYYY-MM-DD');
        data.toDate = moment(item.data.valid_to).format('YYYY-MM-DD');
        data.remarks = item.data.remarks;
        setFormValues(data);
      }
    } catch (error) {
      console.log(error);
    }
    setLottieLoading(false);
  };

  useEffect(() => {
    lottie.loadAnimation({
      container: lottoLoad.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lottieData,
    });
    getSubscriptionLookup();
    if (props.mode === 'edit') {
      getSubscriptionPlansByid();
    }
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={Yup.object().shape({
          formType: Yup.string(),
          name: Yup.string().required('Subscription Name is required field').trim(),
          selectCourse: Yup.string().required('Select course is required field'),
          noOfLicense: Yup.string()
            .test('Is positive?', 'No of license must be greater than 0', (value) => value > 0)
            .required(' No of license is required field'),
          fromDate: Yup.date().required(' From Date is required field'),
          toDate: Yup.date().required(' To Date is required field'),
        })}
        onSubmit={(values) => {
          handleSubscriptionData(values);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
          <Form>
            {props.mode === 'edit' && lottieLoading ? (
              <div style={{ width: 50, margin: 'auto' }} ref={lottoLoad} />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs="12" sm="12" md="12">
                  <Typography gutterBottom>Subscription Name*</Typography>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    name="name"
                    onBlur={handleBlur}
                    // onInput={(e) => {
                    //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    // }}
                    type="text"
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography gutterBottom>Select subscription plan / Course*</Typography>
                  <Autocomplete
                    fullWidth
                    options={selectCourse}
                    autoHighlight
                    getOptionLabel={(option) => option.name || ''}
                    value={values.selectCourse}
                    onChange={(e, value) => {
                      setFieldValue('selectCourse', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="selectCourse"
                        error={Boolean(touched.selectCourse && errors.selectCourse)}
                        helperText={touched.selectCourse && errors.selectCourse}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography gutterBottom>No of license*</Typography>
                  <TextField
                    error={Boolean(touched.noOfLicense && errors.noOfLicense)}
                    fullWidth
                    helperText={touched.noOfLicense && errors.noOfLicense}
                    name="noOfLicense"
                    onBlur={handleBlur}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    type="number"
                    onChange={handleChange}
                    value={values.noOfLicense}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography>Valid From Date *</Typography>
                  <TextField
                    error={Boolean(touched.fromDate && errors.fromDate)}
                    fullWidth
                    helperText={touched.fromDate && errors.fromDate}
                    name="fromDate"
                    onBlur={handleBlur}
                    InputProps={{
                      inputProps: {
                        min: values.formType === 'create' && `${moment(new Date()).format('YYYY-MM-DD')}`,
                      },
                    }}
                    type="date"
                    onChange={handleChange}
                    value={values.fromDate}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography>Valid To Date *</Typography>
                  <TextField
                    error={Boolean(touched.toDate && errors.toDate)}
                    fullWidth
                    helperText={touched.toDate && errors.toDate}
                    name="toDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="date"
                    value={values.toDate}
                    variant="outlined"
                    InputProps={{
                      inputProps: {
                        min: `${moment(new Date(values.fromDate)).format('YYYY-MM-DD')}`,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography gutterBottom>Remarks</Typography>
                  <TextField
                    error={Boolean(touched.remarks && errors.remarks)}
                    fullWidth
                    helperText={touched.remarks && errors.remarks}
                    name="remarks"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.remarks}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                    {props.mode === 'create' ? 'Create' : 'Update'}
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
