import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { persistReducer } from 'redux-persist'
import reducer from './reducer';

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
     reducer:persistedReducer,
     middleware: [thunk],
     devTools: process.env.NODE_ENV !== 'production',
})