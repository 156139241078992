/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Box, TextField, ToggleButtonGroup, Link, ToggleButton, FormControl, FormHelperText } from '@mui/material';
import { Container } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import WebPage from '../../../website/components/WebPage';
import courseApi from '../../../services/users/courseApi';
import CardSkeleton from '../../../components/Skeleton/cardSkeleton';
import IndividualCard from '../../../components/cards/IndividualCard';
import BundleCard from '../../../components/cards/BundleCard';
import preSignedServices from '../../../services/preSignedServices';
import {
  openSubscriptionModal,
  setOpenSubscriptionModalDetails,
  openSnackbar,
  setSubscribedCourses,
} from '../../../store/reducer';
import trialAndStripeSubscriptionService from '../../../services/trialAndStripeSubscriptionService';
import helper from 'src/utils/helper';
import Modal from 'react-modal';
import Account from '../../profile/Index'
import BasicPaper from 'src/components/cards/BasicPaper';
import PhoneNumber from 'src/components/PhoneNumber/Index'
import ResetPasswordModal from 'src/container/profile/ResetPasswordModal'
import SnackBar from 'src/components/snackbar/snackbar'
import PageHeader from 'src/components/PageHeader'
import adminServices from 'src/services/adminServices';
import { loginSuccess } from 'src/store/reducer';
import loginServices from 'src/services/loginServices'
Modal.setAppElement('#root');
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Mycourse from '../../users/course/Mycourse'

import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const KeySkillsetCourses = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [individualCourses, setIndividualCourses] = useState([]);
  const [bundleCourses, setBundleCourses] = useState([]);
  const [individualCoursesLoading, setIndividualCoursesLoading] = useState(false);
  const [bundleCoursesLoading, setBundleCoursesLoading] = useState(false);
  const [isTrialing, setIsTrialing] = useState(null);
  const [isSubscribing, setIsSubscribing] = useState(null);
  const { openSubscriptionModalDetails, userInfo } = useSelector((state) => state);

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [urlImage, setUrlImage] = useState(null);
  const [showimageo, setShowimageo] = useState(true);
  const [editField, setEditField] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openResetModel, setOpenResetModel] = useState(false);
  const [isClickedButton, setIsClickedButton] = useState(false);
  const closeResetModel = () => setOpenResetModel(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  const [lastLoginDatetime, setLastLoginDatetime] = useState(null);
  const [isopenSnackbar, setOpenSnackbar] = useState(false);
  const [actualCosts, setActualCosts] = useState([]);
  const [bundleCosts, setBundleCosts] = useState([]);

  // const [pricing, setPricing] = useState({
  //   76: {
  //     yearly:{
  //         //Excel 
  //         USD: 50.00,
  //         INR: 4000
  //          }
  //       },
  //   82: {
  //     yearly:{
  //         //FM
  //         USD: 60.00,
  //         INR: 4800,
  //           }
  //       },
  //   81: {
  //     yearly:{
  //         //PPS
  //         USD: 50.00,
  //         INR: 4000,
  //           }
  //       },
  //   75: {
  //     yearly:{
  //         //MS Word
  //         USD: 40.00,
  //         INR: 3200,
  //         }
  //       },
  //   78: {
  //     yearly:{
  //         //Quickbooks
  //         USD: 80.00,
  //         INR: 6400,
  //         }
  //       },
  //   83: {
  //     yearly:{
  //         //WS Essentials
  //         USD: 100.00,
  //         INR: 8000,
  //         }
  //       }, 
  //   77: {
  //     yearly:{ 
  //         //Ninja
  //         USD: 75.00,
  //         INR: 6000,
  //       }
  //     },
  //   91: {
  //       yearly:{
  //         // python Beginner with tutor
  //         USD: 150.00 ,
  //         INR: 12000 
  //       }
  //     },
  //     92: {
  //       yearly:{
  //         // python Beginner platform access
  //         USD: 50.00,
  //         INR: 4000,
  //       }
  //     },
  //   79: {
  //       yearly:{
  //         // SQL Beginner
  //         USD: 50.00,
  //         INR: 4000,
  //       }
  //     }
  // });

  // const [devpricing, setDevPricing] = useState({
  //   23: {
  //     monthly:{
  //       USD: 8.99,
  //       INR: 1500,
  //     },
  //     quarterly: {
  //       USD: 20.00,
  //       INR: 3000,
  //     },
  //     halfyearly:{
  //       USD: 40.00,
  //       INR: 6000,
  //     },
  //     yearly:{
  //       USD: 50.00,
  //       INR: 7000,
  //     }
  //   },
  //   27: {
  //     //Excel
  //     monthly:{ 
  //       USD: 129.00,
  //       INR: 10320,
  //     },
  //     quarterly: {
  //       USD: 200.00,
  //       INR: 20000,
  //     },
  //     halfyearly:{
  //       USD: 400.00,
  //       INR: 30000,
  //     },
  //     yearly:{
  //       USD: 500.00,
  //       INR: 60000,
  //     }
  //   },
  //   28: {
  //     monthly:{
  //       USD: 49.99,
  //       INR: 4095,
  //     },
  //     quarterly: {
  //       USD: 100.00,
  //       INR: 8000,
  //     },
  //     halfyearly:{
  //       USD: 200.00,
  //       INR: 15000,
  //     },
  //     yearly:{
  //       USD: 250.00,
  //       INR: 16000,
  //     }
  //   },
  //   29: {
  //     //FM
  //     monthly:{
  //       USD: 99.00,
  //       INR: 7920,
  //     },
  //     quarterly: {
  //       USD: 100.00,
  //       INR: 10000,
  //     },
  //     halfyearly:{
  //       USD: 200.00,
  //       INR: 20000,
  //     },
  //     yearly:{
  //       USD: 350.00,
  //       INR: 30000,
  //     }
  //   },
  //   30: {
  //     //PPS
  //     monthly: {
  //       USD: 59.00,
  //       INR: 4720,
  //     },
  //     quarterly: {
  //       USD: 100.00,
  //       INR: 8000,
  //     },
  //     halfyearly:{
  //       USD: 200.00,
  //       INR: 16000,
  //     },
  //     yearly:{
  //       USD: 300.00,
  //       INR: 17000,
  //     }
  //   },
  //   32: {
  //     //MS Word
  //     monthly:{
  //       USD: 59.00,
  //       INR: 4720,
  //     },
  //     quarterly: {
  //       USD: 100.00,
  //       INR: 6000,
  //     },
  //     halfyearly:{
  //       USD: 200.00,
  //       INR: 10000,
  //     },
  //     yearly:{
  //       USD: 350.00,
  //       INR: 12000,
  //     }
  //   },
  //   34: {
  //     //Quickbooks
  //     monthly:{
  //       USD: 60.00,
  //       INR: 4800,
  //     },
  //     quarterly: {
  //       USD: 100.00,
  //       INR: 10000,
  //     },
  //     halfyearly:{
  //       USD: 200.00,
  //       INR: 20000,
  //     },
  //     yearly:{
  //       USD: 350.00,
  //       INR: 25000,
  //     }
  //   },
  //   42: {
  //     monthly:{
  //       USD: 8.99,
  //       INR: 1500,
  //     },
  //     quarterly: {
  //       USD: 15.00,
  //       INR: 3000,
  //     },
  //     halfyearly:{
  //       USD: 30.00,
  //       INR: 5000,
  //     },
  //     yearly:{
  //       USD: 50.00,
  //       INR: 7000,
  //     }
  //   },
  //   47: {
  //     //WS Essentials
  //     monthly:{
  //       USD: 179.00,
  //       INR: 14320,
  //     },
  //     quarterly: {
  //       USD: 300.00,
  //       INR: 20000,
  //     },
  //     halfyearly:{
  //       USD: 500.00,
  //       INR: 30000,
  //     },
  //     yearly:{
  //       USD: 650.00,
  //       INR: 47000,
  //     }
  //   },
  //   48: {
  //     //Ninja
  //     monthly:{
  //       USD: 179.00,
  //       INR: 14320,
  //     },
  //     quarterly: {
  //       USD: 400.00,
  //       INR: 40000,
  //     },
  //     halfyearly:{
  //       USD: 800.00,
  //       INR: 60000,
  //     },
  //     yearly:{
  //       USD: 950.00,
  //       INR: 70000,
  //     }
  //   },
  //   50: {
  //     monthly:{
  //       USD: 19.99,
  //       INR: 1599,
  //     },
  //     quarterly: {
  //       USD: 40.00,
  //       INR: 5000,
  //     },
  //     halfyearly:{
  //       USD: 800.00,
  //       INR: 7000,
  //     },
  //     yearly:{
  //       USD: 950.00,
  //       INR: 9000,
  //     }
  //   },
  //   2: {
  //     // python Beginner
  //     monthly: {
  //       USD: 249.00,
  //       INR: 19920,
  //     },
  //     quarterly: {
  //       USD: 400.00,
  //       INR: 32000,
  //     },
  //     halfyearly:{
  //       USD: 800.00,
  //       INR: 60000,
  //     },
  //     yearly:{
  //       USD: 950.00,
  //       INR: 70000,
  //     }
  //   },
  //   81: {
  //     // SQL Beginner
  //     monthly: {
  //     USD: 249.00,
  //     INR: 19920,
  //     },
  //     quarterly: {
  //       USD: 450.00,
  //       INR: 45000,
  //     },
  //     halfyearly:{
  //       USD: 800.00,
  //       INR: 60000,
  //     },
  //     yearly:{
  //       USD: 950.00,
  //       INR: 70000,
  //     }
  //   },
  // });
  const [currency, setCurrency] = React.useState(userInfo?.currencyType ? userInfo?.currencyType.code : 'USD');
  const [frequency, setFrequency] = React.useState('yearly');
  const [intfrequency, setintfrequency] = React.useState(1);
  const [reference, setReference] = React.useState(false);

  const [courseGroups, setCourseGroup] = useState([]);

React.useEffect(()=>{
  getAllcourseDetails()
},[reference])

  const getAllcourseDetails = async() => {
    const response = await courseApi.getCourseList();
    setCourseGroup(response.data);
  }

  const handleFrequencyChange = (event, value) => {
    if (value) {

      let intfreq
      if (value === 'monthly') {
        setintfrequency(2);
        intfreq = 2
      } else if (value === 'quarterly') {
        setintfrequency(5);
        intfreq = 5
      } else if (value === 'halfyearly') {
        setintfrequency(3);
        intfreq = 3
      } else {
        setintfrequency(1);
        intfreq = 1
      }
      setFrequency(value);
      getIndividualCourses(intfreq);
      getBundleCourses(intfreq);
    }
  }

  const handleChange = (event, value) => {
    if (value) {
      setCurrency(value);
    }
  };

  const getCourserList = async () => {
    try {
      const response = await courseApi.getCourseList();
      if (response.ok) {
        dispatch(setSubscribedCourses(response.data));
        setReference(true)
        setCourseGroup(response.data);
      // const individualCourses = response.data.filter((item) => item.isBundle === false);
      // const bundleCourses = response.data.filter((item) => item.isBundle);
      // const grouped = Array.from(
      //   bundleCourses
      //     .reduce((m, o) => m.set(o.subscriptionPlanId, (m.get(o.subscriptionPlanId) || []).concat(o)), new Map())
      //     .values()
      // );

      } else {
        console.log('failed');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIndividualCourses = async (freq) => {
    setBundleCoursesLoading(true);
    try {
      const res = await courseApi.getWebsiteIndividualCourses(freq);
      if (res.ok) {
        setIndividualCourses(res.data);
        handlePrice(res.data, 'individual')
        setBundleCoursesLoading(false);
      } else {
        setBundleCoursesLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBundleCourses = async (freq) => {
    setIndividualCoursesLoading(true);
    try {
      const res = await courseApi.getWebsiteBundleCourses(freq);
      if (res.ok) {
        setBundleCourses(res.data);
        handlePrice(res.data, 'bundle')
        setIndividualCoursesLoading(false);
      } else {
        setIndividualCoursesLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(navigator);
    getLoginUserDetailsFirstTime();
    getIndividualCourses(intfrequency);
    getBundleCourses(intfrequency);
    setIsModalOpen(isFirstTimeLogin);
  }, [isFirstTimeLogin]);

  const handleClickTrialButton = async (planId, trialLoadingId) => {
    setIsTrialing(trialLoadingId);
    try {
      const res = await trialAndStripeSubscriptionService.postTrialCourseEnrollmentDetails(
        JSON.stringify({ planId: planId })
      );
      if (res.ok) {
        getCourserList();
        dispatch(openSnackbar('Trial Course Subscribed Successfully.'));
        // navigate('/auth/my-courses');
        navigate('/auth/subscribe');

      } else {
        if (res?.data?.message) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to Subscribe Trial Course, Please try again.'));
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsTrialing(null);
  };

  const handlePrice = (items, type) => {
    console.log('inside function')
    let actualCosts = []

    items.map(item => {
      let element = {
        id: 0,
        USD: 0,
        INR: 0
      }

      let prices = item.prices

      if (prices.length > 0) {
        prices.forEach(p => {
          element.id = item.subscriptionPlanId
          if (p.currencyType?.code === 'USD') {
            element.USD = p.actualCost
          } else
            element.INR = p.actualCost
        })
        actualCosts.push(element)
      }
      if (type === 'individual')
        setActualCosts(actualCosts)
      else
        setBundleCosts(actualCosts)

    })


  }

  const postSubscribeDetails = async (item, planId) => {
    const courseId = item.id;
    if (item.isPaid) {
      // dispatch(setOpenSubscriptionModalDetails(item));
      // dispatch(openSubscriptionModal(planId));

      let impactClickId = '';
      try {
        impactClickId = await helper.getClickId();
      } catch (error) {
        console.error(error);
        impactClickId = '';
      }

      const payload = {
        courseId,
        planId,
        currencyType: currency,
        frequency: parseInt(intfrequency),
        impactClickId,
      };

      try {
        const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify(payload));
        if (res.status === 303) {
          // dispatch(closeSubscriptionModal());
          // setSubscriptionModalLoading(false);
          window.location = res.data.paymentUrl;
        } else {
          if (res?.data?.message) {
            dispatch(openSnackbar(res.data.message));
          } else {
            dispatch(openSnackbar('Failed to Subscribe, Please try again.'));
          }
          // setSubscriptionModalLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsSubscribing(planId);
      try {
        const res = await trialAndStripeSubscriptionService.createStripeSubscription(JSON.stringify({ planId }));
        if (res.ok) {
          navigate('/auth/my-courses');
          dispatch(openSnackbar('Course subscribed successfully'));
        } else if (res.status === 400) {
          dispatch(openSnackbar(res.data.message));
        } else {
          dispatch(openSnackbar('Failed to subscribed pls try again'));
        }
      } catch (error) {
        console.log(error);
      }
      setIsSubscribing(null);
    }
  };

  //profile information starts here
  const [formvalue, setFormvalue] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
    imgUrl: null,
    profileImg: null,
    thumbPreview: null,
  });

  const getLoginUserDetails = async () => {
    const response3 = await loginServices.getUserInfo();
    if (response3.ok) {
      dispatch(loginSuccess(response3.data));
    }
    console.log(response3, 'response3');

  };


  useEffect(() => {
    setIsClickedButton(false);
  }, [editField]);

  const handleSubmitProfileValues = async (values) => {
    const formData = new FormData();
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('phone', values.phone);
    formData.append('profileImg', values.profileImg);

    try {
      const response = await adminServices.profileUpdate(formData);
      console.log('response from adminServices..', response);
      console.log('formData from adminServices..', formData);
      if (response.ok) {
        if (response.data && response.data.preSignedUrl) {
          await preSignedServices.preSignedUrlUpload(response.data.preSignedUrl, values.profileImg)
        }
        await getLoginUserDetails();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setEditField(false);
        setLoading(false);


        setTimeout(() => {
          setSnackbarTitle(response.data.message);
          setIsModalOpen(false);
        }, 400);

      } else if (response.status === 400) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };


  const handleSubmitForm = (values) => {
    // values.profileImg = URL.createObjectURL(values.profileImg);
    handleSubmitProfileValues(values);
    // setFormvalue(values);
    setLoading(true);
    console.log('uservalues :', values.profileImg);

  };
  // const values.phone = `1${userInfo.phone}`

  const getLoginUserDetailsFirstTime = async () => {
    try {
      const hasFunctionRun = localStorage.getItem('hasFunctionRun');
      const response3 = await loginServices.getUserInfo();
      console.log('hasFunctionRun', hasFunctionRun)

      // Condition to show the profile pop up to show only once during login
      if (hasFunctionRun === null) {

        if (response3.ok) {
          dispatch(loginSuccess(response3.data));
          setLastLoginDatetime(response3.data.login_first_time);
          if (response3.data.login_first_time === true) {
            console.log('lastlogin only when it\'s null  ', response3.data.login_first_time);
            setIsModalOpen(true);
            setIsFirstTimeLogin(true);
            localStorage.setItem('hasFunctionRun', true);
          } else {
            setIsFirstTimeLogin(false);
          }
        } else {
          console.log('Response not OK');
        }
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
  // profile popup ends here

  return (
    <div>
      <div>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            contentLabel="Profile Popup"

            style={{
              content: {
                width: '50%',
                margin: 'auto',
                top: '20%',
                right: '0',
                height: '65%',
                backgroundColor: 'rgb(237, 235, 235) ',
                borderRadius: '10px',
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              }
            }}

          >
            <PageHeader pageTitle="Profile" />

            {/* {console.log('userInfoone :', userInfo)} */}


            <BasicPaper>

              {editField ? (
                <>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      firstName: userInfo?.firstName,
                      lastName: userInfo?.lastName,
                      phone: userInfo?.phone,
                      profileImg: null,
                      thumbPreview: userInfo?.profileImg,
                    }}
                    validationSchema={Yup.object().shape({
                      firstName: Yup.string()
                        .trim()
                        .required('First Name is a required field')
                        .min(3, 'First name contains at least 3 character')
                        .label()
                        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
                      lastName: Yup.string()
                        .trim()
                        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
                      thumbPreview: Yup.string().nullable(),

                      profileImg: Yup.mixed()
                        .nullable()
                        .test('fileFormat', 'Unsupported Format', (value) => {
                          return value === null || (value && SUPPORTED_FORMATS.includes(value.type));
                        })
                        .test('fileSize', 'Maximum file size is 3MB', (value) => {
                          return value === null || (value && value.size < 3000000);
                        }),
                    })}
                    onSubmit={(values) => {
                      handleSubmitForm(values);
                    }}
                  >
                    {({ errors, handleBlur, setFieldValue, touched, values }) => (
                      <Form>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                              First Name*
                            </Typography>
                            <TextField
                              fullWidth
                              name="firstName"
                              variant="outlined"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                console.log(e.target.value);
                                setFieldValue('firstName', e.target.value);
                              }}
                              error={Boolean(touched.firstName && errors.firstName)}
                              helperText={touched.firstName && errors.firstName}
                              value={values.firstName}
                              inputProps={{ maxLength: 180 }}
                            />
                          </Grid>

                          <Grid item xs="12" sm="6">
                            <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                              Last Name
                            </Typography>
                            <TextField
                              fullWidth
                              name="lastName"
                              variant="outlined"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue('lastName', e.target.value);
                              }}
                              error={Boolean(touched.lastName && errors.lastName)}
                              helperText={touched.lastName && errors.lastName}
                              value={values.lastName}
                              inputProps={{ maxLength: 180 }}
                            />
                          </Grid>

                          <Grid item xs="12" sm="6">
                            <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                              Phone Number
                            </Typography>
                            <PhoneNumber fullWidth country={'us'} specialLabel={''} />
                          </Grid>

                          <Grid item xs="12" sm="6">
                            <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                              Profile Picture
                            </Typography>

                            {values.thumbPreview !== '' ? (
                              <>
                                <Box mb={0.5}>
                                  <img width={'90px'} src={values.thumbPreview} alt="pic" />
                                </Box>
                                <Box mb={1}>
                                  <div>
                                    <input
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-file"
                                      type="file"
                                      name="profileImg"
                                      onChange={(e) => {
                                        console.log(e.target.files);
                                        if (
                                          SUPPORTED_FORMATS.includes(e.target.files[0].type)
                                          // e.target.files[0].size <= 2097152
                                        ) {
                                          setUrlImage(URL.createObjectURL(e.target.files[0]));
                                          setFieldValue('thumbPreview', URL.createObjectURL(e.target.files[0]));
                                        }
                                        setFieldValue('profileImg', e.currentTarget.files[0]);
                                      }}
                                    />
                                    <label htmlFor="contained-button-file">
                                      <Typography
                                        color="primary"
                                        component="span"
                                        sx={{ cursor: 'pointer;' }}
                                        onClick={(e) => {
                                          setShowimageo(true);
                                          setUrlImage('');
                                          setFieldValue('thumbPreview', URL.createObjectURL(e.target.files[0]));
                                          setFieldValue('profileImg', null);
                                        }}
                                      >
                                        Change Profile
                                      </Typography>
                                      <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                                        {(errors.profileImg || touched.profileImg) && errors.profileImg}
                                      </Typography>

                                      {/* <FormHelperText style={{ color: '#F44336', paddingLeft: 16 }}>
                                                  {errors.profileImg ? errors.profileImg : ''}
                                                </FormHelperText> */}
                                    </label>
                                  </div>
                                </Box>
                              </>
                            ) : (
                              <>
                                {(values.thumbPreview !== null || values.thumbPreview === '') && (
                                  <>
                                    <div className={classes.dropzone}>
                                      <FormControl
                                        required
                                        component="fieldset"
                                        color="primary"
                                        variant="outlined"
                                        type="file"
                                        fullWidth
                                        name="profileImg"
                                        value={values.profileImg}
                                      >
                                        <DropzoneArea
                                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                          dropzoneText="Drag and Drop Image or Browse File"
                                          showPreviewsInDropzone={false}
                                          maxFileSize={30000000}
                                          filesLimit={1}
                                          value={values.profileImg}
                                          showAlerts={false}
                                          onChange={(file) => {
                                            if (SUPPORTED_FORMATS.includes(file[0]?.type) && file[0]?.size <= 3000000) {
                                              const url = URL.createObjectURL(file[0]);
                                              setFieldValue('thumbPreview', url);
                                            }
                                            // setFieldValue('profileImg', file.currentTarget.files[0]);
                                            setFieldValue('profileImg', file[0]);
                                          }}
                                          previewGridClasses={{
                                            container: classes.previewContainer,
                                            item: classes.preview,
                                          }}
                                          showFileNamesInPreview
                                        />
                                      </FormControl>
                                    </div>
                                    {
                                      <FormHelperText style={{ color: '#F44336' }}>
                                        {errors.profileImg && isClickedButton ? errors.profileImg : ''}
                                      </FormHelperText>
                                    }
                                  </>
                                )}

                              </>
                            )}
                          </Grid>

                          <Grid align="end" item xs="12">
                            <Stack direction="row" align="end" spacing={2} sx={{ display: 'flex;', justifyContent: 'end;' }}>
                              <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                sx={{
                                  color: '#fff !important',
                                  background: 'gray;',
                                }}
                                // onClick={() => setEditField(false)}
                                onClick={() => setIsModalOpen(false)}
                              >
                                Skip
                              </LoadingButton>
                              <LoadingButton
                                onClick={() => {
                                  setIsClickedButton(true);

                                }}
                                loading={loading}
                                size="medium"
                                type="submit"
                                variant="contained"
                              >
                                Update
                              </LoadingButton>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  <Grid container mt={0}>
                    <Grid item xs={10} sm={11}>
                      <Grid container spacing={2}>
                        <Grid className={classes.girdContainer} item xs="12">
                          {userInfo && userInfo.profileImg ? (
                            <Box className={classes.logo}>
                              <img width={'120px'} height={'100px'} src={userInfo && userInfo.profileImg} alt="logo" />
                            </Box>
                          ) : (
                            <Box ml={0} className={classes.logo}>
                              <Avatar alt="Remy Sharp" src={formvalue.profileImg} sx={{ width: 50, height: 50 }} />
                            </Box>
                          )}
                          <Box className='name-email' style={{ paddingTop: '30px' }} >
                            <Typography>{userInfo && userInfo.name}</Typography>
                            <Typography> {userInfo && userInfo.email}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={1} textAlign="end">
                      <IconButton
                        onClick={() => {
                          setEditField(!editField);
                          setShowimageo(true);

                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container mt={0} spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
                        Phone
                      </Typography>
                      <Typography variant="body1" component="div">
                        {userInfo && userInfo.phone && userInfo.phone.length > 9 ? `+${userInfo.phone}` : null}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={3} sx={{ cursor: 'pointer' }} mt={3}>
                                <Typography variant="subtitle1" component="div" className={classes.changePassword} gutterBottom>
                                  <Link
                                    onClick={() => {
                                      setOpenResetModel(true);
                                    }}
                                    variant="subtitle2"
                                    sx={{ color: '#e7443e;', textDecoration: 'unset;' }}
                                  >
                                    Change Password
                                  </Link>
                                </Typography>
                              </Grid> */}
                  </Grid>

                  <div className='cancel-button' style={{ position: 'absolute', bottom: 50, width: '90%' }}>
                    <Grid align="end" item xs="12">
                      <Stack direction="row" align="end" spacing={2} sx={{ display: 'flex;', justifyContent: 'end;' }}>
                        <LoadingButton
                          size="medium"
                          type="submit"
                          variant="contained"
                          sx={{
                            color: '#fff !important',
                            background: 'gray;',

                          }}
                          onClick={() => setIsModalOpen(false)}
                        >
                          Cancel
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </div>
                </>
              )}
            </BasicPaper>
            <ResetPasswordModal openModal={openResetModel} closeModel={() => setOpenResetModel(false)} />
            <SnackBar open={isopenSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

          </Modal>
        )}
      </div>


      {/* <WebPage sx={{ backgroundColor: '#fff' }} title="Course Lists"> */}
      <WebPage sx={{ backgroundColor: '#fff' }} title="Course Lists">
        <div className={classes.main}>
          <Container sx={{ maxWidth: '1440px ! important' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="h4"></Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >

                <Typography paddingRight={'1rem'}>
                  {userInfo?.currencyType ? 'preferred currency ' : 'Select your preferred currency'}
                </Typography>

                <ToggleButtonGroup
                  color="primary"
                  value={currency}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  disabled={userInfo?.currencyType !== null && true}
                >
                  <ToggleButton sx={{ padding: '4px 10px' }} value="USD">
                    $ USD
                  </ToggleButton>
                  <ToggleButton sx={{ padding: '4px 10px' }} value="INR">
                    ₹ INR
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>

            <Mycourse courseGroups={courseGroups}/>

            <Typography variant="h4">Subscription plans</Typography>
            <Grid container spacing={4} mt={1} justifyContent={'center'}>
              {individualCoursesLoading ? (
                <>
                  {[1, 2].map((res) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <CardSkeleton />
                    </Grid>
                  ))}
                </>
              ) : (
                <>
                  {individualCourses &&
                    individualCourses.map((item, index) => {
                      return (
                        <>
                          <Grid item xs="12" sm="6" md="4" lg="3" xl="3">
                            <IndividualCard
                              item={item}
                              image={item.thumbImage}
                              title={item.title}
                              description={item.description}
                              category={item.category}
                              logo={item.category.categoryImgUrl}
                              isTrial={item.isTrial}
                              isSubscribed={item.isSubscribed}
                              subscriptionPlanId={item.subscriptionPlanId}
                              handleTrial={handleClickTrialButton}
                              handleSubcribe={postSubscribeDetails}
                              price={new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(actualCosts[index]?.[currency])}
                              // price={pricing[item.subscriptionPlanId]?.INR}
                              // price={new Intl.NumberFormat('en-US', {
                              //   style: 'currency',
                              //   currency: currency,
                              // }).format((['PROD', 'PREPROD'].indexOf(`${process.env.REACT_APP_ENV}`.toUpperCase()) >= 0 )? (pricing[item.subscriptionPlanId]?.[frequency]?.[currency]):(devpricing[item.subscriptionPlanId]?.[frequency]?.[currency]))}

                              isTrialLoading={isTrialing}
                              isSubscribeLoading={isSubscribing}
                              isPaid={item.isPaid}

                            />
                          </Grid>
                        </>
                      );
                    })}
                  {individualCourses.length === 0 && bundleCourses.length === 0 && (
                    <Grid item xs={12}>
                      <Box mt={4}>
                        <Typography variant="h6" textAlign={'center'}>
                          No Data
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </>
              )}

              {bundleCoursesLoading ? (
                <>
                  {[1].map((res) => (
                    <Grid item xs={12} sm={4} md={4} lg={3}>
                      <CardSkeleton />
                    </Grid>
                  ))}
                </>
              ) : (
                <>
                  {bundleCourses &&
                    bundleCourses.map((item, index) => {
                      return (
                        <>
                          <Grid item xs="12" sm="6" md="4" lg="3" xl="3">
                            <BundleCard
                              handleCardClick={() => console.log('clk')}
                              item={item}
                              image={item.courseobj[0].thumbImage}
                              subscriptionPlanId={item.subscriptionPlanId}
                              courseobj={item.courseobj}
                              handleTrial={handleClickTrialButton}
                              handleSubcribe={postSubscribeDetails}
                              // enrollDate={item.validFrom}
                              price={new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(bundleCosts[index]?.[currency])}
                              // price={new Intl.NumberFormat('en-US', {
                              //   style: 'currency',
                              //   currency: currency,
                              // }).format((['PROD', 'PREPROD'].indexOf(`${process.env.REACT_APP_ENV}`.toUpperCase()) >= 0 )? (pricing[item.subscriptionPlanId]?.[frequency]?.[currency]):(devpricing[item.subscriptionPlanId]?.[frequency]?.[currency]))}
                              isTrialLoading={isTrialing}
                              isSubscribeLoading={isSubscribing}
                            />
                          </Grid>
                        </>
                      );
                    })}
                </>
              )}
            </Grid>
          </Container>
        </div>
      </WebPage>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '5rem 0rem',
    '@media (max-width: 540px)': {
      padding: '6rem 1rem 1rem 1rem',
    },
  },
  topcontent: {
    marginLeft: '2rem',
    marginRight: '0rem',
    marginTop: '2rem',
    '@media (max-width: 640px)': {
      marginLeft: '0rem',
      marginRight: '0rem',
      marginTop: '2rem',
    },
  },
  lineHeight1: {
    margin: '0.5rem 0rem 1rem 0rem',
    color: '#0B5D5F',
  },
  aboutCourse: {
    fontSize: '1.2rem',
    fontWeight: '400',
    marginBottom: '2rem',
    color: ' #667085',
  },
  courseBtn: {
    backgroundColor: '#FFD141',
    color: '#000',
    borderRadius: '16px',
    padding: '2px 16px',
    fontWeight: '500',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#FFD141',
    },
  },
  searchfield: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'no-wrap',
    marginBottom: '2rem',
    '@media (max-width:640px)': {
      flexWrap: 'wrap',
    },
  },
  text: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
  },
  getstart: {
    backgroundColor: '#EC7930',
    padding: '7px 26px',
    fontSize: '0.95rem',
    fontWeight: '450',
    color: '#fff',
    borderRadius: '20px',
    margin: '6px 0px 0px 16px',
    '&:hover': {
      backgroundColor: '#EC7930',
      boxShadow: '0 0 1px 6px rgba(236, 121, 48, 40%),0 0 1px 12px rgba(236,121,48, 20%)',
    },
  },
  home: {
    color: '#1D817F',
    fontSize: '0.95rem',
    fontWeight: '550',
  },
  solution: {
    fontSize: '0.95rem',
    color: '#667085',
    marginBottom: '1rem',
  },
  iconsec: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '4px',
    textDecoration: 'none',
  },
}));

export default KeySkillsetCourses;
