import React from 'react';
import Slide from '@mui/material/Slide';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

export default function TransitionsModal(props) {
  const classes = useStyles();
  const handleClose = () => {
    props.toggle();
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        hideBackdrop={false}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide direction="left" in={props.open}>
          <div className={classes.paper}>
            <div className={classes.headerGrid}>
              <div className={classes.header}>
                <Typography color="primary" className={classes.titles} variant="subtitle1">
                  {props.title}
                </Typography>
                <IconButton className={classes.closeIcon} onClick={handleClose} size={'small'} aria-label="delete">
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <Box>{props.children}</Box>
          </div>
        </Slide>
      </Modal>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 'auto !important',
    bottom: 'auto !important',
    height: '100vh',
    [theme.breakpoints.down(330)]: {
      left: '0px !important',
    },
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    [theme.breakpoints.up('sm')]: {
      width: '660px',
    },
    border: 'none',
    overflow: 'auto',
    height: '100vh',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    // background: '#00BCD4',
    padding: '8px 16px',
    margin: '-16px -16px 0px -16px',
    justifyContent: 'space-between',
    '&>h2': {
      flex: 1,
      margin: 'auto',
      color: `#fff`,
    },
  },
  headerGrid: {
    flexDirection: 'column',
    width: '100%',
  },
  closeIcon: {
    color: '#FE7000',
    backgroundColor: '#FFE7D5',
    borderRadius: '50%',
  },
  titles: {
    marginLeft: '9px !important',
    fontSize: '19px !important',
    fontWeight: 'bold !important',
    [theme.breakpoints.down('md')]: {
      marginLeft: '12px !important',
    },
  },
}));
