/* eslint-disable */
import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Typography, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';

const MultipleSubscribedTable = ({ BundleData, loading, getBundleSubscriber, pageCount }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterValue, setFilterValue] = useState('');

  const columns = [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.length >= 19 ? (
                <Tooltip title={value} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} arrow>
                  <Typography className={classes.names}>{value}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.names}>{value}</Typography>
              )}
            </>
          );
        },
      },
    },

    {
      name: 'createDateTime',
      label: 'Date',
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography className={classes.dateWidth}>
              {/* {moment(value).format('MM-DD-YYYY')} */}
              {`${value.substring(5, 7)}/${value.substring(8, 10)}/${value.substring(0, 4)}`}
            </Typography>
          );
        },
      },
    },

    {
      name: 'subscriptionPlanName',
      label: 'Subscription plan name',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.length >= 19 ? (
                <Tooltip title={value} classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} arrow>
                  <Typography className={classes.name}>{value}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.name}>{value}</Typography>
              )}
            </>
          );
        },
      },
    },

    {
      name: 'courses',
      label: 'Course',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const valuelength = value.map((item) => item.courseName).join(',');
          return (
            <>
              {valuelength.length >= 19 ? (
                <Tooltip
                  title={value.map((item) => item.courseName).join(',')}
                  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                  arrow
                >
                  <Typography className={classes.coursePlan}>
                    {value.map((item) => item.courseName).join(',')}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.coursePlan}>{value.map((item) => item.courseName).join(',')}</Typography>
              )}
            </>
          );
        },
      },
    },

    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.length >= 19 ? (
                <Tooltip
                  title={value}
                  classes={{
                    arrow: classes.arrow,
                    tooltip: classes.tooltip,
                  }}
                  arrow
                >
                  <Typography className={classes.name}>{value}</Typography>
                </Tooltip>
              ) : (
                <Typography className={classes.name}>{value}</Typography>
              )}
            </>
          );
        },
      },
    },

    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography className={classes.emailText}>+{value}</Typography>;
        },
      },
    },

    {
      name: 'startDate',
      label: 'Start',
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography className={classes.dateWidth}>
              {/* {moment(value).format('MM-DD-YYYY')} */}
              {`${value.substring(5, 7)}/${value.substring(8, 10)}/${value.substring(0, 4)}`}
            </Typography>
          );
        },
      },
    },

    {
      name: 'endDate',
      label: 'End',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography className={classes.dateWidth}>
              {/* {moment(value).format('MM-DD-YYYY')} */}
              {`${value.substring(5, 7)}/${value.substring(8, 10)}/${value.substring(0, 4)}`}
            </Typography>
          );
        },
      },
    },

    {
      name: 'noOfDaysLeft',
      label: 'No. of days left',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: 'isPaid',
      label: '',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Typography>{value}</Typography>;
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let colors = '';
          const Capitalize = value.toLowerCase();

          if (value === 'ACTIVE') {
            colors = 'green';
          } else if (value === 'EXPIRED') {
            colors = 'red';
          } else {
            colors = 'gray';
          }
          return (
            <>
              {
                <Typography
                  sx={{
                    fontWeight: '400',
                    lineHeight: 'unset',
                    backgroundColor: colors,
                    textAlign: 'center',
                    borderRadius: '24px',
                    minWidth: '91px',
                    padding: '3px 0px',
                    fontSize: '0.9rem',
                    color: 'white',
                  }}
                  className={classes.noofDays}
                >
                  {Capitalize.charAt(0).toUpperCase() + Capitalize.slice(1)}
                </Typography>
              }
            </>
          );
        },
      },
    },

    {
      name: 'subscriptionId',
      label: 'Subscription ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'customerId',
      label: 'Customer ID',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  return (
    <div>
      <div style={{ marginTop: '1rem' }}>
        <MUIDataTable
          data={BundleData}
          columns={columns}
          options={{
            responsive: 'scroll',
            pagination: true,
            filter: true,
            download: true,
            print: false,
            jumpToPage: true,
            page,
            search: true,
            count: pageCount,
            serverSide: true,
            rowsPerPage,
            rowsPerPageOptions: [10, 25, 50, 75, 100],
            selectableRows: false,
            textLabels: {
              body: {
                noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
              },
            },
            onTableChange: (action, tableState) => {
              if (action === 'changeRowsPerPage') {
                setRowsPerPage(tableState.rowsPerPage);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getBundleSubscriber(
                  tableState.page + 1,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : '',
                  `${tableState.filterList[2][0]},${tableState.filterList[3][0]},${
                    tableState.filterList[9][0] === 'Paid'
                      ? true
                      : tableState.filterList[9][0] === 'Free'
                      ? false
                      : tableState.filterList[9][0]
                  },${tableState.filterList[10][0]}`
                );
              } else if (action === 'changePage') {
                setRowsPerPage(tableState.rowsPerPage);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getBundleSubscriber(
                  tableState.page + 1,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : '',
                  `${tableState.filterList[2][0]},${tableState.filterList[3][0]},${
                    tableState.filterList[9][0] === 'Paid'
                      ? true
                      : tableState.filterList[9][0] === 'Free'
                      ? false
                      : tableState.filterList[9][0]
                  },${tableState.filterList[10][0]}`
                );
              } else if (action === 'search') {
                setRowsPerPage(tableState.rowsPerPage);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getBundleSubscriber(
                  tableState.page + 1,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : '',
                  `${tableState.filterList[2][0]},${tableState.filterList[3][0]},${
                    tableState.filterList[9][0] === 'Paid'
                      ? true
                      : tableState.filterList[9][0] === 'Free'
                      ? false
                      : tableState.filterList[9][0]
                  },${tableState.filterList[10][0]}`
                );
              } else if (action === 'sort') {
                setRowsPerPage(tableState.rowsPerPage);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getBundleSubscriber(
                  tableState.page,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : '',
                  `${tableState.filterList[2][0]},${tableState.filterList[3][0]},${
                    tableState.filterList[9][0] === 'Paid'
                      ? true
                      : tableState.filterList[9][0] === 'Free'
                      ? false
                      : tableState.filterList[9][0]
                  },${tableState.filterList[10][0]}`
                );
              } else if (action === 'filterChange') {
                setRowsPerPage(tableState.rowsPerPage);
                setPage(tableState.page);
                setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                getBundleSubscriber(
                  tableState.page,
                  tableState.rowsPerPage,
                  tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                  tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                  tableState.searchText !== null ? tableState.searchText : '',
                  `${tableState.filterList[2][0]},${tableState.filterList[3][0]},${
                    tableState.filterList[9][0] === 'Paid'
                      ? true
                      : tableState.filterList[9][0] === 'Free'
                      ? false
                      : tableState.filterList[9][0]
                  },${tableState.filterList[10][0]}`
                );
              }
            },
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dateWidth: {
    width: '100px',
  },
  noofDays: {
    minWidth: '120px',
  },
  names: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '160px',

    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
      maxWidth: 'unset',
      textOverflow: 'ellipsis',
      '&:hover': {
        maxWidth: 'unset',
        textOverflow: 'clip',
        whiteSpace: 'normal',
      },
    },
  },
  coursePlan: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '160px',
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
      maxWidth: 'unset',
      textOverflow: 'ellipsis',
      '&:hover': {
        maxWidth: 'unset',
        textOverflow: 'clip',
        whiteSpace: 'normal',
      },
    },
  },

  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '160px',
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
      maxWidth: 'unset',
      textOverflow: 'ellipsis',
      '&:hover': {
        maxWidth: 'unset',
        textOverflow: 'clip',
        whiteSpace: 'normal',
      },
    },
  },
  emailText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '190px',
  },
  arrow: {
    [theme.breakpoints.up('md')]: {
      color: 'unset',
      display: 'none',
    },
  },
  tooltip: {
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid gray',
    },
  },
}));

export default MultipleSubscribedTable;
