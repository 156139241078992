import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button, Snackbar, Alert, Chip } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { LoadingButton } from '@mui/lab';
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';

// import AddSubscriptionForm from './AddSubscriptionForm';
// import AddUsersForm from './AddUsers';
import AddUser from './AddUser';
import UsersTable from './UsersTable';
import BasicModel from '../../../../components/modal/BasicModel';
import adminServices from '../../../../services/adminServices';
import SnackBar from '../../../../components/snackbar/snackbar';
import DeleteAlert from '../../../../components/modal/DeleteModal';
import ClientAdminServices from '../../../../services/clientAdmin/course/clientAdminServices';

export default function EnrolledUser(props) {
  const classes = useStyles();
  const location = useLocation();
  const [handleOpenUserModal, setHandleOpenUserModal] = React.useState(false);
  const [handleOpenUserTable, setHandleOpenUserTable] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subscriptionId, setSubscriptionId] = React.useState();
  const [clientPlanId, setClientPlanId] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [values, setValues] = React.useState('');
  const [coursePlanId, setCoursePlanId] = React.useState();
  const [subscriptionData, setSubscriptionData] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [enrolledUser, setEnrolledUser] = useState([]);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);

  const { from } = location.state;

  const open = Boolean(anchorEl);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertModal(false);
  };

  const handleErrorMessage = (message) => {
    setError(message);
  };

  const handleClickMenu = (event, id, clientId, courseId) => {
    setAnchorEl(event.currentTarget);
    setSubscriptionId(id);
    setClientPlanId(clientId);
    setCoursePlanId(courseId);
  };
  const handleClickTable = (event, planId) => {
    getEnrolledUserById(planId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const snakbarHandle = (message) => {
    setOpenSnackbar(true);
    setSnackbarTitle(message);
  };

  const handleUserTableClose = () => {
    setHandleOpenUserTable(false);
    setEnrolledUser([]);
  };

  const handleAddUser = () => {
    setHandleOpenUserModal(true);
  };

  const handleUserClose = () => {
    setHandleOpenUserModal(false);
  };

  const snackbarHandle = (message) => {
    setOpenSnackbar(true);
    setSnackbarTitle(message);
  };

  const getEnrolledUserById = async (planId) => {
    setLoading(true);
    console.log(subscriptionId, 'subscriptionId');
    try {
      const result = await ClientAdminServices.getEnrolledUser(props.courseId, planId);
      if (result.ok) {
        setEnrolledUser(result.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubscription = async () => {
    setLoading(true);
    try {
      const response = await ClientAdminServices.getSubscriptionData(props.courseId);
      if (response.ok) {
        setSubscriptionData(response.data);
        setLoading(false);
        // getEnrolledUserById();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getEnrolledUserById();
    getSubscription();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'clientId',
      label: 'client Id',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'courseId',
      label: 'course Id',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'remarks',
      label: 'Remarks',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'clientPlanName',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'planName',
      label: 'Plan',
    },
    {
      name: 'noOfLicenseAllocated',
      label: 'No of License Purchased',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'noOfLicenseAssigned',
      label: 'No of License Used',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Typography
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: '#FE7000',
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: '#FE7000',
                  },
                }}
                onClick={(e) => {
                  handleClickTable(e, tableMeta.rowData[0]);
                  setHandleOpenUserTable(true);
                }}
              >
                {value}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'validFrom',
      label: 'Valid From',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Typography sx={{ minWidth: 'max-content' }} variant="body2">
                {moment(value).format('DD-MM-YYYY')}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'validTo',
      label: 'Valid Till',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Typography sx={{ minWidth: 'max-content' }} variant="body2">
                {moment(value).format('DD-MM-YYYY')}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Chip label={value} />
            </div>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>
                <IconButton
                  onClick={(e) => {
                    handleClickMenu(e, tableMeta.rowData[0], tableMeta.rowData[1], tableMeta.rowData[2]);
                    setValues(tableMeta.rowData[8]);
                  }}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <MoreVertIcon />
                </IconButton>
                <ThemeProvider theme={customeTheme}>
                  <Menu
                    id="basic-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <div>
                      <div>
                        <MenuItem
                          onClick={() => {
                            handleAddUser();
                            setAnchorEl(null);
                          }}
                        >
                          <PersonAddIcon fontSize="small" style={{ color: '#BCBCBC' }} />
                          &nbsp;
                          <Typography>Enroll User</Typography>
                        </MenuItem>
                      </div>
                    </div>
                  </Menu>
                </ThemeProvider>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <Grid container spacing={3}>
      <Grid item xs="12">
        <div style={{ marginTop: '1rem' }}>
          <MUIDataTable
            data={subscriptionData}
            columns={columns}
            options={{
              rowsPerPage: 25,
              responsive: 'scroll',
              rowsPerPageOptions: [25, 50, 75, 100],
              selectableRows: false,
              filter: false,
              download: false,
              print: false,
              textLabels: {
                body: {
                  noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
                },
              },
            }}
          />
        </div>
      </Grid>

      {/* <DeleteAlert
        open={deleteAlert}
        title="Are you sure you want delete this Subscription"
        confirm={handleDelete}
        close={() => setDeleteAlert(false)}
      /> */}

      <BasicModel
        maxWidth={'md'}
        openModel={handleOpenUserTable}
        title={'Enrolled Users'}
        closeModel={handleUserTableClose}
        children={
          <UsersTable
            enrolledUser={enrolledUser}
            getEnrolledUserById={(id) => getEnrolledUserById(id)}
            snackBarControl={snackbarHandle}
            closeModel={handleUserTableClose}
            getSubscription={getSubscription}
            loading={loading}
            setLoading={setLoading}
            // planId={planId}
          />
        }
      />
      <BasicModel
        openModel={handleOpenUserModal}
        title={'Add User'}
        closeModel={handleUserClose}
        children={
          <AddUser
            subscriptionId={subscriptionId}
            clientPlanId={clientPlanId}
            coursePlanId={coursePlanId}
            getSubscription={getSubscription}
            snackBarControl={snakbarHandle}
            closeModel={handleUserClose}
            setOpenAlertModal={setOpenAlertModal}
            setErrorMessage={handleErrorMessage}
          />
        }
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={openAlertModal} onClose={handleClose}>
        {error.length > 0 ? (
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            <div>
              {' '}
              <table style={{ borderCollapse: 'collapse', padding: '2rem,0rem' }}>
                <tr style={{ textAlign: 'left' }}>
                  <th className={classes.tableData}>Email</th>
                  <th className={classes.tableData}>Error</th>
                  <th className={classes.tableData}>Plan Name</th>
                </tr>
                {error.map((items, index) => {
                  return (
                    <tr>
                      <td className={classes.tableData}>{items.email}</td>
                      <td className={classes.tableData}>{items.error}</td>
                      <td className={classes.tableData}>{items.clientPlanName}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Alert>
        ) : (
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            <Typography variant="body2">User Enrolled Successfully</Typography>
          </Alert>
        )}
      </Snackbar>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down(514)]: {
      marginTop: '14px',
    },
  },
  tableData: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: '8px',
  },
}));

const customeTheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&.MuiModal-backdrop': {
            background: 'transparent',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          color: '#FE7000',
          border: '1px solid #FE7000',
          '&:hover': {
            border: '1px solid #FE7000',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiPopover-paper': {
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px;',
          },
        },
      },
    },
  },
});
