/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  title: {},
}));

export default function PieChart(props) {
  const classes = useStyles();

  var options = {
    width: 480,
    type: 'donut',
    series: [44, 55, 41],
    chart: {
      type: 'donut',
      parentHeightOffset: 0,
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      show: false,
      fontSize: '14px',
      //   fontFamily: config.fontFamily,
      fontWeight: 400,
      horizontalAlign: 'center',
      position: 'left',
      offsetX: 10,
      offsetY: 40,
      margin: 60,
      itemMargin: {
        horizontal: 0,
        vertical: 10,
      },
    },
    labels: ['New Sign ups', 'Subscriptions', 'Canceled Subscriptions'],

    dataLabels: {
      enabled: false,
    },

    colors: ['rgb(0, 138, 229)', 'rgb(0, 182, 115)', 'rgb(254, 112, 0)'],

    responsive: [
      {
        breakpoint: 599,
        options: {
          legend: {
            show: false,
          },
        },
      },
      {
        breakpoint: 899,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],

    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 0.8,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: '60%',
          background: 'transparent',
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: '24px',
              fontWeight: 600,
              color: undefined,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: '38px',
              fontWeight: 600,
              color: '#1F78B4',
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },

            total: {
              show: true,
              showAlways: false,
              label: 'Total',
              fontSize: '22px',
              fontWeight: 600,
              color: '#1F78B4',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
  };

  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: '550', margin: '1rem' }}>
        Pie Chart
      </Typography>
      <div style={{ borderBottom: '1px solid #80808038' }} />
      <ReactApexChart
        options={options}
        series={options.series}
        type="donut"
        // height={380}
        height={337}
      />
    </div>
  );
}
