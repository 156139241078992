import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import CreateCourseContent from './CreateCourseContent';
import Slider from '../../../components/modal/Index';
import BasicModel from '../../../components/modal/BasicModel';
import CaseStudiess from '../../../services/caseStudies/CaseStudies';
import UploadFiles from './UploadFiles';
import DeleteAlert from '../../../components/modal/DeleteModal';
import SnackBar from '../../../components/snackbar/snackbar';
import AddScreenModel from './AddScreenModel';
import Rename from './Rename';
import Iconify from '../../../components/Iconify';
import preSignedServices from '../../../services/preSignedServices';

function CaseStudies({ courseDetails }) {
  const classes = useStyles();
  const location = useLocation();
  const courseId = location.state;
  const [openSlider, setOpenSlider] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [caseStudyFiles, setCaseStudyFiles] = useState([]);
  const [screenData, setScreenData] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [caseStudiesId, setCaseStudiesId] = useState('');
  const [screenId, setScreenId] = useState('');
  const [screenIdForEdit, setScreenIdForEdit] = useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [openAddScreenForm, setOpenAddScreenForm] = useState(false);
  const [caseStudies, setCaseStudies] = useState('');
  const [screenDataForEdit, setScreenDataForEdit] = useState(null);
  const [screenType, setScreenType] = useState('CREATE');
  const [btnLoading, setBtnLoading] = useState(false);
  // const [modalTitle, setModalTitle] = useState('');
  const [configurationType, setConfigurationType] = useState('CREATE');
  const [configurationData, setConfigurationData] = useState({});
  const [configurationId, setConfigurationId] = React.useState('');
  const [name, setName] = useState('');
  const [screenIds, setScreenIds] = useState('');
  const [rename, setRename] = useState('');
  const [id, setId] = useState('');
  const [status, setStatus] = useState('');
  const [fileName, setFileName] = useState('');
  const [screenName, setScreenName] = useState('');
  const handleClose = () => {
    setOpenSlider(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpandClickOpen = () => {
    setExpand(!expand);
  };

  const handleDeleteModalOpen = (id) => {
    setName('deleteScreen');
    setDeleteAlert(true);
    setCaseStudiesId(id);
  };

  const handleDeleteScreen = (id) => {
    setName('');
    setDeleteAlert(true);
    setScreenId(id);
  };

  const snackbarHandle = (message) => {
    setOpenSnackbar(true);
    setSnackbarTitle(message);
  };

  const getCaseStudyFiles = async () => {
    try {
      const response = await CaseStudiess.getCaseStudyFiles(courseId);
      if (response.ok) {
        setCaseStudyFiles(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getScreenForCaseStudies = async () => {
    try {
      const response = await CaseStudiess.getScreenForCaseStudies(courseId);
      if (response.ok) {
        setScreenIds(response.data.id);
        setScreenData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteProject = async () => {
    if (name === 'deleteScreen') {
      try {
        const response = await CaseStudiess.deleteCaseStudiesFiles(caseStudiesId);
        if (response.ok) {
          setDeleteAlert(false);
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
          getCaseStudyFiles();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await CaseStudiess.deleteScreensForCaseStudies(screenId);
        if (response.ok) {
          getScreenForCaseStudies();
          setDeleteAlert(false);
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmitScreenForm = async (values, keyObj) => {
    console.log(values);
    setBtnLoading(true);
    const formData = new FormData();
    formData.append('title', values.screenTitle);
    formData.append('description', values.description);
    formData.append('actionType', values.keyboardType);
    formData.append('caseStudiesQuestion', values.caseStudiesQuestion);

    if (values.keyboardType === 'CODE') {
      formData.append('prependCode', values.prependScript);
      formData.append('appendCode', values.appendScript);
      formData.append('inputCode', values.inputCode);
      formData.append('resultType', values.codeResultType);

      if (values.codeResultType === 'IMAGE' && values.keyboardType === 'CODE' && values.codeResultImage) {
        // formData.append('image', values.codeResultImage);
        const fileObj = {
          fileName: values.codeResultImage?.name,
          contentType: values.codeResultImage?.type,
        };
        formData.append('imagePreSignedUrl', JSON.stringify(fileObj));
      } else {
        formData.append('text', values.codeOutputValue);
      }
    } else {
      formData.append('keyObj', JSON.stringify(keyObj));
      formData.append('horizontalAlign', values.horizontalAlign);
      formData.append('verticalAlign', values.verticalAlign);
    }

    if (screenType === 'CREATE') {
      try {
        const response = await CaseStudiess.CreateScreenForCaseStudies(courseId, formData);
        if (response.ok) {
          if (values.keyboardType !== 'CODE') {
            keyObj.map(async (res, idx) => {
              await preSignedServices.preSignedUrlUpload(response.data?.macPreSignedUrl[idx], res.mac.file);
              await preSignedServices.preSignedUrlUpload(response.data?.windowsPreSignedUrl[idx], res.windows.file);
            });
          }
          if (values.codeResultType === 'IMAGE' && values.keyboardType === 'CODE') {
            await preSignedServices.preSignedUrlUpload(response.data.imagePreSignedUrl, values.codeResultImage);
          }

          getScreenForCaseStudies();
          setOpenAddScreenForm(false);
          setOpenSnackbar(true);
          setSnackbarTitle('Screen created successfully');
          setBtnLoading(false);
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
          setBtnLoading(false);
        } else {
          setOpenAddScreenForm(false);
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
          setBtnLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const response = await CaseStudiess.updateScreenForCaseStudies(courseId, screenIdForEdit, formData);
        setBtnLoading(true);
        if (response.ok) {
          if (values.keyboardType !== 'CODE') {
            keyObj.map(async (res, idx) => {
              await preSignedServices.preSignedUrlUpload(response.data?.macPreSignedUrl[idx], res.mac.file);
              await preSignedServices.preSignedUrlUpload(response.data?.windowsPreSignedUrl[idx], res.windows.file);
            });
          }
          if (values.codeResultType === 'IMAGE' && values.keyboardType === 'CODE' && values.codeResultImage) {
            await preSignedServices.preSignedUrlUpload(response.data.imagePreSignedUrl, values.codeResultImage);
          }
          setScreenDataForEdit(null);
          getScreenForCaseStudies();
          setOpenAddScreenForm(false);
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
          setBtnLoading(false);
        } else {
          setScreenDataForEdit(null);
          setOpenAddScreenForm(false);
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
          setBtnLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getScreenDataByID = async () => {
    try {
      const response = await CaseStudiess.getScreenDataByID(courseId, screenIdForEdit);
      if (response.ok) {
        setScreenIds(response.data.id);
        setScreenDataForEdit(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getConfigurationForCaseStudies = async () => {
    try {
      const response = await CaseStudiess.getConfigurationForCaseStudies(courseId);
      if (response.ok) {
        setStatus(response.data.status);
        setConfigurationData(response.data);
        if (Object.keys(response.data).length > 0) {
          setConfigurationType('EDIT');
        }
        setConfigurationId(response.data.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModleOpen = (id) => {
    setId(id);
    setRename('rename');
    setOpenModal(true);
  };

  const handleStatus = async () => {
    try {
      const response = await CaseStudiess.updateConfigurationStatus(configurationId);
      if (response.ok) {
        getConfigurationForCaseStudies();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCaseStudyFiles();
    getScreenForCaseStudies();
    getConfigurationForCaseStudies();
    if (screenIdForEdit !== '') {
      getScreenDataByID();
    }
  }, [screenIdForEdit]);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const fileIcon = [
    { type: 'image/jpeg', icon: 'ant-design:file-image-outlined' },
    { type: 'image/png', icon: 'ant-design:file-image-outlined' },
    { type: 'image/jpg', icon: 'ant-design:file-image-outlined' },
    { type: 'image/svg+xml', icon: 'ant-design:file-image-outlined' },
    { type: 'application/pdf', icon: 'fa6-regular:file-pdf' },
    { type: 'video/mp4', icon: 'la:file-video-solid' },
    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icon: 'icons8:xls' },
  ];

  let colors = '';
  const Capitalize = status?.toLowerCase();

  if (status === 'ACTIVE') {
    colors = 'green';
  } else {
    colors = 'gray';
  }

  return (
    <div>
      <Box mt={3} pb={3} mb={2} sx={{ borderBottom: '1px solid #00B673 !important', paddingBottom: '12px !important' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">Case Study</Typography>
            {status && (
              <div>
                <Typography
                  sx={{
                    width: 'min-content',
                    fontWeight: '400',
                    lineHeight: 'unset',
                    backgroundColor: colors,
                    textAlign: 'center',
                    borderRadius: '4px',
                    padding: '2px 10px',
                    fontSize: '0.85rem',
                    color: 'white',
                    marginLeft: '1rem',
                    textTransform: 'capitalize',
                  }}
                >
                  {Capitalize}
                </Typography>
              </div>
            )}
          </div>

          <div>
            {Object.keys(configurationData).length > 0 && status !== 'ACTIVE' ? (
              <Button
                variant="contained"
                className={classes.activeButton}
                onClick={() => {
                  handleStatus();
                }}
              >
                Active
              </Button>
            ) : (
              ''
            )}

            <Button
              variant="contained"
              startIcon={<SettingsIcon />}
              onClick={() => {
                setOpenSlider(true);
              }}
            >
              {Object.keys(configurationData).length > 0 ? 'Edit Settings' : 'Create Settings'}
            </Button>
          </div>
        </div>
      </Box>

      <Card className={classes.cardItem}>
        <CardActions disableSpacing onClick={handleExpandClick}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '16px',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="subtitle1" sx={{ fontSize: '1.1rem' }}>
              Materials
            </Typography>

            <div>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ background: '#6D6969' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setRename('');
                  setOpenModal(true);
                  setFileName('');
                }}
              >
                Upload files
              </Button>
            </div>
          </div>
          <ExpandMore onClick={handleExpandClick} aria-label="show more" sx={{ marginLeft: '16px' }}>
            <ExpandMoreIcon style={{ transform: expanded === true ? 'rotate(180deg)' : 'rotate(0deg)' }} />
          </ExpandMore>
        </CardActions>

        <Collapse in={expanded === true} timeout="auto" unmountOnExit>
          <CardContent>
            {caseStudyFiles && caseStudyFiles.length > 0 ? (
              caseStudyFiles.map((item) => {
                return (
                  <>
                    <Box className={classes.cardBox} display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Link
                          underline="none"
                          variant="subtitle1"
                          sx={{ display: 'flex' }}
                          href={item.url}
                          target="new"
                        >
                          {fileIcon.map((res) => {
                            return (
                              <>{item.type === res.type ? <Iconify icon={res.icon} width={20} height={20} /> : ''}</>
                            );
                          })}
                          <Typography sx={{ fontSize: '0.90rem' }} color="primary">
                            {item.name}
                          </Typography>
                        </Link>
                      </Box>
                      <div>
                        <IconButton
                          onClick={() => {
                            handleModleOpen(item.id);
                            setFileName(item.name);
                          }}
                          size="small"
                        >
                          <EditSharpIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleDeleteModalOpen(item.id);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </Box>
                  </>
                );
              })
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography>No Data</Typography>
              </div>
            )}
          </CardContent>
        </Collapse>
      </Card>

      <Card className={classes.cardItem}>
        <CardActions disableSpacing onClick={handleExpandClickOpen}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '16px',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant="subtitle1" sx={{ fontSize: '1.1rem' }}>
                Screens
              </Typography>
            </Box>

            {status !== 'ACTIVE' && (
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ background: '#6D6969' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setScreenDataForEdit(null);
                  setScreenType('CREATE');
                  setCaseStudies('caseStudy');
                  setOpenAddScreenForm(true);
                  setScreenName('');
                }}
              >
                Add screen
              </Button>
            )}
          </div>
          <ExpandMore onClick={handleExpandClickOpen} aria-label="show more" sx={{ marginLeft: '16px' }}>
            <ExpandMoreIcon style={{ transform: expand === true ? 'rotate(180deg)' : 'rotate(0deg)' }} />
          </ExpandMore>
        </CardActions>

        <Collapse in={expand === true} timeout="auto" unmountOnExit>
          <CardContent>
            {screenData && screenData.length > 0 ? (
              screenData.map((item) => {
                return (
                  <>
                    <Box className={classes.cardBox} display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography sx={{ fontSize: '0.90rem' }} color="primary">
                          {item.title}
                        </Typography>
                      </Box>
                      <div>
                        <IconButton
                          onClick={() => {
                            setScreenName(item.title);
                            setScreenIdForEdit(item.id);
                            setScreenType('EDIT');
                            setScreenDataForEdit(item);
                            // setModalTitle('Edit Topic Name');
                            setOpenAddScreenForm(true);
                            setCaseStudies('caseStudy');
                            setScreenIds(item.id);
                          }}
                          size="small"
                        >
                          <EditSharpIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleDeleteScreen(item.id);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </Box>
                  </>
                );
              })
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography>No Data</Typography>
              </div>
            )}
          </CardContent>
        </Collapse>
      </Card>

      <Slider
        open={openSlider}
        toggle={handleClose}
        title={'Settings'}
        children={
          <CreateCourseContent
            toggle={handleClose}
            snackBarControl={snackbarHandle}
            data={configurationData}
            configurationId={configurationId}
            type={configurationType}
            getConfigurationForCaseStudies={getConfigurationForCaseStudies}
          />
        }
      />
      <BasicModel
        openModel={openModal}
        title={rename !== '' ? 'Rename File Name' : 'Upload Case Document'}
        closeModel={handleCloseModal}
        children={
          rename !== '' ? (
            <Rename
              id={id}
              fileName={fileName}
              closeModel={handleCloseModal}
              snackBarControl={snackbarHandle}
              getCaseStudyFiles={getCaseStudyFiles}
            />
          ) : (
            <UploadFiles
              closeModel={handleCloseModal}
              snackBarControl={snackbarHandle}
              getCaseStudyFiles={getCaseStudyFiles}
            />
          )
        }
      />
      <DeleteAlert
        open={deleteAlert}
        title="Are you sure you want delete this screen?"
        confirm={handleDeleteProject}
        close={() => setDeleteAlert(false)}
      />
      <AddScreenModel
        typeOfScreen="ACTION"
        title={screenName}
        modalOpen={openAddScreenForm}
        formMode={caseStudies}
        formType={screenType}
        data={screenDataForEdit}
        loading={btnLoading}
        screenId={screenIds}
        handleSubmitForm={handleSubmitScreenForm}
        type="CASE_STUDIES"
        handleModalClose={() => {
          setScreenDataForEdit(null);
          setOpenAddScreenForm(false);
        }}
        callBackForKeyCreate={() => getScreenDataByID()}
        codeScreenType={courseDetails?.subCategory}
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  thumbImg: {
    width: '100%',
  },
  thumbBox: {
    width: 'max-content',
    background: '#fafafa',
    padding: '4px 8px',
    border: '1px solid #707070',
    borderRadius: '4px',
  },
  cardBox: {
    background: '#E6E6E6',
    padding: '12px',
    borderRadius: '4px',
    marginBottom: '8px',
  },
  editButton: {
    background: '#BCBCBC !important',
  },

  hashTagBox: {
    width: 'max-content',
    background: '#fafafa',
    padding: '4px 8px',
    marginRight: '8px',
    marginBottom: '8px',
    border: '1px solid #707070',
    borderRadius: '4px',
  },
  cardItem: {
    background: '#F9F9F9',
    marginBottom: '24px',
    border: '1px solid #e1e1e1ab',
    cursor: 'pointer',
  },
  showMoreText: {
    display: '-webkit-box',
    WebkitLineClamp: 11,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
  },
  readMore: {
    width: 'max-content',
    cursor: 'pointer',
  },
  activeButton: {
    marginRight: '1rem',
  },
}));

export default CaseStudies;
