import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Typography, Box, Backdrop, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';

const VideoModal = ({ children, openModel, closeModel, title }) => {
  const classes = useStyles();
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openModel}>
        <Grid container justifyContent={'center'} sx={{ maxWidth: '1440px !important' }}>
          <Grid item xs={10} md={10} lg={10}>
            <Box className={classes.combined}>
              <Typography className={classes.title}>{title}</Typography>
              <IconButton className={classes.icon} size="small" onClick={() => closeModel()}>
                <CloseIcon style={{ color: '#FE7000' }} fontSize="small" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={10} md={10} lg={10}>
            <Box sx={{ paddingTop: '10px' }}>{openModel && children}</Box>
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  combined: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingLeft: '22px',
    paddingBottom: '10px',
    paddingTop: '22px',
  },
  dialogContent: {
    marginTop: '1rem',
  },
  background: {
    fontWeight: 'bold',
    color: '#000000',
  },
  icon: {
    backgroundColor: '#FFE7D5',
    borderRadius: '50%',
  },
  title: {
    fontSize: '19px',
    fontWeight: 'bold',
    color: '#FE7000',
  },
}));
const customeTheme = createTheme({
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '-1px !important',
        },
      },
    },
  },
});

export default VideoModal;
