/* eslint no-else-return: "error" */
/* eslint no-var: off */

/* eslint-disable no-useless-escape */

const validateScript = (actualValue, userValue) => {
  const actualInput = userValue;
  const userInput = actualValue;
  const re = /\((.*)\)/;
  const insideParanthesisOutput = userInput.match(re)[1];

  console.log(actualValue, userValue);
  const userInputword = (word) => {
    let inputReturn = '';
    if (word.startsWith(`"`)) {
      try {
        inputReturn = word.split(`"`)[1];
      } catch (error) {
        return 'invalid userInput';
      }
    } else if (word.startsWith(`'`)) {
      try {
        inputReturn = word.split("'")[1];
      } catch (error) {
        return 'invalid userInput';
      }
    } else {
      inputReturn = 'invalid userInput';
    }
    return inputReturn;
  };

  let text1 = `''`;
  let text2 = `""`;

  const realOutPutword = userInputword(insideParanthesisOutput);

  if (realOutPutword !== 'invalid userInput') {
    text1 = `'${realOutPutword}'`;
    text2 = `"${realOutPutword}"`;
  }
  const beforeBracketWords = actualInput.split('(')[0].trimStart();

  const CheckPrintFunction = (char) => {
    try {
      const charbtbrackets = actualInput.match(re)[1];
      if (
        text1.toLowerCase().localeCompare(charbtbrackets.toString().toLowerCase()) === 0 ||
        text2.toLowerCase().localeCompare(charbtbrackets.toString().toLowerCase()) === 0
      ) {
        return true;
      }
      // return 'does not match';
    } catch (e) {
      console.log('error', e);
      return 'does not match';
    }
  };

  let output = '';

  console.log(
    beforeBracketWords.localeCompare('print'),
    CheckPrintFunction(actualInput),
    actualInput.trimEnd().endsWith(')')
  );

  if (
    beforeBracketWords.localeCompare('print') === 0 &&
    CheckPrintFunction(actualInput) === true &&
    actualInput.trimEnd().endsWith(')')
  ) {
    output = 'string is matched';
    // return 'string is matched';S
    console.log('string is matched');
  } else {
    output = 'syntax error';
    console.log('syntax error');
  }
  return output;
  // if (
  //   beforeBracketWords.localeCompare('print') !== 0 &&
  //   CheckPrintFunction(actualInput) !== true &&
  //   actualInput.trimEnd().endsWith(')')
  // ) {
  //   console.log('syntax error');
  //   return 'syntax error';
  // }
};

const pythonCodeChecker = (input, output) => {
  var userInputCode = input;
  var actualcode = output;
  var lineNum = [];

  // const invalidVariableAssign = userInputCode.filter((x, index) => {
  //   if (x.match(/\".*'|\'.*"/)) {
  //     console.log('index', index);
  //     lineNum.push(index);
  //   }
  //   return x.match(/\".*'|\'.*"/);
  // });

  userInputCode.map((value, index) => {
    var regExp = /\(([^)]+)\)/;
    // regex for finding dictionary
    var regExpDis = /=\s?\'\s?{|=\s?\"\s?{/;

    var actualcode1 = actualcode[index].replace(regExp, (k) => {
      return k.toLowerCase();
    });
    var inputData1 = value.replace(regExp, (code) => {
      return code.toLowerCase();
    });

    // this if condition is to chec the dictionary

    // if (inputData1.match(regExpDis)) {
    //   console.log('compare user and content w', inputData1 !== actualcode1)
    //   if (inputData1 !== actualcode1) {
    //     lineNum.push(index);
    //   }
    // }

    // this condition is to check exact matching
    if (inputData1.trimEnd() !== actualcode1.trimEnd()) {
      lineNum.push(index);
    }

    return false;
  });

  console.log(lineNum);
  return lineNum;
};

const sequelCodeChecker = (input, output) => {
  var userInputCode = input;
  var actualcode = output;
  var lineNum = [];

  userInputCode.map((value, index) => {
    var actualcode1 = actualcode[index];
    var inputData1 = value;

    if (inputData1.trim() !== actualcode1.trim()) {
      lineNum.push(index);
    }

    return false;
  });
  return lineNum;
};

export default {
  validateScript,
  pythonCodeChecker,
  sequelCodeChecker,
};
