import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import TriangleCursor from './TriangleCursor';
import SquareCursor from './SquareCursor';

const useStyles = makeStyles((theme) => ({
  cursorContainer: {
    position: 'absolute',
    top:'0px',
    left:'0px',
    transform: 'scale(1)', // Initial position
    transition: '1s ease', // Transition property
    animation: '$pulsate-fwd 2s infinite', // Animation property
    zIndex: 3,
  },
  '@keyframes pulsate-fwd': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.1)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));


function ParentCursor({ midX, midY, setArrow }) {

  const classes = useStyles();

  useEffect(() => {

    // Set the final position after the component mounts to trigger the transition
    const element = document.querySelector(`.${classes.cursorContainer}`);
    
    element.style.left = `${midX}px`;
    element.style.top = `${midY}px`;
  }, [midX, midY, classes.cursorContainer]);

  /*
    let animationInterval = 0;

    useEffect(() => {
      try{
        clearInterval(animationInterval);
      }
      catch(e){
        console.log(e);
      }
      // Set the final position after the component mounts to trigger the transition
      const element = document.querySelector(`.${classes.cursorContainer}`);
      
      let animationRun = 0;
      element.style.left = `${midX}px`;
      element.style.top = `${midY}px`;
      animationInterval = setInterval(() => {
        if(animationRun % 2 === 0){
          element.style.left = `${midX}px`;
          element.style.top = `${midY}px`;
          // eslint-disable-next-line no-plusplus
          animationRun++;
        }
        else{
          element.style.left = `0px`;
          element.style.top = `0px`;
          // eslint-disable-next-line no-plusplus
          animationRun++;
        }
      }, 4000);
    
    }, [midX, midY, classes.cursorContainer]);
  */

  return (
    <div className={classes.cursorContainer} role="presentation" onClick={() => { setArrow((prevArrow) => !prevArrow) }}>
      <TriangleCursor />
      <SquareCursor />
    </div>
  );
}

export default ParentCursor;
