import React, { useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axios from 'axios';

import adminServices from '../../../services/adminServices';
import Page from '../../../components/Page';
import CreateCourse from './CreateCourseForm';
import CloningCourse from './CloningCourse';
import PageHeader from '../../../components/PageHeader';

const CreateCourseTypes = () => {
  const classes = useStyles();
  const isGoBack = true;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [openCloningModal, setOpenCloningModal] = useState(false);

  const courseType = [
    {
      id: 1,
      title: 'Blank',
      description: 'Start a product from scratch with this blank blueprint',
      img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAATlBMVEX////+/v79/f37+/v8/Pz6+vr4+Pj5+fn39/f29vbq6urCwsLd3d3r6+vS0tLx8fGIiIjOzs6wsLDj4+PY2NjKysq/v7+4uLigoKCBgYHry+NbAAAEsElEQVR4nO3djXbaOBCGYf/LFpK1rESye/83uiMbDAmpmhpLM+1+b89pKaSQ58iSgDhpVSWrqaZo8RHr9Cd1YKTr2rZXBev7vo3OYsCm64dxKtuo+q5pyggbFcJcOu/cpNquxCjSITq9+9KZEIg4tiUO1Lpp3Sn/w3yoVaMPs3Zu7AsQ6SAtL5yc8/OsbRliNxQXVqOzmog+ErPPxbodbHFh4zRNxoU40ZKa98HqdiwvrAbrjfHnIkQeYeNoOTXbKGY9UEmoywurydKGUYbIJOwd7RfGmPm8EHOuqExCWmvMHGgyXlfUjEQmYTXEQfQbMeMTOC5hS4N49tpfV1Qi5lpRuYTNZD09OSWiuYSsRC5hPcbDND63MeFGzHOgcglpNaW1xhUgsgk7OkJnPX4gZllR2YTLRPT0MiM3kU24TERT9Y/EIQeRT6hoIpqqIaJ7nIuHr6hswmWpMdVCpFH04ZyJyCekPd+H6ol4+IspPmEXl5p4YSPm2TT4hM1NeCPGuaiPX1ElCPMSGYW0XZjr5e5GPBltp6E98osafML6QbhtGvPFeHvsW8WMwoG2i+1vD0TtpiO3flbhfQzvc/FwohhhNiKjUH0UPhEPWm7kjGEuoiThtmmsRHUMUZQwyygy7vhfCD9vGt0Byw3rcxr9JMywovIK/RdXH00UJ9xeTJ0v/pC5yDoPrf7ylm59B+6yErsXiYxCNTntrNVar+dnGBPWIsxtRPUikXG3WJZNveKup/ScL5fL6XR6e6OX+xuxf43IKOwGIm5juIzgojyfF+bZROKy9b+0afAJaUkZJzLGbIQ+MedwBJFRWN9Pp3NX6DKeZnPOIdzm4n4io7BaT4pcimcsDsNqtXdmMOZlIqdwPbP1Q01DajWtO2Jk3vbF/Ssqr/BH1XQEj6tS6424875ECtdoP6Ej1tp5Ie59v1+yMNbHFYiIy9uou+5BujCeyTi6eMLG3uNUvpCMdYjb4s5B/B2EVTW/GR9Pndrzb38P4eU9xMP0zxYa6/5k4envmSbisGupgVBGEKaCUEYQpoJQRhCmglBGEKaCUEYQpoJQRhCmglBGEKaCUEYQpoJQRhCmglBGEKaCUEYQpoJQRhCmglBGEKaCUEYQpoJQRhCmglBGEKaCUEYQpoJQRhCmglBGEKaCUEYQplqFT99TLSsIIYSQPwghhJA/CCGEkD8IIYSQPwghhJA/CL8hLPu/cv9yEEIIIX8QQgghfxBCCCF/EH5D2MkOQggh5A9CCCHkD0IISdjKDkIIIeQPQggh5A9CCEnYyw5CCCHkD0II/xdCxW1Id4RQdhBCCCF/EEJIwmFQ9Ouauv72eIW6XlD3j7jfoB6ve7zx+Y9PfX3t5zs4Rig6CCGEkD8IvyEcuQ2pxkOEsoMQQgj5g/AbwonbkGo6RCg7CCGEkD8IIbQQsveicLDyf8ru5T14u1NYdYP75y/p/ftuvJ2GZg+wbtTkrDdhPottnkPw2k39TmEbhUSUnPHxIG13Crs4iNqLTmvrRtXtEsZBJKJzVnL0+U2qbXYtNFVNxH7g3g9+2tB3O4ELsWvbnvtrhKn6vm2bpt4rrJafg8X9Dek/afl5WIn+A7wX4E5/WqNrAAAAAElFTkSuQmCC',
    },
    // {
    //   id: 2,
    //   title: 'Excel Efficiency',
    //   description: 'Create a quick and easy to consume course to build brand value',
    //   img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1Kv2opWmfOHzQAl6TM54yPtLPgMOzGUrwtg&usqp=CAU',
    // },
    // {
    //   id: 3,
    //   title: 'Financial Modeling',
    //   description: 'Start a product from scratch with this blank blueprint',
    //   img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1Kv2opWmfOHzQAl6TM54yPtLPgMOzGUrwtg&usqp=CAU',
    // },
    {
      id: 4,
      title: 'Course cloning',
      description: 'Course cloning',
      img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1Kv2opWmfOHzQAl6TM54yPtLPgMOzGUrwtg&usqp=CAU',
    },
  ];
  const handleCloningForm = (values) => {
    console.log(values, 'handleCloningForm:');
  };

  const handleSubmitForm = async (values) => {
    setLoading(true);
    const array = [];
    array.push(values.hashtag.map((res) => res.code));
    const trail = values.isTrial ? 'true' : 'false';
    const formData = new FormData();
    formData.append('category', values.courseCategory.code);
    formData.append('title', values.courseTitle);
    formData.append('description', values.description);
    formData.append('thumbImage', values.thumbImage);
    formData.append('isTrial', JSON.stringify(values.isTrial));
    formData.append("authors", values.authors);
    formData.append("type", values.type)

    if (values.courseCategory.code === 'SOFTWARE_DEVELOPMENT') {
      formData.append('subCategory', values?.subCategory?.code);
    }

    if (values.coverageImage) {
      formData.append('coverageImage', values.coverageImage);
    }

    if (values.videoFile) {
      formData.append('fileName', values.videoFile?.name);
      formData.append('contentType', values.videoFile?.type);
    }
    formData.append('hashTag', JSON.stringify(array[0]));

    const response = await adminServices.createCourse(formData);
    console.log(response);
    if (response.ok) {
      if (values.videoFile) {
        await axios
          .put(response.data?.preSignedUrl, values.videoFile, {
            headers: {
              'Content-Type': values.videoFile?.type,
              // 'x-amz-acl': 'public-read',
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentCompleted);
            },
          })
          .then((res) => {
            if (res.status === 200) {
              navigate('/app/course-content', { state: response.data.id });
            } else {
              console.log(res);
            }
          });
        return;
      }
      setProgress(0);
      navigate('/app/course-content', { state: response.data.id });
    }
    setLoading(false);
  };

  return (
    <Page title="Create-courses">
      <div>
        {/* <Box mb={3}>
                    <Typography variant='h6'>
                         Create Course
                    </Typography>
                    <Typography variant='body1'>
                         Start from scratch with a blank or choose a course template
                    </Typography>
               </Box> */}

        <PageHeader
          pageTitle=" Create Course"
          subTitle="Start from scratch with a blank or choose a course template"
          goBack={isGoBack}
        />

        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {courseType.map((response) => (
            <Grid item sm={4} md={4} lg={3}>
              <Paper
                elevation={2}
                className={classes.paper}
                onClick={() => {
                  <> {response.title === 'Course cloning' ? setOpenCloningModal(true) : setOpenModal(true)} </>;
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <Typography variant="subtitle2" gutterBottom>
                    {response.title}
                  </Typography>
                  {response.title === 'Course cloning' ? (
                    <ContentCopyIcon sx={{ color: '#d2cacadb', fontSize: '20px' }} />
                  ) : (
                    <img className={classes.imageIcon} src={response.img} alt="icon" width="20" height="20" />
                  )}
                </Box>
                <Typography variant="body1">{response.description}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>

      <CreateCourse
        open={openModal}
        modelClose={() => setOpenModal(false)}
        submitForm={handleSubmitForm}
        loading={loading}
        title="Create Course"
        formType="CREATE"
        progress={progress}
      />
      <CloningCourse
        open={openCloningModal}
        modelClose={() => setOpenCloningModal(false)}
        submitForm={handleCloningForm}
        loading={loading}
        title="Cloning Course"
        // formType="Copy"
      />
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '8px 14px',
    position: 'relative',
    background: '#F9F9F9',
    boxShadow: 'none',
    border: '1px solid #e5e1e1',
    minHeight: '116px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.primary.main,
      color: '#fff',
    },
  },
  imageIcon: {
    marginRight: '-6px',
  },
}));

export default CreateCourseTypes;
