import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid, Link, TextField, Typography, FormControl, FormHelperText, Box, Tooltip } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@mui/styles';
import AppleIcon from '@mui/icons-material/Apple';
import Autocomplete from '@mui/material/Autocomplete';

import BasicModals from '../../../components/modal/BasicModel';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import WindowsIcon from '../../../assets/logo/windows.svg';
import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';

const AddHotKeysModel = ({ open, handleClose, handleSubmit, keyType, editData }) => {

  const [initialFilesMac, setInitialFilesMac] = useState([]);
  const [initialFilesWindows, setInitialFilesWindows] = useState([]);

  const classes = useStyles();
  const [combinedWKeys, setCombinedWKeys] = useState([]);
  const [combinedMKeys, setCombinedMKeys] = useState([]);
  const [combinedWKeyName, setCombinedWKeyName] = useState([]);
  const [combinedMKeyName, setCombinedMKeyName] = useState([]);
  const [useWindowVirtualKeys, setUseWindowVirtualKeys] = useState(false);
  const [useMacVirtualKeys, setUseMacVirtualKeys] = useState(false);
  const [widowKeys, setWidowKeys] = useState([]);
  const [macKeys, setMacKeys] = useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getKeyboardLookUp = async () => {
    const lookUpResponse = await adminServices.getLookUpData('keyboard_keys');
    if (lookUpResponse.ok) {
      // setWidowKeys();
      const windowArray = lookUpResponse.data.keyboard_keys.filter((a, b) => a.keyboard === 'WINDOWS');
      const macArray = lookUpResponse.data.keyboard_keys.filter((a, b) => a.keyboard !== 'WINDOWS');
      setWidowKeys(windowArray);
      setMacKeys(macArray);
    }
  };

  useEffect(() => {
    setCombinedWKeys([]);
    setCombinedMKeys([]);
    setCombinedWKeyName([]);
    setCombinedMKeyName([]);
    getKeyboardLookUp();
  }, [open]);
  useEffect(() => {
    if (editData) {
      // Populate form fields with editData
      setCombinedWKeys(editData.combinedWKeys || []);
      setCombinedMKeys(editData.combinedMKeys || []);
      setCombinedWKeyName(editData.combinedWKeyName || []);
      setCombinedMKeyName(editData.combinedMKeyName || []);
      setUseWindowVirtualKeys(editData.useWindowVirtualKeys || false);
      setUseMacVirtualKeys(editData.useMacVirtualKeys || false);
      setWidowKeys(editData.widowKeys || []);
      setMacKeys(editData.macKeys || []);
      
      // setInitialFilesWindows(['https://testmybucket.s3.amazonaws.com/courseInfo/Screenshot__8_1721710472.png'])
      // setInitialFilesMac(['https://testmybucket.s3.amazonaws.com/courseInfo/Screenshot__8_1721710472.png'])

      
      // setInitialFilesWindows(editData?.windows?.windowspresignedurl ? [editData.windows.windowspresignedurl] : []);
      // setInitialFilesMac(editData?.mac?.macpresignedurl ? [editData.mac.macpresignedurl] : []);
      setInitialFilesWindows(editData?.windows?.windowspresignedurl ? [editData.windows.windowspresignedurl] : [editData.windows.backgroundImg]);

      setInitialFilesMac(editData?.mac?.macpresignedurl ? [editData.mac.macpresignedurl] : [editData.mac.backgroundImg]);
      // setInitialFilesWindows(
      getKeyboardLookUp();
    }
  }, [editData]);

  return (
    <>
      <BasicModals
        openModel={open}
        closeModel={handleClose}
        title={keyType === 'INDIVIDUAL_HOT_KEYS' ? 'Individual Hot Keys' : 'Combined Hot Keys'}
        children={
          <Formik
            initialValues={{
              windowsKey: editData?.windows?.keyName.join(' ') || '',
              macKey: editData?.mac?.keyName.join(' ') || '',
              widowImage: editData && editData.windows?.backgroundImg ? editData.windows.backgroundImg : null,
              macImage: editData && editData?.mac?.backgroundImg ? editData?.mac?.backgroundImg : null,
              combinedWindowKeys: editData?.windows?.keyCode || [],
              combinedMacKeys: editData?.mac?.keyCode || [],
              combinedWindowKeyName: editData?.windows?.keyName || [],
              combinedMacKeyName: editData?.mac?.keyName || [],
              windowBase64: editData?.windows?.backgroundImg || '',
              macBase64: editData?.mac?.backgroundImg || '',
            }}
            validationSchema={Yup.object().shape({
              widowImage: Yup.mixed()
                .required('Background image required')
                .test(
                  'fileSize',
                  'Maximum file size is 4MB',
                  (value) => value && typeof value === 'object' && value.size < 4194304
                ),
              macImage: Yup.mixed()
                .required('Background image required')
                .test(
                  'fileSize',
                  'Maximum file size is 4MB',
                  (value) => value && typeof value === 'object' && value.size < 4194304
                ),
              windowsKey: Yup.string().required('Key required').nullable(),
              macKey: Yup.string().required('Key required').nullable(),
            })}
            onSubmit={(values) => {
              console.log(values);
              handleSubmit(values);
              setInitialFilesWindows([])
              setInitialFilesMac([])
            }}
          >
            {({ values, errors, touched, handleBlur, setFieldValue }) => (

              <Form>
                <Grid container spacing={4} rowSpacing={4}>
                  {console.log(values, "valuesvalues")}

                  <Grid item xs="6" sm="6" md="5">
                    <Typography align="right" gutterBottom sx={{ marginRight: '1.2rem !important' }}>
                      Enter Key
                    </Typography>
                    <Box display={'flex'} alignItems="center" justifyContent={'space-around'}>
                      <div className={classes.iconPosition}>
                        <img src={WindowsIcon} alt="icon" />
                      </div>
                      <Box textAlign="center" paddingTop="8px">
                        <TextField
                          sx={{ width: '100px' }}
                          id="outlined-basic"
                          autoComplete="off"
                          variant="outlined"
                          name="windowsKey"
                          onBlur={handleBlur}
                          onChange={(event) => console.log(event)}
                          onKeyDown={(e) => {
                            e.preventDefault();
                            console.log(e);
                            if (keyType === 'INDIVIDUAL_HOT_KEYS') {
                              const wKeyCode = [];
                              const wKeyNames = [];
                              const windowsToMacKeyMap = {
                                'Control': 'Command',
                                'Alt': 'Option',
                                'Enter': 'Return',
                                'Backspace': 'Delete',
                              };
                              wKeyCode.push(e.keyCode);
                              wKeyNames.push(e.keyCode === 32 ? 'space' : e.key);

                              const macKeyNameArray = wKeyNames.map(key => windowsToMacKeyMap[key] || key);

                              setCombinedWKeyName(wKeyNames);
                              setCombinedMKeyName(macKeyNameArray);
                              setFieldValue('windowsKey', e.key);
                              setFieldValue('macKey', e.key);
                              setFieldValue('combinedWindowKeys', wKeyCode);
                              setFieldValue('combinedWindowKeyName', wKeyNames);
                              setFieldValue('combinedMacKeyName', macKeyNameArray);
                              setFieldValue('combinedMacKeys', wKeyCode);
                              setFieldValue('windowsKey', e.keyCode === 32 ? 'space' : e.key);
                              setFieldValue('macKey', e.keyCode === 32 ? 'space' : windowsToMacKeyMap[e.key] || e.key);
                            } else {
                              const keyCodeArray = combinedWKeys;
                              const keyNameArray = combinedWKeyName;
                              if (keyCodeArray.length > 3) {
                                setOpenSnackbar(true);
                                setSnackbarTitle('Maximum keys are 4');
                                return false;
                              }
                              const windowsToMacKeyMap = {
                                'Control': 'Command',
                                'Alt': 'Option',
                                'Enter': 'Return',
                                'Backspace': 'Delete',
                              };

                              keyNameArray.push(e.keyCode === 32 ? 'space' : e.key);
                              keyCodeArray.push(e.keyCode.toString());

                              // Convert Windows keys to Mac keys
                              const macKeyNameArray = keyNameArray.map(key => windowsToMacKeyMap[key] || key);

                              setCombinedWKeys(keyCodeArray);
                              setCombinedWKeyName(keyNameArray);
                              setCombinedMKeys(keyCodeArray);
                              setCombinedMKeyName(macKeyNameArray);

                              setFieldValue('combinedWindowKeys', combinedWKeys);
                              setFieldValue('combinedMacKeys', combinedMKeys);
                              setFieldValue('combinedWindowKeyName', combinedWKeyName);
                              setFieldValue('combinedMacKeyName', macKeyNameArray);
                              setFieldValue('windowsKey', e.keyCode === 32 ? 'space' : e.key);
                              setFieldValue('macKey', e.keyCode === 32 ? 'space' : windowsToMacKeyMap[e.key] || e.key);
                            }
                          }}
                          type="text"
                          value={values.windowsKey}
                          error={Boolean(touched.windowsKey && errors.windowsKey)}
                          helperText={touched.windowsKey && errors.windowsKey}
                        />
                        <ErrorFocus />
                      </Box>
                    </Box>

                    <Box marginTop={1}>
                      {useWindowVirtualKeys ? (
                        <Autocomplete
                          options={widowKeys}
                          autoHighlight
                          sx={{
                            '.MuiOutlinedInput-input': {
                              padding: '0px 6px !important',
                            },
                          }}
                          filterSelectedOptions
                          getOptionLabel={(option) => option.description || ''}
                          value={values.hashtag}
                          onChange={(e, value) => {
                            if (value) {
                              if (keyType === 'INDIVIDUAL_HOT_KEYS') {
                                const wKeyCode = [];
                                const wKeyNames = [];
                                wKeyCode.push(value.code);
                                wKeyNames.push(value.description);
                                setCombinedWKeyName(wKeyNames);
                                setFieldValue('combinedWindowKeys', wKeyCode);
                                setFieldValue('combinedWindowKeyName', wKeyNames);
                                setFieldValue('windowsKey', value.description);
                                setUseWindowVirtualKeys(false);
                              } else {
                                const keyCodeArray = combinedWKeys;
                                const keyNameArray = combinedWKeyName;
                                keyNameArray.push(value.description);
                                keyCodeArray.push(value.code);
                                setCombinedWKeys(keyCodeArray);
                                setCombinedWKeyName(keyNameArray);
                                setFieldValue('combinedWindowKeys', combinedWKeys);
                                setFieldValue('combinedWindowKeyName', combinedWKeyName);
                                setFieldValue('windowsKey', value.description);
                                setUseWindowVirtualKeys(false);
                              }
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              placeholder="select key"
                              {...params}
                              variant="outlined"
                              name="hashtag"
                              fullWidth
                            />
                          )}
                        />
                      ) : (
                        // <Typography variant="body2" align="center" sx={{ cursor: 'pointer' }}>
                        //   If you don't find the relevant keys on your keyboard{' '}
                        <Tooltip arrow title=" If you don't find the relevant keys on your keyboard? Click on Keys">
                          <Link
                            onClick={() => setUseWindowVirtualKeys(true)}
                            sx={{ textDecoration: 'none', cursor: 'pointer', fontSize: '0.7rem' }}
                          >
                            Find keys
                          </Link>
                        </Tooltip>
                        // </Typography>
                      )}
                    </Box>

                    <Box display={'flex'} marginTop={1}>
                      {combinedWKeyName.map((res, i) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.boxItem}>{res}</Typography>
                          {i < combinedWKeyName.length - 1 && (
                            <Typography variant="h5" sx={{ margin: '4px !important' }}>
                              +
                            </Typography>
                          )}
                        </div>
                      ))}
                    </Box>
                    {combinedWKeyName.length > 0 && (
                      <Typography
                        sx={{
                          marginTop: '8px !important',
                          cursor: 'pointer',
                          width: 'max-content',
                          fontSize: '0.7rem',
                        }}
                        color="primary"
                        onClick={() => {
                          setFieldValue('windowsKey', '');
                          setCombinedWKeyName([]);
                          setCombinedWKeys([]);
                          setCombinedMKeys([]);
                        }}
                      >
                        Clear
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs="12" sm="12" md="7">
                    <Typography>Background Image</Typography>
                    <div>
                      <FormControl
                        required
                        component="fieldset"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        name="widowImage"
                        value={values.widowImage}
                      >
                        <DropzoneArea
                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                          showPreviews
                          dropzoneText="Drag and Drop Image or Browse File"
                          showPreviewsInDropzone={false}
                          maxFileSize={104857600}
                          initialFiles={initialFilesWindows}

                          filesLimit={1}
                          showAlerts={false}
                          value={values.widowImage ? values.widowImage : values.windowBase64}
                          onChange={async (file) => {
                            setFieldValue('widowImage', file[0]);
                            if (file[0]?.size <= 4194304) {
                              // setFieldValue('macImage', file[0]);
                              setFieldValue('windowBase64', await convertBase64(file[0]));
                              // setFieldValue('macBase64', await convertBase64(file[0]));
                            }
                          }}
                          useChipsForPreview
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          showFileNamesInPreview
                        />
                        <FormHelperText style={{ color: '#F44336' }}>
                          {(errors.widowImage || touched.widowImage) && errors.widowImage}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xs="6" sm="6" md="5">
                    <Box display={'flex'} alignItems="center" justifyContent={'space-around'}>
                      <div className={classes.iconPosition}>
                        <AppleIcon
                          color="#a0a8ae"
                          sx={{ width: '60px !important', height: '60px !important', color: '#a0a8ae' }}
                        />
                      </div>
                      <Box textAlign="center" paddingTop="8px">
                        <TextField
                          sx={{ width: '100px' }}
                          width="70px"
                          id="outlined-basic"
                          variant="outlined"
                          autoComplete="off"
                          name="macKey"
                          onBlur={handleBlur}
                          onKeyDown={(e) => {
                            e.preventDefault();
                            if (keyType === 'INDIVIDUAL_HOT_KEYS') {
                              const mKeyCode = [];
                              const mKeyNames = [];
                              mKeyCode.push(e.keyCode);
                              if (e.keyCode === 91 || e.keyCode === 93) {
                                mKeyNames.push('command');
                              } else {
                                mKeyNames.push(e.keyCode === 32 ? 'space' : e.key);
                              }

                              setCombinedMKeyName(mKeyNames);
                              setFieldValue('combinedMacKeyName', mKeyNames);
                              setFieldValue('combinedMacKeys', mKeyCode);
                              if (e.keyCode === 91 || e.keyCode === 93) {
                                setFieldValue('macKey', 'command');
                              } else {
                                setFieldValue('macKey', e.keyCode === 32 ? 'space' : e.key);
                              }
                            } else {
                              const macKeyCode = combinedMKeys;
                              const macKeyName = combinedMKeyName;
                              if (macKeyCode.length > 3) {
                                setOpenSnackbar(true);
                                setSnackbarTitle('Maximum keys are 4');
                                return false;
                              }
                              if (e.keyCode === 91 || e.keyCode === 93) {
                                macKeyName.push('command');
                              } else {
                                macKeyName.push(e.keyCode === 32 ? 'space' : e.key);
                              }

                              macKeyCode.push(e.keyCode.toString());
                              setCombinedMKeys(macKeyCode);
                              setCombinedMKeyName(macKeyName);
                              setFieldValue('combinedMacKeys', combinedMKeys);
                              setFieldValue('combinedMacKeyName', combinedMKeyName);

                              if (e.keyCode === 91 || e.keyCode === 93) {
                                setFieldValue('macKey', 'command');
                              } else {
                                setFieldValue('macKey', e.keyCode === 32 ? 'space' : e.key);
                              }
                            }
                          }}
                          type="text"
                          value={values.macKey}
                          error={Boolean(touched.macKey && errors.macKey)}
                          helperText={touched.macKey && errors.macKey}
                        />
                        <ErrorFocus />
                      </Box>
                    </Box>

                    <Box marginTop={1}>
                      {useMacVirtualKeys ? (
                        <Autocomplete
                          options={macKeys}
                          autoHighlight
                          sx={{
                            '.MuiOutlinedInput-input': {
                              padding: '0px 6px !important',
                            },
                          }}
                          filterSelectedOptions
                          getOptionLabel={(option) => option.description || ''}
                          value={values.hashtag}
                          onChange={(e, value) => {
                            if (value) {
                              console.log(value);
                              if (keyType === 'INDIVIDUAL_HOT_KEYS') {
                                const mKeyCode = [];
                                const mKeyNames = [];
                                mKeyCode.push(value.code);
                                mKeyNames.push(value.description);
                                setCombinedMKeyName(mKeyNames);
                                setFieldValue('combinedMacKeys', mKeyCode);
                                setFieldValue('combinedMacKeyName', mKeyNames);
                                setFieldValue('macKey', value.description);
                                setUseMacVirtualKeys(false);
                              } else {
                                const keyCodeArray = combinedMKeys;
                                const keyNameArray = combinedMKeyName;
                                keyNameArray.push(value.description);
                                keyCodeArray.push(value.code);
                                setCombinedMKeys(keyCodeArray);
                                setCombinedMKeyName(keyNameArray);
                                setFieldValue('combinedMacKeys', combinedMKeys);
                                setFieldValue('combinedMacKeyName', combinedMKeyName);
                                setFieldValue('macKey', value.description);
                                setUseMacVirtualKeys(false);
                              }
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              placeholder="select key"
                              {...params}
                              variant="outlined"
                              name="hashtag"
                              fullWidth
                            />
                          )}
                        />
                      ) : (
                        <Tooltip arrow title=" If you don't find the relevant keys on your keyboard? Click on Keys">
                          <Link
                            onClick={() => setUseMacVirtualKeys(true)}
                            sx={{ textDecoration: 'none', cursor: 'pointer', fontSize: '0.7rem' }}
                          >
                            Find keys
                          </Link>
                        </Tooltip>
                      )}
                    </Box>

                    <Box display={'flex'} marginTop={1}>
                      {combinedMKeyName.map((res, i) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Typography className={classes.boxItem}>{res}</Typography>
                          {i < combinedMKeyName.length - 1 && (
                            <Typography variant="h5" sx={{ margin: '4px !important' }}>
                              +
                            </Typography>
                          )}
                        </div>
                      ))}
                    </Box>
                    {combinedMKeyName.length > 0 && (
                      <Typography
                        sx={{
                          marginTop: '8px !important',
                          cursor: 'pointer',
                          width: 'max-content',
                          fontSize: '0.7rem',
                        }}
                        color="primary"
                        onClick={() => {
                          setFieldValue('macKey', '');
                          setCombinedMKeyName([]);
                          setCombinedMKeys([]);
                        }}
                      >
                        Clear
                      </Typography>
                    )}
                  </Grid>

                  {/* <Grid item xs="12" sm="12" md="7">
                    <div>
                      <FormControl
                        required
                        component="fieldset"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        name="macImage"
                        value={values.macImage}
                        // value={values.macImage ? values.macImage:values.macBase64}
                      >
                        <DropzoneArea
                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                          showPreviews
                          dropzoneText="Drag and Drop Image or Browse File"
                          showPreviewsInDropzone={false}
                          maxFileSize={104857600}
                          // initialFiles={editData && editData.mac?.backgroundImg ? [editData.mac.backgroundImg] :[]}
                          initialFiles={initialFilesMac}
                          filesLimit={1}
                          showAlerts={false}
                          value={values.macImage ? values.macImage:values.macBase64}
                          onChange={async (file) => {
                            setFieldValue('macImage', file[0]);
                            if (file[0]?.size <= 4194304) {
                              setFieldValue('macBase64', await convertBase64(file[0]));
                            }
                          }}
                          useChipsForPreview
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          // previewChipProps={{classes: { root: classes.previewChip } }}
                          showFileNamesInPreview
                        />
                        <FormHelperText style={{ color: '#F44336' }}>
                          {(errors.macImage || touched.macImage) && errors.macImage}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={7}>
                    <div>
                      <FormControl
                        required
                        component="fieldset"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        name="macImage"
                      >
                        <DropzoneArea
                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                          showPreviews
                          dropzoneText="Drag and Drop Image or Browse File"
                          showPreviewsInDropzone={false}
                          maxFileSize={104857600}
                          initialFiles={initialFilesMac}
                          filesLimit={1}
                          showAlerts={false}
                          onChange={async (file) => {
                            setFieldValue('macImage', file[0]);
                            if (file[0]?.size <= 4194304) {
                              setFieldValue('macBase64', await convertBase64(file[0]));
                            }
                          }}
                          useChipsForPreview
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          showFileNamesInPreview
                        />
                        <FormHelperText style={{ color: '#F44336' }}>
                          {(errors.macImage || touched.macImage) && errors.macImage}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs="12">
                    <Button fullWidth color="primary" variant="contained" type="submit">
                      {editData ? "Update" : "Add"}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        }
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  boxItem: {
    border: '1px solid gray',
    padding: '4px 8px',
    fontSize: '0.8rem',
  },
}));

export default AddHotKeysModel;