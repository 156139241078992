import React, { useState, useEffect } from 'react';
import Snackbar from './snackbar/snackbar';

const NetworkStatusDetector = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');

  useEffect(() => {
    function changeStatus() {
      if (navigator.onLine) {
        setOpenSnackbar(true);
        setSnackbarTitle('You are back to Online !!');
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle('Lost Network Connection !!');
      }
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  return <Snackbar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />;
};

export default NetworkStatusDetector;
