import React, { useState } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';
import PhoneNumber from '../../../components/PhoneNumber/Index';
import '../../../components/PhoneNumber/styles.css';

export default function CreateAdminForm(props) {
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');

  const handleSubmitValues = async (values) => {
    const formData = new FormData();
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('email', values.email);
    formData.append('phone', values.phone);

    formData.append('role', 'CLIENT_ADMIN');
    setLoading(true);
    try {
      const response = await adminServices.postCreateAdmin(props.clientID, formData);
      if (response.ok) {
        setOpenSnackbar(true);
        props.snackBarControl(response.data.message);
        props.getClientList();
        props.handleClose();
      } else if (response.status === 400) {
        setOpenSnackbar(true);
        props.snackBarControl(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .trim()
            .nullable()
            .min(3, 'First name must required 3 characters')
            .max(180, 'First Name must be with in 180 characters')
            .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
            .required('First name is required field'),

          lastName: Yup.string()
            .trim()
            .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field'),
          email: Yup.string()
            .trim()
            .nullable()
            .required('Email is required field')
            .email()
            .matches(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              'Email is invalid'
            ),
          phone: Yup.string()
            .trim()
            .nullable()
            .min(11, 'Phone Number must be at least 10 characters')
            .max(12, 'Phone Number  must be with in 12 characters')
            .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number')
            .required('Phone is required field'),
        })}
        onSubmit={(values) => {
          handleSubmitValues(values);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>
                  First Name*
                </Typography>
                <TextField
                  fullWidth
                  type="text"
                  id="outlined-basic"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Last Name
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.lastName}
                  variant="outlined"
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Email*
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.email}
                  variant="outlined"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Phone*
                </Typography>
                <PhoneNumber fullWidth country={'us'} specialLabel={''} />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                  Create
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
}
