import React from 'react';
import { makeStyles } from '@mui/styles';
import { Icon } from '@iconify/react';

export default function ButtonAnimation({
  iconStyle = {
    width: ' 56px',
    height: '44px',
    color: '#ff6600',
    background: 'none',
    padding: '6px 6px 0px 0px',
  },
  icon = {
    background: 'white',
    borderRadius: '50%',
    margin: 10,
    height: 50,
    width: 50,

    boxShadow: '0 0 1px 6px rgba(28, 196, 54, 30%),0 0 1px 0px rgba(28,196,54, 20%)',
    transform: 'scale(1)',
    // animation: '$pulse 1.5s infinite',
  },
  iconIfy = 'bi:play-circle-fill',
}) {
  const useStyles = makeStyles((theme) => ({
    pulse: icon,
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
      },
      '70%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
      },
      '100%': {
        transform: 'scale(0.95)',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <div className={classes.pulse}>
        <Icon icon={iconIfy} style={iconStyle} className={classes.playbuttonStyle} />
      </div>
    </>
  );
}
