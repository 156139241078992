import { Container, Grid, Typography, Button, Box, TextField, Divider, IconButton, Checkbox } from '@mui/material';
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { DropzoneArea } from 'material-ui-dropzone';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import AppleIcon from '@mui/icons-material/Apple';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import DaysLeft from '../../assets/logo/days.svg';
// import keyBoardSetUp from '../../assets/logo/Group 1542.png';
import HotKeys from '../../assets/logo/hotKeys.svg';
import Modal from '../../components/modal/Index';
import Page from '../../components/Page';
import BasicModals from '../../components/modal/BasicModel';

// return (
//   <div
//     role="tabpanel"
//     hidden={value !== index}
//     id={`full-width-tabpanel-${index}`}
//     aria-labelledby={`full-width-tab-${index}`}
//     {...other}
//   >
//     {value === index && (
//       <Box sx={{ p: 3 }}>
//         <Typography>{children}</Typography>
//       </Box>
//     )}
//   </div>
// );
// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function CircularProgressWithLabel(props) {
  return (
    <Page title={'Course-details'}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    </Page>
  );
}
CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

const accordion = [
  {
    id: '01',
    settings: 'Commonly Used Hot Keys',
  },
  {
    id: '02',
    settings: 'Commonly Used Hot Keys',
  },
  {
    id: '03',
    settings: 'Commonly Used Hot Keys',
  },
  {
    id: '04',
    settings: 'Commonly Used Hot Keys',
  },
];
const Topics = [
  {
    topic: "Let's Begin",
  },
  {
    topic: "Let's BeginNavigating and Highlighting",
  },
  {
    topic: 'Formatting',
  },
  {
    topic: 'Copy Paste',
  },
];
const list = [
  {
    text: 'FN Button',
  },
  {
    text: 'FN Button',
  },
  {
    text: 'FN Button',
  },
  {
    text: 'FN Button',
  },
  {
    text: 'FN Button',
  },
];
export default function Dashboard() {
  const classes = useStyles();
  const [initialValues, setInitialValues] = React.useState({
    buttonlabel: '',
    screentitle: '',
    selectscreens: 'intro',
  });
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [addKeys, setAddKeys] = React.useState(false);

  const handleIndividualKeys = () => {
    setAddKeys(true);
  };

  const [expanded, setExpanded] = React.useState(false);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalClose, setModalClose] = React.useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleChanges = (res) => (event, isExpanded) => {
    console.log(isExpanded);
    console.log(res);
    setExpanded(isExpanded ? res : false);
  };
  const [progress, setProgress] = React.useState(10);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const [alignment, setAlignment] = React.useState('intro');

  const handleToggle = (event, newAlignment) => {
    console.log();
    setAlignment(newAlignment);
  };

  const [alignments, setAlignments] = React.useState('left');

  const handleAlignment = (event, newAlignment) => {
    setAlignments(newAlignment);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleModal = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const content = {
    blocks: [
      {
        key: '637gr',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  };
  const contentBlock = convertFromRaw(content);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentBlock));
  const [descriptionContent, setDescriptionContent] = useState(
    draftToHtml(convertToRaw(editorState.getCurrentContent()))
  );
  const [descErr, setDescErr] = useState(false);

  const onEditorStateChange = (_editorState) => {
    setEditorState(_editorState);
    setDescriptionContent(draftToHtml(convertToRaw(_editorState.getCurrentContent())));
  };

  return (
    <div>
      <Container maxWidth={'xl'} style={{ marginTop: '1rem' }}>
        <Grid container spacing={3}>
          <Grid item xs="12" sm="12" md="6">
            <Grid container spacing="6">
              <Grid item xs="12" sm="12" md="12">
                <div className={classes.cards}>
                  <Typography variant="h4">Excel Efficiency</Typography>
                  <Box className={classes.box}>
                    <div>
                      <Typography>Welcome back, Tatiana!</Typography>
                      <Typography>
                        You’ve made great progress. Continue <br /> your journey towards excellence.
                      </Typography>
                    </div>
                    <div className={classes.resume}>
                      <div>
                        <Typography>Resume</Typography>
                        <Typography>Let's Begin</Typography>
                      </div>
                      <div className={classes.icon}>
                        <NotStartedIcon style={{ color: '#00B673' }} />
                      </div>
                    </div>
                  </Box>
                </div>
              </Grid>

              <Grid item xs="12" sm="12" md="12">
                <Box style={{ height: '173px' }} className={classes.excel}>
                  <Typography>Excel skillSet IQ</Typography>
                  <div className={classes.keySkills}>
                    <div>
                      <img width={'110px'} src={DaysLeft} alt="icon" />
                    </div>
                    <div>
                      <Typography>5% - first</Typography>
                      <Typography>IQ;5% - best IQ</Typography>
                    </div>
                    <div>
                      <Button onClick={handleModalOpen} variant="contained" color="primary">
                        start
                      </Button>
                    </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs="12" sm="12" md="6">
            <Grid container spacing="12">
              <Grid item xs="12" sm="12" md="6">
                <Box className={classes.progress}>
                  <div className={classes.efficiency}>
                    <CircularProgressWithLabel style={{ width: '80px', height: '80px' }} value={progress} />
                  </div>
                  <div className={classes.efficiency}>
                    <Typography>Overall Progress</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item xs="12" sm="12" md="6">
                <Box className={classes.progress}>
                  <div className={classes.efficiency}>
                    <CircularProgressWithLabel value={progress} />
                  </div>

                  <div className={classes.efficiency}>
                    <Typography>Efficiency Score</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item xs="12" sm="12" md="6">
                <Box className={classes.progress}>
                  <div className={classes.efficiency}>
                    <img width={'90px'} src={HotKeys} alt="key" />
                  </div>

                  <div className={classes.efficiency}>
                    <Typography>Hot Keys & Formula List</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item xs="12" sm="12" md="6">
                <Box className={classes.progress}>
                  <div className={classes.efficiency}>
                    <Typography style={{ fontSize: '12px' }}>
                      You are using Windows version.
                      <br />
                      You can switch to Mac version here.
                    </Typography>
                  </div>

                  <div className={classes.efficiency}>{/* <img width={'60px'} src={keyBoardSetUp} alt="key" /> */}</div>

                  <div className={classes.efficiency}>
                    <Typography>Efficiency Score</Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs="12">
            {accordion.map((item, index) => {
              return (
                <>
                  <div style={{ paddingTop: '12px' }}>
                    <Accordion
                      style={{ boxShadow: '0px 1px 6px #00000029' }}
                      expanded={expanded === index}
                      onChange={handleChanges(index)}
                    >
                      <AccordionSummary
                        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>{item.settings}</Typography>
                      </AccordionSummary>
                      {Topics.map((res) => {
                        return (
                          <>
                            <div style={{ padding: '10px 26px ' }}>
                              <Accordion style={{ boxShadow: '0px 1px 6px #00000029' }}>
                                <AccordionSummary
                                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                >
                                  <AddIcon />
                                  <Typography>{res.topic}</Typography>
                                  <Typography> 50% completed </Typography>
                                  <Button variant="contained" color="primary">
                                    start
                                  </Button>
                                </AccordionSummary>

                                {list.map((items) => {
                                  return (
                                    <>
                                      <List style={{ borderTop: '1px solid #DCDBDB' }}>
                                        <ListItem>
                                          <ListItemIcon>
                                            <NotStartedIcon fontSize="medium" style={{ color: '#00B673' }} />
                                          </ListItemIcon>
                                          <ListItemText>
                                            <Typography>{items.text}</Typography>
                                          </ListItemText>
                                        </ListItem>
                                      </List>
                                    </>
                                  );
                                })}
                              </Accordion>
                            </div>
                          </>
                        );
                      })}
                    </Accordion>
                  </div>
                </>
              );
            })}
          </Grid>

          <Grid item xs="12">
            <Box
              style={{
                boxShadow: '0 178px 98px -182px #645f5f',
              }}
            >
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/lKZiXQWnlUw"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </Box>
          </Grid>
        </Grid>

        <Modal
          open={modalOpen}
          toggle={handleModalClose}
          children={
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  selectscreens: Yup.string().required('Required').nullable(),
                  screentitle: Yup.string().required('Required').nullable(),
                  buttonlabel: Yup.string().required('Required').nullable(),
                })}
                onSubmit={(values) => {
                  console.log(values);
                }}
              >
                {({ values, errors, touched, handleBlur, handleChange, onSubmit, setFieldValue }) => (
                  <Form>
                    <Container sx={{ mt: 2 }}>
                      <Grid container spacing={3}>
                        <Grid item xs="12">
                          <Box sx={{ bgcolor: 'background.paper' }}>
                            <ThemeProvider theme={customeTheme}>
                              <ToggleButtonGroup
                                color="primary"
                                value={values.selectscreens}
                                exclusive
                                name="selectscreens"
                                onChange={(event, selected) => {
                                  console.log('selected', selected);
                                  setFieldValue('selectscreens', selected);
                                }}
                              >
                                <ToggleButton value="intro">Intro</ToggleButton>
                                <ToggleButton value="action">Action</ToggleButton>
                                <ToggleButton value="success">Success</ToggleButton>
                              </ToggleButtonGroup>
                            </ThemeProvider>
                          </Box>
                        </Grid>

                        <Grid item xs="12">
                          <Typography className={classes.background}>Screen Title( For Internal Use )</Typography>
                          <TextField
                            className={classes.dropzone}
                            fullWidth
                            id="outlined-basic"
                            variant="outlined"
                            name="screentitle"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.screentitle}
                            error={Boolean(touched.screentitle && errors.screentitle)}
                            helperText={touched.screentitle && errors.screentitle}
                          />
                        </Grid>
                        <Grid item xs="12">
                          <Typography className={classes.background}>Background Image</Typography>
                          <div className={classes.dropzone}>
                            <FormControl
                              required
                              component="fieldset"
                              color="primary"
                              variant="outlined"
                              fullWidth
                              name="thumbImage"
                              value={values.thumbImage}
                            >
                              <DropzoneArea
                                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                dropzoneText="Drag and Drop Image or Browse File"
                                showPreviewsInDropzone={false}
                                maxFileSize={104857600}
                                filesLimit={2}
                                value={values.thumbImage}
                                onChange={(file) => setFieldValue('thumbImage', file)}
                                previewGridClasses={{
                                  container: classes.previewContainer,
                                  item: classes.preview,
                                }}
                                showFileNamesInPreview
                              />
                              <FormHelperText style={{ color: '#F44336' }}>
                                {errors.thumbImage && touched.thumbImage && errors.thumbImage}
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </Grid>

                        {values.selectscreens === 'action' && (
                          <>
                            <Grid item xs="12">
                              <div className={classes.combined}>
                                <Button onClick={handleIndividualKeys} className={classes.individual}>
                                  <Typography style={{ color: '#00B673', padding: '2px 10px' }}>
                                    Individual Hot Keys
                                  </Typography>
                                </Button>

                                <div className={classes.individual}>
                                  <Typography style={{ color: '#00B673', padding: '2px 10px' }}>
                                    Combined hot keys
                                  </Typography>
                                </div>
                                <div className={classes.individual}>
                                  <Typography style={{ padding: '2px 10px' }}>String</Typography>
                                </div>
                                <div className={classes.individual}>
                                  <Typography style={{ padding: '2px 10px' }}>Pattern</Typography>
                                </div>
                              </div>
                            </Grid>

                            {addKeys && (
                              <Grid item xs="12">
                                <div className={classes.combined}>
                                  <div>
                                    <Typography>Hot Key and Images</Typography>
                                  </div>
                                  <div>
                                    <Button onClick={handleModal} className={classes.individual}>
                                      <Typography style={{ color: '#00B673' }}>Add Keys</Typography>
                                    </Button>
                                  </div>
                                </div>
                                <div style={{ marginTop: '1rem' }}>
                                  <Divider />
                                </div>
                              </Grid>
                            )}
                          </>
                        )}

                        <Grid item xs="12">
                          <Typography className={classes.background}>Description</Typography>
                          <div className={classes.dropzone}>
                            <Editor
                              editorState={editorState}
                              editorClassName={classes.textEditor}
                              toolbarClassName={classes.toolbarEditor}
                              onEditorStateChange={onEditorStateChange}
                            />
                          </div>
                        </Grid>

                        <Grid item xs="12">
                          <Typography className={classes.background}>Button Label</Typography>
                          <TextField
                            className={classes.dropzone}
                            fullWidth
                            name="buttonlabel"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="text"
                            value={values.buttonlabel}
                            variant="outlined"
                            error={Boolean(touched.buttonlabel && errors.buttonlabel)}
                            helperText={touched.buttonlabel && errors.buttonlabel}
                          />
                        </Grid>

                        {values.selectscreens === 'success' && (
                          <Grid item xs="12">
                            <div className={classes.actionkeys}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ color: '#00B673' }}>Play sound</Typography>
                                <Checkbox {...label} defaultChecked />
                              </div>
                              <div>
                                <Typography className={classes.upload}>Upload Custom Sound</Typography>
                              </div>
                            </div>
                          </Grid>
                        )}

                        <Grid item xs="12">
                          <Typography className={classes.background}>Box Position</Typography>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={classes.dropzone}>
                              <Typography gutterBottom>Horizontally Align</Typography>
                              <ToggleButtonGroup
                                value={alignment}
                                exclusive
                                onChange={handleAlignment}
                                aria-label="text alignment"
                              >
                                <ToggleButton value="left" aria-label="left aligned">
                                  <FormatAlignLeftIcon />
                                </ToggleButton>
                                <ToggleButton value="center" aria-label="centered">
                                  <FormatAlignCenterIcon />
                                </ToggleButton>
                                <ToggleButton value="right" aria-label="right aligned">
                                  <FormatAlignRightIcon />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </div>
                            <div style={{ marginLeft: '1rem' }}>
                              <Typography gutterBottom>Vertically Align</Typography>
                              <ToggleButtonGroup
                                value={alignments}
                                exclusive
                                onChange={handleAlignment}
                                aria-label="text alignment"
                              >
                                <ToggleButton value="left" aria-label="left aligned">
                                  <FormatAlignLeftIcon />
                                </ToggleButton>
                                <ToggleButton value="center" aria-label="centered">
                                  <FormatAlignCenterIcon />
                                </ToggleButton>
                                <ToggleButton value="right" aria-label="right aligned">
                                  <FormatAlignRightIcon />
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs="12">
                          <Button type="submit" variant="contained" color="primary" fullWidth>
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                    </Container>
                  </Form>
                )}
              </Formik>
            </>
          }
        />
      </Container>

      <BasicModals
        openModel={openModal}
        closeModel={handleClose}
        title="Individual Hot Keys"
        children={
          <Container style={{ marginTop: '1.5rem' }}>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required().email().label('Email'),
                password: Yup.string()
                  .trim()
                  .min(8)
                  .nullable()
                  .matches(
                    /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    'The password must contain 8 characters, one upper case, one number and one special case character'
                  )
                  .required('Password is Required.'),
              })}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              {({ values, errors, touched, handleBlur, handleChange, onSubmit, setFieldValue }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid className={classes.grid} xs="6" sm="6" md="2">
                      <div />
                      <div className={classes.iconPosition}>
                        <IconButton>
                          <AppleIcon fontSize="large" />
                        </IconButton>
                      </div>
                      <div className={classes.iconPosition}>
                        <IconButton>
                          <AppleIcon fontSize="large" className={classes.apple} />
                        </IconButton>
                      </div>
                    </Grid>
                    <Grid className={classes.grid} xs="6" sm="6" md="3">
                      <Typography style={{ marginTop: '1rem' }}>Enter Key</Typography>
                      <div className={classes.iconPosition}>
                        <IconButton className={classes.dragHandIcon}>
                          <DragHandleIcon />
                        </IconButton>
                      </div>
                      <div className={classes.iconPosition}>
                        <IconButton className={classes.dragHandIcon}>
                          <DragHandleIcon />
                        </IconButton>
                      </div>
                    </Grid>
                    <Grid item xs="12" sm="12" md="7">
                      <Typography>Background Image</Typography>
                      <div className={classes.dropzone}>
                        <FormControl
                          required
                          component="fieldset"
                          color="primary"
                          variant="outlined"
                          fullWidth
                          name="thumbImage"
                          value={values.thumbImage}
                        >
                          <DropzoneArea
                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                            // showPreviews={true}
                            dropzoneText="Drag and Drop Image or Browse File"
                            showPreviewsInDropzone={false}
                            maxFileSize={104857600}
                            filesLimit={2}
                            value={values.thumbImage}
                            onChange={(file) => setFieldValue('thumbImage', file)}
                            previewGridClasses={{
                              container: classes.previewContainer,
                              item: classes.preview,
                              // image: classes.previewImg,
                            }}
                            showFileNamesInPreview
                          />
                          <FormHelperText style={{ color: '#F44336' }}>
                            {errors.thumbImage && touched.thumbImage && errors.thumbImage}
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div className={classes.dropzone}>
                        <FormControl
                          required
                          component="fieldset"
                          color="primary"
                          variant="outlined"
                          fullWidth
                          name="thumbImage"
                          value={values.thumbImage}
                        >
                          <DropzoneArea
                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                            dropzoneText="Drag and Drop Image or Browse File"
                            showPreviewsInDropzone={false}
                            maxFileSize={104857600}
                            filesLimit={2}
                            value={values.thumbImage}
                            onChange={(file) => setFieldValue('thumbImage', file)}
                            previewGridClasses={{
                              container: classes.previewContainer,
                              item: classes.preview,
                            }}
                            showFileNamesInPreview
                          />
                          <FormHelperText style={{ color: '#F44336' }}>
                            {errors.thumbImage && touched.thumbImage && errors.thumbImage}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs="12">
                      <Button fullWidth color="primary" variant="contained">
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Container>
        }
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    padding: '1rem',
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'space-between',
  },
  resume: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    padding: '1rem',
    display: 'flex',
    alignItem: 'center',
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5px',
  },
  excel: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    padding: '1rem',
    marginTop: '12px',
  },
  keySkills: {
    display: 'flex',
    alignItem: 'center !important',
    justifyContent: 'space-between',
    marginTop: '12px',
  },
  progress: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    padding: '5px',
    height: '173px',
    marginTop: '3px',
  },
  efficiency: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '13px',
  },
  cards: {
    height: '173px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  background: {
    fontWeight: 'bold',
    color: '#000000',
  },
  combined: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  individual: {
    border: '1px dotted #BCBCBC',
    padding: '5px',
  },
  actionkeys: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  upload: {
    color: '#00B673',
    borderBottom: '1px dotted #00B673 ',
  },
}));

const customeTheme = createTheme({
  components: {
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          paddingLeft: '36%',
          paddingRight: '36%',
          backgroundColor: '#000E3B',
          color: 'white',
          fontWeight: 'bold',
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          borderRight: '1px solid #707070',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#FE7000',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#000E3B',
            },
          },
          '&:hover': {
            backgroundColor: '#000E3B',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000E3B',
          borderRadius: '5px',
          color: 'white',
          fontWeight: 'bold',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRight: '1px solid #FFFFFF',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#000E3B',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          '&.Mui-selected': {
            color: '#00FFA1 !important',
          },
        },
      },
    },
  },
});
