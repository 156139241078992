import React, { useEffect, useRef } from 'react';
import { Grid, Badge, Avatar, Link, Typography, Box, TextField, Divider } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LoadingButton } from '@mui/lab';
import lottie from 'lottie-web';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';
import lottieData from '../../../assets/lottie/lottie-loading.json';
import PhoneNumber from '../../../components/PhoneNumber/Index';
import '../../../components/PhoneNumber/styles.css';

export default function AddClientForm(props) {
  const location = useLocation();
  const lottoLoad = useRef();
  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
  const [formValues, setFormValues] = React.useState({
    organizationName: '',
    address1: '',
    address2: '',
    country: '',
    selectState: '',
    city: '',
    zipcode: '',
    websiteUrl: '',
    contactName: '',
    contactEmail: '',
    phone: '',
    officeNumber: '',
    dateFormat: '',
    timeZone: '',
    thumbImage: null,
    thumbImagePreview: null,
  });

  const classes = useStyles();
  const [countryCode, setCountryCode] = React.useState(null);
  const [country, SetCountry] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [state, SetState] = React.useState([]);
  const [dateFormat, SetDateFormat] = React.useState([]);
  const [timezone, SetTimezone] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [lottieLoading, setLottieLoading] = React.useState(true);

  const handleAddClientFormValues = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('organizationName', values.organizationName);
    formData.append('address1', values.address1);
    formData.append('address2', values.address2);
    formData.append('country', values.country.code);
    formData.append('state', values.selectState.code);
    formData.append('city', values.city);
    formData.append('zipCode', values.zipcode);
    formData.append('websiteUrl', values.websiteUrl);
    formData.append('contactName', values.contactName);
    formData.append('contactEmail', values.contactEmail);
    formData.append('contactPhone', values.phone);
    formData.append('officeNumber', values.officeNumber);
    formData.append('dateFormat', values.dateFormat.code);
    formData.append('timeZone', values.timeZone.code);
    formData.append('logo', values.thumbImage);

    if (props.mode === 'create') {
      try {
        const response = await adminServices.postClientDetails(formData);
        if (response.ok) {
          setOpenSnackbar(true);
          setSnackbarTitle(response.data.message);
          props.getClientList();
          props.toggle();
          props.snackBarControl(response.data.message);
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          props.snackBarControl(response.data.error);
        }
      } catch (error) {
        console.log(error);
        setOpenSnackbar(false);
      }
    } else {
      try {
        const res = await adminServices.editClientDetailsByID(props.clientId, formData);
        if (res.ok) {
          setOpenSnackbar(true);
          props.snackBarControl(res.data.message);
          props.toggle();
          props.getClientList(true);
        }
      } catch (error) {
        console.log(error);
        setOpenSnackbar(false);
      }
    }
    setLoading(false);
  };

  const getClientDetalsById = async () => {
    const result = await adminServices.getClientDetalsById(props.clientId);
    if (result.ok) {
      console.log(result, 'result');
      const data = { ...formValues };
      data.organizationName = result.data.organizationName;
      data.address1 = result.data.address1;
      data.address2 = result.data.address2;
      data.country = result.data.country;
      setCountryCode(result.data.country.code);
      data.selectState = result.data.state;
      data.city = result.data.city;
      data.zipcode = result.data.zipCode;
      data.websiteUrl = result.data.websiteUrl;
      data.contactName = result.data.contactName;
      data.contactEmail = result.data.contactEmail;
      data.phone = result.data.contactPhone;
      data.officeNumber = result.data.officeNumber;
      data.dateFormat = result.data.dateFormat;
      data.timeZone = result.data.timeZone;
      data.thumbImage = null;
      data.thumbImagePreview = result.data.logo;
      setFormValues(data);
    }
    console.log(result.data, 'result.data');
    console.log(formValues, 'FormValues');
    setLottieLoading(false);
  };

  const getLookUpDetails = async () => {
    const key = ['country', 'state', 'date_format', 'timezone'];
    const response = await adminServices.getLookUpData(key);
    if (response.ok) {
      SetState(response.data.state);
      SetDateFormat(response.data.date_format);
      SetTimezone(response.data.status);
      SetCountry(response.data.country);
    }
  };
  const getLookUpState = async () => {
    const key = ['country', 'state', 'date_format', 'timezone'];
    const response = await adminServices.getLookUpStateData(key, countryCode);
    if (response) {
      SetState(response.data.state);
    }
  };
  useEffect(() => {
    getLookUpDetails();
    if (props.mode === 'edit') {
      getClientDetalsById();
    }
  }, []);

  useEffect(() => {
    if (countryCode !== null) {
      getLookUpState();
    }
  }, [countryCode]);

  useEffect(() => {
    lottie.loadAnimation({
      container: lottoLoad.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lottieData,
    });
  }, []);

  const regMatch =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={Yup.object().shape({
          organizationName: Yup.string()
            .trim()
            .nullable()
            .min(3, 'Company name contains at least 3 character')
            .required('Company name is required field')
            .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field'),
          address1: Yup.string()
            .trim()
            .nullable()
            .min(3, 'Address1 contains at least 3 character')
            .required('Address1 is required field'),
          country: Yup.string().trim().nullable().required(' Country is required field'),
          selectState: Yup.string().trim().nullable().required(' Select state is required field'),
          city: Yup.string()
            .trim()
            .nullable()
            .min(3, 'City contains at least 3 character')
            .required('City is required field'),
          zipcode: Yup.string().trim().min(5, 'Invalid Zip Code').max(9).required(' Zipcode is required field'),
          websiteUrl: Yup.string()
            .trim()
            .nullable()
            .matches(regMatch, 'Website should be a valid URL')
            .required(' Website is required field'),
          contactName: Yup.string()
            .trim()
            .nullable()
            .min(3, 'Contact name contains at least 3 character')
            .matches(/^[A-Za-z ]+$/, 'Only alphabets are allowed for this field')
            .required('Contact name is required field'),
          contactEmail: Yup.string()
            .trim()
            .nullable()
            .required(' Contact email is required field')
            .email('Contact email must be valid email')
            .matches(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              'Email is invalid'
            ),
          phone: Yup.string()
            .trim()
            .nullable()
            .min(11, 'Contact phone must be at least 10 characters')
            .max(12, 'Contact phone must be with in 12 characters')
            .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number')
            .required('Contact phone is required field'),
          officeNumber: Yup.string()
            .trim()
            .nullable()
            .min(8, 'Office number contains at least 8 numbers')
            .max(12, 'Office phone must be with in 12 numbers')
            .matches('^[1-9][0-9]*$', 'Invalid Office number')
            .required(' Office number is required field'),
          dateFormat: Yup.string().trim().nullable().required(' Date format is Required field'),
          timeZone: Yup.string().trim().nullable().required(' Timezone is required field'),
          thumbImagePreview: Yup.string().nullable(),
          thumbImage: Yup.mixed()
            .nullable()
            .when('thumbImagePreview', {
              is: null,
              then: Yup.mixed()
                .nullable()
                .required('Logo is required field')
                .test(
                  'fileFormat',
                  'Unsupported Format',
                  (value) => value === null || (value && SUPPORTED_FORMATS.includes(value.type))
                )
                .test(
                  'fileSize',
                  'Maximum file size is 3MB',
                  (value) => value === null || (value && value.size < 3145728)
                ),
              otherwise: Yup.mixed().nullable(),
            }),
        })}
        onSubmit={(values) => {
          console.log(values);
          handleAddClientFormValues(values);
        }}
      >
        {({ values, errors, touched, handleBlur, handleChange, onSubmit, setFieldValue }) => (
          <Form>
            {props.mode === 'edit' && lottieLoading ? (
              <div style={{ width: 50, margin: 'auto' }} ref={lottoLoad} />
            ) : (
              <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs="12" sm="12" md="12">
                  <Typography variant="subtitle1" gutterBottom>
                    Company Name*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="organizationName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.organizationName}
                    variant="outlined"
                    inputProps={{ maxLength: 180 }}
                    error={Boolean(touched.organizationName && errors.organizationName)}
                    helperText={touched.organizationName && errors.organizationName}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Office Number*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="officeNumber"
                    type="text"
                    onBlur={handleBlur}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    inputProps={{ maxLength: 12 }}
                    onChange={handleChange}
                    value={values.officeNumber}
                    variant="outlined"
                    error={Boolean(touched.officeNumber && errors.officeNumber)}
                    helperText={touched.officeNumber && errors.officeNumber}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Website *
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="websiteUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.websiteUrl}
                    variant="outlined"
                    error={Boolean(touched.websiteUrl && errors.websiteUrl)}
                    helperText={touched.websiteUrl && errors.websiteUrl}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Contact Name*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="contactName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.contactName}
                    variant="outlined"
                    error={Boolean(touched.contactName && errors.contactName)}
                    helperText={touched.contactName && errors.contactName}
                    inputProps={{ maxLength: 180 }}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Contact Phone*
                  </Typography>
                  <PhoneNumber fullWidth country={'us'} specialLabel={''} />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Contact Email*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="contactEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contactEmail}
                    variant="outlined"
                    error={Boolean(touched.contactEmail && errors.contactEmail)}
                    helperText={touched.contactEmail && errors.contactEmail}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Date Format*
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={dateFormat}
                    autoHighlight
                    getOptionLabel={(option) => option.description || ''}
                    value={values.dateFormat}
                    onChange={(e, value) => {
                      setFieldValue('dateFormat', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="dateFormat"
                        error={Boolean(touched.dateFormat && errors.dateFormat)}
                        helperText={touched.dateFormat && errors.dateFormat}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="12" sm="12" md="12">
                  <Typography variant="subtitle1" gutterBottom>
                    Time Zone*
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={timezone}
                    autoHighlight
                    getOptionLabel={(option) => option.description || ''}
                    value={values.timeZone}
                    onChange={(e, value) => {
                      setFieldValue('timeZone', value);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img loading="lazy" width="20" src={option.categoryImgUrl} alt="" />
                        {option.description}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="timeZone"
                        error={Boolean(touched.timeZone && errors.timeZone)}
                        helperText={touched.timeZone && errors.timeZone}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="12">
                  {values.thumbImagePreview === null && (
                    <>
                      <Typography variant="subtitle1">Logo*</Typography>
                      <div className={classes.dropzone}>
                        <FormControl
                          required
                          component="fieldset"
                          color="primary"
                          variant="outlined"
                          type="file"
                          fullWidth
                          name="thumbImage"
                          value={values.thumbImage}
                        >
                          <DropzoneArea
                            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                            dropzoneText="Drag and Drop Image or Browse File"
                            showPreviewsInDropzone={false}
                            maxFileSize={30000000}
                            filesLimit={1}
                            value={values.thumbImage}
                            showAlerts={false}
                            onChange={(file) => {
                              if (SUPPORTED_FORMATS.includes(file[0]?.type) && file[0]?.size <= 3145728) {
                                const url = URL.createObjectURL(file[0]);
                                setFieldValue('thumbImagePreview', url);
                              }
                              setFieldValue('thumbImage', file[0]);
                            }}
                            previewGridClasses={{
                              container: classes.previewContainer,
                              item: classes.preview,
                            }}
                            showFileNamesInPreview
                          />
                        </FormControl>
                      </div>
                      {
                        <FormHelperText style={{ color: '#F44336' }}>
                          {touched.thumbImagePreview && errors.thumbImage}
                        </FormHelperText>
                      }
                    </>
                  )}
                  {values.thumbImagePreview !== null && (
                    <>
                      <Typography variant="body1" gutterBottom>
                        Logo Image
                      </Typography>
                      {values.thumbImagePreview !== null && (
                        <>
                          <div className={classes.imgPreviewRoot}>
                            <Badge
                              badgeContent={
                                <CloseIcon
                                  onClick={() => {
                                    setFieldValue('thumbImage', null);
                                    setFieldValue('thumbImagePreview', null);
                                  }}
                                  className={classes.badgeAlign}
                                />
                              }
                            >
                              <Avatar
                                variant="rounded"
                                src={values.thumbImagePreview}
                                className={values.thumbImagePreview !== null && classes.fileImgSIze}
                              />
                            </Badge>
                            <div style={{ marginTop: 16 }}>
                              <Link
                                variant="contained"
                                color="primary"
                                component="a"
                                href={values.thumbImagePreview}
                                target="_blank"
                              >
                                <Typography style={{ display: 'flex' }} variant="body1" gutterBottom>
                                  <span>
                                    <OpenInNewIcon fontSize="small" />
                                  </span>
                                  <span>View Logo Image</span>
                                </Typography>
                              </Link>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <Typography variant="subtitle1" color="primary">
                      Address
                    </Typography>
                  </div>
                  <div>
                    <Divider />
                  </div>
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Address 1*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="address1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.address1}
                    variant="outlined"
                    error={Boolean(touched.address1 && errors.address1)}
                    helperText={touched.address1 && errors.address1}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Address 2
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="address2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.address2}
                    variant="outlined"
                    error={Boolean(touched.address2 && errors.address2)}
                    helperText={touched.address2 && errors.address2}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    Select Country*
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={country}
                    autoHighlight
                    getOptionLabel={(option) => option.description || ''}
                    value={values.country}
                    onChange={(e, value) => {
                      setFieldValue('country', value);
                      setCountryCode(value.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="country"
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Select State*
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={state}
                    autoHighlight
                    getOptionLabel={(option) => option.description || ''}
                    value={values.selectState}
                    onChange={(e, value) => {
                      setFieldValue('selectState', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="selectState"
                        error={Boolean(touched.selectState && errors.selectState)}
                        helperText={touched.selectState && errors.selectState}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    City*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.city}
                    variant="outlined"
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" gutterBottom>
                    ZipCode*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="zipcode"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    value={values.zipcode}
                    variant="outlined"
                    error={Boolean(touched.zipcode && errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                  />
                </Grid>
                <Grid item xs="12">
                  <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                    {props.mode === 'create' ? 'Create' : 'Update'}
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    paddingBottom: '2rem',
    paddingTop: '2rem',
  },
  divider: {
    height: '2px',
    width: '145px',
    backgroundColor: 'black',
  },
  keySkillsetLogo: {
    position: 'fixed',
    left: '2rem',
    top: '2rem',
  },
  ok: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileImgSIze: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
    objectPosition: 'center',
    border: '1px solid #fff',
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
  },
  imgPreviewRoot: {
    borderRadius: '10px',
    padding: '0.8rem;',
    marginBottom: 16,
  },
  badgeAlign: {
    boxShadow: '0 2px 8px -5px #ff0000',
    color: '#FF0000',
    fontSize: '1.2rem',
    backgroundColor: '#fff',
    padding: '2px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  email: {
    padding: '4px',
  },
  dropzone: {
    marginTop: '1rem',
  },
}));
