import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

const LinearProgressBar = (props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: '24px',
            borderRadius: '4px',
          }}
        />
      </Box>
      <Box sx={{ position: 'absolute', left: '36%', top: 0 }}>
        <Typography variant="body2" color="#fff">
          Uploading video {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressBar;
