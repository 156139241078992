import client from '../apiClient';

const getAnalytics = (courseId) => client.get(`/client/course/analytics?courseId=${courseId}`);
const getAnalyticsTable = (courseId) => client.get(`/client/user/analytics?courseId=${courseId}`);

const courseOptions = () => client.get(`/client/subscribeCourse`);
const activeUsers = (courseId) => client.get(`/client/user/overallProgress?courseId=${courseId}`);

const getCaseStudiesDetails = (courseId) => client.get(`/caseStudies/user/dashBoard/details?courseId=${courseId}`);

const getCaseStudiesAnalytics = (courseId) => client.get(`/caseStudies/user/dashBoard?courseId=${courseId}`);

const postUserCertificate = (data, courseId) => client.post(`/caseStudies/user/sendEmail?courseId=${courseId}`, data);

export default {
  getAnalytics,
  getAnalyticsTable,
  activeUsers,
  courseOptions,
  getCaseStudiesDetails,
  getCaseStudiesAnalytics,
  postUserCertificate,
};
