import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
// components
import Logo from '../components/Logo';
import ThemeProvider from '../theme';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLogin = useSelector((state) => state.isLogin);
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);

  useEffect(() => {
    if (isLogin && (pathname === '/' || pathname === '/login')) {
      if (isLogin && (userRole === 'SUPER_ADMIN' || userRole === 'CLIENT_ADMIN')) {
        navigate('/app/dashboard');
      } else if (isLogin && (userRole === 'CONTENT_WRITER' || userRole === 'AUTH_USER')) {
        navigate('/app/course');
      } else {
        navigate('/auth/my-courses');
      }
    }
  }, []);

  return (
    <ThemeProvider>
      <HeaderStyle>{/* <Logo /> */}</HeaderStyle>
      <Outlet />
    </ThemeProvider>
  );
}
