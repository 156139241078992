import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';

const SearchBar = ({ slice,setSearchResults,filter,setSearchComplete,setIsQuery }) => {
    const handleSubmit = (e) => e.preventDefault();
    const [query,setQuery] = useState('');

    const handleSearchChange = (e) => {
        setQuery(e.target.value);
        setIsQuery(e.target.value);
        if (!e.target.value) return setSearchResults([]);

        const resultsArray = slice.filter(block => 
            (block.type === 's' && block.topic.replace(/\s/g, '').toLowerCase().includes(e.target.value.replace(/\s/g, '').toLowerCase())) || 
            (block.type === 'v' && block.sub.replace(/\s/g, '').toLowerCase().includes(e.target.value.replace(/\s/g, '').toLowerCase()))
        );
        setSearchResults(resultsArray);
        setSearchComplete(true);
    }

    useEffect(() => {
        setQuery('');
        setSearchComplete(false);
    },[filter]);

    return (
        <TextField
          id="outlined-basic"
          placeholder="Find within module..."
          variant="outlined"
          fullWidth
          onSubmit={handleSubmit}
          onChange={handleSearchChange}
          value={query}
          sx={{
            float:'left',
            paddingBottom:'10px'
          }}
        />
    )
}

export default SearchBar