import React, { useState } from 'react';
import { Grid, Typography, Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import BasicPaper from '../../../../components/cards/BasicPaper';
import BasicCard from '../../../../components/cards/BasicCard';
import LeaderBoard from '../../../../assets/logo/images/progress.svg';
import CourseDetailSkeleton from '../../../../components/Skeleton/CourseContentSkeleton';

//
import '../../../users/course/index.css';

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

function LinearProgressWithLabel({ value }) {
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
        {/* <Box sx={{ width: 'max-content' }}>
          <Typography variant="body2" color="gray" sx={{ fontSize: '0.7rem' }}>
            {value}%
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
}

export default function ExcelEfficiency(props) {
  const classes = useStyles();

  const [progress, setProgress] = React.useState(10);
  const [isWantMoreText, setIsWantMoreText] = useState(true);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const [linearProgress, setLinearProgress] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setLinearProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const [expanded, setExpanded] = React.useState(false);
  const handleChanges = (res) => (event, isExpanded) => {
    setExpanded(isExpanded ? res : false);
  };

  return (
    <div>
      {props.loading ? (
        <CourseDetailSkeleton />
      ) : (
        <BasicPaper
          children={
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={7}>
                  <Typography gutterBottom variant="h6">
                    Course description
                  </Typography>
                  <Typography
                    className={isWantMoreText ? classes.showMoreText : null}
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: props.courseDetails.description }}
                  />
                  {props.courseDetails.description.length > 700 && (
                    <Typography
                      className={classes.readMore}
                      color="primary"
                      onClick={() => setIsWantMoreText(!isWantMoreText)}
                    >
                      {isWantMoreText ? 'Read more' : 'Read less'}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Statistics</Typography>
                      <Divider sx={{ marginTop: '5px' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <BasicCard
                        children={
                          <>
                            <Typography variant="subtitle1">Course Started</Typography>

                            <div className={classes.statsCountBlock}>
                              <span className={classes.statsCount}>{`${props.courseDetails.startedCount}`}</span>
                              <span className={classes.statsValue}>{`/${props.courseDetails.enrolledUsers}`}</span>
                            </div>
                            <LinearProgressWithLabel value={props.courseDetails.startedInPercentage} />

                            {/* <div>
                              <Typography variant="subtitle1">Started</Typography>
                            </div>
                            <div className={classes.combined}>
                              <Typography>{props.courseDetails.startedCount}</Typography>
                              <CircularProgressbar
                                value={parseInt(props.courseDetails.startedInPercentage, 10)}
                                text={`${props.courseDetails.startedInPercentage}%`}
                                className={classes.circularProgress}
                                styles={buildStyles({
                                  textColor: '#00B673',
                                  pathColor: '#00B673',
                                  trailColor: '#48FFBC',
                                })}
                              />
                            </div> */}
                          </>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <BasicCard
                        children={
                          <>
                            <Typography variant="subtitle1">Course Completed</Typography>
                            <div className={classes.statsCountBlock}>
                              <span className={classes.statsCount}>{`${props.courseDetails.completedCount}`}</span>
                              <span className={classes.statsValue}>{`/${props.courseDetails.enrolledUsers}`}</span>
                            </div>
                            <LinearProgressWithLabel value={props.courseDetails.completedInPercentage} />
                          </>
                        }
                      />
                    </Grid>

                    {/* Mani is commented out for this because func not done from backed */}
                    {/* <Grid item xs={12} sm={6} md={6}>
                      <BasicCard
                        children={
                          <>
                            <Typography variant="subtitle1" gutterBottom>
                              Course Progress
                            </Typography>
                            <div style={{ letterSpacing: '0px', textAlign: 'right' }}>
                              <span
                                className={classes.statsValue}
                              >{`${props.courseDetails.progress}% (avg of all users)`}</span>
                            </div>
                            <LinearProgressWithLabel value={props.courseDetails.progress} />
                          </>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <BasicCard
                        children={
                          <>
                            <Typography variant="subtitle1" gutterBottom>
                              Leader board
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <img src={LeaderBoard} alt="logo" />
                            </div>
                          </>
                        }
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {props.courseModule &&
                    props.courseModule.map((item, index) => {
                      return (
                        <>
                          <div style={{ paddingTop: '12px' }}>
                            <Accordion
                              className={classes.accordion}
                              expanded={expanded === index}
                              onChange={handleChanges(index)}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Box>
                                  <Typography sx={{ fontSize: '0.8rem' }}>Module {index + 1}</Typography>
                                  <Typography variant="subtitle1" sx={{ fontSize: '1.1rem' }}>
                                    {item.courseModuleName}
                                  </Typography>
                                </Box>
                              </AccordionSummary>
                              {item.subModule &&
                                item.subModule.map((res, subindex) => {
                                  return (
                                    <>
                                      <div style={{ padding: '10px 26px' }}>
                                        <Accordion className={classes.accordion}>
                                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Box>
                                              <Typography sx={{ fontSize: '0.8rem' }}>
                                                Sub Module {subindex + 1}
                                              </Typography>
                                              <Typography variant="subtitle1" sx={{ fontSize: '0.95rem' }}>
                                                {res.courseSubmoduleName}
                                              </Typography>

                                              {/* <Typography sx={{ marginLeft: '2rem' }}> 50% completed </Typography> */}
                                            </Box>
                                          </AccordionSummary>

                                          {res.subModuleTopics &&
                                            res.subModuleTopics.map((items, subindex) => {
                                              return (
                                                <>
                                                  <List className={classes.list}>
                                                    <ListItem>
                                                      <ListItemIcon>
                                                        <NotStartedIcon
                                                          fontSize="medium"
                                                          style={{ color: '#00B673' }}
                                                        />
                                                      </ListItemIcon>
                                                      <ListItemText>
                                                        <Box>
                                                          <Typography>{items.courseSubmoduleTopics}</Typography>
                                                        </Box>
                                                      </ListItemText>
                                                    </ListItem>
                                                  </List>
                                                </>
                                              );
                                            })}
                                        </Accordion>
                                      </div>
                                    </>
                                  );
                                })}
                            </Accordion>
                          </div>
                        </>
                      );
                    })}
                </Grid>

                {props.courseDetails.introVideo && (
                  <Grid item xs={12}>
                    <Box
                      style={{
                        boxShadow: '0 178px 98px -182px #645f5f',
                      }}
                    >
                      {/* <iframe
                        width="100%"
                        height="400"
                        src={props.courseDetails.introVideo}
                        title="YouTube video player"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      /> */}

                      {props.courseDetails.introVideo && (
                        <Video controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                          <source src={props.courseDetails.introVideo} type="video/webm" />
                          <track
                            label="English"
                            kind="subtitles"
                            srcLang="en"
                            src={props.courseDetails.introVideo}
                            default
                          />
                        </Video>
                      )}
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          }
        />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: '#FAFAFA',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    padding: '1rem',
  },
  accordion: {
    boxShadow: '0px 3px 6px #00000029',
    padding: '3px 12px',
  },
  list: {
    borderTop: '1px solid #DCDBDB',
  },
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5px',
  },
  progress: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '6px',
    padding: '5px',
    height: '173px',
    marginTop: '3px',
  },
  circularProgress: {
    width: '40%',
  },
  combined: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    textAlign: 'justify',
  },
  showMoreText: {
    display: '-webkit-box',
    WebkitLineClamp: 11,
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
  },
  readMore: {
    width: 'max-content',
    cursor: 'pointer',
  },
  statsCountBlock: {
    textAlign: 'right',
    letterSpacing: '2px',
  },
  statsCount: {
    fontWeight: '500',
    fontSize: '1rem',
  },

  statsValue: {
    fontSize: '0.775rem',
  },
}));
