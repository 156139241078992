import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Autocomplete,
  Stack,
  Box,
  Grid,
  TextField,
  FormHelperText,
  Link,
  FormControl,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';

const BillingInfo = () => {
  const classes = useStyles();

  const [editField, setEditField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [showimageo, setShowimageo] = useState(true);
  const [countryCode, setCountryCode] = React.useState(null);
  const [country, SetCountry] = React.useState([]);
  const [state, SetState] = React.useState([]);
  const [formvalue, setFormvalue] = useState({
    name: '',
    address: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
  });

  const handleSubmitForm = (values) => {
    setLoading(false);
    setEditField(false);
    setFormvalue(values);
    console.log('uservalues :', values);
  };

  const getLookUpDetails = async () => {
    const key = ['country', 'state'];
    const response = await adminServices.getLookUpData(key);
    if (response.ok) {
      SetState(response.data.state);
      SetCountry(response.data.country);
    }
  };
  const getLookUpState = async () => {
    const key = ['country', 'state'];
    const response = await adminServices.getLookUpStateData(key, countryCode);
    if (response) {
      SetState(response.data.state);
    }
  };

  useEffect(() => {
    getLookUpDetails();
  }, []);

  useEffect(() => {
    if (countryCode !== null) {
      getLookUpState();
    }
  }, [countryCode]);

  return (
    <div>
      {editField ? (
        <Formik
          initialValues={{
            name: '',
            address: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().trim().required('Required').max(20, 'Must be 20 characters or less')
            .matches(/^[A-Za-z ]+$/,"Only alphabets are allowed for this field")
            .label(),
            address: Yup.string().trim().max(200, 'To Long').min(10, 'To Short').required('Required'),
            country: Yup.string().trim().required('Required').label(),
            state: Yup.string().trim().required('Required').label(),
            city: Yup.string().trim().max(20, 'To Long').min(6, 'To Short').required('Required'),
            zipcode: Yup.string().trim().max(6, 'To Long').min(6, 'To Short').required('Required'),
          })}
          onSubmit={(values) => {
            console.log('values', values);
            handleSubmitForm(values);
          }}
        >
          {({ errors, handleBlur, setFieldValue, touched, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="name"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('name', e.target.value);
                    }}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    value={values.name}
                  />
                </Grid>

                <Grid item xs="12" sm="6">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Address
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="address"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('address', e.target.value);
                    }}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                    value={values.address}
                  />
                </Grid>

                <Grid item xs="12" sm="6" md="6">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Select Country
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={country}
                    autoHighlight
                    getOptionLabel={(option) => option.description || ''}
                    value={values.country}
                    onChange={(e, value) => {
                      setFieldValue('country', value);
                      setCountryCode(value.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="country"
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Select State
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={state}
                    autoHighlight
                    getOptionLabel={(option) => option.description || ''}
                    value={values.state}
                    onChange={(e, value) => {
                      setFieldValue('state', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="selectState"
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs="12" sm="6">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    City
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="city"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('city', e.target.value);
                    }}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                    value={values.city}
                  />
                </Grid>

                <Grid item xs="12" sm="6">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Zipcode
                  </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    name="zipcode"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('zipcode', e.target.value);
                    }}
                    error={Boolean(touched.zipcode && errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                    value={values.zipcode}
                  />
                </Grid>

                <Grid align="end" item xs="12">
                  <Stack direction="row" align="end" spacing={2} sx={{ display: 'flex;', justifyContent: 'end;' }}>
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      sx={{
                        color: '#fff !important',
                        background: 'gray;',
                      }}
                      onClick={() => setEditField(false)}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton loading={loading} size="medium" type="submit" variant="contained">
                      Update
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
              Name
            </Typography>
            <Typography variant="body1" component="div" onClick={() => setEditField(!editField)}>
              {formvalue.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
              Address
            </Typography>
            <Typography variant="body1" component="div" onClick={() => setEditField(!editField)}>
              {formvalue.address}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={2} textAlign="end" className={classes.marginTopSm}>
            <IconButton
              onClick={() => {
                setEditField(!editField);
                // setShowimageo(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>

          <>
            <Grid item xs={12} sm={5}>
              <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
                Country
              </Typography>
              <Typography variant="body1" component="div" onClick={() => setEditField(!editField)}>
                {formvalue.country?.description}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
                State
              </Typography>
              <Typography variant="body1" component="div" onClick={() => setEditField(!editField)}>
                {formvalue.state?.description}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
                City
              </Typography>
              <Typography variant="body1" component="div" onClick={() => setEditField(!editField)}>
                {formvalue.city}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
                Zipcode
              </Typography>
              <Typography variant="body1" component="div" onClick={() => setEditField(!editField)}>
                {formvalue.zipcode}
              </Typography>
            </Grid>
          </>
        </Grid>
      )}
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  typoFontWeight: {
    fontSize: '1rem;',
    fontWeight: '400',
  },
  typoFontWeightone: {
    fontSize: '1rem;',
  },

  input: {
    display: 'none',
  },
  marginTopSm: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '-7rem',
    },
  },
}));

export default BillingInfo;
