// TriangleCursor.js

import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cursor: {
    width: '0',
    height: '0',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '15px solid orange',
    transform: 'translate(-50%, -50%)',
  },
}));

function TriangleCursor() {
  const classes = useStyles();

  return (
    <div className={classes.cursor} />
  );
}

export default TriangleCursor;