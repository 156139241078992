import React from 'react';
import { Grid, Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const CourseContentSkeleton = () => {
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Skeleton animation="wave" variant="text" height={40} />
          <Skeleton animation="wave" variant="text" height={40} />
          <Skeleton animation="wave" variant="text" height={40} />
          <Skeleton animation="wave" variant="text" height={40} />
          <Skeleton animation="wave" variant="text" height={40} />
          <Skeleton animation="wave" variant="text" height={40} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton animation="wave" variant="text" height={20} />
          <Skeleton animation="wave" variant="rectangular" width={'100%'} height={140} />
          <Skeleton animation="wave" variant="text" height={20} width={100} />
          <Skeleton animation="wave" variant="text" height={45} width={90} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Skeleton animation="wave" variant="text" height={20} />
          <Skeleton animation="wave" variant="rectangular" width={'100%'} height={140} />
          <Skeleton animation="wave" variant="text" width={100} height={20} />
          <Skeleton animation="wave" variant="text" height={45} width={90} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CourseContentSkeleton;
