import React from 'react';
import { Grid, Typography, Paper, Box, IconButton, CardHeader, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';

export default function SubCard(props) {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.addressCard} sx={{ mt: 3 }}>
        <Box className={classes.cardHeader}>
          <div>
            <Typography variant="h6">{props.title}</Typography>
            <Typography>{props.subtitle}</Typography>
          </div>
        </Box>
        <div>{props.children}</div>
      </Card>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  card: {
    padding: '1rem',
    border: '1px solid #ECECEC',
    boxShadow: '0px 3px 30px #0000000A',
    borderRadius: '10px',
  },
  addressCard: {
    border: '1px solid #ECECEC',
    boxShadow: '0px 3px 30px #0000000A',
    borderRadius: '10px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FBFBFB',
    border: '1px solid #ECECEC',
    padding: '1rem',
  },
}));
