import React from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import { Grid, FormControl, FormHelperText } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import CaseStudies from '../../../services/caseStudies/CaseStudies';

export default function UploadFiles(props) {
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();
  const courseId = location.state;

  const handleSubmitFormValues = async (values) => {
    setLoading(true);
    const data = {
      name: values.fileName,
      fileName: values.uploadFiles?.name,
    };
    const formData = new FormData();
    formData.append('file', values.uploadFiles);
    try {
      const response = await CaseStudies.uploadCaseStudyFiles(courseId, data, values.uploadFiles?.type);
      if (response.ok) {
        await axios
          .put(response.data?.preSignedUrl, values.uploadFiles, {
            headers: {
              'Content-Type': values.uploadFiles?.type,
              // 'x-amz-acl': 'public-read',
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              props.snackBarControl('File uploaded successfully');
              props.closeModel();
              props.getCaseStudyFiles();
            } else {
              console.log(response);
              props.snackBarControl('Please try again');
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          fileName: '',
          uploadFiles: [],
        }}
        validationSchema={Yup.object().shape({
          fileName: Yup.string().required('Required'),
          uploadFiles: Yup.mixed()
            .required('Required')
            .test('fileSize', 'Maximum file size is 20MB', (value) =>
              value && typeof value === 'object' ? value.size < 20000000 : 0 < 20000000
            ),
        })}
        onSubmit={(values) => {
          handleSubmitFormValues(values);
          console.log(values);
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue, touched, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12} style={{ marginTop: '1rem' }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="File Name"
                  variant="outlined"
                  name="fileName"
                  onChange={(e) => {
                    setFieldValue('fileName', e.target.value);
                  }}
                  error={Boolean(touched.fileName && errors.fileName)}
                  helperText={touched.fileName && errors.fileName}
                  value={values.fileName}
                />
              </Grid>

              <Grid item xs="12">
                <FormControl
                  // required
                  component="fieldset"
                  color="primary"
                  variant="outlined"
                  fullWidth
                  name="uploadFiles"
                  value={values.uploadFiles}
                >
                  <DropzoneArea
                    showPreviews
                    dropzoneText="Drag and Drop Image or Browse File"
                    showPreviewsInDropzone={false}
                    maxFileSize={1048576000}
                    filesLimit={1}
                    name="uploadFiles"
                    showAlerts={false}
                    value={values.uploadFiles}
                    onChange={(file) => setFieldValue('uploadFiles', file[0])}
                    useChipsForPreview
                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                    showFileNamesInPreview
                  />

                  <FormHelperText style={{ color: '#F44336' }}>
                    {/* {errors.uploadFiles && touched.uploadFiles && errors.uploadFiles} */}
                    {(errors.uploadFiles || touched.uploadFiles) && errors.uploadFiles}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                  Add
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
