import React, { useState } from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';

import { Grid, TextField } from '@mui/material';
import CaseStudies from '../../../services/caseStudies/CaseStudies';

export default function Rename(props) {
  const [loading, setLoading] = React.useState(false);

  const handleSubmitFormValues = async (values) => {
    setLoading(true);
    const formData = {
      name: values.fileName,
    };
    try {
      const response = await CaseStudies.updateCaseStudiesFileName(props.id, formData);
      if (response.ok) {
        setLoading(false);

        props.snackBarControl(response.data.message);
        props.closeModel();
        props.getCaseStudyFiles();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          fileName: props.fileName,
        }}
        validationSchema={Yup.object().shape({
          fileName: Yup.string().required('Required'),
        })}
        onSubmit={(values) => {
          handleSubmitFormValues(values);
        }}
      >
        {({ errors, handleChange, setFieldValue, touched, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12} style={{ marginTop: '1rem' }}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="File Name"
                  variant="outlined"
                  name="fileName"
                  onChange={(e) => {
                    setFieldValue('fileName', e.target.value.trim());
                  }}
                  error={Boolean(touched.fileName && errors.fileName)}
                  helperText={touched.fileName && errors.fileName}
                  value={values.fileName}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
