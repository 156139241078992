import React, { useEffect, useState } from 'react';
import {
  Box,
  CardContent,
  Button,
  Card,
  CardActions,
  IconButton,
  Grid,
  Link,
  Paper,
  Typography,
  Collapse,
  Modal,
  Fade,
  Backdrop,
} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import swal from 'sweetalert';
import { useLocation, NavLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import GaugeChart from 'react-gauge-chart';
import 'react-circular-progressbar/dist/styles.css';
import Skeleton from '@mui/material/Skeleton';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import Page from '../../components/Page';
import courseApi from '../../services/users/courseApi';
import Iconify from '../../components/Iconify';
import palette from '../../theme/palette';
import iqServices from '../../services/iqTest/index';
import background from '../../assets/logo/cardBackground.png';
import iqImage from '../../assets/logo/iqImage.png';
import playIcon from '../../assets/logo/playicon.png';
//
import BasicModal from '../../components/modal/BasicModel';
import './user.css';
import caseStudyService from '../../services/caseStudy/caseStudyService';

const LinkBtn = React.forwardRef((props, ref) => {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
});

const styles = {};

export default function UserCourseDetails(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const courseDetails = location.state;
  console.log(courseDetails,"courseDetailswer");
  
  const [courseModules, setCourseModules] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [subModuleExpanded, setSubModuleExpanded] = React.useState(false);
  const [resumeData, setResumeData] = useState(null);
  console.log(resumeData,"resumeDataresumeData");
  
  const [courseProgress, setCourseProgress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openContent, setOpenContent] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#00B673' : '#00B673',
    },
  }));
  const [caseStudy, setCaseStudy] = useState([]);
  const [csTestResult, setCsTestResult] = useState(null);
  const [openResult, setOpenResult] = useState(false);

  const getCourseDetails = async () => {
    try {
      const response = await courseApi.getCourseModulesAndSubmodules(
        courseDetails.id,
        courseDetails.subscriptionPlanId
      );
      if (response.ok) {
        setCourseModules(response.data.course);
        setCaseStudy(response.data.files);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const getLastCompletedDetails = async () => {
    const res = await courseApi.getUserLastCompletedDetails(courseDetails.id);
    if (res.ok) {
      setResumeData(res.data);
    }
  };

  const getCourseProgress = async () => {
    const iqResponse = await iqServices.getIqAndCourseProgress(courseDetails.id);
    if (iqResponse.ok) {
      setCourseProgress(iqResponse.data);
      console.log(parseFloat(iqResponse.data.efficiencyScore) / 100);
    }
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: expanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const ExpandMoreSub = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: subModuleExpanded === expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  useEffect(() => {
    getCourseDetails();
    getLastCompletedDetails();
    getCourseProgress();
    getCaseStudyResult();
  }, []);

  const getCaseStudyResult = () => {
    try {
      caseStudyService.getCaseStudyResult(courseDetails.id).then((res) => {
        if (res.ok) {
          if (Object.keys(res.data).length > 0) {
            setCsTestResult(res.data);
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExpandClick = (i) => {
    setExpanded(expanded === i ? -1 : i);
    setSubModuleExpanded(null);
  };

  const handleSubModuleExpandClick = (i) => {
    setSubModuleExpanded(subModuleExpanded === i ? -1 : i);
  };

  const handleStartSimulation = (subModuleId, module, resumeClic = false) => {
    if (resumeData && resumeClic) {
      navigate(
        `/app/course-simulation?module=${resumeData.Modulename}&id=${subModuleId}&topic=${resumeData.courseSubModuleTopicId}&screen=${resumeData.courseSubModuleTopicScreenId}`,
        { replace: true, state: courseDetails }
      );
    } else {
      navigate(`/app/course-simulation?id=${subModuleId}&module=${module}`, { replace: true, state: courseDetails });
    }
  };

  const handleIQstart = (type) => {
    if (type === 'COURSE') {
      navigate(`/app/skilliq-test?courseId=${courseDetails.id}`, { replace: true, state: courseDetails });
    }
  };

  async function downloadURI(url, filename) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  }

  const fileIcon = [
    { type: 'image/jpeg', icon: 'ant-design:file-image-outlined' },
    { type: 'image/png', icon: 'ant-design:file-image-outlined' },
    { type: 'image/jpg', icon: 'ant-design:file-image-outlined' },
    { type: 'image/svg+xml', icon: 'ant-design:file-image-outlined' },
    { type: 'application/pdf', icon: 'ant-design:file-pdf-outlined' },
    { type: 'video/mp4', icon: 'la:file-video-solid' },
  ];

  return (
    <Page title={'Course-details'}>
      <Box className={classes.coursecard}>
        <Grid sx={{ alignItems: 'stretch', display: 'flex' }} container spacing={0}>
          <Grid items xs={12} sm={12} md={7} lg={5.5} xl={5.5}>
            <Box>
              <Typography className={classes.coursetitle} gutterBottom variant="h3" sx={{ color: '#fff' }}>
                {courseDetails.title}
              </Typography>
              <Typography
                variant="body2"
                className={classes.content}
                dangerouslySetInnerHTML={{ __html: courseDetails.description }}
              />

              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Box
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '6px',
                    width: 'auto',
                    marginTop: '10px',
                  }}
                >
                  <img className={classes.logo} src={courseDetails.category.categoryImgUrl} alt="logo" width="100%" />{' '}
                </Box>
                <Box className={classes.powerpoint}>{courseDetails.category.description}</Box>

                <Button
                  onClick={() => handleStartSimulation(resumeData.courseSubModuleId, resumeData.Modulename, true)}
                  className={classes.resume}
                >
                  Resume Course
                  <IconButton
                    size="small"
                    sx={{ marginLeft: '6px' }}
                    onClick={() => handleStartSimulation(resumeData.courseSubModuleId, resumeData.Modulename, true)}
                  >
                    <PlayCircleIcon fontSize="small" sx={{ color: '#000' }} />
                  </IconButton>
                </Button>
              </div>
            </Box>
          </Grid>
          <Grid items xs={12} sm={12} md={5} lg={6.5} xl={6.5}>
            <img
              src={courseDetails.coverageImage && courseDetails.coverageImage}
              className={classes.image}
              alt="meetingimg"
            />
          </Grid>
        </Grid>

        {courseDetails.introVideo ? (
          <div className={classes.playbutton}>
            <Button onClick={handleOpen} className={classes.play}>
              <img className={classes.playbtn} src={playIcon} alt="playIcon" />
              {/* <a  className={classes.playbtn}  href="#">D</a> */}
            </Button>
          </div>
        ) : (
          <div className={classes.playbutton} />
        )}
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{
          '.css-moysu4': {
            border: 'none ! important',
          },
        }}
      >
        <Fade in={open}>
          {courseDetails.introVideo && (
            <Box className={classes.vediobox}>
              <Video controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                <source src={courseDetails.introVideo} type="video/webm" />
                <track label="English" kind="subtitles" srcLang="en" src={courseDetails.introVideo} default />
              </Video>
            </Box>
          )}
        </Fade>
      </Modal>
      {loading ? (
        <div>
          <Skeleton width="100%" height="100px" />
          <Skeleton />
        </div>
      ) : (
        <Box className={classes.imagebox}>
          <div className={classes.iqbox}>
            <img src={iqImage} alt="iqImage" />
            <Typography sx={{ fontSize: '20px' }}>SKILLSET IQ</Typography>
            <div className={classes.assesment}>
              <Typography>First Assessment</Typography>
              <Typography>{courseProgress && courseProgress.firstIq ? courseProgress.firstIq : 'N/A'}%</Typography>
            </div>
            <div className={classes.assesment}>
              <Typography>Best Assessment</Typography>
              <Typography>{courseProgress && courseProgress.bestIq ? courseProgress.bestIq : 'N/A'}%</Typography>
            </div>
            <Button variant="contained" onClick={() => handleIQstart('COURSE')} className={classes.iqbutton}>
              Take IQ Assessment
            </Button>
          </div>
          <div style={{ textAlign: 'end', fontSize: '12px' }}>
            <Typography
              onClick={() => setOpenContent(true)}
              style={{ color: '#747474 ! important', textDecoration: 'underline', cursor: 'pointer' }}
            >
              How it works
            </Typography>
          </div>
        </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Box className={classes.courserContent}>
            <Typography variant="h6">Course Content</Typography>
          </Box>

          {loading && (
            <Box>
              <Card
                className={classes.cardItem}
                sx={{ padding: '0.6rem 1rem', display: 'flex', justifyContent: 'space-between' }}
              >
                <Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" variant="circular" width={25} height={25} />
              </Card>
              <Card
                className={classes.cardItem}
                sx={{ padding: '0.6rem 1rem', display: 'flex', justifyContent: 'space-between' }}
              >
                <Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" variant="circular" width={25} height={25} />
              </Card>
            </Box>
          )}

          {courseModules &&
            courseModules.map((res, index) => (
              <Card className={classes.cardItem}>
                <CardActions disableSpacing>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: '16px',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box sx={{ cursor: 'pointer' }}>
                      <Typography sx={{ fontSize: '0.8rem' }} onClick={() => handleExpandClick(index)}>
                        Module {index + 1}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: '1.1rem' }}
                        onClick={() => handleExpandClick(index)}
                      >
                        {res.courseModuleName}
                      </Typography>
                    </Box>
                  </div>
                  <ExpandMore
                    expand={index}
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={expanded}
                    aria-label="show more"
                    sx={{ marginLeft: '16px' }}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>

                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                  <CardContent>
                    {res.subModule &&
                      res.subModule.map((subModule, indx) => (
                        <Card className={classes.cardItem}>
                          <CardActions disableSpacing>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: '16px',
                                width: '100%',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box sx={{ cursor: 'pointer' }}>
                                <Typography
                                  sx={{ fontSize: '0.8rem' }}
                                  onClick={() => handleSubModuleExpandClick(indx)}
                                >
                                  Sub Module {indx + 1}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  onClick={() => handleSubModuleExpandClick(indx)}
                                  sx={{ fontSize: '0.95rem', minWidth: '60%', display: 'flex', alignItems: 'center' }}
                                >
                                  {subModule.courseSubmoduleName}
                                  <Typography variant="body2" color="secondary" marginLeft={2}>
                                    {` ${subModule.completed}`}% completed
                                  </Typography>
                                </Typography>
                              </Box>
                              <Button
                                variant="contained"
                                onClick={() => handleStartSimulation(subModule.id, res.courseModuleName)}
                              >
                                Start
                              </Button>
                            </div>
                            <ExpandMoreSub
                              expand={indx}
                              onClick={() => handleSubModuleExpandClick(indx)}
                              aria-expanded={subModuleExpanded}
                              aria-label="show more"
                              sx={{ marginLeft: '16px' }}
                            >
                              <ExpandMoreIcon />
                            </ExpandMoreSub>
                          </CardActions>

                          <Collapse in={subModuleExpanded === indx} timeout="auto" unmountOnExit>
                            <CardContent sx={{ paddingTop: 1 }}>
                              {subModule.subModuleTopics &&
                                subModule.subModuleTopics.map((topic, idx) => (
                                  <Box display="flex" alignItems="center" sx={{ padding: '8px 0 !important' }}>
                                    <Iconify
                                      icon={'ant-design:check-circle-outlined'}
                                      width={18}
                                      height={18}
                                      marginRight={1}
                                      sx={{ color: topic.isCompleted ? palette.secondary.main : '#00000029' }}
                                    />
                                    <Typography
                                      variant="subtitle1"
                                      // component={LinkBtn}
                                      // to={{
                                      //   pathname: '/app/submodules',
                                      // }}
                                      sx={{ textDecoration: 'none !important' }}
                                      color="inherit"
                                    >
                                      {topic.courseSubmoduleTopics}
                                    </Typography>
                                  </Box>
                                ))}
                              <Typography
                                variant="subtitle1"
                                key={'myRoute'}
                                component={LinkBtn}
                                to={{
                                  pathname: `/app/skilliq-test?subModuleId=${subModule.id}`,
                                }}
                                state={{ data: courseDetails,subModule }}
                                sx={{ textDecoration: 'none !important' }}
                                color="primary"
                              >
                                SkillSet IQ
                              </Typography>
                            </CardContent>
                          </Collapse>
                        </Card>
                      ))}
                  </CardContent>
                </Collapse>
              </Card>
            ))}
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <Box className={classes.courserContent}>
            <Typography variant="h6">Statistics</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={12}>
              <Paper elevation={1} className={classes.paper1}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <Typography>Overall Progress</Typography>
                  <Typography sx={{ color: '#00B673' }}>
                    {courseProgress ? `${courseProgress.overallProgress}%` : '0%'}
                  </Typography>
                </div>
                <BorderLinearProgress
                  variant="determinate"
                  value={courseProgress && parseInt(courseProgress.overallProgress, 10)}
                />
              </Paper>
            </Grid>
            <Grid mt={0} item xs={12} sm={6} md={12}>
              <Paper elevation={1} className={classes.paper}>
                <div>
                  <GaugeChart
                    id="gauge-chart2"
                    nrOfLevels={4}
                    arcWidth={0.2}
                    percent={0.2}
                    animate
                    textColor="#00b673"
                    formatTextValue={() => (courseProgress ? `${parseInt(courseProgress.efficiencyScore, 10)}x` : '0x')}
                    cornerRadius={0}
                    arcPadding={0}
                    colors={['#e5eaec', '#92d4b5', '#59bd91', '#1da86b']}
                    hideText
                    needleColor="#d8dcdd"
                    needleBaseColor="#d8dcdd"
                  />
                </div>
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ marginTop: '-1.5rem', fontWeight: '600', fontSize: '1.4rem' }}
                >
                  {courseProgress ? `${parseInt(courseProgress.efficiencyScore, 10)}x` : '0x'}
                </Typography>
                <Typography variant="subtitle1">Efficiency Score</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <Paper elevation={2} sx={{ padding: '1.4rem', marginTop: '1rem' }}>
        <Box className={classes.courserContent}>
          <Typography variant="h6">Case studies</Typography>

          {csTestResult && (
            <Box>
              {csTestResult ? (
                <Button variant="contained" color="primary" onClick={() => setOpenResult(true)}>
                  View result
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    swal({
                      // title: 'Good job!',
                      text: ' You have only one chance to pass the test. Please make sure that you have prepared well',
                      // icon: 'success',
                      buttons: {
                        confirm: {
                          text: 'Okay',
                          value: true,
                          visible: true,
                          className: '',
                          closeModal: true,
                        },
                        cancel: {
                          text: 'Cancel',
                          value: null,
                          visible: true,
                          className: '',
                          closeModal: true,
                        },
                      },
                      closeOnClickOutside: false,
                    }).then((res) => {
                      if (res) {
                        navigate(`/app/case-studyiq-test?courseId=${courseDetails.id}`, {
                          replace: true,
                          state: courseDetails,
                        });
                      }
                    });
                  }}
                >
                  Take Test
                </Button>
              )}
            </Box>
          )}
        </Box>
        {caseStudy.map((res, index) => (
          <Box className={classes.fileList}>
            <Link underline="none" variant="subtitle1" sx={{ display: 'flex' }} href={res.url} target="new">
              {fileIcon.map((item) => {
                return (
                  <>
                    {res.type === item.type ? (
                      <Iconify
                        icon={item.icon}
                        width={20}
                        height={20}
                        // marginRight={1}
                        // sx={{ color: topic.isCompleted ? palette.secondary.main : '#00000029' }}
                      />
                    ) : (
                      ''
                    )}
                  </>
                );
              })}
              &nbsp; {res.name}
            </Link>

            <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
              <CloudDownloadIcon color="primary" />
            </IconButton>
          </Box>
        ))}
      </Paper> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9}>
          <Paper elevation={2} sx={{ padding: '1.4rem', marginTop: '1rem' }}>
            <Box className={classes.courserContent}>
              <Typography variant="h6">Case studies</Typography>

              <Box>
                {csTestResult ? (
                  <Button variant="contained" color="primary" onClick={() => setOpenResult(true)}>
                    View result
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      swal({
                        // title: 'Good job!',
                        text: ' You have only one chance to pass the test. Please make sure that you have prepared well',
                        // icon: 'success',
                        buttons: {
                          confirm: {
                            text: 'Okay',
                            value: true,
                            visible: true,
                            className: '',
                            closeModal: true,
                          },
                          cancel: {
                            text: 'Cancel',
                            value: null,
                            visible: true,
                            className: '',
                            closeModal: true,
                          },
                        },
                        closeOnClickOutside: false,
                      }).then((res) => {
                        if (res) {
                          navigate(`/app/case-studyiq-test?courseId=${courseDetails.id}`, {
                            replace: true,
                            state: courseDetails,
                          });
                        }
                      });
                    }}
                  >
                    Take Test
                  </Button>
                )}
              </Box>
            </Box>
            {caseStudy.map((res, index) => (
              <Box className={classes.fileList}>
                <Link underline="none" variant="subtitle1" sx={{ display: 'flex' }} href={res.url} target="new">
                  {fileIcon.map((item) => {
                    return <>{res.type === item.type ? <Iconify icon={item.icon} width={20} height={20} /> : ''}</>;
                  })}
                  &nbsp; {res.name}
                </Link>

                <IconButton size="small" onClick={() => downloadURI(res.url, res.type)}>
                  <CloudDownloadIcon color="primary" />
                </IconButton>
              </Box>
            ))}
          </Paper>
        </Grid>
      </Grid>
      <BasicModal openModel={openResult} closeModel={() => setOpenResult(!openResult)} title="Case study result">
        <Typography variant="h5" gutterBottom sx={{ display: 'flex' }}>
          Test Result :{' '}
          {csTestResult && csTestResult.result ? (
            <Typography variant="h5" color="secondary">
              &nbsp;Pass
            </Typography>
          ) : (
            <Typography variant="h5" color="primary">
              &nbsp; Fail
            </Typography>
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Percentage : {csTestResult && csTestResult.resultInPercentage} %
        </Typography>
        {/* <Typography variant="body1" gutterBottom>
          Time Taken : {csTestResult && csTestResult.timeTaken}
        </Typography> */}
        <Typography variant="body1" gutterBottom>
          Correct Answer: {csTestResult && csTestResult.rightAnswer}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Wrong Answer: {csTestResult && csTestResult.wrongAnswer}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Total number of question: {csTestResult && csTestResult.totalScreen}
        </Typography>
      </BasicModal>

      {/* how its work descriptions */}
      <BasicModal openModel={openContent} closeModel={() => setOpenContent(!openContent)} title="Power Point">
        <Typography id="transition-modal-title" variant="subtitle1" component="h2">
          Description
        </Typography>
        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla in tortor a ex dictum commodo. Fusce pretium,
          quam a egestas dictum, purus erat sodales nibh, eget sollicitudin urna felis non felis.
        </Typography>
      </BasicModal>
    </Page>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '16px',
    textAlign: 'center',
  },
  paper1: {
    padding: '16px',
    textAlign: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
  },
  cardItem: {
    marginBottom: '24px',
    border: '1px solid #e1e1e1ab',
  },
  resumeBox: {
    width: '140px',
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  courserContent: {
    margin: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  infoIcon: {
    color: 'gray',
    marginLeft: '8px',
  },
  progress: {
    width: '42%',
    marginBottom: '0.9rem',
  },
  courseBtn: {
    width: 'max-content',
    background: ' #fff',
    padding: '4px 8px',
    borderRadius: '6px',
  },
  logo: {
    boxShadow: '0px 3px 22px #00000029',
    padding: '1px',
    width: '35px',
    borderRadius: '6px',
  },
  category: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  categoryPosition: {
    position: 'relative',
    top: '13rem',
  },
  coursecard: {
    position: 'relative',
    minHeight: '20rem',
    backgroundColor: '#102D73',
    padding: '2rem',
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '6px',
      minHeight: 'auto',
    },
  },
  coursetitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      WebkitBoxOrient: 'initial',
    },
  },
  content: {
    minHeight: '15rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '10',
    WebkitBoxOrient: 'vertical',
    color: '#fff',
    fontWeight: '200',
    margin: '1rem 0rem 1.5rem 0rem',
    [theme.breakpoints.down('lg')]: {
      minHeight: '13rem',
    },
    '@media (max-width: 1040px)': {
      minHeight: '10rem',
    },
  },
  resume: {
    color: '#000000',
    fontWeight: '450',
    backgroundColor: '#fff',
    fontSize: '14px',
    borderRadius: '6px',
    padding: '2.5px 8px',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  powerpoint: {
    backgroundColor: '#fff',
    fontSize: '14px',
    color: '#02723B',
    borderRadius: '6px',
    padding: '7px 8px',
    width: 'fit-content',
    margin: '0px 12px',
    marginTop: '10px',
  },
  image: {
    borderRadius: '6px',
    [theme.breakpoints.up('lg')]: {
      width: '75%',
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  imagebox: {
    padding: '6px',
    marginTop: '1rem',
    backgroundImage: `url(${background})`,
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  halfRow: {
    width: '50%',
  },
  center: {
    alignItems: 'center',
    width: '100%',
  },
  playbutton: {
    backgroundColor: '#fff',
    borderRadius: '70px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    '@media (max-width: 1440px)': {
      top: '50%',
      left: '50%',
    },
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  playicon: {
    textDecoration: 'none',
    color: 'aliceblue',
    borderRadius: '50%',
    padding: '0px',
    position: 'relative',
    ':after': {
      position: 'absolute',
      content: '',
      bottom: '-2px',
      top: '-2px',
      left: '-2px',
      right: '-2px',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      border: '1px solid green',
      margin: '0px',
      opacity: 1,
      transformOrigin: 'center',
      animation: 'anim 1000ms linear infinite',
    },
    ':before': {
      position: 'absolute',
      content: '',
      bottom: '-2px',
      top: '-2px',
      left: '-2px',
      right: '-2px',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      border: '1px solid green',
      margin: '0px',
      opacity: '0.8',
      transformOrigin: 'center',
      animation: 'anim 1000ms linear 200ms infinite',
    },
  },
  play: {
    padding: '0px ! important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  vedio: {
    height: '100%',
    width: '100%',
  },
  vediobox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: 'auto',
    bgcolor: 'background.paper',
    padding: '20px',
    boxShadow: 24,
    p: 4,
  },
  iqbox: {
    display: 'flex',
    padding: '10px 50px 10px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      padding: '10px',
      justifyContent: 'space-around',
    },
  },
  assesment: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0px',
    },
  },
  iqbutton: {
    padding: '8px',
    fontSize: '0.95rem',
    fontWeight: ' bold',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  fileList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 1rem',
    background: '#F5F5F5',
    borderRadius: '10px',
    marginBottom: '0.8rem',
  },
  playbtn: {
    width: '60px',
    height: '60px',
    background: 'radial-gradient( gree 60%, rgba(255, 255, 255, 1) 62%)',
    borderRadius: '50%',
    position: 'relative',
    display: 'block',
    // margin: "100px auto",
    boxShadow: '0px 0px 25px 3px rgba(255, 0, 128, 0.8)',
    ':after': {
      content: '',
      position: 'absolute',
      left: '50%',
      top: '50%',
      webkitTransform: 'translateX(-40%) translateY(-50%)',
      transform: 'translateX(-40%) translateY(-50%)',
      transformOrigin: 'center center',
      width: '0',
      height: '0',
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: '15px solid #fff',
      zIndex: '100',
      webkitTransition: 'all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
      transition: 'all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19)',
    },
    ':before': {
      content: '',
      position: 'absolute',
      width: '140%',
      height: '140%',
      webkitAnimationDelay: '0s',
      animationDelay: '0s',
      webkitAnimation: 'pulsate1 2s',
      animation: 'pulsate1 2s',
      webkitAnimationDirection: 'forwards',
      animationDirection: 'forwards',
      webkitAnimationIterationCount: 'infinite',
      animationIterationCount: 'infinite',
      webkitAnimationTimingFunction: 'steps',
      animationTimingFunction: 'steps',
      opacity: '1',
      borderRadius: '50%',
      border: '5px solid rgba(255, 255, 255, .75)',
      top: '-30%',
      left: '-30%',
      background: 'rgba(198, 16, 0, 0)',
    },
  },
  '@-webkit-keyframes pulsate1': {
    '0%': {
      webkitTransform: 'scale(0.6)',
      transform: 'scale(0.6)',
      opacity: '1',
      boxShadow: 'inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75)',
    },
    '100%': {
      webkitTransform: 'scale(1)',
      transform: 'scale(1)',
      opacity: '0',
      boxShadow: 'none',
    },
  },
  '@keyframes pulsate1': {
    '0%': {
      webkitTransform: 'scale(0.6)',
      transform: 'scale(0.6)',
      opacity: '1',
      boxShadow: 'inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75)',
    },
    '100%': {
      webkitTransform: 'scale(1, 1)',
      transform: 'scale(1)',
      opacity: '0',
      boxShadow: 'none',
    },
  },
}));
