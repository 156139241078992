import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, Box, Breadcrumbs, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import lottie from 'lottie-web';

import Page from '../../../../../components/Page';
import PageHeader from '../../../../../components/PageHeader';
import lottieData from '../../../../../assets/lottie/lottie-loading.json';
import Pricing from './Pricing';
import Courses from './Courses';
import Invoices from './Invoices';
import Reports from '../../../../../services/report/reports';

export default function UserDetails() {
  const classes = useStyles();
  const isGoBack = true;
  const lottoLoad = useRef();
  const location = useLocation();
  const { userPlanId } = location.state;
  const [lottieLoading, setLottieLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});

  let colors = '';
  let backgroundColors = '';

  const userDetailsLength = Object.keys(userDetails).length;
  console.log(userDetailsLength, 'userDetailsLength');

  const Capitalize = userDetailsLength > 0 && userDetails?.status.toLowerCase();

  if (userDetails?.status === 'ACTIVE') {
    colors = 'green';
    backgroundColors = '#caf5d4';
  } else if (userDetails?.status === 'EXPIRED') {
    colors = 'red';
    backgroundColors = 'rgba(254, 112, 0, 0.08)';
  } else {
    colors = 'gray';
    backgroundColors = 'rgb(128 127 125 / 18%)';
  }

  const getUserInformation = async () => {
    try {
      const response = await Reports.getUserInfo(userPlanId);
      console.log(response, 'response');
      if (response.ok) {
        setUserDetails(response.data);
      }
      setLottieLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    lottie.loadAnimation({
      container: lottoLoad.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lottieData,
    });
    getUserInformation();
  }, []);

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Reports
      </Typography>
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/subscribed-users-new"
      >
        Subscriptions
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Subscription Details
      </Typography>
    </Breadcrumbs>
  );

  return (
    <div>
      <Page title={'Users-Details'}>
        <PageHeader pageTitle={'Subscription Details'} goBack={isGoBack} breadcrumbs={<Breadcrumb />} />
        <Box>
          {lottieLoading ? (
            <div style={{ width: 50, margin: 'auto' }} ref={lottoLoad} />
          ) : (
            <Grid container spacing={2}>
              {userDetailsLength > 0 ? (
                <Grid item xs={12}>
                  <div className={classes.maindiv}>
                    <div className={classes.nameAlign}>
                      <Typography variant="h4">{userDetails?.name}</Typography>
                      <Typography>&nbsp;</Typography>
                      <Typography>
                        <span className={classes.subsciptionName}>| {userDetails?.subsciptionPlanName}</span>
                      </Typography>
                    </div>

                    <div>
                      <Button
                        variant="outlined"
                        sx={{
                          textTransform: 'capitalize',
                          color: colors,
                          backgroundColor: backgroundColors,
                          '&:hover': { color: colors, backgroundColor: backgroundColors },
                        }}
                        className={classes.activeButton}
                      >
                        {Capitalize?.charAt(0).toUpperCase() + Capitalize?.slice(1)}
                        {/* {userDetails?.status} */}
                      </Button>
                    </div>
                  </div>
                </Grid>
              ) : (
                <div />
              )}
              {userDetailsLength > 0 ? (
                <Grid item xs={12} mt={1.5}>
                  <Typography>
                    <span className={classes.subscriptionid}> Stripe Subscription ID : </span>
                    {userDetails?.subscriptionId}
                  </Typography>
                </Grid>
              ) : (
                <div />
              )}
              {userDetailsLength > 0 ? (
                <Grid item xs="12" mb={3}>
                  <div className={classes.subdiv}>
                    <div className={classes.textAlign}>
                      <Typography variant="subtitle1" className={classes.headerName}>
                        Started
                      </Typography>
                      <Typography>{userDetails?.startDate}</Typography>
                    </div>
                    <div className={classes.textAlign}>
                      <Typography variant="subtitle1" className={classes.headerName}>
                        Next Invoice
                      </Typography>
                      {userDetails?.status !== 'CANCELED' && (
                        <Typography color="primary">{userDetails?.nextInvoice}</Typography>
                      )}
                    </div>
                    <div>
                      <Typography variant="subtitle1" className={classes.headerName}>
                        Current period
                      </Typography>
                      <Typography>{userDetails?.currentPeriod}</Typography>
                    </div>
                  </div>
                </Grid>
              ) : (
                <div />
              )}

              <Grid item xs="12" mb={4}>
                <Typography variant="h5" className={classes.tableHeader}>
                  Pricing
                </Typography>
                <Pricing
                  userDetailsLength={userDetailsLength}
                  pricingData={userDetails?.pricing ? userDetails.pricing : []}
                />
              </Grid>
              <Grid item xs="12" mb={4}>
                <Typography variant="h5" className={classes.tableHeader}>
                  Courses
                </Typography>
                <Courses
                  userDetailsLength={userDetailsLength}
                  courseData={userDetails?.courses ? userDetails.courses : []}
                />
              </Grid>
              <Grid item xs="12">
                <Typography variant="h5" className={classes.tableHeader}>
                  Invoices
                </Typography>
                <Invoices
                  userDetailsLength={userDetailsLength}
                  invoiceData={userDetails?.invoices ? userDetails.invoices : []}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Page>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  activeButton: {
    padding: '1px 4px',
    marginLeft: '1rem',
    border: 'none',
    fontSize: '0.8rem',
    '&:hover': {
      border: 'none',
      fontSize: '0.8rem',
      cursor: 'auto',
    },
  },
  headerName: {
    color: 'rgb(104 115 133)',
    fontWeight: 400,
  },
  nameAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  maindiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableHeader: {
    fontWeight: '550',
  },
  textAlign: {
    marginRight: '3rem',
  },
  subdiv: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  subsciptionName: {
    fontSize: '1.1rem',
    fontWeight: '400',
  },
  subscriptionid: {
    fontWeight: 400,
    color: 'grey',
  },
}));
