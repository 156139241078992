import { Keyboard } from '@material-ui/icons';
import client from '../apiClient';

const getCaseStudyFiles = (courseId) => client.get(`/caseStudies/uploadFile?courseId=${courseId}`);

const uploadCaseStudyFiles = (courseId, data, contentType) =>
  client.post(`/caseStudies/uploadFile?courseId=${courseId}&&contentType=${contentType}`, JSON.stringify(data));

const getScreenForCaseStudies = (courseId) => client.get(`/caseStudies/screen?courseId=${courseId}`);

const deleteCaseStudiesFiles = (caseStudiesId) =>
  client.delete(`/caseStudies/uploadFile?caseStudiesId=${caseStudiesId}`);

const lookup = (key) => client.get(`/lookup?key=${key}`);

const MarksTypeLookup = (key) => client.get(`/lookup?key=${key}`);

const CreateConfigurationForCaseStudies = (courseId, data) =>
  client.post(`/caseStudies/configuration?courseId=${courseId}`, data);

const deleteScreensForCaseStudies = (screenId) => client.delete(`/caseStudies/screen?screenId=${screenId}`);

const getCourseModule = (courseId) => client.get(`/course/lookup/module?courseId=${courseId}`);

const getCourseSubModule = (courseModuleId) =>
  client.get(`/course/lookup/module/submodule?courseModuleId=${courseModuleId}`);

const CreateScreenForCaseStudies = (courseId, data) => client.post(`/caseStudies/screen?courseId=${courseId}`, data);

const getScreenDataByID = (courseId, screenId) =>
  client.get(`/caseStudies/screen?courseId=${courseId}&screenId=${screenId}`);

const updateScreenForCaseStudies = (courseId, screenId, data) =>
  client.put(`/caseStudies/screen?screenId=${screenId}&courseId=${courseId}`, data);

const getConfigurationForCaseStudies = (courseId) => client.get(`/caseStudies/configuration?courseId=${courseId}`);

const UpdateConfigurationForCaseStudies = (configurationId, data) =>
  client.put(`/caseStudies/configuration?configurationId=${configurationId}`, data);

const updateCaseStudiesFileName = (caseStudiesId, data) =>
  client.patch(`/caseStudies/fileName?caseStudiesId=${caseStudiesId}`, data);

const updateConfigurationStatus = (configurationId) =>
  client.patch(`/caseStudies/configurationStatus?configurationId=${configurationId}`);

export default {
  getCaseStudyFiles,
  uploadCaseStudyFiles,
  getScreenForCaseStudies,
  deleteCaseStudiesFiles,
  lookup,
  MarksTypeLookup,
  CreateConfigurationForCaseStudies,
  deleteScreensForCaseStudies,
  getCourseModule,
  getCourseSubModule,
  CreateScreenForCaseStudies,
  getScreenDataByID,
  updateScreenForCaseStudies,
  getConfigurationForCaseStudies,
  UpdateConfigurationForCaseStudies,
  updateCaseStudiesFileName,
  updateConfigurationStatus,
};
