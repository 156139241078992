import PropTypes from 'prop-types';

// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import { Box, Collapse, Typography } from '@mui/material';
import Iconify from '../../components/Iconify';

function DefaultNavbarDropdown({ name, icon, children, collapseStatus, href, route, collapse, ...rest }) {
  const linkComponent = {
    component: 'a',
    href,
    target: '_blank',
    rel: 'noreferrer',
  };

  const routeComponent = {
    component: Link,
    to: route,
  };

  return (
    <>
      <Box
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="self-end"
        opacity={1}
        sx={{ cursor: 'pointer', userSelect: 'none', textDecoration: 'none', color: '#667085' }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        <Typography
          variant="button"
          textTransform="capitalize"
          // color="white"
          sx={{ fontWeight: '500', ml: 2, mr: 0.5, fontSize: '1rem', lineHeight: 2 }}
        >
          {name}
        </Typography>
        <Typography variant="body2" ml="auto">
          {collapse && <Iconify icon="bx:chevron-down" sx={{ fontSize: '1.3rem' }} />}
        </Typography>
      </Box>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default DefaultNavbarDropdown;
