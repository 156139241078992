import React from 'react';
import { Breadcrumbs, Grid, Typography, Container, Button, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from '@mui/styles';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import Cards from '../../components/cards/UserCard';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';

export default function Index() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleCardClickItem = (data) => {
    navigate('/auth/my-course-details', { state: data.id });
  };

  const courseList = [
    {
      Image: '',
      title: 'ccc',
      description: '',
      category: '',
      logo: '',
      author: '',
    },
    {
      Image: '',
      title: 'ccc',
      description: '',
      category: '',
      logo: '',
      author: '',
    },
    {
      Image: '',
      title: 'cccc',
      description: '',
      category: '',
      logo: '',
      author: '',
    },
    {
      Image: '',
      title: 'ccc',
      description: '',
      category: '',
      logo: '',
      author: '',
    },
  ];

  return (
    <>
      <Page>
        <PageHeader pageTitle="Courses" />
        <Container maxWidth={false} sx={{ padding: '0 !important' }}>
          <Grid container spacing={3}>
            {courseList.map((item) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
                    <div className={classes.cardHover}>
                      <Cards
                        handleCardClick={() => handleCardClickItem(item)}
                        image={item.thumbImage}
                        title={item.title}
                        description={item.description}
                        category={item.category}
                        logo={item.category.categoryImgUrl}
                        author={item.author}
                      />
                    </div>
                  </Grid>
                </>
              );
            })}
            {courseList.length === 0 && (
              <Typography marginTop={4} variant="h6" textAlign="center" sx={{ width: '100%' }}>
                No Data
              </Typography>
            )}
          </Grid>
        </Container>
      </Page>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  cardHover: {
    transition: 'all .5s ease-out',
    '&:hover': {
      boxShadow: '0 12px 20px 0 rgb(32 40 45 / 8%)',
      transition: 'transform .5s',
      transform: 'translateY(-5px)',
    },
  },
}));
