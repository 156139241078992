import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';

function ImportSubModuleDialog({ open, onClose, onSubmit, courseId, userId, onImportSuccess }) {
  const [courses, setCourses] = useState([]);
  const [loadingbtn, setLoadingbtn] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedSubModuleIds,setSelectedSubModuleIds]=useState([]);
  const [loadingModules, setLoadingModules] = useState(false);
 
  useEffect(() => {
    if (open) {
      fetchCourses(courseId);
    } else {
      setSelectedCourse('');
      setSelectedModules([]);
    }
  }, [open],[selectedSubModuleIds]);

  const fetchCourses = async (courseId) => {
    try {
      const key = ['course'];
      const result = await adminServices.getLookUpData(key);
      if (result.ok) {
        let coursesArray = Object.values(result.data.course);
        
        // Filter out the course with the given courseId
        if (courseId) {
          coursesArray = coursesArray.filter(course => course.id !== courseId);
        }
        
        // Sort coursesArray in ascending order by course title
        coursesArray.sort((a, b) => {
          // Ensure title property exists before accessing it
          const titleA = (a.title || '').toLowerCase();
          const titleB = (b.title || '').toLowerCase();
          return titleA.localeCompare(titleB);
        });
        setCourses(coursesArray);
      } else {
        console.log('Error fetching courses:', result.error);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };
  
  const fetchModules = async (fromCourseId) => {
    try {
      setLoadingModules(true);
      const result = await adminServices.getCourseModule(fromCourseId);
      if (result.ok) {
        setSelectedModules(result.data.map(module => ({ ...module, checked: false })));
      } else {
        console.log('Error fetching modules:', result.error);
      }
    } catch (error) {
      console.error('Error fetching modules:', error);
    } finally {
      setLoadingModules(false);
    }
  };

  const handleCourseChange = (fromCourseId) => {
    setSelectedCourse(fromCourseId);
    fetchModules(fromCourseId);
  };
  const handleCheckboxChange = (moduleId) => {
    setSelectedModules(prevSelectedModules => 
      prevSelectedModules.map(module => 
        module.id === moduleId ? { ...module, checked: !module.checked } : module
      )
    );
  };

  const handleModuleCheckboxChange = (moduleId) => {
    setSelectedModules(prevSelectedModules =>
      prevSelectedModules.map(module =>
        module.id === moduleId ? { ...module, checked: !module.checked } : module
      )
    );
  };
  const handleSubModuleCheckboxChange = (moduleId, subModuleId) => {
    const allSubModules = selectedModules.flatMap(module => module.subModule || []);
  
    const selectedSubModules = allSubModules.reduce((accumulator, subModule) => {
      if (subModule.id === subModuleId) {
        // Toggle the checked property
        subModule.checked = !subModule.checked;
      }
      if (subModule.checked) {
        accumulator.push(subModule.id);
      }
      return accumulator;
    }, []);
    setSelectedSubModuleIds(selectedSubModules); 
  };
  
  const handleSubmit = async (fromCourseId) => {
    const checkedModulesArray = [];
    selectedModules.forEach(obj => {
      if (obj.checked) {
        checkedModulesArray.push(obj.courseModuleName);
      }
    });
    
    try {
      setLoadingModules(true); 
      const moduleData = {
        modulesArray: checkedModulesArray,
        submoduleIds: selectedSubModuleIds
      };
      
      if (selectedSubModuleIds.length === 0 || checkedModulesArray.length === 0) {
        window.alert('Please select the modules and its submodules');
        setLoadingbtn(false);
        return; 
      }
  
      const res=await adminServices.importModules(courseId, fromCourseId, userId, moduleData);
      setLoadingbtn(true);
      if(res.ok){
      onImportSuccess();
      onClose();
      setSelectedCourse('');
      setSelectedModules([]);
      setLoadingbtn(false);
      }
      else
      {
        setOpenSnackbar(true);
        setSnackbarTitle(res.data.message);
        setLoadingbtn(false);
      }
    } catch (error) {
      console.error('Error importing sub modules:', error);
    } finally {
      setLoadingModules(false); 
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{ style: { maxHeight: '80vh', height: '80vh', overflowY: 'scroll' } }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-10px', marginRight: '-10px' }}>
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ marginBottom: '10px', marginTop: '.5cm', textAlign: 'center', overflowY: 'scroll' }}>
      {!selectedCourse && (
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          Select a course to import
        </Typography> )}
        {!selectedCourse && (
      <Select
        value={selectedCourse}
        onChange={(e) => handleCourseChange(e.target.value)}
        fullWidth
        style={{ width: '200px' }}
        disabled={!!selectedCourse}
      >
        {courses
          .filter(course => course.type!== 'video')
          .map(filteredCourse => (
            <MenuItem key={filteredCourse.id} value={filteredCourse.id}>
              {filteredCourse.title}
            </MenuItem>
          ))}
     </Select>)}
      </div>

       {!loadingModules && selectedModules && selectedModules.length > 0 ? (
    <div>
      <Typography variant="h5" style={{ marginBottom: '10px', textAlign: 'center' }}>
      {courses.find(course => course.id === selectedCourse)?.title}
      </Typography>
      <ul style={{ listStyleType: 'none', marginLeft: '.5cm', padding: 0, textAlign: 'left' }}>
      <Typography variant="body1" style={{ color: 'black', fontStyle: 'italic', fontSize: 'small' }}>
              **Please select required modules and its submodules**
      </Typography>
        {selectedModules.map((module, index) => (
          <div key={module.id}>
            <Typography variant="body1" style={{ color: 'orange', fontWeight: 'bold' }}>
              Module {index + 1}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={module.checked}
                  onChange={() => handleCheckboxChange(module.id)}
                  style={{ color: 'orange' }} // Orange color for modules
                />
              }
              label={module.courseModuleName}
              style={{ color: 'orange', }} // Orange color for module labels
            />

            {module.subModule && module.subModule.length > 0 && (
              <ul style={{ marginLeft: '1cm', textAlign: 'left' }}>
                {module.subModule.map((subModule) => (
                  <li key={subModule.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!subModule.checked} // Set the checked state of submodules
                          onChange={() => handleSubModuleCheckboxChange(module.id, subModule.id)} // Handle submodule checkbox change
                          style={{ color: 'blue' }}
                        />
                      }
                      label={subModule.courseSubmoduleName}
                      style={{ color: 'blue' }}
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </ul>
    </div>
  ) : (
    <p>  </p>
  )}
  {selectedCourse && (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button type='submit' onClick={() => handleSubmit(selectedCourse)} disabled={!selectedCourse || loadingModules}>
        {loadingModules ? 'Loading...' : 'Submit'}
      </Button>
      <Button type='submit' onClick={onClose} style={{ marginLeft: '10px' }} disabled={loadingModules}>Cancel</Button>
    </div>
  )}
</Dialog>

  );
}

export default ImportSubModuleDialog;
