import React from 'react';

export default function Tab(theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '50px !important',
        },
        indicator: {
          bottom: '10px',
        },
        scroller: {
          [theme.breakpoints.down(514)]: {
            overflowX: 'scroll !important',
          },
        },
      },
    },
  };
}
