
import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { TextField, Button, Chip, Box, Paper, Typography, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Autocomplete from '@mui/material/Autocomplete';
// import Select from 'react-select';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import DOMPurify from 'dompurify';
import DialogModal from '../../../components/modal/DialogModal';
import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';

const SubModuleAssessment = ({ open,onClose,submoduleId,submitForm })=> {
  const [loading, setLoading] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [hashtag, setHashtag] = useState([]);
  const [category, setCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState('');

  const [nodata, setNodata] = useState(true);
  
  useEffect(async () => {
    await getLookUpDetails();
  }, []);

  useEffect(() => {
    setLoading(false)
  }, [open]);

  // console.log(questionList, "questionListquestionList");

  const handleAddTag = () => {
    if (searchTerm && !tags.includes(searchTerm)) {
      setTags([...tags, searchTerm]);
      getQuestionList([...tags, searchTerm]);
      setIsSearched(true);
    }
    setSearchTerm("");
  };
 
  const handleRemoveTag = (index) => {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    setNodata(true)
    // filterQuestions(newTags);
    // getQuestionList(newTags);
    console.log(searchTerm,"searchTermsearchTerm");
    console.log(newTags,"newTags");
    
    if(newTags?.length > 0){
      getQuestionList([...newTags]);
    }
    else{
      setQuestionList([])
    }

    setIsSearched(false);
  };
 

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
 
  const handleAddQuestion = (question) => {
    if (!selectedQuestions.some(q => q.id === question.id)) {
      setSelectedQuestions([...selectedQuestions, question]);
    }
  };
 
  const handleRemoveQuestion = (question) => {
    setSelectedQuestions(selectedQuestions.filter(q => q.id !== question.id));
  };
 

  const getQuestionList = async (data) => {
    setNodata(false)
    const result = await adminServices.getQuestionData(data);
    if (result.ok) {
      if(result.data?.length === 0){
        setNodata(true)
      }
      else{
        setQuestionList(result.data)
        setNodata(false)
      }

    }
  };


  const getLookUpDetails = async () => {
    const key = ['hashtag', 'course_category'];
    const result = await adminServices.getLookUpData(key);
    if (result.ok) {
      setHashtag(result.data.hashtag);
      setCategory(result.data.course_category);
    }
  };


  const handleSubmit = async() => {
      setLoading(true);
      const filteredquestions = selectedQuestions.map((data) => data.id)        
      try {
        const response = await adminServices.createCourseAssessMent(submoduleId,filteredquestions);
     if(response.ok){
      setSnackbarTitle('Assignment created successfully');
      setOpenSnackbar(true);
      CloseFunction()
     }
     
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

const CloseFunction = () => {
  setQuestionList([])
  setSelectedQuestions([])
  setTags([])
  onClose()
  setIsSearched(false);
}

  return (
    <>
        <DialogModal open={open} handleClose={CloseFunction} title="Assessment Form for Sub Module">

      {/* <Box sx={{p: 3,pl: '20%',bgcolor: "#e0e7ff",borderRadius: 2,width: '60%',minHeight: '500px',maxHeight: '500px'}}> */}
      {/* <Box
  sx={{
    p: 3,
    bgcolor: "#f7f7f7",
    borderRadius: 2,
    width: '80%',
    minHeight: '500px',
    maxHeight: '500px',
    mx: 'auto', 
    mt: 2, 
    ml: '18%', // Left margin for 16% space
    mr: '2%', // Right margin for 4% space
    boxSizing: 'border-box', // Ensure padding and borders are included in the width
  }}
>  */}
{selectedQuestions.length > 0 && (
  <>
     <Typography
     variant="body2"
     sx={{ flexGrow: 1, fontSize: '0.875rem', textDecoration: 'underline' }}
   >
     Selected Questions
   </Typography>
  <Paper
    sx={{
      mb: 2,
      p: 1,
      bgcolor: "#e3faf4",
      // bgcolor: "#cfe0ff",
      width: '100%',
      maxHeight: selectedQuestions.length > 4 ? '150px' : 'auto',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#c1c1c1',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a1a1a1',
      },
      scrollbarWidth: 'thin',
      scrollbarColor: '#c1c1c1 #f1f1f1',
    }}
  >
 
    {selectedQuestions.map((question) => (
      <Box
        key={question.id}
        sx={{ display: "flex", alignItems: "center", mb: 1 }}
      >
        {/* <Typography
          variant="body2"
          sx={{ flexGrow: 1, fontSize: '0.75rem' }}
        >
          {question.question_text}
        </Typography> */}
          <Typography
                      id='questionviewedit1'
                      variant="body2"
                      sx={{
                        flexGrow: 1,
                        fontSize: '0.75rem',
                      }}
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question_text) }}
                    />
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleRemoveQuestion(question)}
          sx={{
            fontSize: '0.75rem',
            minWidth: '24px',
            minHeight: '24px',
            padding: '2px',
            borderRadius: '12px',
            '& .MuiButton-label': { fontSize: '1rem' },
          }}
        >
          x
        </Button>
      </Box>
    ))}
  </Paper>
  </>
)}
 
 
 
      <Paper sx={{ mb: 2, p: 1, bgcolor: "#f7f7f7", width: '100%' }}>
        <Box sx={{ display: "flex", mb: 1 }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search Questions..."
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              bgcolor: "#f0f0f0",
              borderRadius: 1,
              height: 36,
              '& .MuiInputBase-input': {
                fontSize: 14,
                padding: "8px 12px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleAddTag}
                    sx={{ p: 0, color: "#1976d2" }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, mb: 1 }}>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onDelete={() => handleRemoveTag(index)}
              color="primary"
              size="small"
              sx={{ fontSize: 10 }}
            />
          ))}
        </Box>
 
        {isSearched && questionList?.length === 0 ? (
          <Typography variant="body2" sx={{ textAlign: 'center', color: 'text.secondary' }}>
             {nodata === false ? "loading...": "No records found"}
             </Typography>
        ) : (
 
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: questionList.length > 4 ? '140px' : 'auto',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#c1c1c1',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#a1a1a1',
            },
            scrollbarWidth: 'thin',
            scrollbarColor: '#c1c1c1 #f1f1f1',
          }}
        >
         {questionList?.length > 0 && questionList.map((question) => {
  const isSelected = selectedQuestions.some((selected) => selected.question_text === question.question_text);

  return (
    <Box
      key={question.id}
      sx={{ display: "flex", alignItems: "center", mb: 1 }}
    >
      {/* <Typography
       id='questionview'
        variant="body2"
        sx={{
          flexGrow: 1,
          fontSize: '0.75rem',
          bgcolor: isSelected ? "#cfe0ff" : "transparent", 
          padding: '4px', 
          borderRadius: '4px', 
        }}
      >
        {question.question_text}
      </Typography> */}
           <Typography
  id='questionview'
  variant="body2"
  sx={{
    flexGrow: 1,
    fontSize: '0.75rem',
    bgcolor: isSelected ? "#cfe0ff" : "transparent",
    padding: '4px',
    borderRadius: '4px',
  }}
  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question_text) }}
/>
      <Button
       id='questionadd'
        variant="outlined"
        color="primary"
        onClick={() => handleAddQuestion(question)}
        sx={{
          fontSize: '0.75rem',
          minWidth: '24px',
          minHeight: '24px',
          padding: '2px',
          borderRadius: '12px',
          '& .MuiButton-label': { fontSize: '1rem' },
        }}
      >
        +
      </Button>
    </Box>
  );
})}

        </Box>
       
 
        )}
      </Paper>
 
 
<Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 ,marginLeft:1.4}}>
  {/* <Button onClick={CloseFunction}
    variant="outlined"
    color="secondary"
    sx={{
      height: 32,
      minWidth: 10,
      fontSize: '0.75rem',
      padding: '0 12px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      marginRight:1
    }}
  >
    Cancel
  </Button> */}
  {selectedQuestions && selectedQuestions?.length > 0 &&
  // <Button onClick={handleSubmit}
  //   variant="contained"
  //   id="submitquestion"
  //   color="primary"
  //   sx={{ height: 32,
  //     minWidth: 10,
  //     fontSize: '0.75rem',
  //     padding: '0 12px',
  //     borderTopLeftRadius: 0,
  //     borderBottomLeftRadius: 0,

  //   }}
  // >
  //   Submit
  // </Button>

  <LoadingButton
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  fullWidth
                  loading={loading}
                >
                  Submit
                </LoadingButton>
  }
</Box>

    </DialogModal>
    <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />

</>

  );
}
export default SubModuleAssessment;