import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------
const layoutStyle = {
  paddingLeft: '20px !important',
  paddingRight: '20px !important',
  paddingBottom: '16px !important',
  paddingTop: '84px !important',
  '@media (min-width:1280px)': {
    paddingTop: '84px !important',
  },
};

const Page = forwardRef(({ children, title = '', description='', meta, style = layoutStyle, ...other }, ref) => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>{`${title} | keySkillset`}</title>
        <meta name="description" content={`${description}`} />
        {meta}
      </Helmet>

      <Box ref={ref} sx={style} {...other}>
        <Container maxWidth={location.pathname === '/login' ? false : 'xl'} sx={{ padding: '0px !important' }}>
          {children}
        </Container>
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
