/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Typography, Chip, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { TroubleshootOutlined } from '@mui/icons-material';

const ExpiredSubscriptions = ({ ExpiredData, loading, getExpiredSubscriber, pageCount }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchKey, setSearchKey] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterValue, setFilterValue] = useState('');

  const handleClick = (rowData) => {
    console.log(rowData, 'rowData');
    navigate('/app/user-details', {
      state: {
        userPlanId: rowData[0],
      },
    });
  };

  const columns = [
    {
      name: 'userPlanId',
      label: 'userPlanId',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'email',
      label: 'Customer',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              <Typography>
                <Link
                  className={classes.customerLink}
                  component={RouterLink}
                  to="/app/user-details"
                  state={{ userPlanId: tableMeta.rowData[0] }}
                >
                  {value}
                </Link>
              </Typography>
            </>
          ) : (
            ''
          );
        },
      },
    },

    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const Capitalize = value.toLowerCase();
          return value ? (
            <>
              {
                <Typography
                  sx={{
                    width: 'min-content',
                    fontWeight: '400',
                    lineHeight: 'unset',
                    backgroundColor: 'red',
                    textAlign: 'center',
                    borderRadius: '4px',
                    padding: '2px 8px',
                    fontSize: '0.9rem',
                    color: 'white',
                  }}
                >
                  {Capitalize.charAt(0).toUpperCase() + Capitalize.slice(1)}
                </Typography>
              }
            </>
          ) : (
            ''
          );
        },
      },
    },

    {
      name: 'planType',
      label: 'planType',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'subscriptionPlanName',
      label: 'Plan',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            <>
              <Typography>{value}</Typography>
              <Chip label={tableMeta.rowData[3]} size="small" />
            </>
          ) : (
            ''
          );
        },
      },
    },

    {
      name: 'createDateTime',
      label: 'Created on',
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? <Typography className={classes.dateWidth}>{moment(value).format('lll')}</Typography> : '';
        },
      },
    },
  ];

  return (
    <div>
      <div style={{ marginTop: '1rem' }}>
        <ThemeProvider theme={customTheme}>
          <MUIDataTable
            data={ExpiredData}
            columns={columns}
            options={{
              responsive: 'scroll',
              pagination: true,
              filter: false,
              download: true,
              print: false,
              jumpToPage: true,
              viewColumns: false,
              page,
              search: true,
              count: pageCount,
              serverSide: true,
              rowsPerPage,
              rowsPerPageOptions: [10, 25, 50, 75, 100],
              selectableRows: false,
              onRowClick: (rowData) => {
                handleClick(rowData);
                console.log(rowData, 'rowData');
              },
              textLabels: {
                body: {
                  noMatch: loading ? 'Loading...' : 'Sorry, there is no matching data to display',
                },
              },
              onTableChange: (action, tableState) => {
                if (action === 'changeRowsPerPage') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getExpiredSubscriber(
                    'EXPIRED',
                    tableState.page + 1,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                } else if (action === 'changePage') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getExpiredSubscriber(
                    'EXPIRED',
                    tableState.page + 1,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                } else if (action === 'search') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getExpiredSubscriber(
                    'EXPIRED',
                    tableState.page,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                } else if (action === 'sort') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getExpiredSubscriber(
                    'EXPIRED',
                    tableState.page,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                } else if (action === 'filterChange') {
                  setRowsPerPage(tableState.rowsPerPage);
                  setPage(tableState.page);
                  setSortKey(tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '');
                  setSearchKey(tableState.searchText !== null ? tableState.searchText : '');
                  setSortOrder(tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '');
                  getExpiredSubscriber(
                    'EXPIRED',
                    tableState.page,
                    tableState.rowsPerPage,
                    tableState.sortOrder.direction !== undefined ? tableState.sortOrder.direction : '',
                    tableState.sortOrder.name !== undefined ? tableState.sortOrder.name : '',
                    tableState.searchText !== null ? tableState.searchText : ''
                  );
                }
              },
            }}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dateWidth: {
    width: '110px',
    fontSize: '0.95rem',
  },
  customerLink: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: '#000',
    },
  },
}));

const customTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px !important',
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        sortActive: {
          fontFamily: 'Inter,SemiBold',
        },
        data: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        iconActive: {
          color: '#FE7000',
          '&:hover': {
            color: '#FE7000',
          },
        },
        icon: {
          '&:hover': {
            color: '#FE7000',
          },
        },
      },
    },
    MUIDataTableSearch: {
      styleOverrides: {
        clearIcon: {
          '&:hover': {
            color: '#FE7000',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ':after': {
            borderBottom: '2px solid #FE7000',
          },
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
  },
});

export default ExpiredSubscriptions;
