import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import SubCard from '../../../../components/cards/SubCard';
import BasicPaper from '../../../../components/cards/BasicPaper';

export default function ClientInfo(props) {
  const classes = useStyles();

  return (
    <div style={{ marginTop: '1rem' }}>
      <>
        <BasicPaper
          children={
            <Grid container spacing={4} sx={{ mt: 1 }}>
              <Grid className={classes.girdContainer} item xs="12">
                {props.clientDetials.logo && (
                  <div className={classes.logo}>
                    <img width={'90px'} src={props.clientDetials.logo} alt="logo" />
                  </div>
                )}

                <div>
                  <Typography variant="h6">{props.clientDetials.organizationName}</Typography>
                  <Typography>{props.clientDetials.contactEmail}</Typography>
                </div>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Office Phone</span>
                </Typography>
                <Typography>{props.clientDetials.officeNumber}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Website Url</span>
                </Typography>
                <Typography>{props.clientDetials.websiteUrl}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Contact Name</span>
                </Typography>
                <Typography>{props.clientDetials.contactName}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Contact Phone</span>
                </Typography>
                <Typography>{props.clientDetials.contactPhone}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Date Format</span>
                </Typography>
                <Typography>{props.clientDetials.dateFormat && props.clientDetials.dateFormat.description}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Time Zone</span>
                </Typography>
                <Typography>{props.clientDetials.timeZone && props.clientDetials.timeZone.description}</Typography>
              </Grid>
            </Grid>
          }
        />

        <SubCard
          title="Address"
          subtitle="View all of the addresses"
          children={
            <Grid style={{ padding: '1rem' }} container spacing={4}>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Address1</span>
                </Typography>
                <Typography>{props.clientDetials.address1}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Address2</span>
                </Typography>
                {props.clientDetials.address2 && props.clientDetials.address2 !== null ? (
                  <Typography>{props.clientDetials.address2}</Typography>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs="12" sm="12" md="4" />
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>Country</span>
                </Typography>
                <Typography>{props.clientDetials.country && props.clientDetials.country.description}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>State</span>
                </Typography>
                <Typography>{props.clientDetials.state && props.clientDetials.state.description}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4" />
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>City</span>
                </Typography>
                <Typography>{props.clientDetials.city}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4">
                <Typography>
                  <span className={classes.name}>ZipCode</span>
                </Typography>
                <Typography>{props.clientDetials.zipCode}</Typography>
              </Grid>
              <Grid item xs="12" sm="12" md="4" />
            </Grid>
          }
        />
      </>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '1rem',
    border: '1px solid #ECECEC',
    boxShadow: '0px 3px 30px #0000000A',
    borderRadius: '10px',
  },
  addressCard: {
    border: '1px solid #ECECEC',
    boxShadow: '0px 3px 30px #0000000A',
    borderRadius: '10px',
  },
  name: {
    fontSize: '14px',
    color: '#0C0A09',
    fontWeight: '600',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FBFBFB',
    border: '1px solid #ECECEC',
    padding: '1rem',
  },
  girdContainer: {
    display: 'flex',
  },
  logo: {
    border: '1px solid lightgrey',
    borderRadius: '10px',
    marginRight: '3rem',
    padding: '1rem',
  },
}));
