import React, { useEffect, useState } from 'react';
import {
  Box,
  Badge,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Form, Formik } from 'formik';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LinearProgressBar from '../progress/LinearProgressBar';

import DialogModal from '../modal/DialogModal';
import ErrorFocus from '../ErrorFocus/ErrorFocus';

const CreateForm = ({
  open,
  modelTitle,
  closeModel,
  textFieldName,
  handleSubmitForm,
  modelWidth,
  buttonText,
  isAuthor,
  loading,
  data,
  type,
  label,
  progress,
  handleVideoDelete,
  formType = 'MODULE',
  formCategory,
  courseType
}) => {
  const classes = useStyles();
  const [initialValue, setInitialValue] = useState({
    courseTitle: '',
    isTrial: false,
    videoFile: null,
    videoFilePreview: null,
  });
  const [isContainVideo, setIsContainVideo] = useState(false);

  useEffect(() => {
    if (data) {
      
      setInitialValue({
        courseTitle: data.courseTitle,
        isTrial: data.isTrial,
        videoFilePreview: data.videoFilePreview,
        videoFile: null,
      });
      setIsContainVideo(data.videoFilePreview && true);
    } else {
      console.log("course details",isAuthor)
      setInitialValue({ courseTitle: '', isTrial: false, videoFilePreview: null, videoFile: null });
    }
  }, [data]);

  return (
    <DialogModal open={open} title={modelTitle} handleClose={closeModel} maxWidth={modelWidth}>
      <Formik
        enableReinitialize
        initialValues={initialValue}
        validationSchema={Yup.object().shape({
          courseTitle: Yup.string().trim().required('Required'),
          videoFile: Yup.mixed().when('videoFilePreview', {
            is: (videoFilePreview) => videoFilePreview !== null,
            then: Yup.mixed()
              .nullable()
              .test(
                'fileSize',
                'Maximum file size is 500MB',
                // (value) => value && console.log(value)
                (value) => (value && typeof value === 'object' && value.size < 500000000) || true
              ),

            otherwise: Yup.mixed().nullable(),
          }),
        })}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <FormControl fullWidth variant="outlined">
                  <Typography gutterBottom>{textFieldName}</Typography>
                  <OutlinedInput
                    error={Boolean(touched.courseTitle && errors.courseTitle)}
                    id="outlined-adornment-weight"
                    value={values.courseTitle}
                    name="courseTitle"
                    type="text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    fullWidth
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{ 'aria-label': 'courseTitle' }}
                  />
                  <ErrorFocus />
                  <FormHelperText sx={{ color: '#FF4842' }}>{touched.courseTitle && errors.courseTitle}</FormHelperText>
                </FormControl>
              </Grid>

              {formType === 'MODULE' && formCategory ==='subModule' &&  courseType === 'simulation'  && (
                <Grid item xs={12} sm={12}>
                  {values.videoFilePreview === null ? (
                    <FormControl
                      required
                      component="fieldset"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      name="videoFile"
                      value={values.videoFile}
                    >
                      <Typography variant="subtitle1">Video (optional)</Typography>
                      <DropzoneArea
                        acceptedFiles={['video/*']}
                        showPreviews={false}
                        dropzoneText="Drag and Drop Video or Browse File"
                        showPreviewsInDropzone={false}
                        maxFileSize={104857600}
                        filesLimit={1}
                        showAlerts={false}
                        value={values.videoFile}
                        onChange={(file) => {
                          setFieldValue('videoFile', file[0]);
                          if (file[0]?.size < 500000000) {
                            const url = URL.createObjectURL(file[0]);
                            setFieldValue('videoFilePreview', url);
                          }
                        }}
                        useChipsForPreview
                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                        showFileNamesInPreview
                      />
                      <FormHelperText style={{ color: '#F44336' }}>
                        {touched.videoFilePreview && errors.videoFile}
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    <div className={classes.imgPreviewRoot}>
                      <Typography variant="subtitle1">Video</Typography>
                      <Badge
                        // sx={{ paddingLeft: '3rem' }}
                        badgeContent={
                          <Box sx={{ paddingLeft: '2rem !important' }}>
                            <Tooltip title="Change video" arrow placement="top">
                              <IconButton
                                sx={{ marginLeft: '1.5rem' }}
                                size="small"
                                onClick={() => {
                                  setFieldValue('videoFilePreview', null);
                                }}
                                color="primary"
                              >
                                <CloseIcon fontSize="small" className={classes.badgeAlign} />
                              </IconButton>
                            </Tooltip>

                            {buttonText === 'Update' && isContainVideo && (
                              <Tooltip title="Delete video" arrow placement="top">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleVideoDelete().then((res) => {
                                      if (res) {
                                        setFieldValue('videoFilePreview', null);
                                        setIsContainVideo(false);
                                      }
                                    });
                                  }}
                                  color="primary"
                                >
                                  <DeleteOutlineIcon fontSize="small" className={classes.badgeAlign} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        }
                      >
                        <video width="320" controls>
                          <source src={values.videoFilePreview} type="video/mp4" />
                          <track src="captions_en.vtt" kind="captions" label="english_captions" />
                        </video>
                      </Badge>
                      <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                        {touched.videoFilePreview && errors.videoFile}
                      </Typography>
                    </div>
                  )}
                </Grid>
              )}

              {type === 'createTopic' ? (
                ' '
              ) : (
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isTrial}
                        value={values.isTrial}
                        onChange={(e, value) => {
                          setFieldValue('isTrial', value);
                        }}
                        id="isTrial"
                        name="isTrial"
                        color="primary"
                      />
                    }
                    label={label}
                  />
                </Grid>
              )}

              {isAuthor && <Grid item sm={12}>
                {loading && values.videoFile ? (
                  <LinearProgressBar value={progress} />
                ) : (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    type="submit"
                    loading={loading}
                  >
                    {buttonText ? `${buttonText}` : 'Create'}
                  </LoadingButton>
                )}
              </Grid>}
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogModal>
  );
};

const useStyles = makeStyles((theme) => ({
  class: {
    padding: '2px',
  },
}));

export default CreateForm;
