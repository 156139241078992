import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DialogModal = ({ open, handleClose, children, title, maxWidth }) => {
  return (
    <Dialog
      // fullWidth
      // maxWidth={'lg'}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="responsive-dialog-title">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
          <IconButton
            size="small"
            onClick={handleClose}
            sx={{ right: '-12px', top: '-4px', backgroundColor: '#FFE7D5', color: '#FE7000' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '12px !important' }}>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogModal;
