/* eslint no-var: off */

import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const CaseStudyTestTimer = ({ testRemainTime, updateTestStatus, totalCount, isStartedTest, countDown, isFinished }) => {
  const classes = useStyles();
  var i = null;
  const Ref = useRef(null);
  // The state for our timer
  let rTime = testRemainTime;
  const [totalCountdown, setTotalCountdown] = useState(60);
  const [timeProgress, setTimeProgress] = useState(0);
  const [timeOver, setTimeOver] = useState(false);
  const [countDownTimer, setCountDownTimer] = useState(countDown);
  const [testCompleteTime, setTestCompleteTime] = useState(null);

  console.log(totalCount);

  useEffect(() => {
    setCountDownTimer(countDown);
    if (isStartedTest) {
      timedCount();
    }
    if (isFinished) {
      setTestCompleteTime(countDownTimer);
      clearTimeout(i);
    }
  }, [countDown, isStartedTest, isFinished]);

  const timedCount = () => {
    var hours;
    var minutes;
    var seconds;
    var dec = rTime;

    if (rTime < 0) {
      setTimeOver(true);
      updateTestStatus('TIME_EXPIRED');
      clearTimeout(i);
      return false;
    }

    hours = parseInt(rTime / 3600, 10) % 24;
    minutes = parseInt(rTime / 60, 10) % 60;
    seconds = rTime % 60;
    setCountDownTimer({
      hours: hours > 9 ? hours : `0${hours}`,
      minutes: minutes > 9 ? minutes : `0${minutes}`,
      seconds: seconds > 9 ? seconds : `0${seconds}`,
    });
    setTimeProgress((rTime / totalCount) * 100);
    console.log(timeProgress);
    rTime = dec - 1;

    i = setTimeout(async () => {
      await timedCount();
    }, 1000);
  };

  return (
    <>
      {!isFinished && (
        <CircularProgressbar
          value={timeProgress}
          text={`${countDownTimer.minutes}:${countDownTimer.seconds}`}
          strokeWidth={12}
          className={classes.progress}
          styles={buildStyles({
            textColor: '#1F78B4',
            textSize: '16px',
            pathColor: '#A6CEE3',
            trailColor: '#1F78B4',
          })}
        />
      )}

      {isFinished && testCompleteTime && (
        <CircularProgressbar
          value={
            ((parseInt(testCompleteTime.hours, 10) * 3600 +
              parseInt(testCompleteTime.minutes, 10) * 60 +
              parseInt(testCompleteTime.seconds, 10)) /
              totalCount) *
            100
          }
          text={`${testCompleteTime.minutes}:${testCompleteTime.seconds}`}
          strokeWidth={12}
          className={classes.progress}
          styles={buildStyles({
            textColor: '#1F78B4',
            textSize: '16px',
            pathColor: '#A6CEE3',
            trailColor: '#1F78B4',
          })}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  progress: {
    width: '42%',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '1rem',
  },
}));

export default CaseStudyTestTimer;
