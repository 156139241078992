import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Box, CardMedia, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import clsx from 'clsx';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Background from '../../assets/logo/background.svg';
import EssentialsBundleImg from '../../assets/course/MS-WS.jpg';
import NinjaBundleImg from '../../assets/course/Ninja.jpg';
import QBBundleImg from '../../assets/course/QB.jpg';


export default function BundleCard(props) {
  const classes = useStyles();
  const { userSubscribedCourses } = useSelector((state) => state);
  const [isTrial, setIsTrial] = useState(true);
  const navigate = useNavigate();
  const [isPaid, setisPaid] = useState(true);
  const [typeobj, setTypeobj] = useState("");

  const [isSubscribed, setIsSubscribed] = useState(true); const userRole = useSelector((state) => state.userInfo && state.userInfo.role);

  useEffect(() => {
    if (userSubscribedCourses.length > 0) {
      setIsTrial(true);
      setIsSubscribed(true);
      setisPaid(false);
      const obj = userSubscribedCourses.find((item) => item?.subscriptionPlanId === props?.subscriptionPlanId);
      if (obj !== undefined) {
        if (obj.isTrial === false && obj.isSubscribed === false) {
          setIsTrial(true);
          setIsSubscribed(true);
          console.log('Coming here in if..', obj.isSubscribed)
          setisPaid(false);
        } else {
          console.log('Coming here in else..', !obj.isSubscribed)
          setIsTrial(false);
          setIsSubscribed(!obj.isSubscribed);
          setisPaid(true);
        }
      }

      const videotype = props.courseobj.filter((data) => data.courseType === 'video')
      const simulationtype = props.courseobj.filter((data) => data.courseType === 'simulation')
     
      if (videotype?.length > 0 && simulationtype?.length > 0) {
        setTypeobj("Simulation + Video")
      }
      else if (videotype?.length <= 0 && simulationtype?.length > 0) {
        setTypeobj("Simulation")
      }
      else {
        setTypeobj("Video")
      }
  

    }
  }, [props]);

  return (
    <div style={{ display: 'flex' }}>
      <Card className={classes.cards}>
        <Box sx={{
          position: 'relative',
          backgroundColor: '#00B673',
          color: 'white',
          textAlign: 'center',
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
        >
          <Typography className={classes.cardTitle} variant="h6" component="div">
            

            
            {typeobj}
          </Typography>
        </Box>

        {/* <Typography className={classes.price}>$294/yr</Typography> */}
        {/* <Typography className={classes.course}>keySkillset Bundle</Typography> */}

        {/* <Typography className={classes.billed}>Billed Monthly</Typography> */}
        {/* <Typography className={classes.price}>$19.99/month</Typography> */}


        {/* {props?.subscriptionPlanId === 71 && (
          <>
            <CardMedia component="img" height="160" image={NinjaBundleImg} sx={{ objectFit: 'unset' }} alt="green iguana" />

            <Typography className={classes.course}>Dev Test Bundle</Typography>

          </>
        )} */}



        {props?.subscriptionPlanId === 77 && (
          <>
            <CardMedia component="img" height="160" image={NinjaBundleImg} sx={{ objectFit: 'unset' }} alt="green iguana" />
            <Typography className={classes.course}>Number Ninja Bundle</Typography>

          </>
        )}

        {props?.subscriptionPlanId === 83 && (
          <>
            <CardMedia component="img" height="160" image={EssentialsBundleImg} sx={{ objectFit: 'unset' }} alt="green iguana" />
            <Typography className={classes.course}>Workplace Essentials Package</Typography>

          </>
        )}

        {props?.subscriptionPlanId === 78 && (
          <>
            <CardMedia component="img" height="160" image={QBBundleImg} sx={{ objectFit: 'unset' }} alt="green iguana" />

            <Typography className={classes.course}>QuickBooks Online Bundle</Typography>

          </>
        )}

        {props?.subscriptionPlanId === 42 && (
          <>
            <Typography className={classes.course}>Keyskillset Bundle - EPF</Typography>
            <Typography className={classes.price}>$294</Typography>
          </>
        )}

        {props?.subscriptionPlanId === 28 && (
          <>
            <Typography className={classes.course}>Keyskillset Bundle</Typography>
            <Typography className={classes.price}>{props.price}</Typography>
          </>
        )}



        <Box sx={{ minHeight: '130px', display: 'flex', flexDirection: 'column' }}>
          <Tooltip
            title={
              <div>
                <div>{props?.courseobj.length > 0 && `${props?.courseobj.map(item => item.title.trim()).join(', ')}.`}</div>
              </div>
            }
            arrow
            placement="top"
          >
            <div className={classes.list}>
              <Typography className={classes.listItem}>
                {props?.courseobj.length > 0 && `${props?.courseobj.map(item => item.title.trim()).join(', ')}.`}
              </Typography>
            </div>
          </Tooltip>
          <Typography className={classes.price}>{props.price}</Typography>
        </Box>

        <div className={classes.buttonContainer}>
          {isSubscribed ? (
            <LoadingButton
              variant="outlined"
              className={classes.subscribeButton}
              loading={props.item.subscriptionPlanId === props.isSubscribeLoading}
              onClick={() => {
                props.handleSubcribe(props.item, props.subscriptionPlanId);
              }}
            >
              subscribe
            </LoadingButton>
          ) : (
            isPaid && (
              <Button
                variant="contained"
                color="success"
                className={`${classes.startCourseButton} ${classes.greenButton}`}
                onClick={() => {
                  console.log('Start Course button clicked', props.courseobj);
                  console.log('Start Course button clicked', props.subscriptionPlanId);
                  if (userRole === 'USER_DTC') {
                    navigate('/auth/my-courses', { state: props.courseobj });
                  } else {
                    navigate('/app/my-courses', { state: props.courseobj });
                  }
                }}
              >
                Go to Bundle
              </Button>
            )
          )}
        </div>

      </Card>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  cards: {
    border: '1px solid #E4E7EC',
    boxShadow: ' 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)',
    borderRadius: '6px',
    height: '380px',
    backgroundPosition: 'bottom right',
    '@media (max-width: 640px)': {

    },
  },
  price: {
    color: '#00B673',
    fontSize: '16px',
    fontFamily: 'plus jakarta sans',
    paddingLeft: '12px',
    marginTop: '4px',
    '@media (max-width: 640px)': {
      fontSize: '16px',
    },
  },
  course: {
    color: '#FE7000',
    fontSize: '15px',
    fontFamily: 'plus jakarta sans',
    fontWeight: 'bold',
    paddingLeft: '12px',
    paddingTop: '12px',
    margin: 'auto',
  },
  billed: {
    color: '#667085',
    fontWeight: '1rem',
    textAlign: 'center',
    margin: '5px 0px 10px 0px',
    fontSize: '1rem',
  },
  list: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  listItem: {
    color: '#212B36',
    fontSize: '12.8px',
    marginLeft: '12px',
    fontFamily: 'Inter, sans-serif',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    cursor: 'pointer',
    minHeight: '54px',
    maxHeight: '54px',
  },
  date: {
    marginTop: '15px',
  },
  trialButton: {
    width: 110,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  subscribeButton: {
    width: 110,
    color: '#00B673 !important',
    border: '1px solid #00B673 !important',
    fontSize: '12px',
    borderRadius: '6px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  button: {
    backgroundColor: '#F8F5F4',
    padding: '4px 6px',
    borderRadius: '6px',
    width: 'max-content',
  },
  title: {
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  logo: {
    boxShadow: '0px 3px 22px #00000029',
    padding: '4px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    marginTop: '10px',
  },
  description: {
    whiteSpace: 'nowrap',
    minHeight: '20px',
    fontSize: '0.8rem !important',
    overflow: 'hidden !important',
    fontFamily: 'Inter',
    textOverflow: 'ellipsis',
    '-o-text-overflow': 'ellipsis',
    '-ms-text-overflow': 'ellipsis',
    '-moz-binding': "url('ellipsis.xml#ellipsis')",
    '-ms-webkit-line-clamp': 2,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '& span': {
      fontSize: '0.8rem !important',
      color: 'black !important',
      backgroundColor: 'unset !important',
      fontFamily: 'Inter !important',
    },
    '& p': {
      '&:nth-child(1)': {
        display: 'block !important',
      },
      '&:nth-child(even)': {
        display: 'none ',
      },
      '&:nth-child(odd)': {
        display: 'none ',
      },
    },
  },
  enrolledUser: {
    backgroundColor: '#EBFFF8',
    borderRadius: '6px',
    padding: '4px 12px',
    fontSize: '12px',
  },
  active: {
    backgroundColor: '#EBFFF8',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  inActive: {
    backgroundColor: '#BCBCBC',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  Expired: {
    backgroundColor: '#FF8282',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  startCourseButton: {
    width: 110,
    marginTop: '-25px',
    fontSize: '12px',
    backgroundColor: 'white',
    border: '1px solid #00B673 !important',
    borderRadius: '6px',
    color: '#00B673',  // Text color for the button
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  greenButton: {
    backgroundColor: '#00B673',  // Green color
    color: 'white',              // White text color
    '&:hover': {
      backgroundColor: '#00B673',  // Keep the same color on hover
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '12px',
    marginTop: '-24px',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: '0',
      marginTop: '0',
    },
  },
  '@media (max-width: 600px)': {
    startCourseButton: {
      width: '100%',
      marginTop: '6px',
    },
  },
}));