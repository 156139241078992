import axios from 'axios';

const preSignedUrlUpload = async (url, file) => {
  const response = await axios.put(url, file, {
    headers: {
      'Content-Type': file?.type,
      // 'x-amz-acl': 'public-read',
    },
  });
  return response;
};

export default {
  preSignedUrlUpload,
};
