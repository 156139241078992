import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const PageHeader = ({
  pageTitle,
  subTitle,
  buttonText,
  handleButtonClick,
  goBack,
  buttonIcon,
  buttonComponent,
  breadcrumbs,
  submodule
}) => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo);

  const handleGoBack = () => {
    if (submodule === 'submodule') {
      navigate(-1);
    } else {
      navigate('/app/course');
    }
  };

  return (
    <div>
      <Box
        mb={1}
        sx={{
          display: 'flex',
          alignItems: subTitle ? 'baseline' : 'center',
          justifyContent: 'space-between',
          '@media (max-width: 780px)': {
            display: 'block',
          },
          marginBottom: '16px',
        }}
      >
        <Box>
        {pageTitle !== "MycoursePageB2BUser" &&
          <Typography variant="h4" display={'flex'}>
            <IconButton size="small" onClick={handleGoBack} sx={{ marginLeft: '-8px !important' }}>
              <ArrowBackIcon color="inherit" />
            </IconButton>
            {pageTitle}
          </Typography>}
          {subTitle && (
            <Typography variant="body2" sx={{ paddingLeft: '2.15rem' }}>
              {subTitle}
            </Typography>
          )}
        </Box>

        {userInfo.role && userInfo.role !== 'CONTENT_WRITER' && breadcrumbs}
      </Box>

      {buttonText && (
        <Box textAlign="right" mb={3}>
          <Button
            variant="contained"
            sx={{ backgroundColor: buttonIcon === 'EDIT' ? '#6D6969' : '#FE7000' }}
            startIcon={buttonIcon === 'EDIT' ? <ModeEditIcon fontSize="small" /> : <AddIcon fontSize="small" />}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </Box>
      )}

      {buttonComponent && (
        <Box textAlign="right" mb={3}>
          {buttonComponent}
        </Box>
      )}
    </div>
  );
};

export default PageHeader;
