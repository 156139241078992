export default function GlobalStyles() {
  return {
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: '#00B673',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
    },
    // MuiBackdrop: {
    //   styleOverrides: {
    //     root: {
    //       background: "#ababab36"
    //     },
    //   },
    // },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
    },
  };
}
