// ArrowIcon.js
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBack, ArrowForward } from '@mui/icons-material';



const ArrowIcon = ({ direction, onClick, disabled, enabled }) => {
  const classes = useStyles();
  

  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      className={enabled ? classes.iconButtonEnabled : classes.iconButton}
    >
      {direction === 'forward' ? <ArrowForward /> : <ArrowBack />}
    </IconButton>
  );
};

ArrowIcon.propTypes = {
  direction: PropTypes.oneOf(['forward', 'backward']).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  enabled: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
    iconButton: {
      // Default style
      padding: '5px',
    },
    iconButtonEnabled: {
      '&:hover': {
        backgroundColor: 'rgb(236,121,48)', // Change background color on hover
      },
      padding: '5px',
    },
  }));

export default ArrowIcon;
