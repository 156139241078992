import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { Download } from '@mui/icons-material';
import LottieLoading from '../../components/LottieLoading';
import adminServices from '../../services/adminServices';

//  to force file download
const downloadFile = (url, filename) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    })
    .catch(err => console.error('Error downloading file:', err));
};

const ReferenceScreen = ({ id, name }) => {
  const [referenceData, setReferenceData] = useState(null);
  const [showReferenceScreen, setShowReferenceScreen] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id && name) {
      setIsLoading(true);
      getReferenceData(id, name);
    }
  }, [id, name]);

  const getReferenceData = async (id, name) => {
    try {
      const response = await adminServices.getReferenceFileDetails(id, name);
      const { data } = response;

      if (data) {
        setReferenceData({
          name: data.name,
          ref_text: data.ref_text,
          url: data.url,
        });
        setShowReferenceScreen(true);
      } else {
        setError('No reference material available');
      }
    } catch (error) {
      console.error('Error fetching reference data:', error);
      setError('Error fetching reference data');
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return <Typography style={{ marginTop: '100px' }}>{error}</Typography>;
  }

  return referenceData ? (
    <Box style={{ margin: '20px' }}>
      <Typography variant="h5" style={{ marginBottom: '20px' }}>
        {name}
      </Typography>
      <Box style={{ border: '1px solid #ddd', padding: '20px', marginBottom: '20px' }}>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: referenceData.ref_text }} />
      </Box>
      {referenceData.url && (
        <Box style={{ border: '1px solid #ddd', padding: '20px' }}>
          <Typography variant="h6">Reference Material</Typography>
          <Typography variant="body1">
            Go through the Reference file: {referenceData.name}
          </Typography>
          <Box style={{ marginTop: '20px' }}>
            <a
              href={referenceData.url}
              download={referenceData.name}
              onClick={e => {
                e.preventDefault();
                downloadFile(referenceData.url, referenceData.name);
              }}
            >
              <Box
                style={{
                  padding: '10px 20px',
                  border: '1px solid #f0ad4e',
                  borderRadius: '4px',
                  backgroundColor: '#fdf5e6',
                  display: 'inline-flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: '#333',
                }}
              >
                <Download style={{ marginRight: '8px' }} />
                Download Reference File
              </Box>
            </a>
          </Box>
        </Box>
      )}
    </Box>
  ) : (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: `calc(100vh - 70px)`,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
      }}
    >
      <LottieLoading loading={isLoading} />
    </div>
  );
};

// Add propTypes for prop validation
ReferenceScreen.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ReferenceScreen;
