import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import lottieData from '../assets/lottie/lottie-loading.json';

const LottieLoading = ({ loading = false }) => {
  const loader = useRef();

  useEffect(() => {
    lottie.loadAnimation({
      container: loader.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lottieData,
    });
  }, []);

  return loading ? <div style={{ width: 50, margin: 'auto' }} ref={loader} /> : null;
};

export default LottieLoading;
