import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check'; 
import { Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DialogModal from '../../../components/modal/DialogModal';
import adminServices from '../../../services/adminServices';

function ScreensValidation({ moduleId }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loadingModules, setLoadingModules] = useState(false);
  const [data, setData] = useState([]);
  const [moduleName, setModuleName] = useState('');

  const handleOpenDialog = async () => {
    setIsDialogOpen(true);
    setLoadingModules(true); // Set loading to true when opening dialog
    try {
      const response = await adminServices.getModuleDataValidation(moduleId);
      
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.data;
      
      setModuleName(jsonData.length > 0 ? jsonData[0].moduleName : '');
      setData(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingModules(false); // Set loading to false when data fetching is completed
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div>
      <Tooltip title='Validate Screens'>
        <Button
          type="button"
          variant="contained"
          startIcon={<CheckIcon />}
          sx={{ background: '#6D6969', marginLeft: '10px' }}
          onClick={handleOpenDialog}
        />
      </Tooltip>
      
      <DialogModal
        open={isDialogOpen}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { maxHeight: '80vh', height: '80vh', overflowY: 'scroll' } }}
        handleClose={handleCloseDialog}
      >
        <div>
          {!loadingModules && data && data.length > 0 ? (
            <>
              <h2 style={{ fontSize: '1.2rem', marginBottom: '0.5rem', textAlign: 'center' }}>
                Validating {moduleName}
              </h2>
              <table style={{ width: '100%', fontSize: '0.9rem', textAlign: 'center' }}>
                <thead>
                  <tr>
                    <th>Submodule Name</th>
                    <th>Topic Name</th>
                    <th>Action Screen</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      <td>{row.subModuleName}</td>
                      <td>{row.topicName}</td>
                      <td>
                        {row.screen && typeof row.screen === 'object' && row.screen.message ? (
                          <span>{row.screen.message}</span>
                          ) : (
                          <ul>
                            {row.screen.map((screen, idx) => (
                              <li key={idx}>{screen.id}</li>
                            ))}
                          </ul>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p style={{ textAlign: 'center', fontSize: '1.2rem', marginTop: '20px' }}>
              {loadingModules ? <CircularProgress /> : 'There are no missing screens, please proceed with the next module!!'}
            </p>
          )}
        </div>
      </DialogModal>
    </div>
  );
}

export default ScreensValidation;
