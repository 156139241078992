import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { Avatar, Badge, Grid, FormControl, FormHelperText, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LoadingButton } from '@mui/lab';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DialogModal from '../../../components/modal/DialogModal';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import adminServices from '../../../services/adminServices';
import LinearProgressBar from '../../../components/progress/LinearProgressBar';

const SUPPORTED_FORMATS = [
  'video/quicktime',
  'video/webm',
  'video/mp4',
  'video/avi',
  'video/m4v',
  'video/mpeg',
  'video/mpg',
  'video/mkv',
];

const CreateCourse = ({ title, open, modelClose, submitForm, loading, data, formType, deleteVideo, progress }) => {
  const [category, setCategory] = useState([]);
  const [hashtag, setHashtag] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const classes = useStyles();
  const user = useSelector((state) => state.userInfo);
  const [types, setTypes] = useState([]);
  
  
  const [initialValues, setInitialValues] = useState({
    courseTitle: '',
    courseCategory: null,
    courseCategoryValue: null,
    subCategory: null,
    thumbImage: null,
    thumbPreview: null,
    coverageImage: null,
    coverImgPreview: null,
    videoFile: '',
    videoFilePreview: null,
    hashtag: [],
    description: '',
    isTrial: false,
    // eslint-disable-next-line prefer-template 
    authors: formType==='CREATE'? [user.firstName + ' ' + user.lastName]:[],
    type: "simulation"
  });

  useEffect(async () => {
    await getLookUpDetails();
    await getAuthors()
    const typeArray = ["simulation","video"]
    setTypes(typeArray)
  }, []);

  useEffect(() => {
    if (data && formType === 'EDIT') {
      
      setSubCategories(data.subCategory ? [data.subCategory] : []);      
      setInitialValues({
        courseTitle: data.title,
        courseCategory: data.category,
        courseCategoryValue: data.category?.code,
        subCategory: data?.subCategory,
        thumbImage: null,
        thumbPreview: data.thumbImage,
        coverageImage: null,
        coverImgPreview: data.coverageImage,
        videoFile: null,
        hashtag: data.hashTag,
        description: data?.description,
        videoFilePreview: data?.introVideo,
        isTrial: data.isTrial,
        authors: data.authorList===null? [] :data.authorList,
        type: data.type
      });
    }
    
  }, [data]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const getLookUpDetails = async () => {
    const key = ['hashtag', 'course_category'];
    const result = await adminServices.getLookUpData(key);
    if (result.ok) {
      setHashtag(result.data.hashtag);
      setCategory(result.data.course_category);
    }
  };

  const getSubCategory = async (code) => {
    try {
      const response = await adminServices.getSubcategoryLookUp('subCategory', code);
      if (response.ok) {
        setSubCategories(response.data?.subCategory);
        console.log(response);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAuthors = async (code) => {
    try{
      const response = await adminServices.getAuthors();
      if (response.ok) {
        const authArray = []
        console.log(response.data);
        (response.data).forEach(a => { authArray.push(a.name) })
        console.log('Auth array', authArray)
        setAuthors(authArray);
        
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }

  const imageWidthAndHeight = (provideFile) => {
    // take the given file (which should be an image) and return the width and height\
    if (provideFile) {
      const imgDimensions = { width: null, height: null };
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(provideFile);
        reader.onload = function () {
          const img = new Image();
          img.src = reader.result;
          img.onload = function () {
            imgDimensions.width = img.width;
            imgDimensions.height = img.height;
            resolve(imgDimensions);
          };
        };
      });
    }
  };

  return (
    <DialogModal open={open} handleClose={modelClose} title={title}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          courseTitle: Yup.string()
            .trim()
            .required('Required')
            .nullable()
            .min(3, 'Course title contains at least 3 characters'),
          courseCategory: Yup.string().required('Required').nullable(),
          subCategory: Yup.string().when('courseCategoryValue', {
            is: (courseCategory) => courseCategory === 'SOFTWARE_DEVELOPMENT',
            then: Yup.string().trim().required('Required').nullable(),
            otherwise: Yup.string().nullable(),
          }),
          type: Yup.string().required('Required').nullable(),
          thumbImage: Yup.mixed().when('thumbPreview', {
            is: null,
            then: Yup.mixed()
              .nullable()
              .required('Required')
              .test(
                'aspectRatio',
                'image dimensions should be at least 360X200 ',
                (value) => value && imageWidthAndHeight(value).then((res) => res.width >= 360 && res.height >= 200)
              )
              .test('fileSize', 'Maximum file size is 2MB', (value) =>
                value && typeof value === 'object' ? value.size < 2097152 : 0 < 2097152
              ),
            otherwise: Yup.mixed()
              .nullable()
              .test('aspectRatio', 'image dimensions should be at least 360X200 ', (value) => {
                if (value) {
                  return imageWidthAndHeight(value).then((res) => res.width >= 360 && res.height >= 200);
                }
                return 1 < 2;
              }),
          }),
          isTrial: Yup.bool(),
          coverageImage: Yup.mixed().when('coverImgPreview', {
            is: null,
            then: Yup.mixed()
              .required('Required')
              .test(
                'aspectRatio',
                'image dimensions should be at least 1200X400 ',
                (value) => value && imageWidthAndHeight(value).then((res) => res.width >= 1200 && res.height >= 400)
              )
              .test('fileSize', 'Maximum file size is 1MB', (value) =>
                value && typeof value === 'object' ? value.size < 1048576 : 0 < 1048576
              ),
            otherwise: Yup.mixed()
              .nullable()
              .test('aspectRatio', 'image dimensions should be at least 1200X400 ', (value) => {
                if (value) {
                  return imageWidthAndHeight(value).then((res) => res.width >= 1200 && res.height >= 400);
                }
                return 1 < 2;
              }),
          }),

          videoFile: Yup.mixed().when('videoFilePreview', {
            is: null,
            then: Yup.mixed()
              .test('fileSize', 'Maximum file size is 100MB', (value) =>
                value && typeof value === 'object' ? value.size < 104857600 : 0 < 104857600
              )
              // .test('fileType', 'Your Error Message', (value) => console.log(value.type)),
              .test('fileType', 'Preferred file format are mp4,m4v,mov,avi,mkv, mpg, mpeg and mpg', (value) =>
                value && typeof value === 'object' ? SUPPORTED_FORMATS.includes(value.type) : true
              ),

            otherwise: Yup.mixed().nullable(),
          }),
          hashtag: Yup.string().required('Required').nullable(),
        })}
        onSubmit={(values) => submitForm(values)}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="country-select-demo"
                  fullWidth
                  filterSelectedOptions
                  autoComplete
                  options={category}
                  autoHighlight
                  getOptionLabel={(option) => option.description}
                  value={values.courseCategory}
                  onChange={(e, value) => {
                    setFieldValue('courseCategory', value);
                    setFieldValue('courseCategoryValue', value.code);
                    setFieldValue('subCategory', null);

                    if (value) {
                      getSubCategory(value.code);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img loading="lazy" width="20" src={option.categoryImgUrl} alt="i" />
                      {option.description}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="courseCategory"
                      error={Boolean(touched.courseCategory && errors.courseCategory)}
                      helperText={touched.courseCategory && errors.courseCategory}
                      label="Course category*"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="country-select-demo"
                  fullWidth
                  filterSelectedOptions
                  options={subCategories}
                  autoHighlight
                  getOptionLabel={(option) => option.description}
                  value={values.subCategory}
                  onChange={(e, value) => {
                    setFieldValue('subCategory', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="subCategory"
                      error={Boolean(touched.subCategory && errors.subCategory)}
                      helperText={touched.subCategory && errors.subCategory}
                      label="Sub category"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.courseTitle && errors.courseTitle)}
                  fullWidth
                  helperText={touched.courseTitle && errors.courseTitle}
                  label="Course Title*"
                  name="courseTitle"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.courseTitle}
                  variant="outlined"
                  inputProps={{ maxLength: 128 }}
                />
                <ErrorFocus />
              </Grid>
              <Grid item xs={12}>
                
              <Autocomplete
                        options={authors}
                        // filterSelectedOptions
                        autoHighlight
                        multiple
                        getOptionLabel={(option) => option.name || option || ''}
                        value={values.authors}
                        name="authors"
                        onChange={(e, value) => {                          
                          setFieldValue('authors', value)
                        }}
                        onBlur={handleBlur}
                        renderOption={(props, option) => (
                          <>
                            <Box
                              {...props}
                              sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'start',
                                flexDirection: 'column',
                              }}
                            >
                              <Typography variant="body1" width="100%">
                                {option}
                              </Typography>
                              
                            </Box>
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select author *"
                            variant="outlined"
                            fullWidth
                            error={Boolean(touched.authors && errors.authors)}
                            helperText={touched.authors && errors.authors}
                          />
                        )}
                      />
              </Grid>
              <Grid item xs={6}>
                
              <Autocomplete
                        options={types}
                        filterSelectedOptions
                        autoHighlight
                        getOptionLabel={(option) => option.name || option || ''}
                        value={values.type}
                        name="type"
                        onChange={(e, value) => {                          
                          setFieldValue('type', value)
                        }}
                        onBlur={handleBlur}
                        readOnly={formType==='EDIT'}
                        renderOption={(props, option) => (
                          <>
                            <Box
                              {...props}
                              sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'start',
                                flexDirection: 'column',
                              }}
                            >
                              <Typography variant="body1" width="100%">
                                {option}
                              </Typography>
                              
                            </Box>
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select course type *"
                            variant="outlined"
                            fullWidth
                            error={Boolean(touched.type && errors.type)}
                            helperText={touched.type && errors.type}
                          />
                        )}
                      />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>Description</Typography>
                <CKEditor
                  editor={ClassicEditor}
                  data={values.description}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFieldValue('description', data);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    {values.thumbPreview === null ? (
                      <FormControl
                        required
                        component="fieldset"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        name="thumbImage"
                        value={values.thumbImage}
                      >
                        <Typography variant="subtitle1">Thumb Image* (360X200)</Typography>

                        <DropzoneArea
                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                          showPreviews={false}
                          dropzoneText="Drag and Drop Image or Browse File"
                          showPreviewsInDropzone={false}
                          maxFileSize={300000000}
                          filesLimit={1}
                          showAlerts={false}
                          value={values.thumbImage}
                          onChange={(file) => {
                            setFieldValue('thumbImage', file[0]);
                            if (file[0]?.size < 2097152) {
                              imageWidthAndHeight(file[0]).then((res) => {
                                if (res.width >= 360 && res.height >= 200) {
                                  const url = URL.createObjectURL(file[0]);
                                  setFieldValue('thumbPreview', url);
                                }
                              });
                            }
                          }}
                          useChipsForPreview
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          showFileNamesInPreview
                        />
                        <FormHelperText style={{ color: '#F44336' }}>
                          {(errors.thumbImage || touched.thumbImage) && errors.thumbImage}

                          {/* {formType === 'CREATE' ? touched.thumbPreview && errors.thumbImage : errors.thumbImage} */}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      <div className={classes.imgPreviewRoot}>
                        <Typography variant="subtitle1">Thumb Image</Typography>
                        <Badge
                          badgeContent={
                            <CloseIcon
                              onClick={() => {
                                setFieldValue('thumbPreview', null);
                                setFieldValue('thumbImage', null);
                              }}
                              className={classes.badgeAlign}
                            />
                          }
                        >
                          <Avatar
                            variant="rounded"
                            src={values.thumbPreview}
                            className={values.thumbPreview !== null && classes.fileImgSIze}
                          />
                        </Badge>
                        <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                          {(errors.thumbImage || touched.thumbImage) && errors.thumbImage}
                        </Typography>
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    {values.coverImgPreview === null ? (
                      <FormControl
                        required
                        component="fieldset"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        name="coverageImage"
                        value={values.coverageImage}
                      >
                        <Typography variant="subtitle1">Cover Image* (1200X400)</Typography>
                        <DropzoneArea
                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                          showPreviews={false}
                          showAlerts={false}
                          dropzoneText="Drag and Drop Image or Browse File"
                          showPreviewsInDropzone={false}
                          maxFileSize={1048576000}
                          filesLimit={1}
                          value={values.coverageImage}
                          onChange={(file) => {
                            console.log(file[0]);
                            setFieldValue('coverageImage', file[0]);
                            if (file[0]?.size < 1048576) {
                              imageWidthAndHeight(file[0]).then((res) => {
                                if (res.width >= 1200 && res.height >= 400) {
                                  const url = URL.createObjectURL(file[0]);
                                  setFieldValue('coverImgPreview', url);
                                }
                              });
                            }
                          }}
                          useChipsForPreview
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          showFileNamesInPreview
                        />
                        <FormHelperText style={{ color: '#F44336' }}>
                          {(errors.coverageImage || touched.coverageImage) && errors.coverageImage}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      <div className={classes.imgPreviewRoot}>
                        <Typography variant="subtitle1">Cover Image</Typography>
                        <Badge
                          badgeContent={
                            <CloseIcon
                              onClick={() => {
                                setFieldValue('coverImgPreview', null);
                                setFieldValue('coverageImage', null);
                              }}
                              className={classes.badgeAlign}
                            />
                          }
                        >
                          <Avatar
                            variant="rounded"
                            src={values.coverImgPreview}
                            className={values.coverImgPreview !== null && classes.fileImgSIze}
                          />
                        </Badge>
                        <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                          {(errors.coverageImage || touched.coverageImage) && errors.coverageImage}
                        </Typography>
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {values.videoFilePreview === null ? (
                      <FormControl
                        required
                        component="fieldset"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        name="videoFile"
                        value={values.videoFile}
                      >
                        <Typography variant="subtitle1">Introduction Video</Typography>
                        <DropzoneArea
                          acceptedFiles={['video/*']}
                          showPreviews
                          showAlerts={false}
                          dropzoneText="Drag and Drop Video or Browse File"
                          showPreviewsInDropzone={false}
                          maxFileSize={157286400}
                          filesLimit={1}
                          value={values.videoFile}
                          onChange={(file) => {
                            setFieldValue('videoFile', file[0]);
                            console.log(file[0]);
                          }}
                          useChipsForPreview
                          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                          showFileNamesInPreview
                        />
                        <FormHelperText style={{ color: '#F44336' }}>
                          {touched.videoFile && errors.videoFile}
                        </FormHelperText>
                      </FormControl>
                    ) : (
                      <>
                        <div className={classes.imgPreviewRoot}>
                          <Typography variant="subtitle1">Video</Typography>
                          <Badge
                            badgeContent={
                              <CloseIcon
                                onClick={() => {
                                  setFieldValue('videoFilePreview', null);
                                }}
                                className={classes.badgeAlign}
                              />
                            }
                          >
                            <iframe
                              width="99%"
                              height="160"
                              src={values.videoFilePreview}
                              title="YouTube video player"
                              allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />
                          </Badge>
                        </div>
                        <Typography
                          className={classes.deleteLabel}
                          onClick={() => deleteVideo()}
                          variant="subtitle2"
                          color="primary"
                        >
                          Delete Video
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  options={hashtag}
                  multiple
                  // filterSelectedOptions
                  autoHighlight
                  //  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.description || ''}
                  value={values.hashtag}
                  onChange={(e, value) => {
                    setFieldValue('hashtag', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Keyword*"
                      variant="outlined"
                      name="hashtag"
                      fullWidth
                      error={Boolean(touched.hashtag && errors.hashtag)}
                      helperText={touched.hashtag && errors.hashtag}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isTrial}
                      value={values.isTrial}
                      onChange={handleChange}
                      id="isTrial"
                      name="isTrial"
                      color="primary"
                    />
                  }
                  label="Do you want to create a trial course?"
                />
              </Grid>
              
              { // eslint-disable-next-line prefer-template     
              (user.role==='SUPER_ADMIN' || (values.authors).includes(user.firstName + ' '+ user.lastName))&& <Grid item sm={12}>
                {loading && values.videoFile ? (
                  <LinearProgressBar value={progress} />
                ) : (
                  <LoadingButton type="submit" variant="contained" color="primary" fullWidth loading={loading}>
                    {formType === 'CREATE' ? 'Create' : 'Update'}
                  </LoadingButton>
                )}
              </Grid>}
            </Grid>
          </Form>
        )}
      </Formik>
    </DialogModal>
  );
};

const useStyles = makeStyles((theme) => ({
  imgPreviewRoot: {
    borderRadius: '10px',
    padding: '0.8rem;',
  },
  fileImgSIze: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
    objectPosition: 'center',
    border: '1px solid #fff',
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
  },
  badgeAlign: {
    boxShadow: '0 2px 8px -5px #ff0000',
    color: '#FF0000',
    fontSize: '1.2rem',
    backgroundColor: '#fff',
    padding: '2px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  deleteLabel: {
    width: 'max-content',
    cursor: 'pointer',
  },
}));

export default CreateCourse;
