import { Grid, Typography, TextField } from '@mui/material';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import BasicModals from '../../components/modal/BasicModel';
import loginServices from '../../services/loginServices';
import SnackBar from '../../components/snackbar/snackbar';

function ResetPassward({ openModal, closeModel }) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    currentpassword: '',
    password: '',
    confirmPassword: '',
  });

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [visible, setVisible] = React.useState(false);

  const [snackbarTitle, setSnackbarTitle] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (values) => {
    setLoading(true);
    const resetData = {
      confirmPassword: values.confirmPassword,
      currentPassword: values.currentpassword,
      password: values.password,
    };

    try {
      const response = await loginServices.resetPassword(resetData);
      if (response.ok) {
        closeModel();
        setSnackbarTitle(response.data.message);
        setOpenSnackbar(true);
      } else if (response) {
        setSnackbarTitle(response.data.message);
        setOpenSnackbar(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <BasicModals
        openModel={openModal}
        closeModel={closeModel}
        title="Change Password"
        children={
          <Formik
            initialValues={{
              currentpassword: '',
              password: '',
              confirmPassword: '',
            }}
            validationSchema={Yup.object().shape({
              currentpassword: Yup.string().required('Current Password is required'),
              password: Yup.string()
                .trim()
                .min(8)
                .nullable()
                .matches(
                  /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                  'The password must contain 8 characters, one upper case, one number and one special case character'
                )
                .required('New Password is Required.'),

              confirmPassword: Yup.string()
                .required('Confirm Password is Required')
                .trim()
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            })}
            onSubmit={(values) => {
              handleResetPassword(values);
            }}
          >
            {({ errors, handleBlur, handleSubmit, setFieldValue, touched, values }) => (
              <div>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs="12">
                      <Typography variant="subtitle1">Current Password*</Typography>
                      <TextField
                        fullWidth
                        name="currentpassword"
                        variant="outlined"
                        type="password"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('currentpassword', e.target.value.trim());
                        }}
                        error={Boolean(touched.currentpassword && errors.currentpassword)}
                        helperText={touched.currentpassword && errors.currentpassword}
                        value={values.currentpassword}
                      />
                    </Grid>
                    <Grid item xs="12">
                      <Typography variant="subtitle1">New Password*</Typography>

                      <TextField
                        fullWidth
                        name="password"
                        variant="outlined"
                        type={visible ? 'text' : 'password'}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('password', e.target.value.trim());
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        value={values.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {/* <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                                {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton> */}

                              {visible ? (
                                <VisibilityIcon className={classes.visibleIcon} onClick={handleClickShowPassword} />
                              ) : (
                                <VisibilityOffIcon className={classes.visibleIcon} onClick={handleClickShowPassword} />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs="12">
                      <Typography variant="subtitle1">Confirm Password*</Typography>
                      <TextField
                        fullWidth
                        name="confirmPassword"
                        variant="outlined"
                        type="password"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('confirmPassword', e.target.value.trim());
                        }}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        value={values.confirmPassword}
                      />
                    </Grid>
                    <Grid align="center" item xs="12">
                      <LoadingButton
                        // className={classes.LoadingButton}
                        fullWidth
                        size="medium"
                        type="submit"
                        variant="contained"
                        loading={loading}
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </Formik>
        }
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
}

export default ResetPassward;
const useStyles = makeStyles({
  visibleIcon: {
    color: 'grey',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
