/* eslint-disable */
import React from 'react';
import { DefaultPlayer as Video } from 'react-html5video';

import Brain from '../../website/images/brain.png';
import ExcelEfficiency from '../../website/images/ExcelEfficiencyHover.png';
import FinanciaModeling from '../../website/images/FinanciaModelingHover.png';
import Icon from '@mui/material/Icon';
import Network from '../../website/images/network.png';
import PowerPoint from '../../website/images/PowerPointHover.png';
import PythonImage from '../../website/images/PythonImageHover.png';
import Youtube from '../../website/images/youtube.png';
import QuickBooksLogo from '../../website/images/QuickbooksOnline.png';

const routes = (isLogin, userRole) => {
  let data;

  if (isLogin && userRole && userRole === 'USER_DTC') {
    data = [
      {
        name: 'Solutions',
        icon: <Icon>article</Icon>,
        listStyle: 'list',
        collapse: [
          {
            name: 'Individual Learning Solutions',
            description: 'Explore all courses available for self-study',
            icon: Youtube,
            // route: '/self-study',
            href: 'https://www.keyskillset.com/self-study',
          },
          {
            name: 'Business Learning Solutions',
            description: 'Motivate your employees through simulated learning',
            icon: Network,
            // route: '/business',
            href: 'https://www.keyskillset.com/business',
          },
          {
            name: 'Educational Learning Solutions',
            description: 'Utilize our LMS to create custom coursework ',
            icon: Brain,
            // route: '/higher-education',
            href: 'https://www.keyskillset.com/higher-education',
          },
        ],
      },

      {
        name: 'Courses',
        icon: <Icon>article</Icon>,
        listStyle: 'grid',
        route: '/auth/subscribe',
        // href: 'https://www.keyskillset.com/courses',
        // collapse: [
        //   {
        //     name: 'Excel Efficiency',
        //     description:
        //       'Introducing time-saving shortcuts and lesser-known functions & features to make you an Excel power user.',
        //     icon: ExcelEfficiency,
        //     // route: '/excel-efficiency',
        //     href: ' https://www.keyskillset.com/excel-efficiency',
        //   },
        //   {
        //     name: 'Python Beginner',
        //     description:
        //       'Discover the power of Python applied to working with a large dataset quickly and efficiently. ',
        //     icon: PythonImage,
        //     // route: '/python-beginner',
        //     href: 'https://www.keyskillset.com/python-beginner',
        //   },
        //   // {
        //   //   name: 'QuickBooks Online',
        //   //   description:
        //   //     'Learn a gamified version of QuickBooks. This course will provide you with the essential skills to learn everything you need to know about QuickBooks Online. ',
        //   //   icon: QuickBooksLogo,
        //   //   route: '/quickbooks',
        //   // },
        //   {
        //     name: 'Financial Modeling',
        //     description:
        //       'Step by step instructions to build a Discounted Cash Flow financial model for a real company. ',
        //     icon: FinanciaModeling,
        //     // route: '/financial-modeling',
        //     href: 'https://www.keyskillset.com/financial-modeling',
        //   },
        //   {
        //     name: 'Powerpoint Speed',
        //     description:
        //       'Designed for finance professionals, consultants and anyone looking to get the most out of PowerPoint, this course is focused on speed. ',
        //     icon: PowerPoint,
        //     // route: '/powerpoint',
        //     href: 'https://www.keyskillset.com/powerpoint',
        //   },
        // ],
      },

      // {
      //   name: 'Course Subscriptions',
      //   listStyle: 'list',
      //   route: '/auth/subscribe',
      // },

      {
        name: 'Learning Center',
        listStyle: 'list',
        // route: '/courses',
        href: 'https://www.keyskillset.com/learning-center',
      },
    ];
  } else {
    data = [
      {
        name: 'Solutions',
        icon: <Icon>article</Icon>,
        listStyle: 'list',
        collapse: [
          {
            name: 'Individual Learning Solutions',
            description: 'Explore all courses available for self-study',
            icon: Youtube,
            // route: '/self-study',
            href: 'https://www.keyskillset.com/self-study',
          },
          {
            name: 'Business Learning Solutions',
            description: 'Motivate your employees through simulated learning',
            icon: Network,
            route: '/business',
            href: 'https://www.keyskillset.com/business',
          },
          {
            name: 'Educational Learning Solutions',
            description: 'Utilize our LMS to create custom coursework ',
            icon: Brain,
            // route: '/higher-education',
            href: 'https://www.keyskillset.com/higher-education',
          },
        ],
      },

      {
        name: 'Courses',
        icon: <Icon>article</Icon>,
        listStyle: 'grid',
        // collapse: [
        //   {
        //     name: 'Excel Efficiency',
        //     description:
        //       'Introducing time-saving shortcuts and lesser-known functions & features to make you an Excel power user.',
        //     icon: ExcelEfficiency,
        //     route: '/excel-efficiency',
        //   },
        //   {
        //     name: 'Python Beginner',
        //     description:
        //       'Discover the power of Python applied to working with a large dataset quickly and efficiently. ',
        //     icon: PythonImage,
        //     route: '/python-beginner',
        //   },
        //   // {
        //   //   name: 'QuickBooks Online',
        //   //   description:
        //   //     'Learn a gamified version of QuickBooks. This course will provide you with the essential skills to learn everything you need to know about QuickBooks Online. ',
        //   //   icon: QuickBooksLogo,
        //   //   route: '/quickbooks',
        //   // },
        //   {
        //     name: 'Financial Modeling',
        //     description:
        //       'Step by step instructions to build a Discounted Cash Flow financial model for a real company. ',
        //     icon: FinanciaModeling,
        //     route: '/financial-modeling',
        //   },
        //   {
        //     name: 'Powerpoint Speed',
        //     description:
        //       'Designed for finance professionals, consultants and anyone looking to get the most out of PowerPoint, this course is focused on speed. ',
        //     icon: PowerPoint,
        //     route: '/powerpoint',
        //   },
        // ],

        href: 'https://www.keyskillset.com/courses',
      },

      // {
      //   name: 'Course Creation',
      //   // icon: <GitHubIcon />,
      //   listStyle: 'list',
      //   route: '/course-creation',
      //   // href: 'https://www.github.com/creativetimofficial/material-kit-react',
      // },
      // {
      //   name: 'Pricing',
      //   // icon: <GitHubIcon />,
      //   listStyle: 'list',
      //   route: '/pricing',
      //   // href: 'https://www.github.com/creativetimofficial/material-kit-react',
      // },
      {
        name: 'Learning Center',
        // icon: <GitHubIcon />,
        listStyle: 'list',
        // route: '/courses',
        href: 'https://www.keyskillset.com/learning-center',
      },
    ];
  }

  return data;
};

export default routes;
