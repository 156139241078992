/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import { Box, CardMedia, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import  {useNavigate}  from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/Person';
import clsx from 'clsx';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import Background from '../../assets/logo/background.svg';


export default function IndividualCard(props) {
  const classes = useStyles();
  const userRole = useSelector((state) => state.userInfo && state.userInfo.role);
  const { userSubscribedCourses } = useSelector((state) => state);
  const [isTrial, setIsTrial] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const navigate = useNavigate();
  const [isPaid, setisPaid] = useState(true);
  const courseDetails = location.state;
  const activeSubStyle = {
    textDecoration: 'line-through',
  };
  let courseId=null;

  // console.log(props);
  useEffect(() => {
    if (userSubscribedCourses.length > 0) {
      // console.log(userSubscribedCourses);
      setIsTrial(true);
      setisPaid(false);
      // setIsSubscribed(true);
      const obj = userSubscribedCourses.find((item) => item?.subscriptionPlanId === props?.subscriptionPlanId);
      // console.log('subscriptionPlan of the user..',props?.subscriptionPlanId);
      if (obj !== undefined) {
        // console.log(' Inside obj is not undefined..',obj.isSubscribed);
        if (obj.isTrial === false && obj.isSubscribed === false) {
          //setIsTrial(true);
          setIsSubscribed(false);
          setisPaid(false);
        } else {
          setIsTrial(false);
          setIsSubscribed(false);
          setisPaid(true)
          setIsSubscribed(!obj.isSubscribed);
        }
      }
    }
    else{
      console.log('user has some subscribed courses..',userSubscribedCourses);
      setisPaid(true)
    }
  }, [props]);

  return (
    
     <div>
      <Card className={classes.card} >
        <Box sx={{
            position: 'relative',
            backgroundColor: '#00B673',
            color: 'white',
            textAlign: 'center',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}>
            <Typography className={classes.cardTitle} variant="h6" component="div">
              {props.item.type && props.item.type.charAt(0).toUpperCase() + props.item.type.slice(1).toLowerCase()}
            </Typography>
          </Box>
        <CardMedia component="img" height="160" image={props.image} sx={{ objectFit: 'unset' }} alt="green iguana" />
        <div style={{padding: '12px'}}>
          <Typography className={classes.cardTitle} component="div" color="primary">
            {props.title}
          </Typography>
          {props.author && (
            <Typography sx={{ color: '#323030', fontSize: '0.65rem', fontWeight: '600' }}>
              Author : {props.author.name}
            </Typography>
          )}
          <div
            variant="body2"
            className={classes.description}
            color="text.secondary"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />

          {props.progress && <Box>{props.progress}</Box>}

          {props.date && (
            <Typography className={classes.date} gutterBottom color="secondary">
              {props.date}
            </Typography>
          )}

          <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            size="small"
            variant="contained"
          >
            <div>
              <Typography
                // className={classes.button}
                color="secondary"
                sx={{
                  ...(!props.isPaid && activeSubStyle),
                }}
              >
                {props.price} {props.subscriptionPlanId===91?'(With Tutor)':props.subscriptionPlanId===92?'(Without Tutor)':''}
          
              </Typography>
              {!props.isPaid && <Typography variant="body2">Free for students</Typography>}
            </div>
            <div className={classes.back}>
              <div className={classes.title} gutterBottom component="div" color="primary">
                <img className={classes.logo} src={props.logo} alt="logo" width={55} height={50} />
              </div>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: '6px'}}>
            {props.isTrial && isSubscribed && (
              <LoadingButton
                variant="outlined"
                className={classes.trialButton}
                onClick={() => {
                  props.handleTrial(props.subscriptionPlanId, props.item.id);
                }}
                loading={props.item.id === props.isTrialLoading}
              >
                Free Access
              </LoadingButton>
            )}

            {isSubscribed ? (
              <LoadingButton
                variant="outlined"
                className={classes.subscribeButton}
                loading={props.item.subscriptionPlanId === props.isSubscribeLoading}
                onClick={() => {
                  props.handleSubcribe(props.item, props.subscriptionPlanId);
                }}
              >
                subscribe
              </LoadingButton>
            ):

            ( isPaid && (
              <div style={{ marginLeft: 'auto' }}>
              <Button
              variant="contained"  // Use 'contained' for a filled button
              color="success"       // Set the color to 'success' for green
              className={`${classes.startCourseButton} ${classes.greenButton}`} 
              onClick={() => {
                
                console.log('Start Course button clicked',props.item.id);
                console.log('Start Course button clicked',props.subscriptionPlanId)
                courseId=props.item.id;
                console.log('courseId',courseId);
               // getCourseContent(props.item.id,props.subscriptionPlanId);
               if (userRole === 'USER_DTC') {
                navigate('/auth/course-details', { state: props.item });
                  } else {
                navigate('/app/course-details', { state: props.item });
                  }
               }}
            >
              Start Course
            </Button>
            </div>
          ))
            }
          </div>
        </div>
      </Card>
    </div>
   
    
  );
}



const useStyles = makeStyles((theme) => ({
  card: {
    border: '0.5px solid #DFDFDF',
    boxShadow: '0px 3px 6px  #0000001A',
    borderRadius: '6px',
    // backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    height:'380px',
  },
  date: {
    marginTop: '15px',
  },
  trialButton: {
    width: 110,
    borderRadius:'6px',
    fontSize: '12px',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
    marginRight: '8px',
  },
  subscribeButton: {
    width: 110,
    color: '#00B673 ! important',
    border: '1px solid #00B673 ! important',
    borderRadius:'6px',
    fontSize: '12px',
    // marginLeft: '1rem',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
 
  button: {
    backgroundColor: '#F8F5F4',
    padding: '4px 6px',
    borderRadius: '6px',
    width: 'max-content',
  },
  title: {
    marginTop: '10px',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '15px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
  },
  logo: {
    boxShadow: '0px 3px 22px #00000029',
    padding: '4px',
    backgroundColor: '#fff',
    borderRadius: '6px',
    marginTop: '10px',
  },
  description: {
    // whiteSpace: 'normal',
    minHeight: '38px',
    fontSize: '0.8rem !important',
    overflow: 'hidden !important',
    fontFamily: 'Inter',
    textOverflow: 'ellipsis',
    '-o-text-overflow': 'ellipsis',
    '-ms-text-overflow': 'ellipsis',

    '-moz-binding': "url('ellipsis.xml#ellipsis')",
    '-ms-webkit-line-clamp': 2,
    '-webkit-line-clamp': 2,
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '& span': {
      fontSize: '0.8rem !important',
      color: 'black !important',
      backgroundColor: 'unset !important',
      fontFamily: 'Inter !important',
    },
    '& p': {
      '&:nth-child(1)': {
        display: 'block !important',
      },
      '&:nth-child(even)': {
        display: 'none ',
      },
      '&:nth-child(odd)': {
        display: 'none ',
      },
    },
  },
   enrolledUser: {
    backgroundColor: '#EBFFF8',
    borderRadius: '6px',
    padding: '4px 12px',
    fontSize: '12px',
  },
  active: {
    backgroundColor: '#EBFFF8',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  inActive: {
    backgroundColor: '#BCBCBC',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  Expired: {
    backgroundColor: '#FF8282',
    borderRadius: '6px',
    padding: '4px 12px',
  },
  startCourseButton: {
    width: 110,
    fontSize: '12px',
    backgroundColor: 'white',
    border: '1px solid #00B673 ! important',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  
  // New class for the green button
  greenButton: {
    backgroundColor: '#00B673',  // Green color
    color: 'white',              // White text color
    '&:hover': {
      backgroundColor: '#00B673',  // Keep the same color on hover
    },
  },

}));
