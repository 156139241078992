import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'root',
  initialState: {
    isLogin: false,
    userInfo: null,
    openSubscriptionModal: false,
    openSubscriptionModalDetails: null,
    planId: null,
    userSubscribedCourses: [],
    snackbarOpen: false,
    snackbarTitle: '',
    openWebInfoModal: false,
    noOfTimeOpenWebInfo: 2,
    acceptCookies: true,
    isGuiding: false,
    keyIntelligence: false,
    isBannerVisible: true,
  },

  reducers: {
    loginSuccess: (state, action) => {
      state.isLogin = true;
      state.userInfo = action.payload;
    },

    logoutSuccess: (state) => {
      state.isLogin = false;
      state.userInfo = null;
      state.userSubscribedCourses = [];
      state.planId = null;
      state.noOfTimeOpenWebInfo = 2;
      state.isBannerVisible = true;
    },

    openSubscriptionModal: (state, action) => {
      state.openSubscriptionModal = true;
      state.planId = action.payload;
    },

    closeSubscriptionModal: (state) => {
      state.openSubscriptionModal = false;
      state.planId = null;
      state.openSubscriptionModalDetails = null;
    },

    setSubscribedCourses: (state, action) => {
      state.userSubscribedCourses = action.payload;
    },
    setOpenSubscriptionModalDetails: (state, action) => {
      state.openSubscriptionModalDetails = action.payload;
    },

    openSnackbar: (state, action) => {
      state.snackbarTitle = action.payload;
      state.snackbarOpen = true;
    },

    handleOpenWebInfoModal: (state, action) => {
      state.openWebInfoModal = action.payload;
    },

    handleTimerWebInfoModal: (state, action) => {
      state.noOfTimeOpenWebInfo = action.payload;
    },

    closeSnackbar: (state) => {
      state.snackbarTitle = '';
      state.snackbarOpen = false;
    },
    closeCookiesAlert: (state) => {
      state.acceptCookies = false;
    },
    guideHandler: (state, action) => {
      state.isGuiding = action.payload;
      // state.userInfo = action.payload;
    },
    setKeyIntelligence: (state, action) => {
      state.keyIntelligence = action.payload;
    },
    setBanner: (state, action) => {
      state.isBannerVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loginSuccess,
  logoutSuccess,
  openSubscriptionModal,
  closeSubscriptionModal,
  setSubscribedCourses,
  setOpenSubscriptionModalDetails,
  closeSnackbar,
  openSnackbar,
  handleOpenWebInfoModal,
  handleTimerWebInfoModal,
  closeCookiesAlert,
  guideHandler,
  setKeyIntelligence,
  setBanner,
} = counterSlice.actions;

export default counterSlice.reducer;
