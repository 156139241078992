import client from '../apiClient';

const getCaseStudyConfig = (courseId) => client.get(`/caseStudies/configuration?courseId=${courseId}`);

const getCaseStudy = (courseId) => client.get(`/caseStudies/user/screen?courseId=${courseId}`);

const getScreensIQTest = (courseId) => client.get(`/caseStudies/user/screen?courseId=${courseId}`);

const postIQStartNow = (payLoad) => client.post(`/user/iq/test/start`, JSON.stringify(payLoad));

const postIQAnswer = (payLoad) => client.post(`/caseStudies/user/test`, JSON.stringify(payLoad));

const postTestCompleteStatus = (courseId, testId, payLoad) =>
  client.post(`/caseStudies/user/testResult?courseId=${courseId}&&iqTestId=${testId}`, JSON.stringify(payLoad));

const getCaseStudyResult = (courseId) => client.get(`/caseStudies/user/testResult?courseId=${courseId}`);

export default {
  getCaseStudyConfig,
  getCaseStudy,
  getScreensIQTest,
  postIQAnswer,
  postIQStartNow,
  postTestCompleteStatus,
  getCaseStudyResult,
};
