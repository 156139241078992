import React, { useState, useEffect, forwardRef } from 'react';
import { Breadcrumbs, Grid, Typography, Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AssessmentIcon from '@mui/icons-material/Assessment';

import moment from 'moment';

// custom components
import Page from '../../../../../components/Page';
import '../../../clientDetails/Tabs.css';

import PageHeader from '../../../../../components/PageHeader';
import SubscribedTable from './SubscribedTable';
import MultipleSubscribedTable from './MultipleSubscribedTable';

import Reports from '../../../../../services/report/reports';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Index() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 86400000));
  const [endDate, setEndDate] = useState(new Date());
  const [IndiviualData, setIndiviualData] = useState([]);
  const [BundleData, setBundleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [individualPageCount, setIndividualPageCount] = useState(0);
  const [bundlePageCount, setBundlePageCount] = useState(0);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start > 0 && end > 0 && dates != null) {
      if (value === 0) {
        getSubscribedDates(
          moment(start).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD'),
          'individual',
          1,
          25,
          'desc',
          'createDateTime',
          '',
          'undefined,undefined,undefined,undefined'
        );
      } else {
        getSubscribedDates(
          moment(start).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD'),
          'bundle',
          1,
          25,
          'desc',
          'createDateTime',
          '',
          'undefined,undefined,undefined,undefined'
        );
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSubscribedDates = async (
    fromDate,
    toDate,
    subscriptionType,
    page,
    rowsPerPage,
    sortOrder,
    sortKey,
    searchKey,
    filterValues = 'undefined,undefined,undefined,undefined'
  ) => {
    if (value === 0) {
      setIndiviualData([]);
    } else {
      setBundleData([]);
    }
    setLoading(true);
    try {
      const response = await Reports.getSubscribedDates(
        fromDate,
        toDate,
        subscriptionType,
        page,
        rowsPerPage,
        sortOrder,
        sortKey,
        searchKey,
        filterValues,
        `subscriptionPlanName,courseName,isPaid,status`
      );
      if (response.ok) {
        if (value === 0) {
          setIndiviualData(response.data.records);
          setIndividualPageCount(response?.data?.metadata?.totalRecordCount);
        } else {
          setBundleData(response.data.records);
          setBundlePageCount(response?.data?.metadata?.totalRecordCount);
        }

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      sx={{
        width: '200px',
        padding: '4px 4px',
        color: 'black',
        border: '1px solid black',
        fontSize: '0.9rem',
        fontWeight: '500',
        '&:hover': {
          border: '1px solid black',
          background: 'unset',
        },
      }}
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      variant="outlined"
    >
      {value}
    </Button>
  ));

  const getIndividualSubScribedUser = async (
    page,
    rowsPerPage,
    sortOrder,
    sortKey,
    searchKey,
    filterValues = 'undefined,undefined,undefined,undefined'
  ) => {
    setLoading(true);
    setIndiviualData([]);
    try {
      const responseIndividual = await Reports.getSubscribedData(
        'individual',
        page,
        rowsPerPage,
        sortOrder,
        sortKey,
        searchKey,
        filterValues,
        `subscriptionPlanName,courseName,isPaid,status`
      );
      if (responseIndividual.ok) {
        setIndiviualData(responseIndividual.data.records);
        setIndividualPageCount(responseIndividual?.data?.metadata?.totalRecordCount);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBundleSubScribedUser = async (
    page,
    rowsPerPage,
    sortOrder,
    sortKey,
    searchKey,
    filterValues = 'undefined,undefined,undefined,undefined'
  ) => {
    setLoading(true);
    setBundleData([]);
    try {
      const responseBundle = await Reports.getSubscribedData(
        'bundle',
        page,
        rowsPerPage,
        sortOrder,
        sortKey,
        searchKey,
        filterValues,
        `subscriptionPlanName,courseName,isPaid,status`
      );
      if (responseBundle.ok) {
        setBundleData(responseBundle.data.records);
        setBundlePageCount(responseBundle?.data?.metadata?.totalRecordCount);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getIndividualSubScribedUser(1, 25, 'desc', 'createDateTime', '', 'undefined,undefined,undefined,undefined');
    getBundleSubScribedUser(1, 25, 'desc', 'createDateTime', '', 'undefined,undefined,undefined,undefined');
  }, []);

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography variant="body2" color="textPrimary">
        Subscribed users
      </Typography>
    </Breadcrumbs>
  );

  return (
    <div>
      <Page title={'subscribedusers'}>
        <PageHeader pageTitle="Subscribed users " breadcrumbs={<Breadcrumb />} />
        <Box style={{ padding: '0px' }}>
          <Grid container>
            <Grid item xs="12" className={classes.gridAlign}>
              <Box className={classes.calenderStyle}>
                <Box sx={{ width: '100%', marginRight: '1rem' }}>
                  <Typography variant="h6" sx={{ width: '100%', fontWeight: ' 500', marginTop: '3px' }} gutterBottom>
                    Select Dates
                  </Typography>
                </Box>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  customInput={<ExampleCustomInput />}
                  todayButton="Today"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs="12">
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    sx={{
                      '.css-19l1g6u-MuiTabs-scroller': {
                        overflow: 'unset !important',
                      },
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab iconPosition="start" icon={<BusinessIcon />} label="Individual" {...a11yProps(0)} />
                    <Tab iconPosition="start" icon={<AssessmentIcon />} label="Bundle" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <SubscribedTable
                    IndiviualData={IndiviualData}
                    pageCount={individualPageCount}
                    loading={loading}
                    getIndividualSubscriber={getIndividualSubScribedUser}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <MultipleSubscribedTable
                    getBundleSubscriber={getBundleSubScribedUser}
                    pageCount={bundlePageCount}
                    BundleData={BundleData}
                    loading={loading}
                  />
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Page>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  calenderStyle: {
    position: 'absolute',
    zIndex: '111',
    display: 'flex',
    justifyContent: 'end',
  },
  gridAlign: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
      margin: '0rem 0rem 2rem 0rem',
    },
  },
}));
