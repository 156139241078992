import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  square: {
    bottom: '2px',
    position:'relative',
    width: '5px',
    height: '14px',
    backgroundColor: 'orange',
    transform: 'translate(-50%, -50%)',
  },
}));

function Square() {
  const classes = useStyles();

  return (
    <div className={classes.square} />
  );
}

export default Square;