import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import { convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DialogModal from '../../../components/modal/DialogModal';
import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';

const CloningCourse = ({ title, open, modelClose, submitForm, loading }) => {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.userInfo.id && state.userInfo.id);
  const [loadingbtn, setLoadingbtn] = useState(false);
  const [category, setCategory] = useState([]);
  const [ownerID, setOwnerID] = useState([]);
  const [closeModal, setCloseModal] = useState(modelClose);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [authors, setAuthors] = useState([]);

  const [initialValues, setInitialValues] = useState({
    courseCategory: null,
    copiedCourseName: '',
    courseContenter: null,
    authors:[]
  });

  const classes = useStyles();
  const content = {
    blocks: [
      {
        key: '637gr',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  };

  const contentBlock = convertFromRaw(content);

  const postCloningCourses = async (id, authors, owner, copiedCourseName) => {
    console.log('**********',authors)
    try {
      const res = await adminServices.postCloningCourses(id, owner, authors, copiedCourseName);
      if (res.ok) {
        setOpenSnackbar(true);
        setSnackbarTitle(res.data.message);
        setLoadingbtn(false);
        await navigate('/app/course');
        modelClose();
      } else if (!res.ok) {
        setOpenSnackbar(true);
        setSnackbarTitle(res.data.message);
        setLoadingbtn(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingbtn(false);
      modelClose();
    }
  };

  useEffect(async () => {
    getLookUpDetails();
    await getAuthors()
  }, []);

  const getAuthors = async (code) => {
    try{
      const response = await adminServices.getAuthors();
      if (response.ok) {
        const authArray = []
        console.log(response.data);
        (response.data).forEach(a => { authArray.push(a.name) })
        console.log('Auth array', authArray)
        setAuthors(authArray);
        
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }


  const getLookUpDetails = async () => {
    const key = ['course', 'course_category', 'courseCloneAccess'];
    const result = await adminServices.getLookUpData(key);
    if (result.ok) {
      setCategory(result.data.course);
      setOwnerID(result.data.courseCloneAccess);
    }
  };

  return (
    <>
      <DialogModal open={open} handleClose={modelClose} title={title}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            courseCategory: Yup.string().required('Required').nullable(),
            copiedCourseName: Yup.string().required('Required').nullable(),
            courseContenter: Yup.string().nullable(),
          })}
          onSubmit={(values) => {
            const owner = userId;
            const authors = values.authors
            console.log('onSubmit values',values)
            postCloningCourses(values.courseCategory.id, authors, owner, { courseName: values.copiedCourseName });
            setLoadingbtn(true);
          }}
        >
          {({ values, touched, errors, handleBlur, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    filterSelectedOptions
                    options={category.filter((data) => data.type !== 'video')}
                    autoHighlight
                    getOptionLabel={(option) => option.title}
                    value={values.courseCategory}
                    onChange={(e, value) => {
                      if (value != null) {
                        setFieldValue('courseCategory', value);
                        setFieldValue('copiedCourseName', `${value.title}  Copied`);
                      } else {
                        setFieldValue('courseCategory', value);
                        setFieldValue('copiedCourseName', ``);
                      }
                    }}
                    renderOption={(props, option) => (
                      <>
                        <Box
                          {...props}
                          sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography variant="body1" width="100%">
                            {option.title}
                          </Typography>

                          <Typography variant="body1" width="100%" sx={{ fontSize: '12px' }}>
                            Author : {option.authorName}
                          </Typography>
                        </Box>
                      </>
                    )}
                    
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="courseCategory"
                        error={Boolean(touched.courseCategory && errors.courseCategory)}
                        helperText={touched.courseCategory && errors.courseCategory}
                        label="Select course which you want to clone*"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                  <Box mt={2} mb={2}>
                    <TextField
                      fullWidth
                      type="text"
                      value={values.copiedCourseName}
                      name="copiedCourseName"
                      label="Cloning course name*"
                      onBlur={handleBlur}
                      // onChange={handleChange}
                      onChange={(e, value) => {
                        setFieldValue('copiedCourseName', e.target.value);
                      }}
                      variant="outlined"
                      error={Boolean(touched.copiedCourseName && errors.copiedCourseName)}
                      helperText={touched.copiedCourseName && errors.copiedCourseName}
                    />
                  </Box>
                  <Autocomplete
                    options={authors}
                    // filterSelectedOptions
                    autoHighlight
                    multiple
                    getOptionLabel={(option) => option.name || option || ''}
                    value={values.authors}
                    name="authors"
                    onChange={(e, value) => {                          
                      setFieldValue('authors', value)
                    }}
                    onBlur={handleBlur}
                    renderOption={(props, option) => (
                      <>
                        <Box
                          {...props}
                          sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography variant="body1" width="100%">
                            {option}
                          </Typography>
                          
                        </Box>
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                      {...params}
                      label="Select author *"
                      variant="outlined"
                      fullWidth
                      error={Boolean(touched.authors && errors.authors)}
                      helperText={touched.authors && errors.authors}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <LoadingButton id='submitButtonId' type="submit" variant="contained" color="primary" fullWidth loading={loadingbtn}>
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogModal>
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  imgPreviewRoot: {
    borderRadius: '10px',
    padding: '0.8rem;',
  },
  fileImgSIze: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
    objectPosition: 'center',
    border: '1px solid #fff',
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
  },
  badgeAlign: {
    boxShadow: '0 2px 8px -5px #ff0000',
    color: '#FF0000',
    fontSize: '1.2rem',
    backgroundColor: '#fff',
    padding: '2px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  deleteLabel: {
    width: 'max-content',
    cursor: 'pointer',
  },
}));

export default CloningCourse;
