import React, { useEffect, useState, useRef } from 'react';
import { Breadcrumbs, Grid, Typography, Box, Tabs, Tab } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import lottie from 'lottie-web';

import Page from '../../../components/Page';
import ClientInfo from './clientDetailsTabs/ClientInfo';
import User from './clientDetailsTabs/User';
import Subscription from './clientDetailsTabs/Subscription';
import Billing from './clientDetailsTabs/Billing';
import './Tabs.css';
import PageHeader from '../../../components/PageHeader';
import adminServices from '../../../services/adminServices';
import lottieData from '../../../assets/lottie/lottie-loading.json';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Index() {
  const location = useLocation();
  const lottoLoad = useRef();
  const { from } = location.state;
  const isGoBack = true;
  const [value, setValue] = React.useState(0);
  const [clientDetials, setClientDetails] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = React.useState([]);
  const [lottieLoading, setLottieLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getClientInformation = async () => {
    try {
      const response = await adminServices.getClientInfo(from);
      if (response.ok) {
        setClientDetails(response.data);
      }
      setLottieLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getUserLists = async () => {
    try {
      const response = await adminServices.getusers(from);
      if (response.ok) {
        setData(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubscription = async () => {
    try {
      const response = await adminServices.getSubscriptionData(from);
      if (response.ok) {
        setSubscriptionData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClientInformation();
    setLoading(true);
    getUserLists();
    getSubscription();
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: lottoLoad.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: lottieData,
    });
  }, []);

  const Breadcrumb = () => (
    <Breadcrumbs aria-label="breadcrumb" separator="›">
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/dashboard"
      >
        Dashboard
      </Typography>
      <Typography
        sx={{ textDecoration: 'none' }}
        variant="body2"
        color="primary"
        component={RouterLink}
        to="/app/client-users"
      >
        Users
      </Typography>
      <Typography variant="body2" color="textPrimary">
        User-details
      </Typography>
    </Breadcrumbs>
  );

  return (
    <div>
      <Page title={'Client-Details'}>
        <PageHeader pageTitle={clientDetials.organizationName} goBack={isGoBack} breadcrumbs={<Breadcrumb />} />
        <Box>
          {lottieLoading ? (
            <div style={{ width: 50, margin: 'auto' }} ref={lottoLoad} />
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab iconPosition="start" icon={<BusinessIcon />} label="Client Info" {...a11yProps(0)} />
                    <Tab iconPosition="start" icon={<GroupIcon />} label="Client Users" {...a11yProps(1)} />
                    <Tab iconPosition="start" icon={<MonetizationOnIcon />} label="Subscription" {...a11yProps(0)} />
                    <Tab
                      iconPosition="start"
                      icon={<CreditCardIcon fontSize="small" />}
                      label="Billing"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <ClientInfo clientDetials={clientDetials} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <User data={data} getUserLists={getUserLists} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Subscription getSubscription={getSubscription} subscriptionData={subscriptionData} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Billing />
                </TabPanel>
              </Grid>
            </Grid>
          )}
        </Box>
      </Page>
    </div>
  );
}
