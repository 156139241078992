import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {  Outlet, useNavigate } from 'react-router-dom';
import classnames from 'classnames';
// material
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Box, Container, Stack, AppBar, Toolbar, Typography, List, ListItem, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';

import Logo from '../../assets/logo/logo.png';
import AccountPopover from '../dashboard/AccountPopover';
import palette from '../../theme/palette';
import ThemeProvider from '../../theme';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 68;

const RootStyle = styled(AppBar)(({ theme }) => ({
  //   boxShadow: 'none',
  background: 'rgb(253 253 253 / 71%);',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  boxShadow: '0px -30px 28px -3px #686464',
  borderBottom: '2px solid #00B673',

  //   backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    //     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    width: '100%',
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 2),
  },
}));

const MainRootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  // flexGrow: 1,
  width: '100%',
  overflow: 'auto',
  minHeight: '100%',
  background: '#FAFAFA',
  // paddingLeft: theme.spacing(2.5),
  // paddingRight: theme.spacing(2.5),
  // paddingTop: APP_BAR_MOBILE,
  // paddingBottom: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    //   paddingTop: APP_BAR_DESKTOP - 8,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

UserLayout.propTypes = {
  onOpenSidebar: PropTypes.func,
};

/* const LinkBtn = React.forwardRef((props, ref) => {
  return <NavLink to={props.to} {...props} innerRef={ref} />;
}); */

export default function UserLayout({ onOpenSidebar}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [activeList, SetActiveList] = useState('Courses');
  const userInfo = useSelector((state) => state.userInfo);
  const navItem = [{ name: 'Courses', path: '/app/course' }];
  
  /* useEffect(() => {
    removeWeglot();
  });

  const removeWeglot = async () => {
    const res = await helper.removeScript('https://cdn.weglot.com/weglot.min.js');
    window.Weglot.initialize({
      api_key: 'wg_014b43b2959a227f6bd358580eba225b9',
    });
  }; */

  return (
    <>
      <ThemeProvider>
        <MainRootStyle>
          <RootStyle>
            <Container className={classes.container}>
              <ToolbarStyle>
                <Box
                  onClick={() => navigate('/app/course')}
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <img src={Logo} width="110px" alt="logo" />
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                <List sx={{ display: 'flex' }}>
                  {userInfo.role === 'AUTH_USER' &&
                    navItem.map((res) => (
                      <ListItem
                        button
                        disableRipple
                        onClick={() => {
                          navigate(res.path);
                          SetActiveList(res.name);
                        }}
                        className={classnames(classes.linkText, {
                          [classes.linkTextActive]: activeList === res.name,
                        })}
                      >
                        <ListItemText
                          sx={{
                            '.css-6h10z0-MuiTypography-root': {
                              fontSize: '1rem !important',
                              fontWeight: '400',
                              letterSpacing: '0.4px',
                            },
                          }}
                        >
                          {userInfo.name}
                        </ListItemText>
                      </ListItem>
                    ))}
                </List>

                {userInfo.role === 'CONTENT_WRITER' && (
                  <Typography
                    variant="subtitle1"
                    // component={LinkBtn}
                    // to={'/app/course'}
                    color="primary"
                    // sx={{
                    //   '&:hover': {
                    //     cursor: 'pointer',
                    //   },
                    // }}
                  >
                    {userInfo.name}
                  </Typography>
                )}

                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }} ml={3}>
                  <AccountPopover />
                </Stack>
              </ToolbarStyle>
            </Container>
          </RootStyle>

          <MainStyle>
            <Outlet />
          </MainStyle>
        </MainRootStyle>
      </ThemeProvider>
    </>
  );
}

const useStyles = makeStyles(() => ({
  linkText: {
    color: '#000',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  linkTextActive: {
    color: palette.primary.main,
    fontSize: '1.2rem',
  },
  container: {
    maxWidth: '1536px',
    '@media (min-width: 601px)': {
      padding: '0px 2rem',
    },

    '@media (min-width: 1201px)': {
      padding: ' 0px 2rem',
    },
    '@media (max-width: 600px)': {
      padding: '0px 1rem',
    },
  },
}));
