import React from 'react';
import { makeStyles } from '@mui/styles';

export default function Courses(props) {
  const classes = useStyles();

  return (
    <div style={{ marginTop: '1rem' }}>
      <table className={classes.table}>
        <tr>
          <th className={classes.tableHeader}>Course Name</th>
        </tr>
        {props.userDetailsLength ? (
          props.courseData.map((item, index) => (
            <tr key={index}>
              <td className={classes.tableData}>{item.courseName}</td>
            </tr>
          ))
        ) : (
          <td className={classes.tableData} style={{ textAlign: 'center' }}>
            No data found
          </td>
        )}
      </table>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'collapse',
    borderRadius: '4px',
    width: '100%',
    boxShadow:
      '0px 2px 4px -1px rgb(145 158 171 / 20%), 0px 4px 5px 0px rgb(145 158 171 / 14%), 0px 1px 10px 0px rgb(145 158 171 / 12%)',
  },
  tableHeader: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '8px',
    padding: '10px',
    textAlign: 'left',
    fontWeight: '400',
    color: 'rgb(26, 27, 37)',
  },
  tableData: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: '8px',
    padding: '10px',
  },
}));
