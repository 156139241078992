import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Button, Chip, Snackbar, Alert } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { LoadingButton } from '@mui/lab';
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import AddSubscriptionForm from './AddSubscriptionForm';
import AddUsersForm from './AddUsers';
import BasicModel from '../../../../components/modal/BasicModel';
import adminServices from '../../../../services/adminServices';
import SnackBar from '../../../../components/snackbar/snackbar';
import DeleteAlert from '../../../../components/modal/DeleteModal';
import UsersTable from './SubscriptionUsersTable';

export default function Subscription(props) {
  const classes = useStyles();
  const location = useLocation();
  const courseId = location.state;
  const [handleOpenModal, setHandleOpenModal] = React.useState(false);
  const [handleOpenUserModal, setHandleOpenUserModal] = React.useState(false);
  const [mode, setMode] = React.useState('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [status, setStatus] = React.useState([]);
  const [subscriptionId, setSubscriptionId] = React.useState();
  const [clientPlanId, setClientPlanId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [values, setValues] = React.useState('');
  const [buttonIndex, setButtonIndex] = React.useState(null);
  const [coursePlanId, setCoursePlanId] = React.useState();
  const [error, setError] = React.useState([]);
  const [openAlertModal, setOpenAlertModal] = React.useState(false);
  const [enrolledUser, setEnrolledUser] = useState([]);
  const [handleOpenUserTable, setHandleOpenUserTable] = React.useState(false);
  const [userCourseId, setUserCourseId] = useState();
  const [userClientId, setUserClientId] = useState();

  // console.log(coursePlanId, 'coursePlanId');

  const open = Boolean(anchorEl);

  const handleClickMenu = (event, id, clientId, courseId) => {
    setAnchorEl(event.currentTarget);
    setSubscriptionId(id);
    setClientPlanId(clientId);
    setCoursePlanId(courseId);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleErrorMessage = (message) => {
    setError(message);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlertModal(false);
  };

  const handleModal = () => {
    setHandleOpenModal(true);
    setMode('create');
  };

  const handleEdit = () => {
    setHandleOpenModal(true);
    setMode('edit');
  };
  const handleDeleteModalOpen = () => {
    setDeleteAlert(true);
    setAnchorEl(null);
  };

  const handleUserTableClose = () => {
    setHandleOpenUserTable(false);
    setEnrolledUser([]);
  };

  const handleClickTable = (event, courseId, clientId, clientPlanId) => {
    getEnrolledUserById(courseId, clientId, clientPlanId);
    setUserCourseId(courseId);
    setUserClientId(clientId);
  };

  const handleAddUser = () => {
    setHandleOpenUserModal(true);
  };

  const handleUserClose = () => {
    setHandleOpenUserModal(false);
  };

  const snackbarHandle = (message) => {
    setOpenSnackbar(true);
    setSnackbarTitle(message);
  };
  const handleClose = () => {
    setHandleOpenModal(false);
  };

  const getEnrolledUserById = async (courseId, clientId, clientPlanId) => {
    setLoading(true);
    // console.log(subscriptionId, 'subscriptionId');
    try {
      const result = await adminServices.getEnrolledUser(courseId, clientId, clientPlanId);
      if (result.ok) {
        setEnrolledUser(result.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await adminServices.deleteSubsrciptionplanByID(subscriptionId);
      if (response.ok) {
        props.getSubscription();
        setAnchorEl(null);
        setDeleteAlert(false);
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getStatusLookUp = async () => {
    try {
      const res = await adminServices.getLookUpData('submission_status');
      if (res.ok) {
        setStatus(res.data.submission_status);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleStatus = async (data, index) => {
    setButtonIndex(index);
    try {
      const response = await adminServices.handleSubscriptionStatus(subscriptionId, {
        status: data,
      });
      if (response.ok) {
        props.getSubscription();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setAnchorEl(null);
      } else {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
      setAnchorEl(null);
      setButtonIndex(null);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStatusLookUp();
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'clientId',
      label: 'client Id',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'courseId',
      label: 'course Id',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'remarks',
      label: 'Remarks',
      options: {
        sort: false,
        display: false,
      },
    },
    {
      name: 'clientPlanName',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'planName',
      label: 'Plan',
    },
    {
      name: 'courseName',
      label: 'Course',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'noOfLicenseAllocated',
      label: 'No of License Purchased',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'noOfLicenseAssigned',
      label: 'No of License Used',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Typography
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: '#FE7000',
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: '#FE7000',
                  },
                }}
                onClick={(e) => {
                  handleClickTable(e, tableMeta.rowData[2], tableMeta.rowData[1], tableMeta.rowData[0]);
                  setHandleOpenUserTable(true);
                }}
              >
                {value}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'validFrom',
      label: 'Valid From',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Typography sx={{ minWidth: 'max-content' }} variant="body2">
                {moment(value).format('DD-MM-YYYY')}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'validTo',
      label: 'Valid Till',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Typography sx={{ minWidth: 'max-content' }} variant="body2">
                {moment(value).format('DD-MM-YYYY')}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Chip label={value} />
            </>
          );
        },
      },
    },
    {
      name: 'status ',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>
                <IconButton
                  onClick={(e) => {
                    handleClickMenu(e, tableMeta.rowData[0], tableMeta.rowData[1], tableMeta.rowData[2]);
                    setValues(tableMeta.rowData[8]);
                  }}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <MoreVertIcon />
                </IconButton>
                <ThemeProvider theme={customeTheme}>
                  <Menu
                    id="basic-menu"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <div>
                      <div>
                        <MenuItem
                          onClick={() => {
                            setAnchorEl(null);
                            handleEdit();
                          }}
                        >
                          <EditIcon fontSize="small" style={{ color: '#BCBCBC', marginLeft: '2px' }} />
                          &nbsp;
                          <Typography>Edit</Typography>
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          onClick={() => {
                            handleDeleteModalOpen();
                            setAnchorEl(null);
                          }}
                        >
                          <DeleteIcon fontSize="small" style={{ color: '#BCBCBC' }} />
                          &nbsp;
                          <Typography>Delete</Typography>
                        </MenuItem>
                      </div>
                      <div>
                        <MenuItem
                          onClick={() => {
                            handleAddUser();
                            setAnchorEl(null);
                          }}
                        >
                          <PersonAddIcon fontSize="small" style={{ color: '#BCBCBC' }} />
                          &nbsp;
                          <Typography>Enroll User</Typography>
                        </MenuItem>
                      </div>
                    </div>
                    <div style={{ margin: '1rem' }}>
                      {status &&
                        status.map((item, index) => {
                          return (
                            <>
                              {values && values === item.code ? null : (
                                <LoadingButton
                                  onClick={() => {
                                    handleStatus(item.code, index);
                                  }}
                                  style={{ margin: '3px', borderRadius: '50px' }}
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  type="submit"
                                  loading={index === buttonIndex}
                                >
                                  <Typography>{item.desctiption}</Typography>
                                </LoadingButton>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </Menu>
                </ThemeProvider>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
  };
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <Grid container spacing={3}>
      <Grid item xs="12">
        <div className={classes.button}>
          <Button onClick={handleModal} variant="contained" color="primary">
            Add Subscription
          </Button>
        </div>
        <div style={{ marginTop: '1rem' }}>
          <MUIDataTable
            data={props.subscriptionData}
            columns={columns}
            options={{
              rowsPerPage: 25,
              responsive: 'scroll',
              rowsPerPageOptions: [25, 50, 75, 100],
              selectableRows: false,
              filter: false,
              download: false,
              print: false,
              textLabels: {
                body: {
                  noMatch: props.tableLoading ? 'Loading...' : 'Sorry, there is no matching data to display',
                },
              },
            }}
          />
        </div>
      </Grid>

      <BasicModel
        openModel={handleOpenModal}
        title={mode === 'create' ? 'Add Subscription' : 'Edit Subscription'}
        closeModel={handleClose}
        children={
          <AddSubscriptionForm
            snackBarControl={snackbarHandle}
            closeModel={handleClose}
            handleCloseMenu={handleCloseMenu}
            mode={mode}
            subscriptionId={subscriptionId}
            getSubscription={props.getSubscription}
          />
        }
      />
      <DeleteAlert
        open={deleteAlert}
        title="Are you sure you want delete this Subscription"
        confirm={handleDelete}
        close={() => setDeleteAlert(false)}
      />

      <BasicModel
        openModel={handleOpenUserModal}
        title={'Add User'}
        closeModel={handleUserClose}
        children={
          <AddUsersForm
            snackBarControl={snackbarHandle}
            closeModel={handleUserClose}
            handleCloseMenu={handleCloseMenu}
            mode={mode}
            subscriptionId={subscriptionId}
            clientPlanId={clientPlanId}
            coursePlanId={coursePlanId}
            getSubscription={props.getSubscription}
            setOpenAlertModal={setOpenAlertModal}
            setErrorMessage={handleErrorMessage}
          />
        }
      />

      <BasicModel
        maxWidth={'md'}
        openModel={handleOpenUserTable}
        title={'Enrolled Users'}
        closeModel={handleUserTableClose}
        children={
          <UsersTable
            enrolledUser={enrolledUser}
            getEnrolledUserById={(courseId, ClientId, clientPlanId) =>
              getEnrolledUserById(courseId, ClientId, clientPlanId)
            }
            userCourseId={userCourseId}
            userClientId={userClientId}
            snackBarControl={snackbarHandle}
            closeModel={handleUserTableClose}
            getSubscription={props.getSubscription}
            loading={loading}
            setLoading={setLoading}
          />
        }
      />

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openAlertModal}
        onClose={handleCloseAlert}
      >
        {error.length > 0 ? (
          <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
            <div>
              {' '}
              <table style={{ borderCollapse: 'collapse', padding: '2rem,0rem' }}>
                <tr style={{ textAlign: 'left' }}>
                  <th className={classes.tableData}>Email</th>
                  <th className={classes.tableData}>Error</th>
                </tr>
                {error.map((items, index) => {
                  return (
                    <tr>
                      <td className={classes.tableData}>{items.email}</td>
                      <td className={classes.tableData}>{items.error}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
            <Typography variant="body2">User Enrolled Successfully</Typography>
          </Alert>
        )}
      </Snackbar>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down(514)]: {
      marginTop: '14px',
    },
  },
}));

const customeTheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&.MuiModal-backdrop': {
            background: 'transparent',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter,SemiBold',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          color: '#FE7000',
          border: '1px solid #FE7000',
          '&:hover': {
            border: '1px solid #FE7000',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          width: '13rem',
          '&.MuiPopover-paper': {
            boxShadow: 'rgba(0, 0, 0, 0.01) 0px 2px 4px 2px',
          },
        },
      },
    },
  },
});
