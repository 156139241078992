import React, { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import katex from "katex";
import "katex/dist/katex.min.css";
import { Avatar, Badge, Grid, FormControl, FormHelperText, Typography, Button, Radio, RadioGroup, Link, Tooltip, InputLabel,IconButton } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Checkbox from '@mui/material/Checkbox';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik, FieldArray } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import AppleIcon from '@mui/icons-material/Apple';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LoadingButton } from '@mui/lab';
import ClearIcon from '@mui/icons-material/Clear';
import DialogModal from '../../../components/modal/DialogModal';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import LinearProgressBar from '../../../components/progress/LinearProgressBar';
import WindowsIcon from '../../../assets/logo/windows.svg';
import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
    },
}));

window.katex = katex;

const modules = {
  toolbar: [
    ["bold", "italic", "underline"], // toggled buttons
    // ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],
    ["image", { formula: { customClass: 'qlformula' } }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["clean"], // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};


const descriptionModules = {
  toolbar: [
    ["bold", "italic", "underline"], // toggled buttons
    // ["blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ header: [1, 2, 3, false] }],
    [{ color: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ["clean"], // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
 
const descriptionFormats = [
 "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
  "align",
  "code-block",
  "script",
  "clean",
];
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "formula",
  "color",
  "background",
  "align",
  "code-block",
  "script",
  "clean",
];
// const formats = [
//   'header', 'font', 'list', 'bold', 'italic', 'underline', 'color', 'background', 'align', 'math'
// ];
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const editorPlugin = [
  'a11ychecker',
  'advlist',
  'advcode',
  'advtable',
  'autolink',
  'checklist',
  'export',
  'lists',
  'link',
  'image',
  'charmap',
  'preview',
  'anchor',
  'searchreplace',
  'visualblocks',
  'powerpaste',
  'fullscreen',
  'formatpainter',
  'insertdatetime',
  'media',
  'table',
  'help',
  'wordcount',
];

const toolbarStyle =
  'undo redo | casechange blocks | bold italic backcolor | ' +
  'alignleft aligncenter alignright alignjustify | ' +
  'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help';

const CreateMcqQuestionModel = ({ open, modelClose, title, handleCreateMcq, loading, searchedDetails }) => {
    const [combinedWKeys, setCombinedWKeys] = useState([]);
    const [combinedWKeyName, setCombinedWKeyName] = useState([]);
    const [combinedMKeys, setCombinedMKeys] = useState([]);
    const [combinedMKeyName, setCombinedMKeyName] = useState([]);
    const [widowKeys, setWidowKeys] = useState([]);
    const [macKeys, setMacKeys] = useState([]);
    const [useWindowVirtualKeys, setUseWindowVirtualKeys] = useState(false);
    const [useMacVirtualKeys, setUseMacVirtualKeys] = useState(false);
    const [category, setCategory] = useState([]);
    const [keyword, setKeyWord] = useState([]);
    const [subModule, setSubModule] = useState([]);
    const [allSubModules, setAllSubModules] = useState([]);
    const [submitError, setSubmitError] = useState('');
    const [currectError,setCurrectError] = useState('')
    const [initialValues, setInitialValues] = useState({
        question: '',
        questionType: '',
        mcqOptions: [],
        level: '',
        points: null,
        windowsKey: '',
        keyType: '',
        macKey: '',
        combinedWindowKeys: [],
        combinedMacKeys: [],
        combinedWindowKeyName: [],
        combinedMacKeyName: [],
        course: null,
        module: '',
        subModule: '',
        courseCategory: null,
        tag: '',
        keyword: [],

    });
    useEffect(() => {
        setCombinedWKeys([]);
        setCombinedMKeys([]);
        setCombinedWKeyName([]);
        setCombinedMKeyName([]);
        getKeyboardLookUp();
    }, [open]);
    // console.log("details....>",searchedDetails)
    // useEffect(()=>{
    //     setInitialValues({
    //         question: searchedDetails.question_text,
    //         questionType: searchedDetails.question_type,
    //         level: searchedDetails.level,
    //         points: searchedDetails.points,
    //         windowsKey: searchedDetails?.windows?.keyName.join(' ') || '',
    //         macKey: searchedDetails?.mac?.keyName.join(' ') || '',
    //         combinedWindowKeys: searchedDetails?.windows?.keyCode || [],
    //         combinedMacKeys: searchedDetails?.mac?.keyCode || [],
    //         combinedWindowKeyName: searchedDetails?.windows?.keyName || [],
    //         combinedMacKeyName: searchedDetails?.mac?.keyName || [],


    //     })
    // },[searchedDetails])

    const getKeyboardLookUp = async () => {
        const lookUpResponse = await adminServices.getLookUpData('keyboard_keys');
        if (lookUpResponse.ok) {
            // setWidowKeys();
            const windowArray = lookUpResponse.data.keyboard_keys.filter((a, b) => a.keyboard === 'WINDOWS');
            const macArray = lookUpResponse.data.keyboard_keys.filter((a, b) => a.keyboard !== 'WINDOWS');
            setWidowKeys(windowArray);
            setMacKeys(macArray);
        }
    };

    const validationSchema = Yup.object().shape({
        question: Yup.string().required('Question is required'),
        level: Yup.string().required('Level is required'),
        questionType: Yup.string().required('Question type is required'),
        mcqOptions: Yup.array().of(
            Yup.object().shape({
                option: Yup.string().required('Option field is required'),
                isCorrect: Yup.boolean(),
            })
        ).required('At least one option is required'),
        keyType: Yup.string().when('questionType', {
            is: 'IQ',
            then: Yup.string().required('Key Type is required for IQ questions'),
            otherwise: Yup.string().nullable(),
        }),
    });
    const getLookUpDetails = async () => {
        const key = ['course', 'course_category', 'hashtag'];
        const result = await adminServices.getLookUpData(key);
        if (result.ok) {
            setCategory(result.data.course_category);
            setKeyWord(result.data.hashtag);
        }
    };
    useEffect(() => {
        getLookUpDetails()
    }, [])

    const classes = useStyles();

    return (
        <DialogModal open={open} handleClose={modelClose} title={title}>
      <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setSubmitError('');
                setCurrectError('');
                console.log(values,'sfdgf');
                
                const hasAtLeastOneCorrect = values.mcqOptions?.some(option => option.isCorrect);
                if (values.mcqOptions.length < 3&&values.questionType==="MCQ") {
                    setSubmitError('You must provide at least 3 options.');
                    return; 
                }
                  if (hasAtLeastOneCorrect ===false&&values.questionType==="MCQ"){
                  setCurrectError("You must select at least one correct answer")
                  return; 
                 }
                const transformedValues = {
                    ...values,
                    mcqOptions: values.mcqOptions.map(opt => ({
                        ...opt,
                        isCorrect: opt.isCorrect === true,
                    })),
                };

                handleCreateMcq(transformedValues);
            }}
        >
                {({ values, touched, errors, handleBlur, handleChange, setFieldValue, isSubmitting }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl} error={touched.points && Boolean(errors.points)}>
                                    <Autocomplete
                                        id="CourseCategory"
                                        fullWidth
                                        filterSelectedOptions
                                        autoComplete
                                        options={category}
                                        autoHighlight
                                        getOptionLabel={(option) => option.description}
                                        value={category.find(cat => cat.code === values.courseCategory) || null}
                                        onChange={(e, value) => {
                                            const codeValue = value ? value.code : null;
                                            setFieldValue('courseCategory', codeValue);
                                        }}
                                        getOptionSelected={(option, value) => option.code === value}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img loading="lazy" width="20" src={option.categoryImgUrl} alt="i" />
                                                {option.description}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="courseCategory"
                                                error={Boolean(touched.courseCategory && errors.courseCategory)}
                                                helperText={touched.courseCategory && errors.courseCategory}
                                                label="Question category"
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl className={classes.formControl} error={touched.points && Boolean(errors.points)}>
                                    <Autocomplete
                                        id="questionTag"
                                        fullWidth
                                        filterSelectedOptions
                                        autoComplete
                                        multiple
                                        options={keyword}
                                        autoHighlight
                                        getOptionLabel={(option) => option.description}
                                        value={
                                            values.keyword
                                                ? keyword.filter(cat => values.keyword.includes(cat.code))
                                                : []
                                        }
                                        onChange={(e, value) => {
                                            const selectedCodes = value ? value.map(item => item.code) : [];
                                            setFieldValue('keyword', selectedCodes);
                                        }}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.description}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="keyword"
                                                error={Boolean(touched.keyword && errors.keyword)}
                                                helperText={touched.keyword && errors.keyword}
                                                label="Tag"
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl className={classes.formControl} error={touched.questionType && Boolean(errors.questionType)}>
                                    <InputLabel id="demo-simple-select-standard-label">Level*</InputLabel>
                                    <Select
                                        name="level"
                                        labelId="demo-simple-select-standard-label"
                                        id="level"
                                        label="Level"
                                        value={values.level}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        displayEmpty
                                    >
                                        <MenuItem value="easy">Easy</MenuItem>
                                        <MenuItem value="medium">Medium</MenuItem>
                                        <MenuItem value="complex">Complex</MenuItem>
                                    </Select>
                                    {touched.level && errors.level && (
                                        <FormHelperText>{errors.level}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl className={classes.formControl} error={touched.questionType && Boolean(errors.questionType)}>
                                    <InputLabel id="demo-simple-select-standard-label">Question Type*</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="questionType"
                                        name="questionType"
                                        label="Question Type"
                                        value={values.questionType}
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            setFieldValue('questionType', e.target.value)
                                            if (e.target.value === 'IQ') {
                                                setFieldValue("mcqOptions", [])
                                                setFieldValue("question", '')
                                            } else if (e.target.value === 'MCQ') {
                                                setFieldValue("keyType", '')
                                                setFieldValue("question", '')
                                                setFieldValue("windowsKey", '')
                                                setFieldValue("macKey", '')
                                                // setFieldValue("combinedWindowKeys",[])
                                                // setFieldValue("combinedMacKeys",[])
                                                // setFieldValue("combinedWindowKeyName",[])
                                                // setFieldValue("combinedMacKeyName",[])
                                            }
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem value="MCQ">MCQ Question</MenuItem>
                                        <MenuItem value="IQ">IQ Question</MenuItem>
                                    </Select>
                                    {touched.questionType && errors.questionType && (
                                        <FormHelperText>{errors.questionType}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl className={classes.formControl} error={touched.points && Boolean(errors.points)}>
                                    <TextField
                                        name="points"
                                        type="number"
                                        id="points"
                                        label="Points"
                                        variant="outlined"
                                        value={values.points}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        onKeyDown={(e) => {
                                            if (e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && (e.key < '0' || e.key > '9')) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {touched.points && errors.points && (
                                        <FormHelperText>{errors.points}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl className={classes.formControl} error={touched.question && Boolean(errors.question)}>
                                    <Typography className={classes.background} gutterBottom variant="subtitle1">
                                        Create Question*
                                    </Typography>
                                    <ReactQuill
                                        theme="snow"
                                        id='questionText'
                                        name="question"
                                        value={values.question || ''}
                                        onChange={(value) => {
                                            setFieldValue('question', value);
                                          }}
                                        modules={modules}
                                        formats={formats}
                                        onBlur={() => {
                                            handleBlur({ target: { name: 'question' } });
                                          }}                                       
                                        fullWidth
                                    />
                                    {touched.question && errors.question && (
                                        <FormHelperText>{errors.question}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {values.questionType === "MCQ" && (
                               <FieldArray name="mcqOptions">
                               {({ insert, remove, push }) => (
                                   <Grid item xs={12}>
                                       {values.mcqOptions && values.mcqOptions.length > 0 &&
                                           values.mcqOptions.map((opt, index) => (
                                               <div style={{ position: 'relative', marginTop: '10px' }} key={index}>
                                                   <Grid container spacing={2} alignItems="center">
                                                       <Grid container spacing={2} alignItems="center">
                                                           <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginLeft: 40 }}>
                                                               <ReactQuill
                                                                   theme="snow"
                                                                   name="mcqQuestion"
                                                                   value={opt.option}
                                                                   onChange={(value) => {
                                                                       setFieldValue(`mcqOptions.${index}.option`, value);
                                                                   }}
                                                                   modules={modules}
                                                                   formats={formats}
                                                                   placeholder="Option"
                                                                   style={{ marginTop: 10, flex: 1 }}
                                                               />
                                                               <IconButton
                                                                   aria-label="delete"
                                                                   color="error"
                                                                   onClick={() => remove(index)}
                                                                   style={{ marginLeft: '-8px', marginTop: '-8px' }}
                                                               >
                                                                   <ClearIcon fontSize="small" />
                                                               </IconButton>
                                                               <FormControlLabel
                                                                   control={
                                                                       <Checkbox
                                                                           name={`mcqOptions.${index}.isCorrect`}
                                                                           checked={opt.isCorrect}
                                                                           onChange={() => {
                                                                               if (opt.option.trim()) {
                                                                                   setFieldValue(`mcqOptions.${index}.isCorrect`, !opt.isCorrect);
                                                                               }
                                                                               setCurrectError('');
                                                                           }}
                                                                           disabled={!opt.option.trim()}
                                                                       />
                                                                   }
                                                                   label="Correct"
                                                               />
                                                           </Grid>
                                                           {errors.mcqOptions?.[index]?.option && (
                <div className="error-text"style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                    {errors.mcqOptions[index].option}
                </div>
            )}
                                                       </Grid>
                                                   </Grid>
                                               </div>
                                           ))}
                                       {submitError && (
                                           <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                               {submitError}
                                           </div>
                                       )}
                                       {currectError && (
                                           <div style={{ color: 'red', marginBottom: '10px', marginLeft: '100px', fontSize: '12px' }}>
                                               {currectError}
                                           </div>
                                       )}
                                       <button
                                           type="button"
                                           id="addOptionButton"
                                           style={{
                                               width: '90%',
                                               backgroundColor: 'rgb(63, 186, 150)',
                                               color: 'white',
                                               border: 'none',
                                               borderRadius: '6px',
                                               padding: '10px 20px',
                                               margin: '10px',
                                               cursor: 'pointer',
                                           }}
                                           onClick={async () => {
                                               if (values.mcqOptions.every(option => option.option.trim())) {
                                                   await new Promise((resolve) =>
                                                       setTimeout(() => {
                                                           push({ option: "", isCorrect: false });
                                                           resolve();
                                                       }, 500)
                                                   );
                                               }
                                               setSubmitError('');
                                           }}
                                       >
                                           Add Option
                                       </button>
                                   </Grid>
                               )}
                           </FieldArray>

                            )}


                            {
                                values.questionType === "IQ" && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography gutterBottom variant="subtitle1">
                                                Key Type
                                            </Typography>
                                            <FormControl className={classes.formControl} error={touched.points && Boolean(errors.points)}>
                                                <RadioGroup
                                                    id='keyType'
                                                    row
                                                    name="keyType"
                                                    value={values.keyType}
                                                    onChange={(e) => {
                                                        // setKeyType(e.target.value)
                                                        setFieldValue('keyType', e.target.value)
                                                    }}
                                                >
                                                    <FormControlLabel value="INDIVIDUAL_HOT_KEYS" control={<Radio />} label="Individual Key" />
                                                    <FormControlLabel value="COMBINED_HOT_KEYS" control={<Radio />} label="Combined Keys" />
                                                </RadioGroup>
                                                {touched.keyType && errors.keyType && (
                                                    <FormHelperText style={{ color: '#FF4842' }} >{errors.keyType}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align="left" gutterBottom sx={{ marginRight: '1.2rem !important' }}>
                                                Enter Key
                                            </Typography>
                                            <Box display={'flex'} alignItems="center" justifyContent={'space-around'}>
                                                <div className={classes.iconPosition}>
                                                    <img src={WindowsIcon} alt="icon" />
                                                </div>
                                                <Box textAlign="center" paddingTop="8px">
                                                    <TextField
                                                        sx={{ width: '100px' }}
                                                        id="outlined-basic"
                                                        autoComplete="off"
                                                        variant="outlined"
                                                        name="windowsKey"
                                                        onBlur={handleBlur}
                                                        onChange={(event) => console.log(event)}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                            console.log(e);
                                                            if (values.keyType === 'INDIVIDUAL_HOT_KEYS') {
                                                                const wKeyCode = [];
                                                                const wKeyNames = [];
                                                                const windowsToMacKeyMap = {
                                                                    'Control': 'Command',
                                                                    'Alt': 'Option',
                                                                    'Enter': 'Return',
                                                                    'Backspace': 'Delete',
                                                                };
                                                                wKeyCode.push(e.keyCode);
                                                                wKeyNames.push(e.keyCode === 32 ? 'space' : e.key);

                                                                const macKeyNameArray = wKeyNames.map(key => windowsToMacKeyMap[key] || key);

                                                                setCombinedWKeyName(wKeyNames);
                                                                setCombinedMKeyName(macKeyNameArray);
                                                                setFieldValue('windowsKey', e.key);
                                                                setFieldValue('macKey', e.key);
                                                                setFieldValue('combinedWindowKeys', wKeyCode);
                                                                setFieldValue('combinedWindowKeyName', wKeyNames);
                                                                setFieldValue('combinedMacKeyName', macKeyNameArray);
                                                                setFieldValue('combinedMacKeys', wKeyCode);
                                                                setFieldValue('windowsKey', e.keyCode === 32 ? 'space' : e.key);
                                                                setFieldValue('macKey', e.keyCode === 32 ? 'space' : windowsToMacKeyMap[e.key] || e.key);
                                                            } else {
                                                                const keyCodeArray = combinedWKeys;
                                                                const keyNameArray = combinedWKeyName;
                                                                if (keyCodeArray.length > 3) {
                                                                    // setOpenSnackbar(true);
                                                                    // setSnackbarTitle('Maximum keys are 4');
                                                                    return false;
                                                                }
                                                                const windowsToMacKeyMap = {
                                                                    'Control': 'Command',
                                                                    'Alt': 'Option',
                                                                    'Enter': 'Return',
                                                                    'Backspace': 'Delete',
                                                                };

                                                                keyNameArray.push(e.keyCode === 32 ? 'space' : e.key);
                                                                keyCodeArray.push(e.keyCode.toString());

                                                                // Convert Windows keys to Mac keys
                                                                const macKeyNameArray = keyNameArray.map(key => windowsToMacKeyMap[key] || key);

                                                                setCombinedWKeys(keyCodeArray);
                                                                setCombinedWKeyName(keyNameArray);
                                                                setCombinedMKeys(keyCodeArray);
                                                                setCombinedMKeyName(macKeyNameArray);

                                                                setFieldValue('combinedWindowKeys', combinedWKeys);
                                                                setFieldValue('combinedMacKeys', combinedMKeys);
                                                                setFieldValue('combinedWindowKeyName', combinedWKeyName);
                                                                setFieldValue('combinedMacKeyName', macKeyNameArray);
                                                                setFieldValue('windowsKey', e.keyCode === 32 ? 'space' : e.key);
                                                                setFieldValue('macKey', e.keyCode === 32 ? 'space' : windowsToMacKeyMap[e.key] || e.key);
                                                            }
                                                        }}
                                                        type="text"
                                                        value={values.windowsKey}
                                                        error={Boolean(touched.windowsKey && errors.windowsKey)}
                                                        helperText={touched.windowsKey && errors.windowsKey}
                                                    />
                                                    <ErrorFocus />
                                                </Box>
                                            </Box>
                                            <Box marginTop={1}>
                                                {useWindowVirtualKeys ? (
                                                    <Autocomplete
                                                        options={widowKeys}
                                                        autoHighlight
                                                        sx={{
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '0px 6px !important',
                                                            },
                                                        }}
                                                        filterSelectedOptions
                                                        getOptionLabel={(option) => option.description || ''}
                                                        value={values.hashtag}
                                                        onChange={(e, value) => {
                                                            if (value) {
                                                                if (values.keyType === 'INDIVIDUAL_HOT_KEYS') {
                                                                    const wKeyCode = [];
                                                                    const wKeyNames = [];
                                                                    wKeyCode.push(value.code);
                                                                    wKeyNames.push(value.description);
                                                                    setCombinedWKeyName(wKeyNames);
                                                                    setFieldValue('combinedWindowKeys', wKeyCode);
                                                                    setFieldValue('combinedWindowKeyName', wKeyNames);
                                                                    setFieldValue('windowsKey', value.description);
                                                                    setUseWindowVirtualKeys(false);
                                                                } else {
                                                                    const keyCodeArray = combinedWKeys;
                                                                    const keyNameArray = combinedWKeyName;
                                                                    keyNameArray.push(value.description);
                                                                    keyCodeArray.push(value.code);
                                                                    setCombinedWKeys(keyCodeArray);
                                                                    setCombinedWKeyName(keyNameArray);
                                                                    setFieldValue('combinedWindowKeys', combinedWKeys);
                                                                    setFieldValue('combinedWindowKeyName', combinedWKeyName);
                                                                    setFieldValue('windowsKey', value.description);
                                                                    setUseWindowVirtualKeys(false);
                                                                }
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                placeholder="select key"
                                                                {...params}
                                                                variant="outlined"
                                                                name="hashtag"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                ) : (
                                                    // <Typography variant="body2" align="center" sx={{ cursor: 'pointer' }}>
                                                    //   If you don't find the relevant keys on your keyboard{' '}
                                                    <Tooltip arrow title=" If you don't find the relevant keys on your keyboard? Click on Keys">
                                                        <Link
                                                            onClick={() => setUseWindowVirtualKeys(true)}
                                                            sx={{ textDecoration: 'none', cursor: 'pointer', fontSize: '0.7rem' }}
                                                        >
                                                            Find keys
                                                        </Link>
                                                    </Tooltip>
                                                    // </Typography>
                                                )}
                                            </Box>

                                            <Box display={'flex'} marginTop={1}>
                                                {combinedWKeyName.map((res, i) => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography className={classes.boxItem}>{res}</Typography>
                                                        {i < combinedWKeyName.length - 1 && (
                                                            <Typography variant="h6" sx={{ margin: '4px !important' }}>
                                                                +
                                                            </Typography>
                                                        )}
                                                    </div>
                                                ))}
                                            </Box>
                                            {combinedWKeyName.length > 0 && (
                                                <Typography
                                                    sx={{
                                                        marginTop: '8px !important',
                                                        cursor: 'pointer',
                                                        width: 'max-content',
                                                        fontSize: '0.7rem',
                                                    }}
                                                    color="primary"
                                                    onClick={() => {
                                                        setFieldValue('windowsKey', '');
                                                        setCombinedWKeyName([]);
                                                        setCombinedWKeys([]);
                                                        setCombinedMKeys([]);
                                                    }}
                                                >
                                                    Clear
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography align="left" gutterBottom sx={{ marginRight: '1.2rem !important' }}>
                                                Enter Key
                                            </Typography>
                                            <Box display={'flex'} alignItems="center" justifyContent={'space-around'}>
                                                <div className={classes.iconPosition}>
                                                    <AppleIcon
                                                        color="#a0a8ae"
                                                        sx={{ width: '60px !important', height: '55px !important', color: '#a0a8ae' }}
                                                    />
                                                </div>
                                                <Box textAlign="center" >
                                                    <TextField
                                                        sx={{ width: '100px' }}
                                                        id="outlined-basic"
                                                        variant="outlined"
                                                        autoComplete="off"
                                                        name="macKey"
                                                        onBlur={handleBlur}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                            if (values.keyType === 'INDIVIDUAL_HOT_KEYS') {
                                                                const mKeyCode = [];
                                                                const mKeyNames = [];
                                                                mKeyCode.push(e.keyCode);
                                                                if (e.keyCode === 91 || e.keyCode === 93) {
                                                                    mKeyNames.push('command');
                                                                } else {
                                                                    mKeyNames.push(e.keyCode === 32 ? 'space' : e.key);
                                                                }

                                                                setCombinedMKeyName(mKeyNames);
                                                                setFieldValue('combinedMacKeyName', mKeyNames);
                                                                setFieldValue('combinedMacKeys', mKeyCode);
                                                                if (e.keyCode === 91 || e.keyCode === 93) {
                                                                    setFieldValue('macKey', 'command');
                                                                } else {
                                                                    setFieldValue('macKey', e.keyCode === 32 ? 'space' : e.key);
                                                                }
                                                            } else {
                                                                const macKeyCode = combinedMKeys;
                                                                const macKeyName = combinedMKeyName;
                                                                if (macKeyCode.length > 3) {
                                                                    // setOpenSnackbar(true);
                                                                    // setSnackbarTitle('Maximum keys are 4');
                                                                    return false;
                                                                }
                                                                if (e.keyCode === 91 || e.keyCode === 93) {
                                                                    macKeyName.push('command');
                                                                } else {
                                                                    macKeyName.push(e.keyCode === 32 ? 'space' : e.key);
                                                                }

                                                                macKeyCode.push(e.keyCode.toString());
                                                                setCombinedMKeys(macKeyCode);
                                                                setCombinedMKeyName(macKeyName);
                                                                setFieldValue('combinedMacKeys', combinedMKeys);
                                                                setFieldValue('combinedMacKeyName', combinedMKeyName);

                                                                if (e.keyCode === 91 || e.keyCode === 93) {
                                                                    setFieldValue('macKey', 'command');
                                                                } else {
                                                                    setFieldValue('macKey', e.keyCode === 32 ? 'space' : e.key);
                                                                }
                                                            }
                                                        }}
                                                        type="text"
                                                        value={values.macKey}
                                                        error={Boolean(touched.macKey && errors.macKey)}
                                                        helperText={touched.macKey && errors.macKey}
                                                    />
                                                    <ErrorFocus />
                                                </Box>
                                            </Box>
                                            <Box marginTop={1}>
                                                {useMacVirtualKeys ? (
                                                    <Autocomplete
                                                        options={macKeys}
                                                        autoHighlight
                                                        sx={{
                                                            '.MuiOutlinedInput-input': {
                                                                padding: '0px 6px !important',
                                                            },
                                                        }}
                                                        filterSelectedOptions
                                                        getOptionLabel={(option) => option.description || ''}
                                                        value={values.hashtag}
                                                        onChange={(e, value) => {
                                                            if (value) {
                                                                console.log(value);
                                                                if (values.keyType === 'INDIVIDUAL_HOT_KEYS') {
                                                                    const mKeyCode = [];
                                                                    const mKeyNames = [];
                                                                    mKeyCode.push(value.code);
                                                                    mKeyNames.push(value.description);
                                                                    setCombinedMKeyName(mKeyNames);
                                                                    setFieldValue('combinedMacKeys', mKeyCode);
                                                                    setFieldValue('combinedMacKeyName', mKeyNames);
                                                                    setFieldValue('macKey', value.description);
                                                                    setUseMacVirtualKeys(false);
                                                                } else {
                                                                    const keyCodeArray = combinedMKeys;
                                                                    const keyNameArray = combinedMKeyName;
                                                                    keyNameArray.push(value.description);
                                                                    keyCodeArray.push(value.code);
                                                                    setCombinedMKeys(keyCodeArray);
                                                                    setCombinedMKeyName(keyNameArray);
                                                                    setFieldValue('combinedMacKeys', combinedMKeys);
                                                                    setFieldValue('combinedMacKeyName', combinedMKeyName);
                                                                    setFieldValue('macKey', value.description);
                                                                    setUseMacVirtualKeys(false);
                                                                }
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                placeholder="select key"
                                                                {...params}
                                                                variant="outlined"
                                                                name="hashtag"
                                                                fullWidth
                                                            />
                                                        )}
                                                    />
                                                ) : (
                                                    <Tooltip arrow title=" If you don't find the relevant keys on your keyboard? Click on Keys">
                                                        <Link
                                                            onClick={() => setUseMacVirtualKeys(true)}
                                                            sx={{ textDecoration: 'none', cursor: 'pointer', fontSize: '0.7rem' }}
                                                        >
                                                            Find keys
                                                        </Link>
                                                    </Tooltip>
                                                )}
                                            </Box>

                                            <Box display={'flex'} marginTop={1}>
                                                {combinedMKeyName.map((res, i) => (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <Typography className={classes.boxItem}>{res}</Typography>
                                                        {i < combinedMKeyName.length - 1 && (
                                                            <Typography variant="h5" sx={{ margin: '4px !important' }}>
                                                                +
                                                            </Typography>
                                                        )}
                                                    </div>
                                                ))}
                                            </Box>
                                            {combinedMKeyName.length > 0 && (
                                                <Typography
                                                    sx={{
                                                        marginTop: '8px !important',
                                                        cursor: 'pointer',
                                                        width: 'max-content',
                                                        fontSize: '0.7rem',
                                                    }}
                                                    color="primary"
                                                    onClick={() => {
                                                        setFieldValue('macKey', '');
                                                        setCombinedMKeyName([]);
                                                        setCombinedMKeys([]);
                                                    }}
                                                >
                                                    Clear
                                                </Typography>
                                            )}

                                        </Grid>
                                    </>
                                )}
                            <Grid item xs={12}>
                                <LoadingButton
                                    id="subMitButton"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={loading}
                                    fullWidth
                                >
                                    Submit
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </DialogModal>
    );
};

export default CreateMcqQuestionModel;
