import React, { useState, useEffect } from "react";
import {
    Breadcrumbs,
    Grid,
    Typography,
    Container,
    Button,
    IconButton,
    TextField,
    Avatar,
    Menu,
    MenuItem,
    Table,
    TableBody,  
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination
} from '@mui/material';
import { get } from "immutable";
import { object } from "prop-types";
import DOMPurify from 'dompurify';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import DeleteAlert from '../../../components/modal/DeleteModal';


import Page from '../../../components/Page';
import PageHeader from '../../../components/PageHeader';
import CreateMcqQuestionModel from "./CreateMcqQuestionModel";
 import EditQuestionModule from "./EditMcqQuestionsModule";
import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';


const McqQuestion = () => {
    const [openCreateMcq, setOpenCreateMcq] = useState(false);
    const [openEditMcq, setOpenEditMcq] = useState(false);
    const [keyObjects, setKeyObjects] = useState([]);
    const [snackbarTitle, setSnackbarTitle] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchedDetails, setSearchedDetails] = useState('')
    const [questionList, setQuestionList] = useState([]);
    
    const [searchText, setSearchText] = useState('');
    const [maxCount, setMaxCount] = useState(0);
    const [deletOpen, setDeleteOpen] = React.useState(false);
    const [newData,setNewData] = React.useState('');
    const [deleteId,setDeletetId] = React.useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleCreateMcqQuestion = () => {
        setOpenCreateMcq(true)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
      
      const getAllQuestions = async () => {
        setLoading(true);
        const result = await adminServices.getAllQuestion(rowsPerPage, page, searchText);
        if (result.ok) {
            console.log(result);
            setMaxCount(result.data.total)
            const sortedQuestions = result.data.questions.sort((a, b) =>
                new Date(b.created_date) - new Date(a.created_date)
            );
            setQuestionList(sortedQuestions);
            setLoading(false);
        }
        setLoading(false);
    };
    
      useEffect(() => {
        getAllQuestions();
      }, [page,rowsPerPage,searchText]);

      const handleInputChange = (event) => {
        const newSearch = event.target.value;
        setSearchText(newSearch);
    };




    const handleSubmitForm = async (values) => {
        const array = keyObjects;
        const object = {
            windows: {
                keyCode: values.combinedWindowKeys,
                keyName: values.combinedWindowKeyName,
            },
            mac: {
                keyCode: values.combinedMacKeys,
                keyName: values.combinedMacKeyName,
            },
            positionIndex: array.length,
        };
        array.push(object);
        setKeyObjects(array);
        console.log('object', object);
        handleCreateQuestion(object, values)

    }

    const handleCreateQuestion = async (object, values) => {
        setLoading(true);
        try {
            const keysData = new FormData();
            keysData.append('question', values.question);
            keysData.append('level', values.level);
            keysData.append('questionType', values.questionType);
            if (values.points !== null) {
                keysData.append('points', values.points);
            }
                keysData.append('courseCategory', values.courseCategory?values.courseCategory:null);
                keysData.append('Tag', JSON.stringify(values.keyword?values.keyword:null));
        
            if (values.questionType === "IQ") {
                const updatedObject = {
                    ...object,  
                    keyType: values.keyType  
                };
                keysData.append('keyObj', JSON.stringify(updatedObject));  
            }
            else if (values.questionType === "MCQ") {
                const mcqdata = {
                    question: values.question,
                    mcqOptions: values.mcqOptions.map(option => option.option),
                    correctAnswer: values.mcqOptions.map(option => option.isCorrect),
                };
                keysData.append('mcqData', JSON.stringify(mcqdata));
            }
            const response = await adminServices.createQuestions(keysData);
            if (response.ok) {
                const newQuestion = {
                    question_text: values.question,
                    question_type: values.questionType,
                    is_active: true,
                    created_date: new Date().toISOString(),
                    mcqOptions: values.mcqOptions,
                };
                setQuestionList(prevList => [newQuestion, ...prevList]);
                setOpenSnackbar(true);
                setSnackbarTitle(response.data.message);
                setOpenCreateMcq(false);
                setLoading(false);
                getAllQuestions()
 
            } else {
                console.error("Error:", response);
                setLoading(false);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

     const handleDeletOpen =(data)=>{        
        setDeletetId(data.id)
        setDeleteOpen(true)
     }

      const handleDelet =async()=>{
        console.log(deleteId,"deleteIddeleteId")
        try {
            const data = await adminServices.deletQuestion(deleteId);
            if (data.ok) {
                console.log(data,"datadatadata");
                setDeleteOpen(false)
                setDeleteOpen(false)
                getAllQuestions(page, rowsPerPage,searchText);
               setOpenSnackbar(true);
               setSnackbarTitle(data.data.message);
            } else {
              setDeleteOpen(false);
              setOpenSnackbar(true);
              setSnackbarTitle(data.data.error);
            }
          } catch (error) {
            console.log(error);
          }
      }


       const handleEdit = (values)=>{
         setNewData(values)
          setOpenEditMcq(true)
       }
 const  handleEditCallBack = (values)=>{
    const array = keyObjects;
    const object = {
        windows: {
            keyCode: values.combinedWindowKeys,
            keyName: values.combinedWindowKeyName,
        },
        mac: {
            keyCode: values.combinedMacKeys,
            keyName: values.combinedMacKeyName,
        },
        positionIndex: array.length,
    };
    array.push(object);
    handleEditCallBack1(object, values);
    console.log('object', object);

 }

       const handleEditCallBack1 = async (object, values) => {
         setLoading(true)
        const mergedArray = values.mcqOptions?.map((option, index) => ({
              option,
            isCorrect: values.curectAnswer[index] || false,
        }));
    
        try {
            const requestData = {
                question: values.question,
                level: values.level,
                questionType: values.questionType,
                points: values.points !== null ? values.points : undefined,
                courseCategory: values.courseCategory || undefined,
                Tag: values.courseCategory ? JSON.stringify(values.keyword) :  JSON.stringify(values.keyword),
            };
    
            if (values.questionType === "IQ") {
                const updatedObject = {
                    ...object,
                    keyType: values.keyType,
                };
                requestData.keyObj = JSON.stringify(updatedObject); 
            }
            else if (values.questionType === "MCQ") {
                requestData.mcqData = {
                    question: values.question,
                    mcqOptions: mergedArray.map(option => option.option),
                    correctAnswer: mergedArray.map(option => option.isCorrect),
                };
            }    
            const questionId = newData.id;
            const response = await adminServices.updateQuestion(questionId, requestData);
            if (response.ok) {
                setOpenSnackbar(true);
                setSnackbarTitle(response.data.message);
                setOpenEditMcq(false);
                setLoading(false);
                getAllQuestions();
            } else {
                console.error("Error:", response);
                setLoading(false);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };
    
    return (
        <>
            <div>
                <Page title="Mcq Question" >
                <PageHeader pageTitle="MCQ Question" submodule="submodule"  />
                <Container maxWidth={false} sx={{ padding: '0 !important' }}>
                        <Grid container spacing={2}>
                            <Grid align="end" item xs={12}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Search questions"
                                    size="small"
                                    sx={{ width: '250px', height: '40px' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        sx: { height: '100%' },
                                    }}
                                    value={searchText}
                                    onChange={handleInputChange}
                                />
                                <Button
                                    style={{ marginLeft: '15px', height: '40px' }} 
                                    id="createQuestionButton"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreateMcqQuestion}
                                >
                                    Create Questions
                                </Button>
                             
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {loading && (
                                            <TableRow>
                                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                                    Loading...
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {!loading && questionList?.length===0 && (
                                            <TableRow>
                                                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                                    Sorry, there is no matching data to display
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {!loading && questionList && questionList.map((row, index) => (
                                            <TableRow key={index}>
                            <TableCell dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(row.question_text) }} />
                            <TableCell>{row.question_type}</TableCell>
                                                <TableCell style={{ color: row.is_active ? 'green' : 'red' }}>
                                                    {row.is_active ? 'Active' : 'Inactive'}
                                                </TableCell>
                                                <TableCell>{moment(row.created_date).isValid() ? moment(row.created_date).format('MM-DD-YYYY (h:mm A)') : 'Invalid Date'}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton color="primary" onClick={() => handleEdit(row)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton color="secondary" onClick={() =>handleDeletOpen(row)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                            
                            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                    <div >
                    <TablePagination
                component="div"
                count={maxCount || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                sx={{ marginTop: 2 }}
            />
                    </div>
                  </Grid>
                        </Grid>
                    </Container>

                    <CreateMcqQuestionModel
                        open={openCreateMcq}
                        modelClose={() => setOpenCreateMcq(!openCreateMcq)}
                        title="Create Questions"
                        handleCreateMcq={handleSubmitForm}
                        loading={loading}
                        searchedDetails={searchedDetails}
                    />
                     <EditQuestionModule
                        open={openEditMcq}
                        modelClose={() => setOpenEditMcq(!openEditMcq)}
                        title="Edit Questions"
                        handleEditMcq={handleEditCallBack}
                        loading={loading}
                        searchedDetails={searchedDetails}
                        data ={newData}
                    />
                </Page>
                <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
            </div>
            <DeleteAlert
        open={deletOpen}
        title="Are you sure you want delete this Question"
        confirm={handleDelet}
        close={() => setDeleteOpen(false)}
      />
        </>
    )
}
export default McqQuestion;