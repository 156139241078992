// const ApiEndPoint = 'https://7zkioczq2c.execute-api.us-east-2.amazonaws.com/production'; //  Production  endpoint
// const ApiEndPoint = 'https://lctzs6co8d.execute-api.us-east-2.amazonaws.com/dev'; //  Production  endpoint
// const ApiEndPoint = 'https://devapi.keyskillset.com'; //  development  endpoint
// ApiEndPoint moved to env file
// const ApiEndPoint = 'https://lctzs6co8d.execute-api.us-east-2.amazonaws.com/dev'; // staging serverless endpoint
const ImageKitUrl = 'https://ik.imagekit.io/k38yuwpb2/videoProd'; // Production  endpoint

// const ImageKitUrl = 'https://ik.imagekit.io/k38yuwpb2/videostaging'; // PreProduction & staging  endpoint

const appUrl = 'https://www.keyskillset.com';
// id and subscriptionPlanId are used rest all dummy

const coursePlans = {
  excelEfficiency: {
    id: 32,
    lastUpdatedDate: '2022-09-22T12:29:30.550Z',
    category: {
      id: 1,
      categoryImgUrl: 'https://keyskillsetbucket.s3.us-east-2.amazonaws.com/assist/exls1652534891.jpg',
      code: 'MS_EXCEL',
      description: 'MS Excel',
    },
    title: 'Excel Efficiency',
    description:
      '<p>In the this Specialization, learners develop advanced Excel Skills for Business.  Upon completing the four courses in this Specialization, learners can design sophisticated spreadsheets, including professional dashboards, and perform complex calculations using advanced Excel features and techniques. Learners have acquired the skills to manage large datasets efficiently, extract meaningful information from datasets, present data and extract information effectively. In addition, learners have mastered the skills needed to validate data and prevent errors in spreadsheets, create automation, apply advanced formulas and conditional logic to help make decisions and create spreadsheets that help forecast and model data.',
    thumbImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/clipart24304631657611184.png',
    coverageImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/0101_01011657611184.jpg',
    introVideo: 'https://keyskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Excel+Intro1659432705.mp4',
    hashTag: [
      {
        description: 'Accounts',
        code: 'ACCOUNTS',
      },
      {
        description: 'Finance',
        code: 'FINANCE',
      },
    ],
    status: 'LIVE',
    isPublic: true,
    validFrom: '2022-09-28T12:24:09.000Z',
    validTo: '2022-10-28T12:24:09.000Z',
    completed: '0',
    isTrial: false,
    subscriptionName: 'StripePayment-Monthly_Individual',
    subscriptionPlanId: 27,
    subscriptionPlanName: 'SubscriptionPlan_monthly_Indivual_USD',
    remainingDays: {
      days: -29,
      hours: -19,
      minutes: -51,
      seconds: -28,
      milliseconds: -60.961,
    },
    planStatus: 'Active',
    isSubscribed: true,
  },
  financeCourse: {
    id: 44,
    lastUpdatedDate: '2022-09-21T12:51:53.681Z',
    category: {
      id: 6,
      categoryImgUrl: 'https://keyskillsetbucket.s3.amazonaws.com/assist/accounts1660118220.png',
      code: 'ACCOUNTS',
      description: 'Accounts',
    },
    title: 'Financial Modelling',
    description:
      '<p>Introducing time saving shortcuts and lesser-known functions and features to make you an Excel power user. Increase your efficiency by mouse-free use and save tons of time.</p>\r\n<p>*The winner of Financial Modelling Innovation Award 2020, category “Education”.</p>\r\n</p>',
    thumbImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/img21661587494.jpg',
    coverageImage:
      'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/Screen%20Shot%202022-07-24%20at%20416586766891661587496.webp',
    introVideo: 'https://keyskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/powerpoint+intro1660975605.mp4',
    hashTag: [
      {
        description: 'Accounts',
        code: 'ACCOUNTS',
      },
      {
        description: 'Finance',
        code: 'FINANCE',
      },
    ],
    status: 'LIVE',
    isPublic: true,
    isTrial: true,
    subscriptionName: 'Monthly - Manual',
    subscriptionId: 23,
    subscriptionPlanName: 'Attributes',
    subscriptionPlanId: 29,
  },
  powerPoint: {
    id: 40,
    lastUpdatedDate: '2022-09-21T12:51:53.681Z',
    category: {
      id: 6,
      categoryImgUrl: 'https://keyskillsetbucket.s3.amazonaws.com/assist/accounts1660118220.png',
      code: 'ACCOUNTS',
      description: 'Accounts',
    },
    title: 'Powerpoint Speed',
    description:
      '<p>PowerPoint Speed is a course that can help you create professional-looking decks in minutes. And with our mouse-free approach, you can learn time-saving shortcuts and features that will make you a pro at building presentations. Be it Marketing, Business Analytics or your Corporate decks, build your presentations in minutes by using PowerPoint Speed with our mouse-free approach. Learn time-saving shortcuts and features that will make you a pro in building presentations.</p>',
    thumbImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/041660064250.jpg',
    coverageImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/041660064251.jpg',
    introVideo: null,
    hashTag: [
      {
        description: 'Accounts',
        code: 'ACCOUNTS',
      },
      {
        description: 'Finance',
        code: 'FINANCE',
      },
    ],
    status: 'LIVE',
    isPublic: true,
    isTrial: true,
    subscriptionName: 'Monthly - Manual',
    subscriptionId: 23,
    subscriptionPlanName: 'Attributes',
    subscriptionPlanId: 30,
  },
  msWord: {
    id: 26,
    lastUpdatedDate: '2022-10-19T18:19:48.564Z',
    category: {
      id: 5,
      categoryImgUrl: 'https://keyskillsetbucket.s3.amazonaws.com/assist/ms-word-logo1655375455.png',
      code: 'MS_WORD',
      description: 'MS Word',
    },
    title: 'MS Word',
    description:
      "<p>Microsoft Word is a word processor that allows you to do your personal work, business, and office. Fortunately, in this course, you'll learn all tools and features to understand and build your skills while having fun.</p>\r\n<p></p>\r\n<p><strong>Requirements:</strong></p>\r\n<p>· No previous knowledge</p>\r\n<p>· Willingness to learn</p>\r\n<p></p>\r\n<p><strong>By the end of this course, you will be able to:</strong></p>\r\n<p>· Understanding shortcuts</p>\r\n<p>· Work with the environment of Microsoft Word</p>\r\n<p>· Inserting Citation, Table of Figure and The Rest in Reference Menu</p>\r\n<p>· Reviewing documents</p>\r\n<p>· And so much more...</p>\r\n<p>So don't hesitate anymore. Join us now to make new experiences with <strong>The Complete Microsoft Word Course</strong> today!</p>",
    thumbImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/Thumb-081660120285.jpg',
    coverageImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/011660063616.jpg',
    introVideo: 'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Quickbooks%20Online1666203588.mov',
    hashTag: [
      {
        description: 'MS Word',
        code: 'MS_WORD',
      },
    ],
    status: 'LIVE',
    isPublic: true,
    isTrial: false,
    subscriptionName: 'online-monthly-individual',
    subscriptionId: 23,
    subscriptionPlanName: 'MS Word Monthly Plan',
    subscriptionPlanId: 32,
    isPaid: true,
  },
  python: {
    id: 57,
    lastUpdatedDate: '2022-10-19T18:19:48.564Z',
    category: {
      id: 5,
      categoryImgUrl:
        'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Screenshot%202023-01-25%20at%2041674683730.png',
      code: 'MS_WORD',
      description: 'Python Essentials',
    },
    title: 'Python Beginner',
    description:
      '<p>We offer 160 hours of Python Coding courses. Our guided simulation and hands on experience helps you Master Python Programming. Accelerate your coding speed by using Python coding with our mouse-free approach. Master the time saving shortcuts and techniques, which will make you a pro in Python coding. Work on Data Automation effectively and swiftly using Python coding.</p>',
    thumbImage:
      'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Screenshot%202023-01-25%20at%2041674683730.png',
    coverageImage:
      'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Screenshot%202023-01-25%20at%2041674683730.png',
    introVideo: 'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Quickbooks%20Online1666203588.mov',
    hashTag: [
      {
        description: 'MS Word',
        code: 'MS_WORD',
      },
    ],
    status: 'LIVE',
    isPublic: true,
    isTrial: false,
    subscriptionName: 'online-monthly-individual',
    subscriptionId: 23,
    subscriptionPlanName: 'Python Beginner Monthly Plan',
    subscriptionPlanId: 60,
    isPaid: true,
  },
  quickBooks: {
    id: 48,
    lastUpdatedDate: '2022-10-19T18:19:48.564Z',
    category: {
      id: 6,
      categoryImgUrl: 'https://keyskillsetbucket.s3.amazonaws.com/assist/accounts1660118220.png',
      code: 'ACCOUNTS',
      description: 'Accounts',
    },
    title: 'Quickbooks Online',
    description:
      '<p>This course is designed to help you get the most out of QuickBooks Online. It covers the basics of setting up and managing your QuickBooks Online account, including topics such as setting up customers, vendors, and products; creating invoices and entering payments; tracking expenses and creating reports.</p>\r\n<p>In addition, you will also learn how to connect your bank accounts and manage your accounts payable and receivable. You will also explore the features of the mobile app, as well as best practices for using the software.</p>\r\n<p>Finally, the course will provide tips and tricks on customizing QuickBooks Online and troubleshooting any issues that may arise. The course is designed to give you an enjoyable and interactive experience while learning how to use this powerful accounting program.</p>',
    thumbImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/021660079292.jpg',
    coverageImage: 'https://ik.imagekit.io/k38yuwpb2/tr:pr-true/021660079293.jpg',
    introVideo:
      'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Quickbooks%20Online%2021666203628.mov',
    hashTag: [
      {
        description: 'Accounts',
        code: 'ACCOUNTS',
      },
    ],
    status: 'LIVE',
    isPublic: true,
    isTrial: false,
    subscriptionName: 'online-monthly-bundle',
    subscriptionId: 24,
    subscriptionPlanName: 'QuickBooks Monthly Plan',
    subscriptionPlanId: 34,
    isPaid: true,
  },
  sql: {
    id: 70,
    lastUpdatedDate: '2023-07-06T18:19:48.564Z',
    category: {
      id: 5,
      categoryImgUrl:
        'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Screenshot%202023-01-25%20at%2041674683730.png',
      code: 'SQL_Beginner',
      description: 'SQL Beginner',
    },
    title: 'SQL Beginner',
    description:
      '<p>We offer 130 hours of SQL courses. Our guided simulation and hands on experience helps you Master SQL skill.</p>',
    thumbImage:
      'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Screenshot%202023-01-25%20at%2041674683730.png',
    coverageImage:
      'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Screenshot%202023-01-25%20at%2041674683730.png',
    introVideo: 'https://prodskillsetbucket.s3.us-east-2.amazonaws.com/courseInfo/Quickbooks%20Online1666203588.mov',
    hashTag: [
      {
        description: 'SQL Beginner',
        code: 'SQL_Beginner',
      },
    ],
    status: 'LIVE',
    isPublic: true,
    isTrial: false,
    subscriptionName: 'online-monthly-individual',
    subscriptionId: 23,
    subscriptionPlanName: 'SQL Beginner Monthly Plan',
    subscriptionPlanId: 66,
    isPaid: true,
  }
};

const dispalySalasIqUrl = [
  '/',
  '/solution',
  '/courses',
  '/excel-efficiency',
  '/course-content',
  '/course-creation',
  '/pricing',
  '/self-study',
  '/business',
  '/higher-education',
  '/category',
  '/blog-post',
  '/sign-up',
  '/password-protected',
  '/privacy',
  '/terms',
  '/aboutUs',
  '/support',
  '/resource-blog',
  '/search',
  '/404-error',
  '/questions',
  '/python',
  '/financial-modeling',
  '/powerpoint',
  '/quickbooks',
  '/ms-word',
  '/course',
  '/explore-course',
  '/return-investment',
];

module.exports = {
  coursePlans,
  ImageKitUrl,
  dispalySalasIqUrl,
  appUrl,
};
