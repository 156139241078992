import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({ videoUrl, onClickCallBack }) => {
  const playerRef = useRef(null);
  const [showError, setShowError] = useState(false);
console.log(videoUrl,"videoUrl");


useEffect(() => {
  // if (videoUrl) {
    const timer = setInterval(() => {
    const loadGriffithPlayer = () => {
      const Griffith = window.Griffith;
      if (!Griffith) {
        console.error('Griffith library could not be loaded');
        setShowError(true);
        return;
      }

      try {
        const target = playerRef.current;
        if (!target) {
          console.error('Player target element not found');
          setShowError(true);
          return;
        }

        const sources = {
          hd: { play_url: videoUrl },
        };

        const newPlayer = Griffith.createPlayer(target);
        newPlayer.render({
          sources,
          autoplay: true,
        });
        // console.log(newPlayer, "newPlayer");

        const videoElement = target.querySelector('video');
        if (videoElement) {
          // videoElement.muted = true;
          videoElement.addEventListener('canplay', () => {
            videoElement.play().catch((error) => {
              console.error('Error playing video:', error);
            });
          });

          videoElement.addEventListener('ended', onVideoEnd);
        }
      } catch (error) {
        console.error('Error initializing Griffith player:', error);
        setShowError(true);
      }
    };

    if (!window.Griffith) {
      const script = document.createElement('script');
      script.src = 'https://unpkg.com/griffith-standalone/dist/index.umd.min.js';
      script.onload = loadGriffithPlayer;
      script.onerror = (e) => {
        console.error('Script loading error:', e);
        setShowError(true);
      };
      document.body.appendChild(script);
    } else {
      loadGriffithPlayer();
    }

    return () => {
      const videoElement = playerRef.current?.querySelector('video');
      if (videoElement) {
        videoElement.removeEventListener('ended', onVideoEnd);
      }
    };
  }, 100);
  return () => {
    clearInterval(timer);
  };
  // }
}, [videoUrl]);

  // useEffect(() => {
  //   if(videoUrl){
  //   const loadGriffithPlayer = () => {
  //     const Griffith = window.Griffith;
  //     if (!Griffith) {
  //       console.error('Griffith library could not be loaded');
  //       setShowError(true);
  //       return;
  //     }

  //     try {
  //       const target = playerRef.current;
  //       if (!target) {
  //         console.error('Player target element not found');
  //         setShowError(true);
  //         return;
  //       }

  //       const sources = {
  //         hd: { play_url: videoUrl },
  //       };

  //       const newPlayer = Griffith.createPlayer(target);
  //       newPlayer.render({
  //         sources,
  //         autoplay: true,
  //       });
  //       console.log(newPlayer,"newPlayer");
        

  //       const videoElement = target.querySelector('video');
  //       if (videoElement) {
  //         videoElement.muted = true;
  //         videoElement.addEventListener('canplay', () => {
  //           videoElement.play().catch((error) => {
  //             console.error('Error playing video:', error);
  //           });
  //         });

  //         videoElement.addEventListener('ended', onVideoEnd);
  //       }
  //     } catch (error) {
  //       console.error('Error initializing Griffith player:', error);
  //       setShowError(true);
  //     }
  //   };

  //   if (!window.Griffith) {
  //     const script = document.createElement('script');
  //     script.src = 'https://unpkg.com/griffith-standalone/dist/index.umd.min.js';
  //     script.onload = loadGriffithPlayer;
  //     script.onerror = (e) => {
  //       console.error('Script loading error:', e);
  //       setShowError(true);
  //     };
  //     document.body.appendChild(script);
  //   } else {
  //     loadGriffithPlayer();
  //   }

  //   return () => {
  //     const videoElement = playerRef.current?.querySelector('video');
  //     if (videoElement) {
  //       videoElement.removeEventListener('ended', onVideoEnd);
  //     }
  //   };
  // }
  // }, [videoUrl]);

  const onVideoEnd = () => {
    onClickCallBack();
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '75%' }}>
      {showError ? (
        <div style={{ position: 'absolute', paddingTop: '10%', paddingLeft: '10%', textAlign: 'center' }}>
          <div>Oops! Something went wrong while trying to play the video. Please try again later or switch browser.</div>
        </div>
      ) : (
        <div
          ref={playerRef}
          style={{ position: 'relative', width: '100%', height: '500px' }}
        />
      )}
    </div>
  );
};

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  onClickCallBack: PropTypes.func.isRequired,
  mute: PropTypes.bool
};

export default VideoPlayer;