import client from '../apiClient';

const getTrialUserData = (status, page, rowsPerPage, sortOrder, sortKey, searchKey, filterValue, filterColumn) =>
  client.get(
    `/report/trialUser?status=${status}&page=${page}&pageCount=${rowsPerPage}&sortOrder=${sortOrder}&sort=${sortKey}&search=${searchKey}&filterValue=${filterValue}&filterColumn=${filterColumn}`
  );

const getTrialDataBetweenDates = (
  fromDate,
  toDate,
  subscriptionType,
  page,
  rowsPerPage,
  sortOrder,
  sortKey,
  searchKey,
  filterValue,
  filterColumn
) =>
  client.get(
    `/report/trialUser?subscriptionType=${subscriptionType}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageCount=${rowsPerPage}&sortOrder=${sortOrder}&sort=${sortKey}&search=${searchKey}&filterValue=${filterValue}&filterColumn=${filterColumn}`
  );

const getSubscribedData = (status, page, rowsPerPage, sortOrder, sortKey, searchKey, filterValue, filterColumn) =>
  client.get(
    `/report/subscribedUser?status=${status}&page=${page}&pageCount=${rowsPerPage}&sortOrder=${sortOrder}&sort=${sortKey}&search=${searchKey}&filterValue=${filterValue}&filterColumn=${filterColumn}`
  );

const getSubscribedDates = (
  fromDate,
  toDate,
  subscriptionType,
  page,
  rowsPerPage,
  sortOrder,
  sortKey,
  searchKey,
  filterValue,
  filterColumn
) =>
  client.get(`/report/subscribedUser?subscriptionType=${subscriptionType}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageCount=${rowsPerPage}&sortOrder=${sortOrder}&sort=${sortKey}&search=${searchKey}&filterValue=${filterValue}&filterColumn=${filterColumn}
  `);

const getUserInfo = (userPlanId) => client.get(`/report/user/subscriptionDetails?userPlanId=${userPlanId}`);

const getFeedbackList = (page, pageCount, sortOrder, sort, search) =>
  client.get(`/report/ticket?page=${page}&pageCount=${pageCount}&sortOrder=${sortOrder}&sort=${sort}&search=${search}`);

const getFeedbackListBetweenDates = (fromDate, toDate, subscriptionType) =>
  client.get(`/report/ticket?fromDate=${fromDate}&toDate=${toDate}`);

const putFeedbackList = (ticketId, data) => client.put(`/report/ticket?id=${ticketId}`, JSON.stringify(data));

const getLookUpData = (key) => client.get(`/lookup?key=${key}`);

const getUserCourseProgress = () => client.get('/report/individualUser/courseProgress');

export default {
  getTrialUserData,
  getTrialDataBetweenDates,
  getSubscribedData,
  getSubscribedDates,
  getUserInfo,
  getFeedbackList,
  getFeedbackListBetweenDates,
  putFeedbackList,
  getLookUpData,
  getUserCourseProgress,
};
