import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import SnackBar from '../../../components/snackbar/snackbar';
import userServices from '../../../services/clientAdmin/userServices';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import PhoneNumber from '../../../components/PhoneNumber/Index';
import '../../../components/PhoneNumber/styles.css';

export default function CreateAdminForm(props) {
  // const phoneNumber = `1${""}`
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [formValues, setFormValues] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const handleAddClientFormValues = async (values) => {
    setLoading(true);
    const data = new FormData();
    data.append('firstName', values.firstName);
    data.append('lastName', values.lastName);
    data.append('email', values.email);
    data.append('phone', values.phone);
    data.append('role', 'AUTH_USER');

    if (props.mode === 'create') {
      try {
        const response = await userServices.postUsersDetails(data);
        if (response.ok) {
          props.getUsersDetails();
          setOpenSnackbar(true);
          props.snackBarControl(response.data.message);
          props.handleModalClose();
          setLoading(false);
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          props.snackBarControl(response.data.message);
        }
      } catch (error) {
        console.log(error);
        setOpenSnackbar(false);
      }
    } else {
      try {
        const response = await userServices.editUserDetailsByID(props.id, data);
        if (response.ok) {
          setOpenSnackbar(true);
          props.snackBarControl(response.data.message);
          props.getUsersDetails();
          props.handleModalClose();
          setLoading(false);
        } else if (response.status === 400) {
          setOpenSnackbar(true);
          props.snackBarControl(response.data.message);
          props.handleModalClose();
        }
      } catch (error) {
        console.log(error);
        setOpenSnackbar(false);
      }
    }
    setLoading(false);
  };
  const getUserDetalsById = async () => {
    const result = await userServices.getUserDetalsById(props.id);
    if (result.ok) {
      const data = { ...formValues };
      data.firstName = result.data.firstName;
      data.lastName = result.data.lastName;
      data.email = result.data.email;
      data.phone = result.data.phone;
      setFormValues(data);
    }
  };
  useEffect(() => {
    if (props.mode === 'edit') {
      getUserDetalsById();
    }
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formValues}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().trim().min(3, 'First name must be at least 3 characters')
          .matches(/^[A-Za-z ]+$/,"Only alphabets are allowed for this field")
          .required('Required '),
          lastName: Yup.string().trim()
          .matches(/^[A-Za-z ]+$/,"Only alphabets are allowed for this field"),
          email: Yup.string().required('Required').email(),
          phone: Yup.string()
            .trim()
            .nullable()
            .min(11, 'Contact phone must be at least 10 characters')
            .max(12, 'Contact phone must be with in 12 characters')
            .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number')
            .required('Contact phone is required field'),
        })}
        onSubmit={(values) => {
          handleAddClientFormValues(values);
        }}
      >
        {({ errors, handleBlur, handleChange, touched, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle1" gutterBottom>
                  First Name*
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.firstName}
                  variant="outlined"
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  inputProps={{ maxLength: 180 }}
                />
                <ErrorFocus />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Last Name
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.lastName}
                  variant="outlined"
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  inputProps={{ maxLength: 180 }}
                />
                <ErrorFocus />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Email*
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={props.mode === 'edit'}
                  type="text"
                  value={values.email}
                  variant="outlined"
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <ErrorFocus />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Phone*
                </Typography>
                <PhoneNumber fullWidth country={'us'} specialLabel={''} />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                  {props.mode === 'create' ? 'Add' : 'Update'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <SnackBar open={openSnackbar} close={() => setOpenSnackbar(false)} />
    </div>
  );
}
