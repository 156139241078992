// @mui
import PropTypes from 'prop-types';
// import { alpha, styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, total, icon, color = 'primary', sx, ...other }) {
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          boxShadow: 0,
          padding: '1rem 1rem 1rem 1rem !important',
          borderRadius: '10px',
          ...sx,
        }}
        {...other}
      >
        <Box>
          <Typography variant="subtitle2" textAlign="end" sx={{ opacity: 1 }}>
            {title}
          </Typography>
        </Box>

        <Box className={classes.spaceBetween}>
          <IconWrapperStyle
            sx={{
              color: (theme) => theme.palette[color].dark,
            }}
            className={classes.iconWrapperOne}
          >
            <Iconify icon={icon} width={73} height={30} />
          </IconWrapperStyle>

          <IconWrapperStyle className={classes.iconWrappermargin}>
            <Typography variant="h3" sx={{ fontSize: '1.5rem' }}>
              {fShortenNumber(total)}
            </Typography>
          </IconWrapperStyle>
        </Box>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  spaceBetween: {
    display: ' flex',
    justifyContent: 'space-between',
  },
  iconWrapperOne: { marginLeft: '0rem', width: '50px', height: '50px', borderRadius: '20%', background: 'white' },
  iconWrappermargin: {
    margin: 'inherit',
    width: '50px',
    height: '50px',
  },
}));
