import React, { useEffect, useState } from 'react';
import { Grid, Container, IconButton, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import ViewListIcon from '@mui/icons-material/ViewList';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

import Page from '../../../components/Page';
import Cards from '../../../components/cards/UserCard';
import PageHeader from '../../../components/PageHeader';
import clientAdminServices from '../../../services/clientAdmin/course/clientAdminServices';
import CardSkeleton from '../../../components/Skeleton/cardSkeleton';

export default function CourseAnalytics() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleCardClickItem = (data) => {
    console.log(data);
    navigate('/app/analytics-details', { state: data });
    // navigate('/app/courses-analytics', { state: data });
  };

  const getUser = async () => {
    try {
      const response = await clientAdminServices.getCourse();
      if (response.ok) {
        setCourseList(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  const ButtonCmp = () => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      <IconButton>
        <SearchIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
      <IconButton>
        <FilterAltIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
      <IconButton>
        <GridOnIcon fontSize="medium" style={{ color: '#00B673' }} />
      </IconButton>
      <IconButton>
        <ViewListIcon fontSize="medium" style={{ color: '#BCBCBC' }} />
      </IconButton>
    </div>
  );

  return (
    <div>
      <Page title="course-analytics">
        <PageHeader pageTitle="Courses" breadcrumbs={<ButtonCmp />} />
        <Container maxWidth={false} sx={{ padding: '0 !important' }}>
          <Grid container spacing={2}>
            {loading ? (
              <>
                {[1, 2, 3, 4, 5, 6].map((res) => (
                  <Grid item xs={12} sm={4} md={4}>
                    <CardSkeleton />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {courseList.map((item) => {
                  return (
                    <>
                      <Grid item xs="12" sm="6" md="4" lg={4} xl="3">
                        <div className={classes.cardHover}>
                          <Cards
                            handleCardClick={() => handleCardClickItem(item)}
                            image={item.thumbImage}
                            title={item.title}
                            category={item.category}
                            icon={item.icon}
                            logo={item.category.categoryImgUrl}
                          />
                        </div>
                      </Grid>
                    </>
                  );
                })}

                {courseList.length === 0 && (
                  <Typography marginTop={4} variant="h6" textAlign="center" sx={{ width: '100%' }}>
                    No Data
                  </Typography>
                )}
              </>
            )}
          </Grid>
        </Container>
      </Page>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  cardHover: {
    transition: 'all .5s ease-out',
    '&:hover': {
      boxShadow: '0 12px 20px 0 rgb(32 40 45 / 8%)',
      transition: 'transform .5s',
      transform: 'translateY(-5px)',
    },
  },
}));
