function sortByPositionIndex(a, b) {
  if (a.positionIndex < b.positionIndex) {
    return -1;
  }
  if (a.positionIndex > b.positionIndex) {
    return 1;
  }
  return 0;
}

// function loadScript(src) {
//   //   const scriptVisible = document.getElementById('weglot-language-en');
//   //   document.body.appendChild((scriptVisible.style.display = 'block'));

//   return new Promise((resolve) => {
//     const script = document.createElement('script');
//     script.src = src;
//     script.id = 'lang';
//     script.onload = () => {
//       resolve(true);
//     };
//     script.onerror = () => {
//       resolve(false);
//     };
//     document.body.appendChild(script);
//   });
// }

// function removeScript(src) {
//   //   return new Promise((resolve) => {
//   //     const script = document.getElementById('lang');
//   //     console.log(script, ' removeScript weglot script');
//   //     script.src = src;
//   //     script.style.display = 'none';

//   //     // script.onload = () => {
//   //     //   resolve(true);
//   //     // };
//   //     // script.onerror = () => {
//   //     //   resolve(false);
//   //     // };
//   //     // document.body.appendChild((script.style.display = 'none !important'));
//   //   });

//   return new Promise((resolve) => {
//     const script = document.getElementById('weglot-language-en');
//     // script.src = src;
//     // script.id = 'lang';
//     console.log(script, 'weglot-language-en');
//     document.body.appendChild((script.style.display = 'none'));
//     // script.onload = () => {
//     //   resolve(true);
//     // };
//     // script.onerror = () => {
//     //   resolve(false);
//     // };
//     document.body.appendChild((script.style.display = 'none'));
//   });
// }

// const loadImpact = () => {
//   return new Promise((resolve, reject) => {
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.async = true;
//     script.src = 'https://utt.impactcdn.com/A4136764-e2cd-491b-b25d-e508e97282d61.js';
//     script.onload = resolve;
//     script.onerror = reject;
//     document.head.appendChild(script);
//     // document.body.appendChild(script);
//     console.log(script);
//   });
// };

// const identifyCustomer = (customerId, customerEmail) => {
//   window.ire('identify', {
//     customerid: customerId,
//     customeremail: customerEmail,
//   });
// };

// const captureClickId = async () => {
//   console.log('window object', window);
//   window.ire('generateClickId', (clickId) => {
//     console.log('click id callback', clickId);
//     return clickId;
//   });
// };

// function getClickId() {
//   return new Promise((resolve, reject) => {
//     try {
//       window.ire('generateClickId', (clickId) => {
//         resolve(clickId);
//       });
//     } catch (erro) {
//       console.log('error getting imapct click id');
//       reject(erro);
//     }
//   });
// }

export default {
  sortByPositionIndex,
  // loadScript,
  // removeScript,
  // loadImpact,
  // identifyCustomer,
  // captureClickId,
  // getClickId,
};
