import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

const CardSkeleton = () => {
  return (
    <Box sx={{ boxShadow: '0px 0px 3px #c9c9c9', paddingBottom: '5px !important' }}>
      <Skeleton variant="rectangular" height={118} />
      <Box sx={{ pr: 2 }}>
        <Typography gutterBottom variant="body2">
          <Skeleton variant="rectangular" height={35} />
        </Typography>

        <Typography display="block" gutterBottom variant="caption" color="text.secondary">
          <Skeleton variant="rectangular" height={25} />
        </Typography>

        <Typography variant="caption" color="text.secondary" gutterBottom>
          <Skeleton variant="rectangular" height={10} />
        </Typography>
        <Typography variant="caption" color="text.secondary">
          <Skeleton variant="rectangular" height={10} />
        </Typography>
      </Box>

      <Box m={2} mt={3} display={'flex'} justifyContent="space-between" alignItems={'center'}>
        <Skeleton variant="rectangular" height={40} width={100} />
        <Skeleton variant="rectangular" height={55} width={60} />
      </Box>
    </Box>
  );
};

export default CardSkeleton;
