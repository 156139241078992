import React, { useState } from 'react';
import { Grid, Typography, Button, Collapse, Alert, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import MUIDataTable from 'mui-datatables';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

import ClientAdminServices from '../../../../services/clientAdmin/course/clientAdminServices';
import BasicModal from '../../../../components/modal/BasicModel';
import AddUser from './AddUser';
import DeleteModal from '../../../../components/modal/DeleteModal';
import SnackBar from '../../../../components/snackbar/snackbar';

export default function EnrolledUser(props) {
  const classes = useStyles();
  //   const [handleOpenModal, setHandleOpenModal] = React.useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [planId, setPlanId] = useState();
  const [courseId, setCourseId] = useState();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const handleDeleteModalOpen = (id, planId) => {
    setDeleteAlert(true);
    setCourseId(id);
    setPlanId(planId);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await ClientAdminServices.deleteEnrolledUser(planId, courseId);
      if (response.ok) {
        props.setLoading(false);
        props.getEnrolledUserById(planId);
        props.getSubscription();
        setDeleteAlert(false);
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      } else {
        props.setLoading(false);
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: 'id',
      label: 'Users ID',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'planId',
      label: 'Plan ID',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'fullName',
      label: 'Name',
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'phone',
      label: 'Phone No',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography>{value ? `+${value}` : ''}</Typography>;
        },
      },
    },

    {
      name: 'lastLoginDateTime',
      label: 'Last Login',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value && value !== null ? (
                <div>
                  <Typography variant="body2">{moment(value).format('MM-DD-YYYY HH:mm:ss')}</Typography>
                </div>
              ) : (
                ''
              )}
            </>
          );
        },
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              onClick={() => {
                handleDeleteModalOpen(tableMeta.rowData[0], tableMeta.rowData[1]);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          );
        },
      },
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ marginTop: '1rem' }}>
            <MUIDataTable
              data={props.enrolledUser}
              columns={columns}
              options={{
                responsive: 'scroll',
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 50, 75, 100],
                selectableRows: false,
                filter: false,
                download: false,
                print: false,
                textLabels: {
                  body: {
                    noMatch: props.loading ? 'Loading...' : 'Sorry, there is no matching data to display',
                  },
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
      <DeleteModal
        open={deleteAlert}
        title="Are you sure you want delete this Subscription"
        confirm={handleDelete}
        close={() => setDeleteAlert(false)}
      />
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </>
  );
}
const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down(514)]: {
      marginTop: '14px',
    },
  },
}));
