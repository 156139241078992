import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ImportExportIcon from '@mui/icons-material/ImportExport'; 
import ImportSubModuleDialog from './ImportSubModuleDialog'; 

function ImportSubModuleButton({ onClick, courseId, userId , onImportSuccess,courseDetails }) {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = (selectedCourse) => {
    // Handle submission logic here
    handleCloseDialog();
  };
  

  return (
    <div>
      {courseDetails.type !== 'video' && (
        <Button
          type="submit"
          variant="contained"
          startIcon={<ImportExportIcon />}
          sx={{ background: '#6D6969',marginLeft: '10px' }}
          onClick={handleOpenDialog}
        >
          Import Module
        </Button>
     )} 
      <ImportSubModuleDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmit}
        courseId={courseId}
        userId={userId}
        onImportSuccess={onImportSuccess}
       
        PaperProps={{
          sx: {
            '& .MuiDialog-paper': {
              scrollbarWidth: 'auto',
              scrollbarColor: '#ccc #f4f4f4',
              '&::-webkit-scrollbar': {
                width: '12px',
               
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#ccc',
                borderRadius: '6px',
              },
            },
          },
        }}
      />
    </div>
  );
}

export default ImportSubModuleButton;
