import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Autocomplete, Stack, Box, Grid, TextField, FormHelperText, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import SnackBar from '../../../components/snackbar/snackbar';
import adminServices from '../../../services/adminServices';
import SubCard from '../../../components/cards/SubCard';
import BasicPaper from '../../../components/cards/BasicPaper';
import PhoneNumber from '../../../components/PhoneNumber/Index';
import '../../../components/PhoneNumber/styles.css';

const CompanyInfo = (props) => {
  const classes = useStyles();

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [imageShow, setImageShow] = useState(true);
  const [editField, setEditField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [urlImage, setUrlImage] = useState(null);
  const [countryCode, setCountryCode] = React.useState(null);
  const [country, SetCountry] = React.useState([]);
  const [state, SetState] = React.useState([]);
  const [dateFormat, SetDateFormat] = React.useState([]);
  const [timezone, SetTimezone] = React.useState([]);
  const [clientList, setClientList] = useState(props.clientList);

  const handleSubmitForm = async (values) => {
    const data = await props.submit(values);
    if (data === true) {
      setEditField(false);
    }
  };

  useEffect(() => {
    setClientList(props.clientList);
  }, [props.clientList]);

  const getLookUpDetails = async () => {
    const key = ['country', 'state', 'date_format', 'timezone'];
    const response = await adminServices.getLookUpData(key);
    if (response.ok) {
      SetState(response.data.state);
      SetCountry(response.data.country);
      SetDateFormat(response.data.date_format);
      SetTimezone(response.data.status);
    }
  };
  const getLookUpState = async () => {
    const key = ['state'];
    const response = await adminServices.getLookUpStateData(key, countryCode);
    if (response) {
      SetState(response.data.state);
    }
  };

  useEffect(() => {
    getLookUpDetails();
    if (countryCode) {
      getLookUpState();
    }
  }, [countryCode]);

  return (
    <>
      {editField ? (
        <Formik
          initialValues={{
            phone: clientList.contactPhone,
            address1: clientList.address1,
            address2: clientList.address2,
            country: clientList.country.code,
            state: clientList.state.code,
            city: clientList.city,
            zipCode: clientList.zipCode,
            imgUrl: clientList.profileImg,
            logo: clientList.logo,
            organizationName: clientList.organizationName,
            websiteUrl: clientList.websiteUrl,
            contactEmail: clientList.contactEmail,
            officeNumber: clientList.officeNumber,
            contactName: clientList.contactName,
            dateFormat: clientList.dateFormat,
            timeZone: clientList.timeZone,
          }}
          validationSchema={Yup.object().shape({
            contactName: Yup.string()
              .trim()
              .nullable()
              .min(3, 'Contact name contains at least 3 characters')
              .matches(/^[A-Za-z ]+$/,"Only alphabets are allowed for this field")
              .required('Contact name is required field'),
            phone: Yup.string()
              .trim()
              .max(12, 'Contact phone must be with in 12 characters')
              .min(11, 'Contact phone must be at least 10 characters')
              .matches('^([0|[0-9]{1,5})?([2-9][0-9]{9})$', 'Invalid Phone number')
              .required('Contact Phone Number is required field'),

            address1: Yup.string()
              .trim()
              .nullable()
              .min(3, 'Address1 contains at least 3 characters')
              .required('Address1 is required field'),
            address2: Yup.string().trim().nullable(),
            // .min(3, 'Address2 contains at least 3 characters')
            // .required('Address2 is required field'),
            country: Yup.string().trim().nullable().required(' Country is required field'),
            state: Yup.string().trim().required('State is required field').label(),
            city: Yup.string()
              .trim()
              .nullable()
              .min(3, 'City contains at least 3 characters')
              .required('City is required field'),
            zipCode: Yup.string()
              .trim()
              .min(5, 'Zip code contains at least 5 digits')
              .max(9, 'Maximum 9 digits')
              .required(' Zip code is required field'),
            imgUrl: Yup.string().nullable(),
            logo: Yup.mixed()
              .nullable()
              .when('imgUrl', {
                is: null,
                then: Yup.mixed()
                  .nullable()
                  .required('Profile image required')
                  .test('fileFormat', 'Unsupported Format', (value) => {
                    return value === null || (value && SUPPORTED_FORMATS.includes(value.type));
                  })
                  .test('fileSize', 'Maximum File Size Is 2MB', (value) => {
                    console.log('value', value);
                    return value === null || (value && value.size < 2097152);
                  }),
              }),

            organizationName: Yup.string()
              .trim()
              .nullable()
              .min(3, 'Organization name mcontains at least 3 characters')
              .matches(/^[A-Za-z ]+$/,"Only alphabets are allowed for this field")
              .required('Organization name is required field'),
            websiteUrl: Yup.string()
              .url()
              .trim()
              .nullable()
              .matches(
                /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
                'Enter valid url!'
              )
              .required(' Website is required field'),
            contactEmail: Yup.string()
              .trim()
              .nullable()
              .required(' Contact email is required field')
              .email("Email must be valid email")
              .matches(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                'Email is invalid'
              ),
            officeNumber: Yup.string()
              .trim()
              .nullable()
              .min(10)
              .max(12, 'Office number must be with in 12 characters')
              .matches('^[6-9][0-9]{9,11}$', 'Invalid Phone number')
              .required(' Office number is required field'),
            dateFormat: Yup.string().trim().nullable().required(' Date format is Required field'),
            timeZone: Yup.string().trim().nullable().required(' Timezone is required field'),
          })}
          onSubmit={(values) => {
            handleSubmitForm(values);
          }}
        >
          {({ errors, handleBlur, setFieldValue, handleChange, touched, values }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Company Name*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="organizationName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.organizationName}
                    variant="outlined"
                    inputProps={{ maxLength: 180 }}
                    error={Boolean(touched.organizationName && errors.organizationName)}
                    helperText={touched.organizationName && errors.organizationName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Contact Name*
                  </Typography>
                  <TextField
                    fullWidth
                    name="contactName"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('contactName', e.target.value);
                    }}
                    inputProps={{ maxLength: 180 }}
                    error={Boolean(touched.contactName && errors.contactName)}
                    helperText={touched.contactName && errors.contactName}
                    value={values.contactName}
                  />
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Phone Number*
                  </Typography>
                  <PhoneNumber fullWidth country={'us'} specialLabel={''} />
                  {/* <TextField
                    fullWidth
                    type="number"
                    name="contactPhone"
                    variant="outlined"
                    onBlur={handleBlur}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    onChange={(e) => {
                      setFieldValue('contactPhone', e.target.value);
                    }}
                    error={Boolean(touched.contactPhone && errors.contactPhone)}
                    helperText={touched.contactPhone && errors.contactPhone}
                    value={values.contactPhone}
                  /> */}
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Address1*
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="address1"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('address1', e.target.value);
                    }}
                    inputProps={{ maxLength: 260 }}
                    error={Boolean(touched.address1 && errors.address1)}
                    helperText={touched.address1 && errors.address1}
                    value={values.address1}
                  />
                </Grid>
                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Address2
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="address2"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('address2', e.target.value);
                    }}
                    inputProps={{ maxLength: 260 }}
                    error={Boolean(touched.address2 && errors.address2)}
                    helperText={touched.address2 && errors.address2}
                    value={values.address2}
                  />
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Select Country*
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={country}
                    autoHighlight
                    getOptionLabel={(option) => option.description || values.country}
                    value={values.country}
                    onChange={(e, value) => {
                      setFieldValue('country', value);
                      setCountryCode(value.code);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="country"
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Select State*
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={state}
                    autoHighlight
                    getOptionLabel={(option) => option.description || values.state}
                    value={values.state}
                    onChange={(e, value) => {
                      setFieldValue('state', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="selectState"
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    City*
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    name="city"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue('city', e.target.value);
                    }}
                    inputProps={{ maxLength: 180 }}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                    value={values.city}
                  />
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Zip code*
                  </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    name="zipCode"
                    variant="outlined"
                    onBlur={handleBlur}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    onChange={(e) => {
                      setFieldValue('zipCode', e.target.value);
                    }}
                    inputProps={{ maxLength: 9 }}
                    error={Boolean(touched.zipCode && errors.zipCode)}
                    helperText={touched.zipCode && errors.zipCode}
                    value={values.zipCode}
                  />
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Website *
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="websiteUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.websiteUrl}
                    variant="outlined"
                    error={Boolean(touched.websiteUrl && errors.websiteUrl)}
                    helperText={touched.websiteUrl && errors.websiteUrl}
                  />
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Contact Email
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="contactEmail"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.contactEmail}
                    variant="outlined"
                    error={Boolean(touched.contactEmail && errors.contactEmail)}
                    helperText={touched.contactEmail && errors.contactEmail}
                  />
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Date Format*
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={dateFormat}
                    autoHighlight
                    getOptionLabel={(option) => option.description || ''}
                    value={values.dateFormat}
                    onChange={(e, value) => {
                      setFieldValue('dateFormat', value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="dateFormat"
                        error={Boolean(touched.dateFormat && errors.dateFormat)}
                        helperText={touched.dateFormat && errors.dateFormat}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Time Zone*
                  </Typography>
                  <Autocomplete
                    id="country-select-demo"
                    fullWidth
                    options={timezone}
                    autoHighlight
                    getOptionLabel={(option) => option.description || ''}
                    value={values.timeZone}
                    onChange={(e, value) => {
                      setFieldValue('timeZone', value);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img loading="lazy" width="20" src={option.categoryImgUrl} alt="" />
                        {option.description}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="timeZone"
                        error={Boolean(touched.timeZone && errors.timeZone)}
                        helperText={touched.timeZone && errors.timeZone}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Office Number*
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    name="officeNumber"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.officeNumber}
                    variant="outlined"
                    error={Boolean(touched.officeNumber && errors.officeNumber)}
                    helperText={touched.officeNumber && errors.officeNumber}
                  />
                </Grid>

                <Grid item xs="12" sm="4">
                  <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                    Profile Picture*
                  </Typography>
                  <>
                    <Box mb={0.5}>
                      {imageShow ? (
                        <img width={'90px'} src={clientList.logo && clientList.logo} alt="logo" />
                      ) : (
                        <img width={'90px'} src={urlImage} alt="" />
                      )}
                    </Box>

                    <Box mb={1}>
                      <div>
                        <input
                          accept="image/*"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          name="logo"
                          onChange={(e) => {
                            if (
                              SUPPORTED_FORMATS.includes(e.target.files[0].type) &&
                              e.target.files[0].size <= 2097152
                            ) {
                              setUrlImage(URL.createObjectURL(e.target.files[0]));
                              setFieldValue('imgUrl', URL.createObjectURL(e.target.files[0]));
                            }
                            setFieldValue('logo', e.currentTarget.files[0]);
                          }}
                        />
                        <label htmlFor="contained-button-file">
                          <Typography
                            color="primary"
                            component="span"
                            sx={{ cursor: 'pointer;' }}
                            onClick={() => {
                              setImageShow(false);
                              setUrlImage('');
                              setFieldValue('imgUrl', null);
                              setFieldValue('profileImg', null);
                            }}
                          >
                            Change Profile
                          </Typography>

                          <FormHelperText style={{ color: '#F44336', paddingLeft: 16 }}>
                            {touched.logo ? errors.logo : ''}
                          </FormHelperText>
                        </label>
                      </div>
                    </Box>
                  </>
                </Grid>

                <Grid align="end" item xs="12">
                  <Stack direction="row" align="end" spacing={2} sx={{ display: 'flex;', justifyContent: 'end;' }}>
                    <LoadingButton
                      size="medium"
                      type="submit"
                      variant="contained"
                      sx={{
                        color: '#fff !important',
                        background: 'gray;',
                      }}
                      onClick={() => {
                        setEditField(false);
                        setLoading(false);
                      }}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton loading={props.loading} size="medium" type="submit" variant="contained">
                      Update
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <BasicPaper
            children={
              <Grid container spacing={4} sx={{ mt: -2 }}>
                <Grid className={classes.girdContainer} item xs="10" sm="3">
                  {clientList.logo && clientList.logo ? (
                    <Box className={classes.logo}>
                      <img width={'120px'} src={clientList.logo && clientList.logo} alt="logo" />
                    </Box>
                  ) : (
                    <Box ml={0} className={classes.logo}>
                      <Avatar
                        alt="Remy Sharp"
                        src={clientList.logo && clientList.logo}
                        sx={{ width: 50, height: 50 }}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs="12" sm="8">
                  <div>
                    <Typography variant="h6">{clientList.organizationName && clientList.organizationName}</Typography>
                    <Typography>{clientList.contactEmail && clientList.contactEmail}</Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={1} textAlign="center" className={classes.marginTopSmedit}>
                  <IconButton
                    onClick={() => {
                      setEditField(!editField);
                      setImageShow(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Office Phone</span>
                  </Typography>
                  <Typography>{clientList.officeNumber && clientList.officeNumber}</Typography>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Website Url</span>
                  </Typography>
                  <Typography>{clientList.websiteUrl && clientList.websiteUrl}</Typography>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Contact Name</span>
                  </Typography>
                  <Typography>{clientList.contactName && clientList.contactName}</Typography>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Contact Phone</span>
                  </Typography>
                  <Typography>{clientList.contactPhone && clientList.contactPhone}</Typography>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Date Format</span>
                  </Typography>
                  <Typography>{clientList.dateFormat && clientList.dateFormat?.code}</Typography>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Time Zone</span>
                  </Typography>
                  <Typography>{clientList.timeZone && clientList.timeZone?.code}</Typography>
                </Grid>
              </Grid>
            }
          />

          <SubCard
            title="Address"
            subtitle="View all of the addresses"
            children={
              <Grid style={{ padding: '1rem' }} container spacing={4}>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Address1</span>
                  </Typography>
                  <Typography>{clientList.address1}</Typography>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Address2</span>
                  </Typography>
                  <Typography>{clientList.address2}</Typography>
                </Grid>

                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>Country</span>
                  </Typography>
                  <Typography>{clientList.country && clientList.country?.code}</Typography>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>State</span>
                  </Typography>
                  <Typography>{clientList.state && clientList.state?.code}</Typography>
                </Grid>

                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>City</span>
                  </Typography>
                  <Typography>{clientList.city}</Typography>
                </Grid>
                <Grid item xs="12" sm="12" md="4">
                  <Typography>
                    <span className={classes.name}>ZipCode</span>
                  </Typography>
                  <Typography>{clientList.zipCode}</Typography>
                </Grid>
              </Grid>
            }
          />
        </>
      )}
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  typoFontWeight: {
    fontSize: '1rem;',
    fontWeight: '400',
  },
  typoFontWeightone: {
    fontSize: '1rem;',
  },

  input: {
    display: 'none',
  },

  marginTopSm: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '-13rem',
    },
  },
  marginTopSmedit: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '-9rem',
      textAlign: 'end',
    },
  },
  logo: {
    border: '1px solid lightgrey',
    borderRadius: '10px',
    marginRight: '3rem',
    padding: '1rem',
  },
}));

export default CompanyInfo;
