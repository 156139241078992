import React, { useState, useEffect } from 'react';
import { Grid, TextField, Select, MenuItem, FormHelperText, Link, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { LoadingButton } from '@mui/lab';
import { DropzoneArea } from 'material-ui-dropzone';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

import loginServices from '../../services/loginServices';
import preSignedServices from '../../services/preSignedServices';
import PageHeader from '../../components/PageHeader';
import Page from '../../components/Page';
import adminServices from '../../services/adminServices';
import SnackBar from '../../components/snackbar/snackbar';
import ResetPasswordModal from './ResetPasswordModal';
import BasicPaper from '../../components/cards/BasicPaper';
import { loginSuccess } from '../../store/reducer';
import PhoneNumber from '../../components/PhoneNumber/Index';
import '../../components/PhoneNumber/styles.css';

const Input = styled('input')({
  display: 'none',
});
const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);

  const [showimageo, setShowimageo] = useState(true);
  const [editField, setEditField] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarTitle, setSnackbarTitle] = React.useState('');
  const [buttondropzone, setButtondropzone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlImage, setUrlImage] = useState(null);
  const [isClickedButton, setIsClickedButton] = useState(false);
  const [countries, setCountries] = useState([]);

  const [formvalue, setFormvalue] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
    imgUrl: null,
    profileImg: null,
    thumbPreview: null,
    country: ''
  });

  const fetchCountries = async (key) => {
    try {
      const response = await adminServices.getLookUpData(key);
       
      if (response.ok) {      
        setCountries(response.data.country);          
         
      } else {
        console.error('Failed to fetch countries');
      }
    } catch (error) {
      console.error('Error fetching countries', error);
    }
  };

  useEffect(() => {
    const key = 'country'
    fetchCountries(key);
  }, []);

  const getLoginUserDetails = async () => {
    const response3 = await loginServices.getUserInfo();
    if (response3.ok) {
      dispatch(loginSuccess(response3.data));
    }
    console.log(response3, 'response3');
  };

  useEffect(() => {
    setIsClickedButton(false);
  }, [editField]);

  const handleSubmitProfileValues = async (values) => {
    const formData = new FormData();
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('phone', values.phone);
    formData.append('profileImg', values.profileImg);
    formData.append('country', values.country);

    try {
      const response = await adminServices.profileUpdate(formData);
      if (response.ok) {
        if(response.data && response.data.preSignedUrl) {
          await preSignedServices.preSignedUrlUpload(response.data.preSignedUrl, values.profileImg)
        }
        await getLoginUserDetails();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setEditField(false);
        setLoading(false);
      } else if (response.status === 400) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };
  const [openResetModel, setOpenResetModel] = useState(false);
  const closeResetModel = () => setOpenResetModel(false);

  const handleSubmitForm = (values) => {
    // values.profileImg = URL.createObjectURL(values.profileImg);

    handleSubmitProfileValues(values);
    // setFormvalue(values);
    setLoading(true);
    console.log('uservalues :', values.profileImg);
  };

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

  return (
    <Page title={'Profile'}>
      <PageHeader pageTitle="Profile" />
      {console.log('userInfoone :', userInfo)}

      <BasicPaper>
        {editField ? (
          <>
            <Formik
              enableReinitialize
              initialValues={{
                firstName: userInfo?.firstName,
                lastName: userInfo?.lastName,
                phone: userInfo?.phone,
                profileImg: null,
                thumbPreview: userInfo?.profileImg,
                country: userInfo?.country,
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .trim()
                  .required('First Name is a required field')
                  .min(3, 'First name contains at least 3 character')
                  .label()
                  .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
                lastName: Yup.string()
                  .trim()
                  .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
                thumbPreview: Yup.string().nullable(),
                // profileImg: Yup.mixed()
                //   .nullable()
                //   .when('thumbPreview', {
                //     is: (val) => {
                //       return val === null || val === '' || val === undefined;
                //     },
                //     then: Yup.mixed()
                //       .nullable()
                //       .required('Profile image required')
                //       .test('fileFormat', 'Unsupported Format', (value) => {
                //         return value === null || (value && SUPPORTED_FORMATS.includes(value.type));
                //       })
                //       .test('fileSize', 'Maximum file size is 3MB', (value) => {
                //         return value === null || (value && value.size < 3145728);
                //       }),
                //   }),
                profileImg: Yup.mixed()
                  .nullable()
                  .test('fileFormat', 'Unsupported Format', (value) => {
                    return value === null || (value && SUPPORTED_FORMATS.includes(value.type));
                  })
                  .test('fileSize', 'Maximum file size is 3MB', (value) => {
                    return value === null || (value && value.size < 3000000);
                  }),
                country: Yup.string()
                  .nullable()
                  .required('Please select a country'),
              })}
              onSubmit={(values) => {
                handleSubmitForm(values);
              }}
            >
              {({ errors, handleBlur, setFieldValue, touched, values }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                        First Name*
                      </Typography>
                      <TextField
                        fullWidth
                        name="firstName"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          
                          setFieldValue('firstName', e.target.value);
                        }}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        value={values.firstName}
                        inputProps={{ maxLength: 180 }}
                      />
                    </Grid>

                    <Grid item xs="12" sm="6">
                      <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                        Last Name
                      </Typography>
                      <TextField
                        fullWidth
                        name="lastName"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue('lastName', e.target.value);
                        }}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        value={values.lastName}
                        inputProps={{ maxLength: 180 }}
                      />
                    </Grid>
                    
                    <Grid item xs="12" sm="6">
                      <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                        Country*
                      </Typography>
                      <Select
                          fullWidth
                          value={values.country}// Assuming 'id' is the appropriate property for the value
                          onChange={(e) => {
                            const selectedCountry = countries.find((country) => country.description === e.target.value);
                            
                            setFieldValue('country', selectedCountry.description);
                          }}
                          sx={{
                            '.css-1gn4piy-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                              padding: '6.5px 14px',
                            },
                            borderRadius: '4px !important',
                          }}
                          helperText={touched.country && errors.country}
                          error={Boolean(touched.country && errors.country)}
                        >
                          {countries.map((country) => (
                            <MenuItem key={country.id} value={country.description}>
                              {country.description}
                            </MenuItem>
                          ))}
                        </Select>

                      <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                        {touched.country && errors.country}
                      </Typography>

                   </Grid>

                    <Grid item xs="12" sm="6">
                      <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                        Phone Number
                      </Typography>
                      <PhoneNumber fullWidth country={'us'} specialLabel={''} />
                    </Grid>
                    <Grid item xs="12" sm="6">
                      <Typography variant="subtitle1" component="div" className={classes.typoFontWeight} gutterBottom>
                        Profile Picture
                      </Typography>

                      {values.thumbPreview !== '' ? (
                        <>
                          <Box mb={0.5}>
                            <img width={'90px'} src={values.thumbPreview} alt="pic" />
                          </Box>
                          <Box mb={1}>
                            <div>
                              <input
                                accept="image/*"
                                className={classes.input}
                                id="contained-button-file"
                                type="file"
                                name="profileImg"
                                onChange={(e) => {
                                  console.log(e.target.files);
                                  if (
                                    SUPPORTED_FORMATS.includes(e.target.files[0].type)
                                    // e.target.files[0].size <= 2097152
                                  ) {
                                    setUrlImage(URL.createObjectURL(e.target.files[0]));
                                    setFieldValue('thumbPreview', URL.createObjectURL(e.target.files[0]));
                                  }
                                  setFieldValue('profileImg', e.currentTarget.files[0]);
                                }}
                              />
                              <label htmlFor="contained-button-file">
                                <Typography
                                  color="primary"
                                  component="span"
                                  sx={{ cursor: 'pointer;' }}
                                  onClick={(e) => {
                                    setShowimageo(true);
                                    setUrlImage('');
                                    setFieldValue('thumbPreview', URL.createObjectURL(e.target.files[0]));
                                    setFieldValue('profileImg', null);
                                  }}
                                >
                                  Change Profile
                                </Typography>
                                <Typography sx={{ fontSize: '0.75rem !important' }} color="red">
                                  {(errors.profileImg || touched.profileImg) && errors.profileImg}
                                </Typography>

                                {/* <FormHelperText style={{ color: '#F44336', paddingLeft: 16 }}>
                                  {errors.profileImg ? errors.profileImg : ''}
                                </FormHelperText> */}
                              </label>
                            </div>
                          </Box>
                        </>
                      ) : (
                        <>
                          {(values.thumbPreview !== null || values.thumbPreview === '') && (
                            <>
                              <div className={classes.dropzone}>
                                <FormControl
                                  required
                                  component="fieldset"
                                  color="primary"
                                  variant="outlined"
                                  type="file"
                                  fullWidth
                                  name="profileImg"
                                  value={values.profileImg}
                                >
                                  <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    dropzoneText="Drag and Drop Image or Browse File"
                                    showPreviewsInDropzone={false}
                                    maxFileSize={30000000}
                                    filesLimit={1}
                                    value={values.profileImg}
                                    showAlerts={false}
                                    onChange={(file) => {
                                      if (SUPPORTED_FORMATS.includes(file[0]?.type) && file[0]?.size <= 3000000) {
                                        const url = URL.createObjectURL(file[0]);
                                        setFieldValue('thumbPreview', url);
                                      }
                                      // setFieldValue('profileImg', file.currentTarget.files[0]);
                                      setFieldValue('profileImg', file[0]);
                                    }}
                                    previewGridClasses={{
                                      container: classes.previewContainer,
                                      item: classes.preview,
                                    }}
                                    showFileNamesInPreview
                                  />
                                </FormControl>
                              </div>
                              {
                                <FormHelperText style={{ color: '#F44336' }}>
                                  {errors.profileImg && isClickedButton ? errors.profileImg : ''}
                                </FormHelperText>
                              }
                            </>
                          )}
                          {/* {values.thumbPreview !== null && (
                            <>
                              {values.thumbPreview !== null && (
                                <>
                                  <div className={classes.imgPreviewRoot}>
                                    <Badge
                                      badgeContent={
                                        <CloseIcon
                                          onClick={() => {
                                            setFieldValue(' profileImg', null);
                                            setFieldValue('thumbPreview', null);
                                          }}
                                          className={classes.badgeAlign}
                                        />
                                      }
                                    >
                                      <Avatar
                                        variant="rounded"
                                        src={values.thumbPreview}
                                        className={values.thumbPreview !== null && classes.fileImgSIze}
                                      />
                                    </Badge>
                                    <div style={{ marginTop: 16 }}>
                                      <Link
                                        variant="contained"
                                        color="primary"
                                        component="a"
                                        href={values.thumbPreview}
                                        target="_blank"
                                      >
                                        <Typography style={{ display: 'flex' }} variant="body1" gutterBottom>
                                          <span>
                                            <OpenInNewIcon fontSize="small" />
                                          </span>
                                          <span>View Logo Image....</span>
                                        </Typography>
                                      </Link>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )} */}
                        </>
                      )}
                    </Grid>

                    <Grid align="end" item xs="12">
                      <Stack direction="row" align="end" spacing={2} sx={{ display: 'flex;', justifyContent: 'end;' }}>
                        <LoadingButton
                          size="medium"
                          type="submit"
                          variant="contained"
                          sx={{
                            color: '#fff !important',
                            background: 'gray;',
                          }}
                          onClick={() => setEditField(false)}
                        >
                          Cancel
                        </LoadingButton>
                        <LoadingButton
                          onClick={() => {
                            setIsClickedButton(true);
                          }}
                          loading={loading}
                          size="medium"
                          type="submit"
                          variant="contained"
                        >
                          Update
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <Grid container mt={0}>
              <Grid item xs={10} sm={11}>
                <Grid container spacing={2}>
                  <Grid className={classes.girdContainer} item xs="12">
                    {userInfo && userInfo.profileImg ? (
                      <Box className={classes.logo}>
                        <img width={'120px'} height={'100px'} src={userInfo && userInfo.profileImg} alt="logo" />
                      </Box>
                    ) : (
                      <Box ml={0} className={classes.logo}>
                        <Avatar alt="Remy Sharp" src={formvalue.profileImg} sx={{ width: 50, height: 50 }} />
                      </Box>
                    )}
                    <Box>
                      <Typography>{userInfo && userInfo.name}</Typography>
                      <Typography> {userInfo && userInfo.email}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2} sm={1} textAlign="end">
                <IconButton
                  onClick={() => {
                    setEditField(!editField);
                    setShowimageo(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container mt={0} spacing={2}>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle1" component="div" className={classes.typoFontWeightone} gutterBottom>
                  Phone
                </Typography>
                <Typography variant="body1" component="div">
                  {userInfo && userInfo.phone && userInfo.phone.length > 9 ? `+${userInfo.phone}` : null}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={3} sx={{ cursor: 'pointer' }} mt={3}>
                <Typography variant="subtitle1" component="div" className={classes.changePassword} gutterBottom>
                  <Link
                    onClick={() => {
                      setOpenResetModel(true);
                    }}
                    variant="subtitle2"
                    sx={{ color: '#e7443e;', textDecoration: 'unset;' }}
                  >
                    Change Password
                  </Link>
                </Typography>
              </Grid> */}
            </Grid>
          </>
        )}
      </BasicPaper>

      <ResetPasswordModal openModal={openResetModel} closeModel={() => setOpenResetModel(false)} />

      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </Page>
  );
};

const useStyles = makeStyles((theme) => ({
  girdContainer: {
    display: 'flex',
  },
  logo: {
    border: '1px solid lightgrey',
    borderRadius: '10px',
    marginRight: '3rem',
    padding: '1rem',
  },

  name: {
    fontSize: '14px',
    color: '#0C0A09',
    fontWeight: '600',
  },

  changePassword: {
    color: '#FE7000',
    letterSpacing: '0px;',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start',
    },
  },
  backgroundStyle: {
    boxShadow: '0px 3px 30px #0000000A;',
    border: '1px solid #ECECEC',
    borderRadius: '10px;',
    background: '#FFFFFF',
    padding: '1rem',
  },

  btnStyle: {
    color: '#FE7000;',
    fontSize: '1rem',
    fontWeight: '600',
    outline: '1px solid #FE7000',
  },

  fontSizeiconname: {
    letterSpacing: '0.43px;',
    color: '#6D6969;',
    marginLeft: '1rem;',
    fontSize: '0.8rem',
  },
  contanerBackground: {
    backgroundColor: '#FAFAFA',
    height: '100vh',
  },

  typoFontWeight: {
    fontSize: '1rem;',
    fontWeight: '400',
  },
  typoFontWeightone: {
    fontSize: '1rem;',
  },
  input: {
    display: 'none',
  },
  imgPreviewRoot: {
    borderRadius: '10px',
    padding: '0.8rem;',
    marginBottom: 16,
  },
  badgeAlign: {
    boxShadow: '0 2px 8px -5px #ff0000',
    color: '#FF0000',
    fontSize: '1.2rem',
    backgroundColor: '#fff',
    padding: '2px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  fileImgSIze: {
    width: '100%',
    height: '120px',
    objectFit: 'cover',
    objectPosition: 'center',
    border: '1px solid #fff',
    borderRadius: '5px',
    boxShadow: '0 3px 10px rgb(0 0 0 / 20%)',
  },
}));

export default Account;
