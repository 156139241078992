import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import PersonIcon from '@mui/icons-material/Person';
import ApprovalIcon from '@mui/icons-material/Approval';
import { useSelector, useDispatch } from 'react-redux';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import PageHeader from '../../../components/PageHeader';
import Page from '../../../components/Page';
import BasicPaper from '../../../components/cards/BasicPaper';
import YourProfileTab from './YourProfileTab';
import CompanyInfo from './CompanyInfo';
import BillingInfo from './BillingInfo';
import adminServices from '../../../services/adminServices';
import SnackBar from '../../../components/snackbar/snackbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClientAccount = () => {
  const userInfo = useSelector((state) => state.userInfo);

  const [value, setValue] = React.useState(0);
  const [clientList, setClientList] = useState({});
  const [snackbarTitle, setSnackbarTitle] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [countryCode, setCountryCode] = React.useState(null);
  const [clientLoading, setClientLoading] = useState(false);

  const editCourseDetailsByIdOne = async (values) => {
    setClientLoading(true);
    const formData = new FormData();
    formData.append('address1', values.address1);
    formData.append('address2', values.address2);
    formData.append('contactPhone', values.phone);
    formData.append('country', values.country.code !== undefined ? values.country.code : values.country);
    formData.append('state', values.state.code !== undefined ? values.state.code : values.state);
    formData.append('city', values.city);
    formData.append('zipCode', values.zipCode);
    formData.append('organizationName', values.organizationName);
    formData.append('websiteUrl', values.websiteUrl);
    formData.append('contactEmail', values.contactEmail);
    formData.append('officeNumber', values.officeNumber);
    formData.append('contactName', values.contactName);
    formData.append('dateFormat', values.dateFormat.code);
    formData.append('timeZone', values.timeZone.code);

    if (typeof values.logo === 'string') {
      formData.append('logo', null);
    } else {
      formData.append('logo', values.logo);
    }

    try {
      const response = await adminServices.editCourseDetailsByIdOne(
        userInfo?.id, formData);
      if (response.ok) {
        const data = await getClientDetailsByIdONE();
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setClientLoading(false);
        return data;
      }

      if (!response.ok) {
        setOpenSnackbar(true);
        setSnackbarTitle(response.data.message);
        setClientLoading(false);
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getClientDetailsByIdONE = async () => {
    try {
      const response = await adminServices.getCourseDetailsByIdOne(userInfo?.id);
      if (response.ok) {
        setClientList(response.data);
        console.log('response.data', response.data);
        return true;
      }
      if (!response.ok) {
        console.log('response.data', response.data);
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClientDetailsByIdONE();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Page title={'Account'}>
        <PageHeader pageTitle="Account" />

        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab iconPosition="start" icon={<PersonIcon />} label="Your Profile" {...a11yProps(0)} />
            <Tab iconPosition="start" icon={<LocationCityIcon />} label="Company Info" {...a11yProps(1)} />
            {/* <Tab iconPosition="start" icon={<PersonIcon />}
                   label="Billing Info" {...a11yProps(0)} /> */}
          </Tabs>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={3}>
            <TabPanel value={value} index={0}>
              <BasicPaper>
                <YourProfileTab />
              </BasicPaper>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CompanyInfo submit={editCourseDetailsByIdOne} clientList={clientList} loading={clientLoading} />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
              <BillingInfo />
              </TabPanel> */}
          </Grid>
        </Grid>
      </Page>
      <SnackBar open={openSnackbar} snackbarTitle={snackbarTitle} close={() => setOpenSnackbar(false)} />
    </div>
  );
};

export default ClientAccount;

const useStyles = makeStyles((theme) => ({
  divider: {
    height: '20px',
    width: '145px',
    backgroundColor: 'red',
  },
}));
